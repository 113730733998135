// @flow

import { presets, type App } from 'types';

import SubmissionsPage from './pages/Submissions';
import SubmissionsSidebar from './sidebars/Submissions';
import LeaderboardsPage from './pages/Leaderboards';
import PublishingCalendar from '../publishing/pages/PublishingCalendar/PublishingCalendar';

const app: App = {
    feature: 'content_fuel',
    path: 'photo',
    title: 'Catalyst Photo',
    mobileTitle: 'Photo',
    description: 'Capture & Curate Content',
    className: 'photo',
    browserThemeColor: '#4e280e',
    icon: 'ce',
    // marketingMessages: [
    //     'Centralized content library for all users',
    //     'QC your content before posting',
    //     'Publish content directly from platform via an RSS feed that easily integrates with all major publishing platforms',
    // ],
    // marketingMessageDisabled:
    //     'Gather, schedule, QC and publish content directly to social networks. Have one centralized place for all of your content collected by your employees.',
    disabledLink: 'http://www.friendemic.com/catalyst-photo/',
    routes: {
        submissions: {
            path: 'submissions',
            title: 'Library',
            component: SubmissionsPage,
        },
        leaderboards: {
            path: 'leaderboards',
            title: 'Leaderboards',
            preset: presets.report,
            component: LeaderboardsPage,
        },
        calendar: {
            path: 'calendar',
            title: 'Calendar',
            component: PublishingCalendar,
        },
    },
    defaultRoute: 'submissions',
    sidebars: {
        submissions: SubmissionsSidebar,
    },
};

export default app;
