// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import Resizer from 'components/Resizer';

type Props = {
    id?: string,
    title: string,
    dropdown?: React.Node,
    multiply?: number,
    children: React.Node,
    noResizer?: boolean,
};

@observer
export default class Panel extends React.Component<Props> {
    render() {
        const { id, title, dropdown, multiply, children, noResizer } = this.props;

        return (
            <React.Fragment>
                <div className="panel-header clearfix">
                    <div className="panel-title clearfix">{title}</div>

                    <div className="tools clearfix">{dropdown && <div className="tool">{dropdown}</div>}</div>
                </div>

                <div id={id} className="panel shadow clearfix">
                    {noResizer ? (
                        children
                    ) : (
                        <Resizer className="scroll-wrapper" minus={30} multiply={multiply}>
                            {children}
                        </Resizer>
                    )}
                </div>
            </React.Fragment>
        );
    }
}
