import * as React from 'react';
import DraftContent from 'models/DraftContent';
import draftContentStore from 'app/stores/draftContent/draftContentStore';

export default function useDraft() {
    const [draftState, setDraftState] = React.useState(new DraftContent());
    const [progress, setProgress] = React.useState(0);

    const updateDraft = draft => {
        setDraftState(draft);
    };

    const createDraft = async (publish = true, draftArg) => {
        const draft = draftArg || draftState;
        try {
            const responseCreate = await draftContentStore.create(draft).then(resp => {
                if (!resp.data || !resp.data.id) throw resp;
                return resp.data;
            });
            if (draft.testimonial) {
                await draftContentStore.createTestimonial(draft).then(resp => {
                    if (!resp.data || !resp.data.id) throw resp;
                });
            }
            if (draft.content_videos && draft.content_videos.length) {
                await draftContentStore
                    .createVideo(draft, progressEvent => {
                        const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(progress);
                    })
                    .then(resp => {
                        if (!resp.data || !resp.data.id) throw resp;
                    });
            }

            if (draft.content_photos && draft.content_photos.length) {
                await Promise.all(
                    draft.content_photos.map(photo =>
                        draftContentStore
                            .createPhoto(draft, photo, progressEvent => {
                                const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                                setProgress(progress);
                            })
                            .then(async response => {
                                if (!response.data || !response.data.content_story_id) throw response;
                                if (photo.customer) {
                                    photo.customer.content_story_id = draft.id;
                                    photo.customer.content_photo_id = response.data.id;
                                    await photo.customer.save();
                                }
                                return response;
                            })
                    )
                );
            }
            setProgress(0);
            publish && (await draftContentStore.publish(draft));
            return responseCreate;
        } catch (e) {
            setProgress(0);
            throw e;
        }
    };

    return { updateDraft, draft: draftState, progress, createDraft };
}
