// @flow

export function toRgba(value: string, opacity: number): string {
    // $FlowFixMe
    const [r, g, b] = value
        .replace(/ /g, '')
        .match(/\(([^)]+)\)/)[1]
        .split(',');

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
