// @flow

import Model from 'models/Model';

import { color } from 'helpers';

const icons = {
    facebook: 'facebook f',
    googleplace: 'google',
    yelp: 'yelp',
};

export default class SiteInfo extends Model {
    path = 'sites-info';

    api_enabled: ?boolean;
    api_name: ?boolean;
    disallowed_fields: string[];
    display_name: string;
    editable_fields: string[];
    external_id_example: ?string;
    external_id_name: null;
    formats: string[];
    invite_url_example: string;
    monitoring_url_example: string;
    channel: string;
    notes: ?(string[]);
    required_fields: string[];
    urls_must_match: boolean;
    color: string;

    get backgroundColor(): string {
        return color.toRgba(this.color, 0.9);
    }

    get hoverBackgroundColor(): string {
        return color.toRgba(this.color, 1);
    }

    get group(): string {
        return groups[this.channel] || 'Invite Only';
    }

    get icon(): string {
        return icons[this.channel] || 'car';
    }

    static async allByChannel(): Promise<SitesInfoByChannel> {
        const all = await SiteInfo.all();

        const byChannel = {};
        all.forEach(siteInfo => (byChannel[siteInfo.channel] = siteInfo));

        return byChannel;
    }

    static async allByGroup(): Promise<SitesInfoByGroup> {
        let all = await this.all();
        const customSite = new this().set({ channel: 'custom', display_name: 'Custom' });
        all = [...all, customSite];

        const byGroup = { 'Invite Only': [], all: [], 'Basic Response': ['Basic Response'] };
        all.forEach(siteInfo => {
            if (!byGroup[siteInfo.group]) byGroup[siteInfo.group] = [];
            byGroup[siteInfo.group].push(siteInfo);
            if (siteInfo.channel === 'facebook') byGroup['Invite Only'].push(siteInfo);
        });

        byGroup.all = all;

        return byGroup;
    }
}

export type SitesInfoByChannel = { [channel: string]: SiteInfo };

export type SitesInfoGroup =
    | 'Most Popular'
    | 'Automotive'
    | 'Other'
    | 'Monitoring Only'
    | 'all'
    | 'Invite Only'
    | 'Basic Response';
export type SitesInfoByGroup = { [group: SitesInfoGroup]: SiteInfo[] };

const groups: { [channel: string]: SitesInfoGroup } = {
    googleplace: 'Most Popular',
    facebook: 'Most Popular',
    dealerrater: 'Automotive',
    carscom: 'Automotive',
    cargurus: 'Automotive',
    yelp: 'Monitoring Only',
    carfax: 'Automotive',
    custom: 'Invite Only',
    basic_review_response: 'Basic Response',
};
