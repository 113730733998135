// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import PropTypes from 'prop-types';

type Props = {
    sidebar: string,
    children: React.Node,
};

@observer
export default class AppSidebar extends React.Component<Props> {
    static childContextTypes = {
        sidebar: PropTypes.string,
    };

    getChildContext() {
        return { sidebar: this.props.sidebar };
    }

    render() {
        return this.props.children;
    }
}
