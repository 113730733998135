// @flow

import * as React from 'react';
import moment from 'moment';

type Props = {
    title: string,
    subtitle?: string,
    name?: string,
    hideSideSection?: boolean,
    children?: React.Node,
    noBorder?: boolean,
    dates?: { startDate?: Object, endDate?: Object },
};

export default class ReportHeader extends React.Component<Props> {
    get dateString() {
        if (!this.props.dates) return '';
        const { startDate, endDate } = this.props.dates;
        if (!endDate) return 'All time';
        return `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`;
    }

    render() {
        const { name, subtitle, hideSideSection, noBorder } = this.props;

        return (
            <React.Fragment>
                {!hideSideSection && (
                    <div className="main-section">
                        <div className={`section info clearfix ${noBorder ? 'no-border' : ''}`}>
                            {name && <div className="report-name">{name}</div>}

                            <h1>{this.props.title}</h1>
                            {!subtitle && <h2>{this.dateString}</h2>}
                            {!subtitle ? null : <h2>{subtitle}</h2>}
                        </div>
                    </div>
                )}
                {hideSideSection && (
                    <div className={`section info clearfix ${noBorder ? 'no-border' : ''}`}>
                        {name && <div className="report-name">{name}</div>}

                        <h1>{this.props.title}</h1>
                        {!subtitle && <h2>{this.dateString}</h2>}
                        {!subtitle ? null : <h2>{subtitle}</h2>}
                        {this.props.children}
                    </div>
                )}
            </React.Fragment>
        );
    }
}
