// @flow

import { computed, observable } from 'mobx';
import { field } from 'app/external/form-for';
import Model from 'models/Model';
// import { api } from '@friendemic/premiere';

const DISCLAIMER =
    'This customer provided their phone number and consented to allow you to send a single text message; they provided consent without being required to as a condition of any purchase.';

export default class InvitesDispatch extends Model {
    path = 'invites/dispatch';

    @observable
    @field({ required: true })
    name: string;

    @observable
    @field({ error: 'validateEmailPhone' })
    email: string;

    @observable
    @field
    phone: string;

    @observable
    @field({ type: 'checkbox', required: true, label: DISCLAIMER })
    optedIn: boolean;

    @observable
    @field({
        type: 'select',
        options: [
            { value: 'sales', text: 'Sales' },
            { value: 'service', text: 'Service' },
        ],
        label: 'Type',
        search: false,
        required: true,
        error: 'validateInviteType',
    })
    type: string;

    @observable
    @field({
        type: 'select',
        options: [
            { value: 'english', text: 'English' },
            { value: 'french', text: 'French' },
            { value: 'spanish', text: 'Spanish' },
        ],
        label: 'Language',
        search: false,
        required: true,
        error: 'validateInviteLanguage',
    })
    language: string;

    place_uuid: string;

    @computed
    get hasEmail(): boolean {
        return !!(this.email && this.email.length);
    }

    @computed
    get hasPhone(): boolean {
        return !!(this.phone && this.phone.length);
    }

    validateEmailPhone() {
        if (!this.hasEmail && !this.hasPhone) return 'Please fill in either email or phone';
    }

    validateInviteType() {
        if (this.type !== 'sales' && this.type !== 'service') return 'Please select invitation type';
    }

    validateInviteLanguage() {
        if (this.language !== 'english' && this.language !== 'french' && this.language !== 'spanish')
            return 'Please select invitation language';
    }
}
