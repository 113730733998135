/* eslint-disable no-console */
// @flow
import { action, observable } from 'mobx';
import tokenStore from 'app/stores/token/tokenStore';
import axios, { AxiosInstance } from 'axios';
import * as Sentry from '@sentry/react';

const TOKEN_STORAGE = 'jwt';

class AxiosStore {
    @observable axiosApi: AxiosInstance = null;

    @action
    async set() {
        const token =
            tokenStore && tokenStore.jwtToken
                ? tokenStore.jwtToken
                : localStorage.getItem(TOKEN_STORAGE)
                ? localStorage.getItem(TOKEN_STORAGE)
                : '';

        const instance = axios.create({
            baseURL: process.env.REACT_APP_INVITE_V2_URL,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (token === '') {
            Sentry.captureException('No token found');
        }

        this.axiosApi = instance;
    }
}

export default new AxiosStore();
