// @flow

function decorate(target: any, key: string, descriptor: any, details: string | Object) {
    let denormalize, property;
    if (typeof details === 'object') {
        denormalize = details.denormalize;
        property = details.property;
    } else {
        property = details;
    }

    if (!target.__extract) target.__extract = {};

    if (!target.__extract[property]) {
        target.__extract[property] = [];

        target[`normalize_${property}`] = function(value) {
            if (!value) return;

            target.__extract[property].forEach(props => {
                this.set({ [props.key]: value[props.key] });
            });
        };

        target[`denormalize_${property}`] = function() {
            const response = {};

            target.__extract[property].forEach(props => {
                const thisValue = this[props.key];
                const value = props.denormalize ? props.denormalize.bind(this)(thisValue) : thisValue;
                response[props.key] = value;
            });

            return response;
        };
    }

    target.__extract[property].push({ key, denormalize });
    target[`denormalize_${key}`] = () => undefined;

    return {
        writable: true,
    };
}

export default function extractDecorator(property: string) {
    return (target: any, key: string, descriptor: any) => decorate(target, key, descriptor, property);
}
