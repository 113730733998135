// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';

import type { App } from 'types';
import { Message } from 'semantic-ui-react';

import AppIcon from 'components/App/Icon';
// import chatLogo from 'images/ci.png';
// import chatLogoWhite from 'images/ci_white.png';

type Props = {
    app: App,
    enabled: boolean,
};

@observer
export default class AppCard extends React.Component<Props, State> {
    render() {
        return this.props.enabled || this.props.app.feature === 'kenect' ? this.renderEnabled() : this.renderDisabled();
    }

    renderEnabled() {
        const { className, title, description, path } = this.props.app;
        const facesClasses = ['faces'];

        return (
            <Link
                to={path.startsWith('http') ? { pathname: path } : path}
                target={path.startsWith('http') ? '_blank' : ''}
                className={`app-card clearfix ${className || ''}`}
                style={{ marginBottom: '20px' }}
            >
                <div className={`flip ${title === 'Catalyst Publishing' && isInPast() ? 'disabled' : ''}`}>
                    <div className={facesClasses.join(' ')}>
                        <div className="face front clearfix">
                            <div className="description">
                                <div>
                                    <h3 className="description-title">{description}</h3>
                                </div>
                                <AppIcon app={this.props.app} className="main-icon" />
                            </div>
                            <h2 className="title">Launch {title}</h2>
                        </div>

                        <div className="face back vertical-center-container clearfix">
                            <div className="description vertical-center">
                                <AppIcon app={this.props.app} className="main-icon" />
                                <h2 className="title">
                                    {title.split(' ').map((part, index) => (
                                        <React.Fragment key={index}>
                                            {!!index && <br />}
                                            {part}
                                        </React.Fragment>
                                    ))}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

    renderDisabled() {
        const { className, title, description, disabledLink } = this.props.app;

        return (
            <a href={disabledLink} rel="noopener noreferrer" target="_blank">
                <div className={`app-card inactive ${className || ''}`} style={{ marginBottom: '20px' }}>
                    <div className="face clearfix">
                        <div className="description">
                            <h3 className="description-title">{description}</h3>
                            <AppIcon app={this.props.app} className="main-icon" />
                            <div>
                                <h3 className="description-sub-title">Add this app to get started</h3>
                            </div>
                        </div>
                        <h2 className="title">Explore {title}</h2>
                    </div>
                </div>
            </a>
        );
    }
}
