// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { Field, FieldGroup } from 'app/external/mobx-form-for';

import placeStore from 'stores/place';
import noContactListStore from 'stores/noContactList';

import SiteInfo, { type SitesInfoByGroup, type SitesInfoGroup } from 'models/SiteInfo';
import Place, { type ProfilesByChannel } from 'models/Place';
import Profile from 'models/Profile';
import Integration from 'models/Integration';

import { route, toast } from 'helpers';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import PlaceMonitor from 'components/Place/Monitor';
import IntegrationInformation from './partials/IntegrationInformation';
import ReviewInviteFrequency from './partials/ReviewInviteFrequency';
import NoContactList from 'reputation/pages/InviteSettings/partials/NoContactList';

type Props = {};

@observer
export default class InviteSettingsPage extends React.Component<Props> {
    @observable sitesInfoByGroup: SitesInfoByGroup;
    @observable place: ?Place;
    @observable profilesByChannel: ProfilesByChannel;
    @observable integration: Integration;

    get profiles(): Profile[] {
        // $FlowFixMe
        return Object.values(this.profilesByChannel);
    }

    get changed(): boolean {
        if (!this.place || !this.profilesByChannel) return false;
        return (
            this.place.changed ||
            this.integration.changed ||
            !!this.profiles.find(profile => profile.changed) ||
            noContactListStore.hasStaged
        );
    }

    @action
    setSitesInfoGroup(data: SitesInfoByGroup) {
        this.sitesInfoByGroup = data;
    }

    @action
    clearPlace() {
        this.place = null;
    }

    @action
    setPlace(place: Place) {
        this.place = place.clone();
    }

    @action
    setProfilesByChannel(profiles: ?ProfilesByChannel) {
        // $FlowFixMe
        if (profiles) Object.keys(profiles).forEach(channel => (profiles[channel] = profiles[channel].clone()));

        // $FlowFixMe
        this.profilesByChannel = profiles;
    }

    @action
    setIntegration(integration: ?Integration) {
        // $FlowFixMe
        this.integration = integration ? integration.clone() : null;
    }

    @action
    resetPlaceData() {
        this.clearPlace();
        this.setProfilesByChannel(null);
        this.setIntegration(null);
    }

    async loadSitesInfoByGroup() {
        this.setSitesInfoGroup(await SiteInfo.allByGroup());
    }

    async loadProfilesByChannel() {
        this.setProfilesByChannel(await placeStore.place.profilesByChannel());
    }

    async loadPlace() {
        this.setPlace(await Place.find(placeStore.place.id));
        this.place.memorizeChanges();
        await this.loadIntegration();
    }

    async loadIntegration() {
        this.setIntegration(await this.place.integration());
    }

    load = async () => {
        if (this.place) this.resetPlaceData();

        try {
            await Promise.all([
                this.loadSitesInfoByGroup(),
                this.loadProfilesByChannel(),
                this.loadPlace(),
                noContactListStore.load(),
            ]);
        } catch (error) {
            throw new Error('An error ocurred while loading settings data');
        }
    };

    handleSubmit = async (event: Event) => {
        event.preventDefault();

        const promises = [];
        let error = false;

        if (this.integration.changed) promises.push(this.integration.saveChanges().catch(() => (error = true)));

        this.profiles.forEach((profile: Profile) => {
            if (profile.changed) promises.push(profile.saveChanges().catch(() => (error = true)));
        });

        promises.push(
            noContactListStore.saveStaged().catch(e => {
                error = true;
                toast.error(
                    `An error occurred while saving ${e.response.data.value} to no Contact List data: ${e.response.data.message}`
                );
            })
        );

        promises.push(this.place.saveChanges());

        await Promise.all(promises);

        if (!error) toast.success('Settings updated');
    };

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        return (
            <Page for={{ changed: this.changed, schema: {} }} onSubmit={this.handleSubmit}>
                <Column>
                    <IntegrationInformation integration={this.integration} />
                    <SettingsPanel
                        title="Reputation Sites (3 max)"
                        tooltip='Selecting one of the review sites below will add the site as a‌n option for your customer to leave a review on when they receive a review invite via text or email. If a network does not show select "Add More Sites" below.'
                    >
                        {this.renderGroup('Most Popular')}
                        {this.renderGroup('Automotive')}
                        {this.renderGroup('Other')}
                        {this.renderGroup('Invite Only')}

                        <Button as={Link} to={route('general', 'onlineReviewSites')} size="tiny" primary>
                            <Icon name="plus" />
                            Add More Sites
                        </Button>
                    </SettingsPanel>
                </Column>

                <Column>
                    <ReviewInviteFrequency place={this.place} />
                    <NoContactList place={this.place} />
                </Column>
            </Page>
        );
    };

    renderGroup(group: SitesInfoGroup) {
        const disableUnchecked = this.profiles.filter(profile => profile.review_invite).length >= 3;
        const groupLabel = group === 'Invite Only' ? 'Custom' : group;
        const groupSitesInfo = (this.sitesInfoByGroup[group] || []).filter(siteInfo => {
            const profile = this.profilesByChannel[siteInfo.channel] || {};
            if (profile.is_custom && !profile.channel) return false;
            if (group === 'Invite Only' && profile.channel === 'facebook') return false;

            return this.profilesByChannel[siteInfo.channel];
        });
        if (!groupSitesInfo.length) return null;

        return (
            <React.Fragment>
                <h4>{groupLabel}</h4>
                <div className="field">
                    {groupSitesInfo.map(siteInfo => {
                        const profile = this.profilesByChannel[siteInfo.channel];

                        return (
                            <FieldGroup key={siteInfo.channel} for={profile}>
                                <Field
                                    name="review_invite"
                                    disabled={!profile.review_invite && disableUnchecked}
                                    label={siteInfo.display_name}
                                    url={profile.url}
                                />
                            </FieldGroup>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }
}
