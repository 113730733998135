// @flow

import { presets } from 'types';
import type { App } from 'types';

import OrganizationOverviewPage from './pages/OrganizationOverview';

const app: App = {
    feature: 'admin',
    path: 'system-admin',
    title: 'System Admin',
    mobileTitle: 'SA',
    description: 'Admin Tools',
    className: 'admin',
    browserThemeColor: '#3f3f3f',
    icon: 'first order',
    semanticIcon: true,
    role: 'system-admin',
    routes: {
        organizationOverview: {
            title: 'Organization Overview',
            path: 'organization-overview',
            component: OrganizationOverviewPage,
            preset: presets.superAdminSettings,
            notLocationDependent: true,
            notOrganizationDependent: true,
        },
    },
    defaultRoute: 'organizationOverview',
};

export default app;
