// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import placeStore from 'stores/place';

import type { App, AppRoute } from 'types';
import { route, hasRole } from 'helpers';

import AppIcon from 'components/App/Icon';
import SidebarListItem from 'components/Sidebar/ListItem';
import { isAnyLocationUpgraded, isPlaceUpgraded } from 'app/helpers/kenectUpgrade';
import userInfoStore from 'app/stores/userInfo/userInfoStore';

type Props = {
    app: App,
};

type State = {
    active: boolean,
};

@observer
export default class SettingsSidebarApp extends React.Component<Props, State> {
    static contextTypes = {
        app: PropTypes.object,
    };

    constructor(props: Props, context: Object) {
        super(props);
        this.state = { active: props.app === context.app };
    }

    handleToggle = () => {
        this.setState({ active: !this.state.active });
    };

    getAppRoutes(): AppRoute[] {
        const routes: AppRoute[] = [];
        Object.keys(this.props.app.routes).forEach(key => {
            const route = this.props.app.routes[key];
            if ((route.preset || '').indexOf('settings') !== -1) routes.push(route);
        });

        return routes;
    }

    render() {
        const { app } = this.props;

        const routes = this.getAppRoutes();
        if (!routes.length) return null;

        if (!hasRole(app.role)) return null;

        const icon = app.semanticIcon ? <Icon name={app.icon} /> : <AppIcon app={app} />;

        let hasAllowedRoutes = false;
        const content = routes.map(routeItem => {
            const path = route(app, routeItem);

            const requiresLocation = !routeItem.notLocationDependent;
            if ((requiresLocation && !placeStore.place) || !hasRole(routeItem.role)) return null;
            if (routeItem.hidden) return;

            hasAllowedRoutes = true;
            return (
                <SidebarListItem
                    key={path}
                    activeLink={path}
                    exact={routeItem.exact}
                    disabled={
                        (isAnyLocationUpgraded(placeStore, userInfoStore) && !path.includes('admin')) ||
                        isPlaceUpgraded(placeStore.place)
                    }
                >
                    <Link className="main" to={path}>
                        {routeItem.title}
                    </Link>
                </SidebarListItem>
            );
        });

        if (!hasAllowedRoutes) return null;

        return [
            <Accordion.Title
                key="title"
                active={this.state.active}
                onClick={this.handleToggle}
                className="label-title-large clearfix"
            >
                {icon}
                <span className="label-title">{app.title}</span>
                <Icon name="dropdown" className="custom" />
            </Accordion.Title>,

            <Accordion.Content key="content" active={this.state.active}>
                {content}
            </Accordion.Content>,
        ];
    }
}
