import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Media from './Media';
import InfiniteScroll from 'react-infinite-scroller';
import InlineLoader from '../InlineLoader/InlineLoader';

export default function MediaLibrarySelect({ media = [], handleMediaSelect, loadNext, hasMore }) {
    return (
        <div className="media-library-select-container">
            <InfiniteScroll
                loadMore={loadNext}
                hasMore={hasMore}
                useWindow={false}
                loader={
                    <div key={0} className="list-item">
                        <div className="main">
                            <InlineLoader />
                        </div>
                    </div>
                }
            >
                <Grid columns={3} className="media-library-select">
                    {media.map(({ src, selected, type }, index) => (
                        <Grid.Column key={`${src}-${index}`}>
                            <Media
                                src={src}
                                selected={selected}
                                type={type}
                                handleMediaSelect={handleMediaSelect}
                                index={index}
                            />
                        </Grid.Column>
                    ))}
                </Grid>
            </InfiniteScroll>
        </div>
    );
}

MediaLibrarySelect.propTypes = {
    media: PropTypes.arrayOf(PropTypes.object),
    handleMediaSelect: PropTypes.func,
    loadNext: PropTypes.func,
    hasMore: PropTypes.bool,
};
