// @flow

import { api } from '@friendemic/premiere';
import { action, computed, observable, runInAction } from 'mobx';
import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';

import User from 'models/User';
import Place from 'models/Place';
import Organization from 'models/Organization';

import type { Role } from 'types';

/**
 * Matches ERC interface FullStoryUserVars
 */
declare interface FullStoryUserVars {
    displayName: string;
    email: string;
    ulid_str: string;
    id_int?: number;
    catalyst_userRole_str?: string;
    catalyst_placeNames_strs?: string[];
    catalyst_placeIds_strs?: string[];
    catalyst_organizationId_int?: number;
    catalyst_organizationName_str?: string;
    erc_userRole_str?: string;
    erc_userRole_int?: number;
    erc_responseApproverUlid_str?: string;
    erc_responseOrganizationUlid_str?: string;
    erc_responseOrganizationName_str?: string;
}

class UserInfoStore {
    @observable user: User;
    @observable roles: { name: string, slug: Role };
    @observable organization: ?Organization;

    @observable places: Place[];

    get path(): string {
        return 'users-info';
    }

    @computed
    get placesIds(): ?(number[]) {
        return this.places.map(place => place.id);
    }

    @computed
    get roleSlug(): ?Role {
        return (this.roles || {}).slug;
    }

    @computed
    get isPlaceManager(): boolean {
        return this.roleSlug === 'place-manager';
    }

    @computed
    get isOrganizationManager(): boolean {
        return this.roleSlug === 'organization-manager';
    }

    @computed
    get isAdmin(): boolean {
        return this.roleSlug === 'system-admin';
    }

    @computed
    get isManager() {
        return this.roleSlug !== 'place-member';
    }

    @computed
    get isPlaceMember() {
        return this.roleSlug === 'place-member';
    }

    @action
    clear() {
        // $FlowFixMe
        this.user = null;

        // $FlowFixMe
        this.roles = null;

        this.organization = null;
    }

    async load() {
        const { data } = await api.http.get('user-info');

        runInAction(() => {
            this.user = new User().set(data.currentUser);
            this.organization = (data.organization || {}).id ? new Organization().set(data.organization) : null;
            this.places = data.places.map(place => new Place().set(place));
            this.roles = data.roles;
        });

        /**
         * FullStory.identify User
         * - Check if FullStory is initialized and user object is available
         *
         */
        const { currentUser, organization, roles, places } = data;
        const reducedPlaceNames = places && [...new Set(places.map(place => place.name))];
        const reducedPlaceIds = places && [...new Set(places.map(place => place.id))];
        let fullStoryUserVars: FullStoryUserVars = {
            displayName: currentUser && currentUser.name,
            email: currentUser && currentUser.email,
            ulid_str: currentUser && currentUser.ulid,
            id_int: currentUser && currentUser.id,
            catalyst_userRole_str: roles && roles.name,
            catalyst_placeNames_strs: reducedPlaceNames,
            catalyst_placeIds_strs: reducedPlaceIds,
            catalyst_organizationId_int: organization && organization.id,
            catalyst_organizationName_str: organization && organization.name,
            catalyst_is_any_user_location_upgrade_scheduled_str:
                places && places.some(place => place.status === 'SCHEDULED'),
            catalyst_is_any_user_location_upgraded_str: places && places.some(place => place.status === 'UPGRADED'),
        };
        data && currentUser && FullStory.isInitialized() && FullStory.identify(currentUser.ulid, fullStoryUserVars);

        /**
         * Sentry.setUser
         * - Check if user object is available and set user data for sentry error reporting
         *
         */
        data &&
            currentUser &&
            Sentry.setUser({
                email: currentUser.email,
                username: currentUser.name,
                id: currentUser.id,
            });
    }
}

export default new UserInfoStore();
