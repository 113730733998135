// @flow

import React from 'react';
import Page from 'components/Page';
import Column from 'components/Column';

export default class ChangeLogPage extends React.Component<*> {
    render() {
        return (
            <Page className="two-column clearfix">
                <Column>
                    <div className="ui normal-padding panel shadow">
                        <h1>All notable changes to this project will be documented in this file.</h1>

                        <p>
                            The format is based on [Keep a Changelog](http://keepachangelog.com/en/1.0.0/) and this
                            project adheres to [Semantic Versioning](http://semver.org/spec/v2.0.0.html).
                        </p>

                        <h2>
                            [Unreleased] <br />
                            [1.0.0] 2017-06-20
                        </h2>

                        <h3>Added</h3>
                        <ul className="ui list">
                            <li>New visual identity by @tylerfortune8.</li>
                            <li>Version navigation.</li>
                            <li>Links to latest released version in previous versions.</li>
                            <li>&quot;Why keep a changelog?&quot; section.</li>
                            <li>&quot;Who needs a changelog?&quot; section.</li>
                            <li>&quot;How do I make a changelog?&quot; section.</li>
                            <li>&quot;Frequently Asked Questions&quot; section.</li>
                            <li>
                                New &quot;Guiding Principles&quot; sub-section to &quot;How do I make a
                                changelog?&quot;.
                            </li>
                            <li>Simplified and Traditional Chinese translations from @tianshuo.</li>
                            <li>German translation from @mpbzh &amp; @Art4.</li>
                            <li>Italian translation from @azkidenz.</li>
                            <li>Swedish translation from @magol.</li>
                            <li>Turkish translation from @karalamalar.</li>
                            <li>French translation from @zapashcanon.</li>
                            <li>Brazilian Portugese translation from @aisamu.</li>
                            <li>Polish translation from @amielucha.</li>
                            <li>Russian translation from @aishek.</li>
                            <li>Czech translation from @h4vry.</li>
                            <li>Slovak translation from @jkostolansky.</li>
                        </ul>

                        <h3>Changed</h3>
                        <ul className="ui list">
                            <li>New visual identity by @tylerfortune8.</li>
                            <li>Version navigation.</li>
                            <li>Links to latest released version in previous versions.</li>
                            <li>&quot;Why keep a changelog?&quot; section.</li>
                            <li>&quot;Who needs a changelog?&quot; section.</li>
                        </ul>

                        <h3>Removed</h3>
                        <ul className="ui list">
                            <li>&quot;Who needs a changelog?&quot; section.</li>
                        </ul>
                    </div>
                </Column>
            </Page>
        );
    }
}
