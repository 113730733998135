// @flow

import * as React from 'react';
import ReportSection from 'components/Report/Section';
import VideoMetricsChartPartial from './VideoMetricsChartPartial';
import ViewMetric from 'models/ViewMetric';
import VideoData from 'models/VideoData';
import videoDataStore from 'video/stores/videoData';
import VideoDataTablePartial from './VideoDataTablePartial';
import InlineLoader from 'components/InlineLoader';
import { observer } from 'mobx-react';

type Props = {
    viewMetrics: ?ViewMetric,
    videoDatas: ?(VideoData[]),
};

@observer
export default class VideoMetricsPartial extends React.Component<Props> {
    render() {
        const { viewMetrics, videoDatas } = this.props;

        return (
            <React.Fragment>
                <ReportSection title="Unique Views">
                    <VideoMetricsChartPartial viewMetrics={viewMetrics} />
                </ReportSection>
                <ReportSection title="Video Data" className="clearfix">
                    {videoDataStore.loading ? (
                        <div className="no-data">
                            <InlineLoader />
                        </div>
                    ) : (
                        videoDatas && <VideoDataTablePartial videoDatas={videoDatas} />
                    )}
                </ReportSection>
            </React.Fragment>
        );
    }
}
