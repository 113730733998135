// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Accordion as SemanticAccordion } from 'semantic-ui-react';

import AccordionItem from './AccordionItem';

type Props = {
    children: React.Node,
};
type State = {
    activeIndex: number,
};

@observer
export default class Accordion extends React.Component<Props, State> {
    state = { activeIndex: -1 };
    static Item = () => null;

    handleClick = (e: Event, titleProps: Object) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    };

    render() {
        const { activeIndex } = this.state;
        const { children } = this.props;
        return (
            <SemanticAccordion {...this.props}>
                {React.Children.map(
                    children,
                    (item, index) =>
                        item && (
                            <AccordionItem
                                active={activeIndex === index}
                                index={index}
                                onClick={this.handleClick}
                                {...item.props}
                            />
                        )
                )}
            </SemanticAccordion>
        );
    }
}
