// @flow

import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Page from 'components/Page';
import Calendar from '../../components/Calender';
import ActionButton from 'components/ActionButton';
import { Link, Redirect } from 'react-router-dom';
import SocialPostFormModal from 'app/namespaces/photo/components/Submission/Modal/SocialPostFormModal';
import placeStore from 'app/stores/place/placeStore';
import useSociNetworks from 'app/hooks/useSociNetworks';
import SubmissionModal from 'app/namespaces/photo/components/Submission/Modal/SubmissionModal';
import { Button, Icon } from 'semantic-ui-react';

const PublishingCalendar = () => {
    const [refetch, setRefetch] = React.useState(false);

    const { networks, loading: loadingNetworks } = useSociNetworks();

    const actions = (
        <div>
            {networks ? (
                <SocialPostFormModal
                    placeNetworks={networks}
                    trigger={<ActionButton icon="plus" text="Create Content" />}
                    refetchEvents={setRefetch}
                />
            ) : (
                <SubmissionModal
                    trigger={<ActionButton icon="plus" text="Create Content" disabled={loadingNetworks} />}
                />
            )}
            <Link to="/photo/submission" replace>
                <ActionButton icon="arrow alternate circle left outline" text="Back to Photo" />
            </Link>
        </div>
    );

    const mobileActions = (
        <div>
            {networks ? (
                <SocialPostFormModal
                    placeNetworks={networks}
                    trigger={
                        <Button className="ui button option-btn">
                            <Icon name="plus" /> Create Content
                        </Button>
                    }
                    refetchEvents={setRefetch}
                />
            ) : (
                <SubmissionModal
                    trigger={
                        <Button className="ui button option-btn" disabled={loadingNetworks}>
                            <Icon name="plus" /> Create Content
                        </Button>
                    }
                />
            )}
            <Link to="/photo/submission" replace>
                <Button className="ui button option-btn">
                    <Icon name="arrow alternate circle left outline" />
                    Back to Photo
                </Button>
            </Link>
        </div>
    );

    return (
        <Page className="calender" actions={actions} mobileActions={mobileActions}>
            {!placeStore.place.features.soci_enabled && <Redirect to="/photo/submission" />}
            <div className="header">
                <h3>Content Calendar</h3>
                <div className="status-key">
                    <h2>Status Key</h2>
                    <div className="keys">
                        <div className="item">
                            <Icon name="calendar outline" color="grey" /> Scheduled
                        </div>
                        <div className="item">
                            <Icon name="check circle" color="green" /> Posted
                        </div>
                        <div className="item">
                            <Icon name="exclamation triangle" color="red" /> Failed
                        </div>
                    </div>
                </div>
            </div>
            <Calendar refetch={refetch} setRefetch={setRefetch} />
        </Page>
    );
};

export default PublishingCalendar;
