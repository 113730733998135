// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import reviewStore from 'reputation/stores/review';

import InlineLoader from 'components/InlineLoader';
import ReviewCard from 'reputation/components/Review/Card';

type Props = {
    ellipsisBreakpoint: number,
    inverted?: boolean,
};

@observer
export default class ReviewCardList extends React.Component<Props> {
    render() {
        const { reviews } = reviewStore;
        const { inverted, ellipsisBreakpoint } = this.props;
        if (!reviews) return null;
        const uniqueReviews = [];
        reviews.forEach(review => {
            const found = uniqueReviews.find(item => item.combine_id === review.combine_id);
            if (!found) {
                uniqueReviews.push(review);
            }
        });
        return (
            <InfiniteScroll
                initialLoad={false}
                loadMore={reviewStore.loadNext}
                hasMore={reviewStore.hasMore}
                useWindow={false}
                loader={
                    <div key={0} className="list-item">
                        <div className="main">
                            <InlineLoader inverted={inverted} />
                        </div>
                    </div>
                }
            >
                {uniqueReviews.map((review, index) => (
                    <ReviewCard
                        key={index}
                        review={review}
                        inverted={inverted}
                        ellipsisBreakpoint={ellipsisBreakpoint}
                    />
                ))}
            </InfiniteScroll>
        );
    }
}
