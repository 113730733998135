// @flow

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Divider, Modal as SemanticModal } from 'semantic-ui-react';
import { Field } from 'app/external/mobx-form-for';
// import { api } from '@friendemic/premiere';

import InviteDispatch from 'models/InviteDispatch';
import Place from 'models/Place';

import placeStore from 'stores/place';

import { toast, httpResponseErrors } from 'helpers';

import Modal from 'components/Modal';
import FormButton from 'components/FormButton';

type Props = {
    trigger: React.Node,
};

@observer
export default class SendManualInvite extends React.Component<Props> {
    @observable invite: InviteDispatch;
    @observable saving: boolean;
    @observable place: Place;

    modalAction: any;

    state = {
        isInviteEmail: false,
        isInvitePhone: false,
        sentInviteDate: '',
        resendConfirmationModalOpen: false,
    };

    @action
    resetInvite() {
        // api.base = '/invite-api';
        this.invite = new InviteDispatch();
        this.invite.place_uuid = placeStore.place.uuid;
        this.invite.language = 'english';
    }

    @action
    setPlace(place: Place) {
        this.place = place.clone();
    }

    loadPlace = async () => {
        this.setPlace(await Place.find(placeStore.place.id));
    };

    componentWillMount() {
        this.loadPlace();
        this.resetInvite();
    }

    handleConfirmationModalClose = () => {
        this.setState({
            ...this.state,
            resendConfirmationModalOpen: false,
        });
        this.modalAction.open();
    };

    handleConfirmationModalSubmit = async () => {
        this.invite.force_duplicates = true;
        await this.invite.saveChanges({}, 'realtimeInvite');
        this.resetInvite();
        toast.success('Real Time Invite sent!');
        this.setState(prevState => {
            return {
                ...prevState,
                resendConfirmationModalOpen: false,
            };
        });
    };

    handleSubmit = async () => {
        try {
            await this.invite.saveChanges({}, 'realtimeInvite');
        } catch (e) {
            const invite_duplicate_force_allowed = this.place.invite_duplicate_force_allowed;
            const invitePreviouslySend =
                httpResponseErrors(e).includes('email last sent') || httpResponseErrors(e).includes('text last sent');

            if (invitePreviouslySend && invite_duplicate_force_allowed) {
                const errorArr = httpResponseErrors(e).split(' ');
                errorArr.splice(0, 3);
                const date = errorArr.join(' ');
                this.setState(prevState => {
                    return {
                        ...prevState,
                        isInviteEmail: httpResponseErrors(e).includes('email'),
                        isInvitePhone: httpResponseErrors(e).includes('text'),
                        sentInviteDate: date,
                        resendConfirmationModalOpen: true,
                    };
                });

                return;
            } else {
                throw e;
            }
        }
        this.resetInvite();
        toast.success('Real Time Invite sent!');
    };

    render() {
        const { isInviteEmail, resendConfirmationModalOpen, sentInviteDate } = this.state;
        return (
            <React.Fragment>
                <SemanticModal className="custom" size="mini" open={resendConfirmationModalOpen}>
                    <SemanticModal.Header>Customer Already Invited</SemanticModal.Header>
                    <SemanticModal.Content>
                        <p>
                            This customer has already received {isInviteEmail ? 'an e-mail' : 'a text'} invite{' '}
                            {sentInviteDate}. Are you sure you want to proceed?
                        </p>
                    </SemanticModal.Content>
                    <SemanticModal.Actions>
                        <Button
                            type="button"
                            onClick={this.handleConfirmationModalClose}
                            size="tiny"
                            color="grey"
                            basic
                        >
                            Cancel
                        </Button>
                        <FormButton primary onClick={this.handleConfirmationModalSubmit} text="Resend Invite" />
                    </SemanticModal.Actions>
                </SemanticModal>
                <Modal.Action
                    ref={el => (this.modalAction = el)}
                    className="custom"
                    forObject={this.invite}
                    title="Send Real Time Invite"
                    icon="send"
                    action="Send"
                    onAction={this.handleSubmit}
                    trigger={this.props.trigger}
                >
                    <Field name="name" autoFocus />
                    <Field name="type" />
                    <Field name="language" />
                    <Field name="email" />
                    <Divider horizontal>Or</Divider>
                    <Field name="phone" />
                    {this.invite.hasPhone && <Field name="optedIn" />}
                </Modal.Action>
            </React.Fragment>
        );
    }
}
