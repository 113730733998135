// @flow

import { Model } from '@friendemic/premiere';

import { dateTime } from 'helpers';

export default class Review extends Model {
    path = 'reviews';

    id: number;
    combine_id: number;
    profile_id: number;
    external_id: string;
    channel: string;
    customer: string;
    rating: number;
    title: string;
    comment: string;
    responses: Object[];
    submitted_at: string;

    response_id: ?number;
    responded_at: ?string;

    elapsed: null;
    created_at: string;
    updated_at: string;
    deleted_at: ?string;
    isRespondable: ?Boolean;
    pending_response: ?number;

    normalize_rating(value: string) {
        return parseFloat(value);
    }

    get hasTitle(): boolean {
        return !!(this.title && this.title.length);
    }

    get hasNegativeRating(): boolean {
        return this.rating <= 3;
    }

    get date(): string {
        return dateTime.toDate(this.submitted_at);
    }

    get time(): string {
        return dateTime.toHour(this.submitted_at);
    }

    get respondedAtDate(): ?string {
        return this.responded_at && dateTime.toDate(this.responded_at);
    }

    get respondedAtTime(): ?string {
        return this.responded_at && dateTime.toHour(this.responded_at);
    }
}
