/* eslint-disable no-console */
// @flow

import { action, observable } from 'mobx';

import placeStore from 'stores/place';

import ReputationLeaderboard from 'models/ReputationLeaderboard';
import Filters from 'models/Filters';
import axiosStore from 'stores/axios';
import * as Sentry from '@sentry/react';

class ReputationLeaderboardStore {
    @observable reputationLeaderboard: ReputationLeaderboard[];
    @observable filters: ?Filters;
    @observable sortDirection: ?string = 'desc';
    @observable sortProperty: ?string = 'invites_sent';
    @observable searchText: ?string = '';
    @observable dateStart: ?string;
    @observable dateEnd: ?string;
    @observable currentPlace: number;

    @action
    set(reputationLeaderboard: reputationLeaderboard[]) {
        this.reputationLeaderboard = reputationLeaderboard;
    }

    get queryParams() {
        let queryParams = {};

        queryParams.order_by = this.sortProperty;
        queryParams.order = this.sortDirection;

        if (this.searchText) {
            queryParams.search = this.searchText;
        }

        if (this.filters && undefined !== this.filters.startDate) {
            queryParams.start_date = this.filters.startDate;
        }

        if (this.filters && undefined !== this.filters.endDate) {
            queryParams.end_date = this.filters.endDate;
        }

        if (this.filters && undefined !== this.filters.getFilterValue('delivery_method', 'email')) {
            queryParams.email = this.filters.getFilterValue('delivery_method', 'email');
        }

        if (this.filters && undefined !== this.filters.getFilterValue('delivery_method', 'text')) {
            queryParams.text = this.filters.getFilterValue('delivery_method', 'text');
        }

        return queryParams;
    }

    @action.bound
    search(text: string) {
        this.searchText = text;
        this.resetState();
        this.load();
    }

    @action
    resetState() {
        this.reputationLeaderboard = [];
    }

    @action.bound
    resetParams() {
        this.sortDirection = 'desc';
        this.sortProperty = 'invites_sent';
        this.searchText = '';
    }

    @action.bound
    // eslint-disable-next-line no-unused-vars
    async load(ignoreCache: ?boolean = false) {
        if (this.reputationLeaderboard) this.resetState();

        if (this.currentPlace !== placeStore.place.id) {
            this.resetParams();
        }

        // const leaderboard = await placeStore.place.raLeaderboard({
        //     ignoreCache,
        //     queryParams: this.queryParams,
        // });

        const params = `email=${this.queryParams.email}&text=${this.queryParams.text}&start_date=${this.queryParams.start_date}&end_date=${this.queryParams.end_date}&order_by=${this.queryParams.order_by}&order=${this.queryParams.order}`;

        const response = await axiosStore.axiosApi
            .get(`/analytics/${placeStore.place.id}/leaderboard?${params}`)
            .catch(error => {
                console.error(error);
                Sentry.captureException(error);
            });
        const leaderboard = await response.data;

        this.currentPlace = placeStore.place.id;
        this.set(leaderboard);
    }

    @action
    sort(property: string) {
        if (property === this.sortProperty) {
            this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
        } else {
            this.sortProperty = property;
            this.sortDirection = 'asc';
        }

        this.load(true);
    }

    @action
    async filter(filters: Filters) {
        if (this.filters) this.filters = undefined;
        this.filters = filters;
        this.resetState();
        await this.load();
    }
}

export default new ReputationLeaderboardStore();
