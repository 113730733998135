// @flow

import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import Page from 'components/Page';
import PlaceMonitor from 'components/Place/Monitor';
import ReportHeader from 'components/Report/Header';

import ScoreNumber from './partials/ScoreNumber';
import ScoreChart from './partials/ScoreChart';

import ReputationSnapshot from 'models/ReputationSnapshot';

import reputationSnapshotStore from 'reputation/stores/reputationSnapshot';

import placeStore from 'stores/place';
import Filters from 'models/Filters';
import FiltersPopup from 'components/Filters/Popup';
import FiltersBar from 'components/Filters/Bar';
import InlineLoader from 'components/InlineLoader';
import ActionButton from 'components/ActionButton';
import { Icon, Label } from 'semantic-ui-react';
import MediaQueryDesktop from 'components/MediaQuery/Desktop';

type Props = {};

@observer
export default class ReputationSnapshotPage extends React.Component<Props> {
    @observable reputationSnapshot: ?ReputationSnapshot;
    @observable filters: Filters;

    @action
    setReputationSnapshot(reputationSnapshot: ?ReputationSnapshot) {
        this.reputationSnapshot = reputationSnapshot;
    }

    @action
    setFilters(filters: Filters) {
        this.filters = filters;
    }

    load = async () => {
        this.setFilters(new Filters());

        await reputationSnapshotStore.filter(this.filters);
    };

    @action
    clearDates() {
        this.filters.resetDateRange();
    }

    handleFiltering = () => {
        reputationSnapshotStore.filter(this.filters);
    };

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    renderReportHeader() {
        return (
            <ReportHeader
                title={placeStore.place.name}
                dates={this.filters.dateRange}
                name="Reputation Snapshot"
                hideSideSection
                noBorder
            />
        );
    }

    handleFiltering = () => {
        reputationSnapshotStore.filter(this.filters);
    };

    handleReset = () => {
        this.setFilters(new Filters());
    };

    get actions(): React.Node {
        return (
            <FiltersPopup
                onChange={this.handleFiltering}
                onReset={this.handleReset}
                filters={this.filters}
                trigger={<ActionButton icon="filter" text="Filters" />}
            />
        );
    }

    get mobileFilters(): React.Node {
        return (
            <FiltersBar
                filters={this.filters}
                onClick={this.handleFiltering}
                withPopup={
                    <FiltersPopup
                        onChange={this.handleFiltering}
                        onReset={this.handleReset}
                        filters={this.filters}
                        trigger={
                            <Label className="tags-field label-btn no-icon clickable">
                                <Icon name="filter" /> Filters
                            </Label>
                        }
                    />
                }
            />
        );
    }

    renderContent = () => {
        const { reputationSnapshot, loading } = reputationSnapshotStore;
        let lastMonthReviews =
            reputationSnapshot && reputationSnapshot.last_month ? reputationSnapshot.last_month[0].new_reviews : null;

        return (
            <Page
                className="four-item-grid clearfix"
                beforeInner={this.renderReportHeader()}
                actions={this.actions}
                mobileFilters={this.mobileFilters}
            >
                {loading && (
                    <div className="no-data">
                        <InlineLoader />
                    </div>
                )}

                {!loading && reputationSnapshot && (
                    <React.Fragment>
                        <MediaQueryDesktop>
                            <FiltersBar filters={this.filters} onClick={this.handleFiltering} />
                        </MediaQueryDesktop>
                        <div className="four-items clearfix">
                            <div className="four-items-row clearfix">
                                <ScoreNumber
                                    number={+reputationSnapshot.total.avg_rating}
                                    rating
                                    title="OVERALL RATING"
                                />
                                <ScoreNumber
                                    number={reputationSnapshot.total.total_reviews}
                                    reviews={lastMonthReviews}
                                    monthly
                                    title="TOTAL REVIEWS"
                                />
                            </div>
                            <div className="four-items-row clearfix">
                                <ScoreChart data={reputationSnapshot} title="RATING BY SITE" rating />
                                <ScoreChart data={reputationSnapshot} title="REVIEW NUMBERS BY SITE" />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </Page>
        );
    };
}
