// @flow

import * as React from 'react';
import { Grid } from 'semantic-ui-react';

import Page from 'components/Page';
import Resizer from 'components/Resizer';

type Props = {};

const searchUrl = 'http://search.friendemic.com/';

export default class MenuDealershipSearchPage extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        const newWindow = window.open(searchUrl, '_blank');
        if (newWindow) newWindow.focus();
    }

    render() {
        return (
            <Page>
                <Resizer minus={30} min={700}>
                    <div className="ui centered stackable grid" style={{ height: 'inherit' }}>
                        <Grid.Column width="four" verticalAlign="middle">
                            <p>Dealership Search opened on a new tab</p>

                            <a href={searchUrl} target="_blank" className="ui button primary" rel="noopener noreferrer">
                                Reopen Delearship Search
                            </a>
                        </Grid.Column>
                    </div>
                </Resizer>
            </Page>
        );
    }
}
