// @flow

import { action, computed, observable } from 'mobx';

import placeStore from 'stores/place';
import sidebarStore from 'stores/sidebar';
import { toast } from 'helpers';

import Review from 'models/Review';
import Filters from 'models/Filters';
import { api } from '@friendemic/premiere';
import userInfoStore from 'app/stores/userInfo/userInfoStore';

class ReviewStore {
    @observable review: ?Review;
    @observable reviews: ?(Review[]);

    @observable loading: boolean = false;
    @observable reviewLoading: boolean = false;
    @observable totalReviews: number;
    @observable page: number;
    @observable sortProperty: string = 'submitted_at';
    @observable sortDirection: string = 'desc';
    @observable filters: ?Filters;
    @observable responseText: string;

    @action
    setReviews(reviews: Review[]) {
        this.reviews = reviews;
        // $FlowFixMe
        this.review = this.reviews[0];
    }

    @action
    async select(review: Review) {
        if (
            !placeStore.place.features.review_response &&
            placeStore.place.id !== 3634 &&
            placeStore.place.id !== 1357
        ) {
            this.review = review;
            sidebarStore.close();
            this.disableLoading();
        } else {
            if (userInfoStore.isPlaceManager || userInfoStore.isOrganizationManager || userInfoStore.isAdmin) {
                try {
                    this.reviewLoading = true;
                    const response = await api.http.get(`profiles/${review.profile_id}/isRespondable/${review.id}`);
                    if (response.data) {
                        this.updateReviewRespondable(response.data.respondable, review);
                    } else {
                        this.review = review;
                    }
                } catch (e) {
                    toast.error('Something went wrong');
                }
            } else {
                this.review = review;
            }
            this.setResponseText('');
            sidebarStore.close();
            this.disableLoading();
        }
    }

    @action
    disableLoading() {
        this.loading = false;
        this.reviewLoading = false;
    }

    @action
    updateReviewRespondable(flag: Boolean, review: Review) {
        this.review = review;
        this.review.isRespondable = flag;
    }

    @action
    async response(review_id: Number, response_text: string) {
        try {
            const response = await api.http.post('reviews-publish', { review_id, response_text });
            if (response.data) {
                this.updateReviewResponse();
                toast.success('Response has been submitted and will be available in 1-2 hours.');
                return true;
            } else {
                toast.error('Something went wrong');
                return false;
            }
        } catch (e) {
            toast.error('Something went wrong');
            return false;
        }
    }

    @action
    updateReviewResponse() {
        this.review = this.review;
        this.review.pending_response = 1;
        const index = this.reviews.findIndex(review => review.id === this.review.id);
        this.reviews[index].pending_response = 1;
    }

    @action
    add(review: Review) {
        // $FlowFixMe
        this.reviews.push(review);
        this.review = review;
    }

    @action
    resetState() {
        this.reviews = null;
        this.review = null;
    }

    @action.bound
    async load() {
        if (this.reviews) this.resetState();
        this.page = 0;

        await this.loadNext(true);
    }

    @action.bound
    pushReviews(reviews: Review[]) {
        if (!this.reviews) this.reviews = [];
        this.reviews = this.reviews.concat(reviews);

        if (!this.review && !!this.reviews.length) this.select(this.reviews[0]);
        else this.disableLoading();
    }

    @action
    setPage(page: number) {
        this.page = page;
    }

    @action
    setTotalReviews(total: number) {
        this.totalReviews = total;
    }

    @action
    setResponseText(responseText: string) {
        this.responseText = responseText;
    }

    @action.bound
    async loadNext(clear: ?boolean = false) {
        const { queryParams } = this;

        if (clear === true) {
            this.resetState();
        }

        const reviews = await placeStore.place.reviews({
            ignoreCache: clear === true,
            queryParams,
            callback: response => {
                const { data, current_page, total } = response.data;
                this.setPage(current_page);
                this.setTotalReviews(total);
                return { data };
            },
        });

        this.pushReviews(reviews);
    }

    get queryParams() {
        const rating = [],
            channel = [];
        let params = {};

        if (this.filters && undefined !== this.filters.startDate) {
            params.start_date = this.filters.startDate;
        }

        if (this.filters && undefined !== this.filters.endDate) {
            params.end_date = this.filters.endDate;
        }

        if (this.filters && this.filters.getFilterValue('reputation_site', 'googleplace')) {
            channel.push('googleplace');
        }

        if (this.filters && this.filters.getFilterValue('reputation_site', 'yelp')) {
            channel.push('yelp');
        }

        if (this.filters && this.filters.getFilterValue('reputation_site', 'cargurus')) {
            channel.push('cargurus');
        }

        if (this.filters && this.filters.getFilterValue('reputation_site', 'dealerrater')) {
            channel.push('dealerrater');
        }

        if (this.filters && this.filters.getFilterValue('reputation_site', 'facebook')) {
            channel.push('facebook');
        }

        if (this.filters && this.filters.getFilterValue('reputation_site', 'carscom')) {
            channel.push('carscom');
        }

        if (this.filters && this.filters.getFilterValue('reputation_site', 'carfax')) {
            channel.push('carfax');
        }

        if (this.filters && this.filters.getFilterValue('rating', 'one_star')) {
            rating.push(1);
        }

        if (this.filters && this.filters.getFilterValue('rating', 'two_stars')) {
            rating.push(2);
        }

        if (this.filters && this.filters.getFilterValue('rating', 'three_stars')) {
            rating.push(3);
        }

        if (this.filters && this.filters.getFilterValue('rating', 'four_stars')) {
            rating.push(4);
        }

        if (this.filters && this.filters.getFilterValue('rating', 'five_stars')) {
            rating.push(5);
        }

        if (channel.length) {
            params.channel = channel.join(',');
        }

        if (rating.length) {
            params.rating = rating.join(',');
        }

        if (this.filters && undefined !== this.filters.getFilterValue('response', 'with_response')) {
            params.with_response = this.filters.getFilterValue('response', 'with_response');
        }

        if (this.filters && undefined !== this.filters.getFilterValue('response', 'without_response')) {
            params.without_response = this.filters.getFilterValue('response', 'without_response');
        }

        return {
            ...params,
            page: this.page + 1,
            per_page: 25,
            order_by: this.sortProperty,
            order: this.sortDirection,
            paginated: true,
        };
    }

    @computed
    get hasMore(): boolean {
        // $FlowFixMe
        return this.reviews.length < this.totalReviews;
    }

    @action
    async filter(filters: Filters) {
        if (this.filters) this.filters = undefined;

        this.filters = filters;
        this.page = 0;
        this.loading = true;

        await this.loadNext(true);
    }

    @computed
    get sortedReviews(): ?(Review[]) {
        if (!this.reviews) return null;

        const factor = this.sortDirection === 'asc' ? 1 : -1;

        return this.reviews
            .slice()
            .slice()
            .sort((a: Review, b: Review) => {
                const valueA = a[this.sortProperty];
                const valueB = b[this.sortProperty];

                return valueA < valueB ? -factor : factor;
            });
    }

    @action
    getExportLink(filters: Filters): string {
        if (this.filters) this.filters = undefined;
        this.filters = filters;

        const params = new URLSearchParams({
            xls: '1',
            ...this.queryParams,
        });

        return `/places/${placeStore.place.id}/reviews?${params.toString()}`;
    }
}

export default new ReviewStore();
