// @flow

import { observer } from 'mobx-react';
import { action, observable, computed } from 'mobx';
import * as React from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { Field, FieldGroup } from 'app/external/mobx-form-for';
import { v4 } from 'uuid';

import oauthApi from 'api/oauthApi';

import { toast, route } from 'helpers';

import Place from 'models/Place';
import CustomLinks from 'models/VIPCustomLinks';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import PlaceMonitor from 'components/Place/Monitor';
import ConnectButton from 'components/ConnectButton';
import CustomLinksPartial from './partials/CustomLinksPartial';

import organizationStore from 'stores/organization';
import { httpResponseErrors } from 'helpers';

type Props = { history: Object };

const contactInfoHelpText = {
    user: 'The contact information displayed on the VIP will be the contact information for the user that created the video. If they would like to update this information, they can do so in ‘My Profile’ settings.',
    custom: 'This option allows you to choose a custom email address and phone number for your locations VIP contact information. If these fields are blank, contact information will not be displayed in VIP.',
    location:
        'The contact information displayed on the VIP will be the locations contact information. This can be updated in your ‘General’ settings under ‘Location Info’.',
};

@observer
export default class YoutubeSettingsPage extends React.Component<Props> {
    @observable place: Place;
    @observable places: Place[];
    @observable customLinks: CustomLinks[] = [];
    customLinksQuantity = 0;
    reply_to: string;

    @computed
    get changed(): boolean {
        if (!this.customLinks) return false;
        if (this.customLinksQuantity !== this.customLinks.length) return true;
        return !!this.customLinks.find(customLink => customLink.changed) || this.place.changed;
    }

    @action
    addCustomLink() {
        const key = v4();
        this.customLinks.push(new CustomLinks().set({ key }).memorizeChanges());
        this.customLinksQuantity = this.customLinks.length;
    }

    @action
    setCustomLinks(customLinks: CustomLinks[]) {
        if (this.customLinks.length) this.customLinks = [];
        if (customLinks.length) {
            this.customLinks = customLinks.map(links => new CustomLinks().set({ ...links }).memorizeChanges());
            this.customLinksQuantity = this.customLinks.length;
        } else {
            this.addCustomLink();
        }
    }

    @action
    handleCustomLinksDelete = (id: Number) => {
        if (this.customLinks.length === 1) {
            this.customLinks[0].set({ label: undefined, url: undefined });
        } else {
            this.customLinks = this.customLinks.filter(links => links.id !== id);
        }
    };

    handleCustomLinksAdd = () => {
        this.addCustomLink();
    };

    @action
    setPlace(place: ?Place) {
        if (place) place = place.clone();
        // $FlowFixMe
        this.place = place;

        this.setCustomLinks((this.place || {}).video_ignition_settings_custom_links || []);
        // $FlowFixMe
        this.reply_to = place ? place.reply_to_email : null;
    }

    @action
    setPlaces(places: Place[]) {
        this.places = places;
    }

    async loadPlaces() {
        this.setPlaces(await organizationStore.organization.places());
    }

    async loadPlace(place: Place) {
        this.setPlace(await Place.find(place.id));
    }

    load = async (place: Place) => {
        if (this.place) this.setPlace(null);
        await Promise.all([this.loadPlace(place), this.loadPlaces()]);
    };

    handleClick = (event: Event) => {
        event.preventDefault();
        oauthApi.auth('youtube');
    };

    getEmailPreview(place: Place) {
        return `config=${JSON.stringify({
            heading: place.video_ignition_settings_email_heading,
            body: place.video_ignition_settings_email_body,
        })}`;
    }

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    @action
    handleSubmit = async () => {
        if (this.disableFields.disabled) {
            const {
                reply_to_email,
                video_ignition_settings_email_subject,
                video_ignition_settings_email_heading,
                video_ignition_settings_email_body,
            } = this.place.originalMap;

            this.place.set({
                reply_to_email,
                video_ignition_settings_email_subject,
                video_ignition_settings_email_heading,
                video_ignition_settings_email_body,
            });
        }

        this.customLinks.forEach(link => link.memorizeChanges());

        const filteredLinks = this.customLinks.filter(link => {
            if (!!link.label && !!link.url) return link.memorizedChanges;
            return false;
        });

        this.place.set({
            video_ignition_settings_custom_links: [...filteredLinks.map(link => link.memorizedChanges)],
        });

        try {
            await this.place.saveChanges();
            toast.success('Location settings updated');
        } catch (error) {
            toast.error(httpResponseErrors(error));
        }
    };

    @action
    handleDelete = async () => {
        this.place.clearYoutube();

        this.place = this.place.clone();
    };

    get emailFieldOptions() {
        if (this.reply_to) return [{ text: this.reply_to, value: this.reply_to }];
        return [{ text: 'User e-mail', value: 'user' }];
    }

    get disableFields() {
        if (this.place.isUsingCustomEmailTemplate) {
            return {};
        }
        return { disabled: true, className: 'disabled' };
    }

    renderContent = () => {
        //console.log('allow_public_youtube_videos', this.place.allow_public_youtube_videos);
        //console.log('youtube_channel_id', this.place.youtube_channel_id);
        //console.log('hasYoutubeAuth', this.place.hasYoutubeAuth());
        return (
            <Page
                for={{ schema: this.place, changed: this.changed }}
                onSubmit={this.handleSubmit}
                history={this.props.history}
            >
                <FieldGroup for={this.place}>
                    <Column>
                        <SettingsPanel title="YouTube Authentication">
                            <Form.Field>
                                <ConnectButton
                                    network="youtube"
                                    connected={!!this.place.youtube_channel_id}
                                    connectedName={this.place.youtube_channel_name}
                                    avatar={this.place.youtube_channel_avatar}
                                    onDelete={this.handleDelete}
                                />
                            </Form.Field>
                            {this.place.hasYoutubeAuth() && <Field name="allow_public_youtube_videos" />}
                        </SettingsPanel>

                        <SettingsPanel title="Share video e-mail settings">
                            <Field name="video_ignition_settings_custom_email_template" />

                            <Message className="custom" info color="green">
                                <p>
                                    <strong>{'{user}'}</strong> - Catalyst username
                                </p>
                                <p>
                                    <strong>{'{location}'}</strong> - Location name that the video is coming from
                                </p>
                                <p>
                                    <strong>{'{recipient}'}</strong> - The person&apos;s name that you are sharing the
                                    video with
                                </p>
                            </Message>
                            <Field
                                name="reply_to_email"
                                options={this.emailFieldOptions}
                                place={this.place}
                                {...this.disableFields}
                            />
                            <Field name="video_ignition_settings_email_subject" {...this.disableFields} />
                            <Field name="video_ignition_settings_email_heading" {...this.disableFields} />
                            <Field name="video_ignition_settings_email_body" {...this.disableFields} />
                            <Button
                                href={`${route('general', 'emailPreview')}/abc?${this.getEmailPreview(this.place)}`}
                                as="a"
                                size="tiny"
                                className="custom"
                                target="_blank"
                                {...this.disableFields}
                            >
                                Preview E-mail
                            </Button>
                        </SettingsPanel>
                    </Column>
                </FieldGroup>

                <Column>
                    <FieldGroup for={this.place}>
                        <SettingsPanel title="BCC Customization">
                            <Field
                                name="bcc_email_user_customizable"
                                options={[
                                    { value: 'false', text: 'Location defined' },
                                    { value: 'true', text: 'User defined' },
                                ]}
                            />
                            {this.place.bcc_email_user_customizable === 'false' && (
                                <React.Fragment>
                                    <Field name="bcc_email_addresses" />
                                    <Message className="custom" info color="green">
                                        Leave blank for none or type and press enter.
                                    </Message>
                                </React.Fragment>
                            )}
                            {this.place.bcc_email_user_customizable === 'true' && (
                                <Message className="custom" info color="green">
                                    Each user will need to add this setting in the mobile app.
                                </Message>
                            )}
                        </SettingsPanel>
                    </FieldGroup>
                    <SettingsPanel title="Video page">
                        <FieldGroup for={this.place}>
                            <h5>contact info</h5>
                            <Field name="video_ignition_settings_contact_info_source" />
                            <Message className="custom" info color="green">
                                {contactInfoHelpText[this.place.video_ignition_settings_contact_info_source] || ''}
                            </Message>
                            {this.place.isUsingCustomContactInfo && (
                                <Form.Group widths={2}>
                                    <Field name="video_ignition_settings_phone" />
                                    <Field name="video_ignition_settings_email" />
                                </Form.Group>
                            )}
                        </FieldGroup>
                        <CustomLinksPartial
                            customLinks={this.customLinks}
                            onDelete={this.handleCustomLinksDelete}
                            onAdd={this.handleCustomLinksAdd}
                        />
                    </SettingsPanel>
                </Column>
            </Page>
        );
    };
}
