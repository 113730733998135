// @flow

import { action, observable } from 'mobx';

import placeStore from 'stores/place';
import ViewMetric from 'models/ViewMetric';

class ViewMetricStore {
    @observable viewMetrics: ?ViewMetric;

    @observable startDate: ?string;
    @observable endDate: ?string;

    @observable loading: boolean = false;

    @action
    setViewMetrics(viewMetrics: ViewMetric) {
        this.viewMetrics = viewMetrics;
    }

    @action
    resetState() {
        this.viewMetrics = null;
    }

    @action
    startLoading() {
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
    }

    get queryParams() {
        const params = {};

        if (undefined !== this.startDate) {
            params.start_date = this.startDate;
        }

        if (undefined !== this.endDate) {
            params.end_date = this.endDate;
        }

        return params;
    }

    async load(ignoreCache: boolean = false) {
        this.startLoading();

        this.setViewMetrics(
            await placeStore.place.viewMetrics({
                ignoreCache,
                queryParams: this.queryParams,
            })
        );

        this.stopLoading();
    }

    @action.bound
    searchByDate(startDate: ?string, endDate: ?string) {
        this.startDate = startDate;
        this.endDate = endDate;

        return this.load();
    }
}

export default new ViewMetricStore();
