// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

type Props = {
    sortable?: boolean,
    title: string,
    property?: string,
    render?: (data: Object) => React.Node,
};

@observer
export default class SortableColumn extends React.Component<Props> {
    render() {
        return null;
    }
}
