// @flow

import { observer } from 'mobx-react';
import React from 'react';
import SubmissionMedia from 'photo/components/Submission/Media';
import PublishedContent from 'models/PublishedContent';
import proxyS3Image from 'helpers/proxyS3Image';
import FlexVideo from 'components/FlexVideo';
import FlexImage from 'components/FlexImage';
import * as mediaType from 'enum/mediaType';
import ContentCustomer from 'models/ContentCustomer';

type Props = {
    content: PublishedContent,
};

@observer
export default class SubmissionMediaList extends React.Component<Props> {
    handleOnShare = (customer: ContentCustomer, photoId: number) => {
        const { content } = this.props;

        customer.content_story_id = content.id;
        customer.content_photo_id = photoId;

        content.setContentConstumer(customer);

        customer.save();
    };

    render() {
        const { content } = this.props;

        return (
            <div className="media-group clearfix">
                {content.content_videos.map((video, i) => (
                    <SubmissionMedia
                        key={i}
                        product={'product' === video.type}
                        locked={video.consented === 0 && 'customer' === video.type}
                        mediaType={mediaType.VIDEO}
                        mediaURL={proxyS3Image(video.s3_path)}
                    >
                        <FlexVideo key={video.id} src={proxyS3Image(video.s3_path)} />
                    </SubmissionMedia>
                ))}

                {content.content_photos.map((photo, i) => (
                    <SubmissionMedia
                        key={i}
                        product={'product' === photo.type}
                        locked={photo.consented === 0 && 'customer' === photo.type}
                        mediaType={mediaType.PHOTO}
                        customer={content.getCustomerForPhotoId(photo.id)}
                        mediaURL={proxyS3Image(photo.s3_path)}
                    >
                        <FlexImage src={proxyS3Image(photo.s3_path)} />
                    </SubmissionMedia>
                ))}
            </div>
        );
    }
}
