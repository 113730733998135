// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

type Props = {
    onClick?: Function,
    title: string,
    icon?: string,
};

@observer
export default class SortableAction extends React.Component<Props> {
    static contextTypes = {
        incrementActions: PropTypes.func,
    };

    render() {
        const { icon, title, onClick } = this.props;

        this.context.incrementActions();
        return (
            <Popup
                trigger={
                    <Button type="button" className="custom outline" circular primary icon={icon} onClick={onClick} />
                }
                content={title}
                inverted
                size="tiny"
            />
        );
    }
}
