// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import sidebarStore from 'stores/sidebar';

export type SidebarButtonRender = { active: boolean, onClick: Function };

type Props = {
    sidebar: string,
    render: SidebarButtonRender => React.Node,
};

@observer
export default class SidebarButton extends React.Component<Props> {
    get active(): boolean {
        return this.props.sidebar === sidebarStore.sidebar;
    }

    handleClick = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        sidebarStore.open(this.props.sidebar);
    };

    render() {
        return this.props.render({ active: this.active, onClick: this.handleClick });
    }
}
