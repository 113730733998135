// @flow

import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Field } from 'app/external/mobx-form-for';

import { toast } from 'helpers';

import Organization from 'models/Organization';

import organizationStore from 'stores/organization';

import Page from 'components/Page';
import Loader from 'components/Loader';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import { getErrorMessageFromSaveModelError } from 'helpers';

type Props = {};

@observer
export default class GeneralPage extends React.Component<Props> {
    @observable organization: Organization;

    @action
    setOrganization(organization: Organization) {
        if (organization) organization = organization.clone();
        this.organization = organization;
    }

    load = async () => {
        this.setOrganization(await Organization.find(organizationStore.organization.id));
    };

    handleSubmit = async (event: Event, organization: Organization) => {
        try {
            await organization.saveChanges();
            toast.success('Location info updated');
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError(error));
        }
    };

    render() {
        return <Loader onLoad={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        return (
            <Page for={this.organization} onSubmit={this.handleSubmit}>
                <Column>
                    <SettingsPanel title="Edit Organization">
                        <Field name="name" />
                    </SettingsPanel>
                    <SettingsPanel title="Footer Logo">
                        <Field name="organization_logo" />
                    </SettingsPanel>
                </Column>
            </Page>
        );
    };
}
