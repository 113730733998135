// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { Button, Divider, Icon, Tab } from 'semantic-ui-react';
import { Form } from 'app/external/mobx-form-for';
import ContentCustomer from 'models/ContentCustomer';
import { observable, action } from 'mobx';
import validateEmail from 'helpers/validateEmail';
import placeStore from 'stores/place';
import MaskedInput from 'react-maskedinput';
import formatPhoneNumber from 'helpers/formatPhoneNumber';

type Props = {
    onShare?: Function,
    customer?: ?ContentCustomer,
};

type State = {
    shared: boolean,
};

@observer
export default class SubmissionMediaToolbarShare extends React.Component<Props, State> {
    @observable customer: ContentCustomer;

    state = {
        shared: false,
    };

    constructor(props: Props) {
        super(props);

        this.customer = this.getInitialCustomer();
    }

    getInitialCustomer(): ContentCustomer {
        if (this.props.customer) return this.props.customer;

        return new ContentCustomer();
    }

    get canShare(): boolean {
        return !!(
            (this.customer.name && this.customer.email && validateEmail(this.customer.email)) ||
            (this.customer.phone && this.customer.phone.length === 10)
        );
    }

    @action
    handleNameChange = (event: Event) => {
        this.customer.name = event.target.value;
    };

    @action
    handleEmailChange = (event: Event) => {
        this.customer.email = event.target.value;
    };

    @action
    handlePhoneChange = (event: Event) => {
        this.customer.phone = event.target.value.replace(/[^\d]/g, '');
    };

    @action
    handleSendInviteChange = () => {
        this.customer.send_invite = !this.customer.send_invite;
    };

    handleOnClick = (event: Event) => {
        event.preventDefault();
        this.setState({ shared: true });
        this.props.onShare && this.props.onShare(this.customer);
    };

    renderShared() {
        return (
            <React.Fragment>
                <label>
                    <Icon name="share alternate" />
                    Shared with
                </label>
                {this.customer.name} ({this.customer.email || formatPhoneNumber(this.customer.phone)})
                {this.customer.send_invite && (
                    <div className="actions">
                        <Icon className="check circle outline" />
                        Review Invite Sent
                    </div>
                )}
            </React.Fragment>
        );
    }

    renderForm() {
        const panes = [
            {
                menuItem: { key: 'E-mail', icon: 'envelope', content: 'E-mail' },
                render: () => (
                    <Tab.Pane>
                        <label>
                            <Icon name="share alternate" />
                            Share with
                        </label>
                        <div className="ui input fluid">
                            <input type="name" onChange={this.handleNameChange} placeholder="Customer Name" />
                        </div>
                        <Divider hidden />
                        <div className="ui input fluid">
                            <input type="email" onChange={this.handleEmailChange} placeholder="Customer E-mail" />
                        </div>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: { key: 'Text', icon: 'mobile', content: 'Text' },
                render: () => (
                    <Tab.Pane>
                        <label>
                            <Icon name="share alternate" />
                            Share with
                        </label>
                        <div className="ui input fluid">
                            <input type="name" onChange={this.handleNameChange} placeholder="Customer Name" />
                        </div>
                        <Divider hidden />
                        <div className="ui input fluid">
                            <MaskedInput
                                onChange={this.handlePhoneChange}
                                mask="(111) 111 - 1111"
                                placeholder="Customer Number"
                            />
                        </div>
                    </Tab.Pane>
                ),
            },
        ];

        return (
            <Form for={this.customer}>
                <Tab panes={panes} />
                <div className="actions">
                    <Button size="tiny" type="submit" onClick={this.handleOnClick} disabled={!this.canShare}>
                        Share
                    </Button>
                    {placeStore.place.features && placeStore.place.features.reputation_accelerator && (
                        <div className="ui toggle checkbox clearfix">
                            <input
                                id="send_invite"
                                type="checkbox"
                                name="send_invite"
                                className="hidden"
                                checked={!!this.customer.send_invite}
                                onChange={this.handleSendInviteChange}
                            />
                            <label htmlFor="send_invite">Send Review Invite</label>
                        </div>
                    )}
                </div>
            </Form>
        );
    }

    render() {
        return (
            <div className="color-popup-wrapper clearfix">
                {this.state.shared || !!this.props.customer ? this.renderShared() : this.renderForm()}
            </div>
        );
    }
}
