import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Preview from './Preview';

export default function MediaPreview({ media, label, onDelete, onEdit }) {
    const [currentMedia, setCurrentMedia] = React.useState(0);

    React.useEffect(() => {
        if (media) {
            if (media.length <= currentMedia) {
                setCurrentMedia(currentMedia - 1);
            }
            if (currentMedia === -1 && media.length > 0) {
                setCurrentMedia(0);
            }
        }
    }, [media, currentMedia]);

    return (
        <div>
            <label>{typeof label === 'string' ? label : label(currentMedia)}</label>
            <div className="social-post-media">
                {media[currentMedia] ? (
                    <Preview src={media[currentMedia].src} type={media[currentMedia].type} />
                ) : (
                    <div className="social-post-media-overlay">Please select from the library to preview.</div>
                )}

                {currentMedia > 0 && (
                    <Button
                        circular
                        icon="chevron left"
                        onClick={() => setCurrentMedia(currentMedia - 1)}
                        style={{
                            top: '12px',
                            left: '12px',
                        }}
                    />
                )}
                {currentMedia < media.length - 1 && media.length !== 1 && (
                    <Button
                        circular
                        icon="chevron right"
                        onClick={() => setCurrentMedia(currentMedia + 1)}
                        style={{
                            top: '12px',
                            right: '12px',
                        }}
                    />
                )}
                {onDelete && media.length > 0 && (
                    <Button
                        circular
                        onClick={() => onDelete(currentMedia)}
                        style={{
                            bottom: '12px',
                            right: '12px',
                        }}
                    >
                        <Icon name="trash alternate" />
                        Delete
                    </Button>
                )}
                {onEdit && (
                    <Button
                        circular
                        onClick={onEdit}
                        style={{
                            bottom: '12px',
                            right: '12px',
                        }}
                    >
                        <Icon name="edit outline" />
                        Edit
                    </Button>
                )}
            </div>
        </div>
    );
}

MediaPreview.propTypes = {
    media: PropTypes.array,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};
