// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import placeStore from 'stores/place';

import indexedFeatureApps from 'indexedFeatureApps';
import type { App } from 'types';

type Props = {
    render: (apps: App[]) => React.Node,
};

@observer
export default class AppArrayAvailable extends React.Component<Props> {
    render() {
        let features = [];
        if (placeStore.place) features = placeStore.place.features;

        // $FlowFixMe
        const availableApps = Object.values(indexedFeatureApps).filter((app: App) => features[app.feature]);
        return this.props.render(availableApps, features);
    }
}
