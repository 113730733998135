// @flow

import { action, observable } from 'mobx';

import placeStore from 'stores/place';

import ReputationSnapshot from 'models/ReputationSnapshot';
import Filters from 'models/Filters';

class ReputationSnapshotStore {
    @observable reputationSnapshot: ?ReputationSnapshot;
    @observable filters: ?Filters;
    @observable loading: boolean = false;

    @action
    setReputationSnapshot(reputationSnapshot: ReputationSnapshot) {
        this.reputationSnapshot = reputationSnapshot;
    }

    @action
    resetState() {
        this.reputationSnapshot = null;
    }

    @action
    startLoading() {
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
    }

    get queryParams() {
        const queryParams = {};

        if (this.filters && undefined !== this.filters.startDate) {
            queryParams.start_date = this.filters.startDate;
        }

        if (this.filters && undefined !== this.filters.endDate) {
            queryParams.end_date = this.filters.endDate;
        }

        return queryParams;
    }

    async load(ignoreCache: boolean = false) {
        this.startLoading();

        if (this.reputationSnapshot) this.resetState();

        this.setReputationSnapshot(
            await placeStore.place.reputationSnapshot({
                ignoreCache,
                queryParams: this.queryParams,
            })
        );

        this.stopLoading();
    }

    @action
    async filter(filters: Filters) {
        if (this.filters) this.filters = undefined;
        this.filters = filters;
        this.resetState();
        await this.load();
    }
}

export default new ReputationSnapshotStore();
