// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import type { App } from 'types';

type Props = {
    app: App,
    route: string,
};

@observer
export default class AppRoute extends React.Component<Props> {
    static childContextTypes = {
        app: PropTypes.object,
        route: PropTypes.string,
    };

    getChildContext() {
        const { app, route } = this.props;
        return { app, route };
    }

    render() {
        const { ...props } = this.props;
        return <Route {...props} />;
    }
}
