// @flow

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import placeStore from 'stores/place';

import ActionButton from 'components/ActionButton';
import ActionButtonClose from 'components/ActionButton/Close';
import OmniSearch from 'components/Omni/Search';
import MediaQueryDesktop from 'components/MediaQuery/Desktop';

import HeaderMain from './Main';
import HeaderUser from './User';
import HeaderLocation from './Location';

type Props = {
    title?: string,
    mobileTitle?: string,
    actions?: React.Node,
    saveable: boolean,
    saving?: boolean,
    disableSave?: boolean,
    closeable: ?(string | boolean),
    basic?: boolean,
    hideLocationSelection?: boolean,
    onSearch?: Function,
    onSearchSelect?: Function,
};

@observer
export default class Header extends React.Component<Props> {
    @observable openedMobile: boolean = false;

    @action.bound
    handleToggleSearchOnMobile(active: boolean = false): void {
        this.openedMobile = active;
    }

    render() {
        const {
            title,
            mobileTitle,
            actions,
            saveable,
            disableSave,
            closeable,
            saving,
            basic,
            hideLocationSelection,
            onSearch,
            onSearchSelect,
        } = this.props;

        const hasActions = actions || saveable || closeable;
        const hasSubBar = !basic && (hasActions || placeStore.place);
        const openedMobileClass = this.openedMobile ? 'opened-mobile' : '';

        return (
            <header id="header" className={`clearfix ${openedMobileClass}`}>
                <div id="main-bar" className="header-container">
                    <HeaderMain title={title} mobileTitle={mobileTitle} basic={basic} />
                    <OmniSearch onSearch={onSearch} onSelect={onSearchSelect} />
                    <HeaderUser
                        basic={basic}
                        onToggleSearchOnMobile={this.handleToggleSearchOnMobile}
                        searchable={!!onSearch}
                    />
                </div>

                {hasSubBar && (
                    <div id="sub-bar" className="header-container">
                        {placeStore.place && !hideLocationSelection ? <HeaderLocation /> : <div />}

                        {(saveable || actions || closeable) && (
                            <MediaQueryDesktop>
                                <div id="sub-bar-actions">
                                    <div id="options">
                                        {saveable && (
                                            <React.Fragment>
                                                <ActionButton
                                                    type="submit"
                                                    icon="save"
                                                    text="Save"
                                                    saving={saving}
                                                    disabled={disableSave}
                                                />
                                            </React.Fragment>
                                        )}
                                        {actions}
                                        {closeable && <ActionButtonClose text={closeable} />}
                                    </div>
                                </div>
                            </MediaQueryDesktop>
                        )}
                    </div>
                )}
            </header>
        );
    }
}
