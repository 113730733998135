// @flow

import * as React from 'react';

import InputField, { type Props as ComponentProps } from 'fields/Input';

export type Props = ComponentProps & {
    accept?: string,
};

export default class FileInput extends React.Component<Props> {
    render() {
        const { accept, name, value, onChange, ...rest } = this.props;

        return (
            <InputField {...{ name, value, onChange, ...rest }}>
                <input name={name} type="file" accept={accept} onChange={onChange} />
            </InputField>
        );
    }
}
