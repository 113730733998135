// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';
import { FieldGroup } from 'app/external/form-for';
import FilterGroup from 'models/FilterGroup';
import { Field } from 'app/external/form-for';

type Props = ComponentProps & {
    group: FilterGroup,
};

type State = {
    expanded: boolean,
};

@observer
export default class TagGroupField extends React.Component<Props, State> {
    input: HTMLElement;
    state: {
        expanded: false,
    };

    onChange = (filter: string) => {
        this.props.group.setFilterValue(filter, false);
    };

    onExpandClick = () => {
        this.setState({ expanded: true });
    };

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.group.allChecked && this.state && this.state.expanded) {
            this.setState({ expanded: false });
        }
    }

    get schema() {
        const { group, addedAction } = this.props;
        const schema = {};

        if (!this.expanded) {
            schema[group.name] = {
                type: 'tags',
                addedAction: this.onExpandClick,
                label: group.alias,
                icon: 'clone',
            };

            return schema;
        }

        group.filters.forEach(filter => {
            schema[filter.name] = {
                type: 'tags',
                addedAction: addedAction,
                label: filter.label,
                disableDelete: !group.canRemoveFilter,
            };
        });

        return schema;
    }

    get for() {
        const { group } = this.props;

        if (!this.expanded) {
            const obj = {};

            obj[group.name] = true;

            return obj;
        }

        return this.props.group.toObject();
    }

    get expanded() {
        return !this.props.group.allChecked || (this.state && this.state.expanded);
    }

    render() {
        const { group } = this.props;

        return (
            <FieldGroup for={this.for} schema={this.schema}>
                {this.expanded &&
                    group.filters.map((filter, key) => (
                        <Field
                            key={key}
                            name={filter.name}
                            label={filter.label}
                            onChange={() => this.onChange(filter.name)}
                        />
                    ))}

                {!this.expanded && <Field name={group.name} label={group.alias} />}
            </FieldGroup>
        );
    }
}
