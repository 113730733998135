// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Form, Input, TextArea } from 'semantic-ui-react';
import { humanized, uniqueId } from 'app/external/form-for-component-helpers';
import type { ComponentProps } from 'app/external/form-for';

import ErrorLabel from 'components/ErrorLabel';

export type Props = ComponentProps & {
    label?: string,
    placeholder?: string,
    required?: boolean,
    fieldLabel?: string,
    fieldLabelPosition?: string,
    textArea?: boolean,
    maxLength?: number,
    minLength?: number,
    fileInput?: boolean,
};

const passwordPlaceholder = '';

@observer
export default class InputField extends React.Component<Props> {
    input: ?Input;

    handleRef = (input: Input) => {
        if (input) this.input = input.inputRef;
    };

    handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.props.onChange(event);
    };

    componentDidMount() {
        this.props.onMount(this.input);
    }

    render() {
        let {
            fileInput,
            maxLength,
            minLength,
            textArea,
            error,
            touched,
            label,
            placeholder,
            fieldLabel,
            fieldLabelPosition,
            ...rest
        } = this.props;
        delete rest.onMount;
        delete rest.onChange;
        if (fileInput) delete rest.value;

        const id = uniqueId(this);
        const humanizedName = humanized(this);

        label = label || humanizedName;

        if (this.props.type === 'password') placeholder = passwordPlaceholder;
        else placeholder = placeholder || label;

        return (
            <Form.Field error={touched && !!error} required={this.props.required}>
                <label htmlFor={id}>{label}</label>

                {!textArea && (
                    <Input
                        id={id}
                        ref={this.handleRef}
                        {...rest}
                        placeholder={placeholder}
                        onChange={this.handleChange}
                        label={fieldLabel}
                        labelPosition={fieldLabelPosition}
                    />
                )}

                {textArea && (
                    <TextArea
                        id={id}
                        ref={this.handleRef}
                        {...rest}
                        placeholder={placeholder}
                        onChange={this.handleChange}
                        label={fieldLabel}
                        maxLength={maxLength}
                        minLength={minLength}
                    />
                )}
                {maxLength && minLength && (
                    <div>{`Message size must be between ${minLength} and ${maxLength} characters`}</div>
                )}
                <ErrorLabel error={error} touched={touched} />
            </Form.Field>
        );
    }
}
