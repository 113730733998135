// @flow

import { observable } from 'mobx';
import { field } from 'app/external/form-for';
import Model from 'models/Model';

import Place from 'models/Place';
import Group from 'models/Group';
import User from 'models/User';

import type { Features } from 'types';

export default class Organization extends Model {
    path = 'organizations';
    usersMeta: ?{ page: number, last_page: number };

    id: number;

    @observable
    @field({ required: true, label: 'Organization Name:' })
    name: string;

    @observable features: ?Features;
    @observable settings: ?Object;

    legacy_uuid: ?string;
    created_at: string;
    updated_at: string;
    deleted_at: ?string;

    @observable
    @field({ type: 'image', width: 220, height: 40, label: 'Logo' })
    organization_logo: string;

    places(): Promise<Place[]> {
        return this.hasMany(Place);
    }

    groups(): Promise<Group[]> {
        return this.hasMany(Group);
    }

    users(options: Object = {}): Promise<User[]> {
        if (!options.callback) options.callback = response => response.data;
        return this.hasMany(User, options);
    }
}
