// @flow

import { type App } from 'types';

const app: App = {
    feature: 'soci_enabled',
    path: '',
    title: 'Managed Publishing',
    mobileTitle: 'MP',
    description: 'Post to Social Media',
    className: 'managedPublishing',
    browserThemeColor: '#673999',
    icon: 'rpm',
    routes: {},
    defaultRoute: '',
    // marketingMessageDisabled:
    //     'COMING SOON: Reach out to your Friendemic Client Success Representatives to join the pilot program!',
    disabledLink: 'http://www.friendemic.com/catalyst-publishing/',
};

export default app;
