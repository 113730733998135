import { observable } from 'mobx';
import { field } from 'app/external/form-for';

import Model from 'models/Model';

export default class Contact extends Model {
    path = 'contact';

    @observable
    @field
    contact_message;
    @observable
    @field
    contact_subject;
}
