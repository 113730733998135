// @flow

import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Icon } from 'semantic-ui-react';
import queryString from 'query-string';

import Place from 'models/Place';

import { route } from 'helpers';

import PlaceMonitor from 'components/Place/Monitor';

import thumbnail_placeholder from 'images/video_thumbnail_placeholder.gif';
import logo_placeholder from 'images/logo_placeholder.png';

type Props = {
    location: Object,
};

@observer
export default class EmailPreviewPage extends React.Component<Props> {
    @observable place: Place;

    @action
    setPlace(place: ?Place) {
        if (place) place = place.clone();
        // $FlowFixMe
        this.place = place;
    }

    load = async (place: Place) => {
        this.setPlace(await Place.find(place.id));
    };

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        // $FlowFixMe
        const { body, heading } = JSON.parse(queryString.parse(this.props.location.search)['config'] || '{}');

        return (
            <div className="EmailPreviewPage email-preview-page">
                <div className="main-wrapper">
                    <Button
                        href={route('video', 'youtubeSettings')}
                        icon
                        labelPosition="left"
                        className="custom back-button"
                    >
                        Back to Settings
                        <Icon name="left arrow" />
                    </Button>
                    <div className="body-wrapper">
                        <div className="email-section header">
                            <div className="logo flex-image">
                                <img src={this.place.logo_url || logo_placeholder} alt="logo" />
                            </div>
                            <div className="title clearfix">
                                <h2>Connect with</h2>
                                <h1>{this.place.name}</h1>
                            </div>
                        </div>
                        <div className="email-section">
                            <h3>{heading}</h3>
                            <p>{body}</p>
                            <div className="flex-image">
                                <img src={thumbnail_placeholder} alt="placeholder" />
                                <div className="long-button btn">Play Video</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
