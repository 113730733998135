// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Form } from 'semantic-ui-react';
import MaskedInput from 'react-maskedinput';
import { humanized, uniqueId } from 'app/external/form-for-component-helpers';
import type { ComponentProps } from 'app/external/form-for';

import ErrorLabel from 'components/ErrorLabel';

export type Props = ComponentProps & {
    label?: string,
    mask: string,
    placeholder: string,
    required?: boolean,
};

@observer
export default class MaskedField extends React.Component<Props> {
    input: ?HTMLInputElement;

    handleRef = (input: HTMLInputElement) => {
        this.input = input;
    };

    componentDidMount() {
        this.props.onMount(this.input);
    }

    get classes() {
        const { disabled } = this.props;
        const classes = ['ui', 'input'];

        if (disabled) classes.push('disabled');

        return classes.join(' ');
    }

    render() {
        const { error, touched, label, ...rest } = this.props;
        delete rest.onMount;

        const id = uniqueId(this);
        const humanizedName = humanized(this);

        return (
            <Form.Field error={touched && !!error} required={this.props.required}>
                <label htmlFor={id}>{label || humanizedName}</label>
                <div className={this.classes}>
                    <MaskedInput id={id} {...rest} type="text" />
                </div>
                <ErrorLabel error={error} touched={touched} />
            </Form.Field>
        );
    }
}
