// @flow
import * as React from 'react';
import { api } from '@friendemic/premiere';
import { observer } from 'mobx-react';

type Props = {
    link: string,
    filename: string,
    children: Function,
};

type State = {
    loading: boolean,
};

@observer
export default class TriggerDownload extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { loading: false };
    }

    handleClick = (event: Event) => {
        event.preventDefault();
        this.setState({ loading: true });
        api.http
            .request({
                url: this.props.link,
                method: 'get',
                responseType: 'blob',
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', this.props.filename);

                window.document.body.appendChild(link);

                link.click();
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        return this.props.children(this.handleClick, this.state.loading);
    }
}
