// @flow

import queryString from 'query-string';

export function push(history: Object, path: string) {
    history.push(path);
}

export function queryParams({ location, history }: Object, params: Object) {
    const newParams = { ...queryString.parse(location.search), ...params };
    push(history, `${location.pathname}?${queryString.stringify(newParams)}`);
}

export function removeQueryParams(history: Object) {
    const { location } = history;
    history.push(location.pathname);
}
