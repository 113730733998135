// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Field } from 'app/external/mobx-form-for';

import { toast } from 'helpers';

import Organization from 'models/Organization';

import Sortable from 'components/Sortable';
import Modal from 'components/Modal';
import { getErrorMessageFromSaveModelError } from 'helpers';

type Props = {
    organization: Organization,
};

@observer
export default class OrganizationEditPartial extends React.Component<Props> {
    @observable organization: Organization;

    @action
    updateOrganization() {
        // $FlowFixMe
        Object.assign(this.props.organization, this.organization);
    }

    componentWillMount() {
        this.organization = this.props.organization.clone();
        this.organization.memorizeChanges();
    }

    handleSave = async () => {
        try {
            await this.organization.saveChanges();
            this.updateOrganization();
            toast.success(`Organization ${this.organization.name} updated`);
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError(error));
        }
    };

    render() {
        return (
            <Modal.Action
                className="custom"
                forObject={this.organization}
                title="Edit Organization"
                size="tiny"
                action="Save"
                onAction={this.handleSave}
                trigger={<Sortable.Action title="Edit organization" icon="setting" />}
            >
                <Field name="name" autoFocus />
            </Modal.Action>
        );
    }
}
