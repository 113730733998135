// @flow

import React from 'react';
import { Form as SemanticForm, Icon } from 'semantic-ui-react';
import { FieldGroup } from 'app/external/mobx-form-for';

import Integration from 'models/Integration';
import SettingsPanel from 'components/Settings/Panel';

type Props = {
    integration: Integration,
};

const IntegrationInformation = ({ integration }: Props) => (
    <FieldGroup for={integration}>
        <SettingsPanel title="Integration information">
            <div className="field">
                <label>Integration ID: {integration.external_id} </label>
                <div>
                    {integration.hasExternalId
                        ? ''
                        : 'This location does not have an integration ID, please contact support.'}
                </div>
            </div>

            {integration.hasExternalId && (
                <React.Fragment>
                    <SemanticForm.Field>
                        <div className="field switch labeled clearfix">
                            <Icon name={integration.sales ? 'check' : 'delete'} />{' '}
                            {`${integration.sales ? 'Sending' : 'Not sending'} Invites for Sales Transactions`}
                        </div>
                        <div className="field switch labeled clearfix">
                            <Icon name={integration.service ? 'check' : 'delete'} />{' '}
                            {`${integration.service ? 'Sending' : 'Not sending'} Invites for Service Transactions`}
                        </div>
                    </SemanticForm.Field>
                    <SemanticForm.Field>
                        {integration.sales && (
                            <div className="field switch labeled clearfix">
                                {`Sending Sales Invites are being delayed by: ${integration.sales_delay} Days `}
                            </div>
                        )}
                        {integration.service && (
                            <div className="field switch labeled clearfix">
                                {`Sending Service Invites are being delayed by: ${integration.service_delay} Days `}
                            </div>
                        )}
                    </SemanticForm.Field>
                </React.Fragment>
            )}
        </SettingsPanel>
    </FieldGroup>
);

export default IntegrationInformation;
