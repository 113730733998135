// @flow

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import Place from 'models/Place';

import placeStore from 'stores/place';
import videoStore from 'video/stores/video';

import Page from 'components/Page';
import PlaceMonitor from 'components/Place/Monitor';
import Column from 'components/Column';
import MediaQueryDesktop from 'components/MediaQuery/Desktop';
import MediaQueryMobile from 'components/MediaQuery/Mobile';
import Panel from 'components/Panel';
import ActionButton from 'components/ActionButton';
import InlineLoader from 'components/InlineLoader';

import VideoPlayer from 'video/components/Video/Player';

import SelectedVideoPartial from './partials/SelectedVideoPartial';
import VideoInfoPartial from './partials/VideoInfoPartial';
import VideosPartial from './partials/VideosPartial';
import UploadVideoPartial from './partials/UploadVideoPartial';
import ResumeTusUpload from './ResumeUpload';

type Props = {};

@observer
export default class VideosPage extends React.Component<Props> {
    @observable place: Place;

    constructor(props: Props) {
        super(props);
        this.state = { openUploadPartial: false, type: '' };
        videoStore.resetState();
    }

    @action
    setPlace(place: ?Place) {
        // $FlowFixMe
        this.place = place;
    }

    async loadPlace() {
        this.setPlace(await Place.find(placeStore.place.id));
    }

    load = async () => {
        if (this.place) this.setPlace(null);

        videoStore.resetState();
        await Promise.all([videoStore.loadNext(), this.loadPlace()]);
    };

    handleSearch = (value: string) => {
        videoStore.search(value);
    };

    get actions(): React.Node {
        return (
            <UploadVideoPartial
                openUploadPartial={this.state.openUploadPartial}
                type={this.state.type}
                closeModal={() => this.setState({ openUploadPartial: false })}
                trigger={
                    <div>
                        <ActionButton
                            onClick={() => this.setState({ openUploadPartial: true, type: 'upload' })}
                            icon="cloud upload"
                            text="Upload File"
                        />
                        <ActionButton
                            onClick={() => this.setState({ openUploadPartial: true, type: 'video' })}
                            icon="camera"
                            text="Record Video"
                        />
                        <ActionButton
                            onClick={() => this.setState({ openUploadPartial: true, type: 'screen' })}
                            icon="record"
                            text="Record Screen"
                        />
                    </div>
                }
            />
        );
    }

    get mobileActions(): React.Node {
        return (
            <UploadVideoPartial
                openUploadPartial={this.state.openUploadPartial}
                type={this.state.type}
                closeModal={() => this.setState({ openUploadPartial: false })}
                trigger={
                    <div>
                        <Button
                            className="option-btn"
                            onClick={() => this.setState({ openUploadPartial: true, type: 'upload' })}
                        >
                            <Icon name="cloud upload" /> Upload File
                        </Button>
                        <Button
                            className="option-btn"
                            onClick={() => this.setState({ openUploadPartial: true, type: 'video' })}
                        >
                            <Icon name="camera" /> Record Video
                        </Button>
                    </div>
                }
            />
        );
    }

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        const video = videoStore.video;

        return (
            <Page
                className="three-column-middle-flex clearfix"
                actions={this.actions}
                mobileActions={this.mobileActions}
                onSearch={this.handleSearch}
            >
                {!video && !videoStore.loading && 'No videos available'}

                {!video && videoStore.loading && <InlineLoader />}

                {video && <ResumeTusUpload />}

                {video && (
                    <React.Fragment>
                        <MediaQueryDesktop>
                            <Column position="left">
                                <VideosPartial />
                            </Column>
                        </MediaQueryDesktop>

                        <Column position="middle">
                            <MediaQueryMobile>
                                <SelectedVideoPartial />
                            </MediaQueryMobile>

                            <div id="main-video-container">
                                <Panel title="Video" position="middle">
                                    {video && (
                                        <div id="main-video" className="video-wrap" style={{ height: '100%' }}>
                                            <VideoPlayer />
                                        </div>
                                    )}

                                    {!video && 'No videos available'}
                                </Panel>
                            </div>
                        </Column>

                        <Column position="right">
                            <VideoInfoPartial />
                        </Column>
                    </React.Fragment>
                )}
            </Page>
        );
    };
}
