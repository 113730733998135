// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import placeStore from 'stores/place';

import Place from 'models/Place';

import Loader from 'components/Loader';

type Props = {
    onChange: (place: Place) => Promise<*>,
    render: () => React.Node,
};

@observer
export default class PlaceMonitor extends React.Component<Props> {
    handleLoad = () => {
        return this.props.onChange(placeStore.place);
    };

    render() {
        const { render } = this.props;
        return <Loader key={placeStore.place.id} onLoad={this.handleLoad} render={render} />;
    }
}
