// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

type Props = {
    className?: string,
    aligned?: boolean,
    position?: string,
    children: React.Node,
};

@observer
export default class Column extends React.Component<Props> {
    render() {
        const { className } = this.props;

        const classes = ['panel-wrapper column clearfix'];

        if (className) classes.push(className);

        const { aligned, position } = this.props;
        if (aligned) classes.push('aligned');
        if (position) classes.push(`${position}-column`);

        return <div className={classes.join(' ')}>{this.props.children}</div>;
    }
}
