// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Form, Checkbox, Icon } from 'semantic-ui-react';
import { humanized, uniqueId } from 'app/external/form-for-component-helpers';
import type { ComponentProps } from 'app/external/form-for';

import ErrorLabel from 'components/ErrorLabel';

export type Props = ComponentProps & {
    label?: string,
    required?: boolean,
    verbose?: boolean,
    className?: string,
    url?: string,
};

@observer
export default class InputField extends React.Component<Props> {
    input: ?HTMLInputElement;

    handleRef = (input: ?HTMLInputElement) => {
        if (input) this.input = input;
    };

    handleChange = (event: SyntheticEvent<HTMLInputElement>, meta: { checked: boolean }) => {
        const value = this.props.verbose ? meta.checked.toString() : meta.checked;
        this.props.onChange(event, value);
    };

    handleHiddenFocus = () => {
        const checkbox = document.getElementById(uniqueId(this));
        if (checkbox) checkbox.focus();
    };

    componentDidMount() {
        this.props.onMount(this.input);
    }

    render() {
        let { error, touched, label, value, required, verbose, className, url, ...rest } = this.props;
        delete rest.type;
        delete rest.onMount;

        const id = uniqueId(this);
        const humanizedName = humanized(this);

        label = label || humanizedName;

        let checked;

        if (typeof value === 'string' && verbose) {
            checked = value === 'true';
        } else {
            checked = !!value;
        }

        return (
            <Form.Field
                className={className}
                error={touched && !!error}
                required={required}
                style={{ position: 'relative' }}
            >
                {!checked && required && (
                    <input
                        ref={this.handleRef}
                        type="text"
                        style={{ opacity: '0', height: 0, padding: 0, position: 'absolute', bottom: 0 }}
                        tabIndex="-1"
                        onFocus={this.handleHiddenFocus}
                        required
                    />
                )}

                <Checkbox
                    id={id}
                    label={label}
                    {...rest}
                    onChange={this.handleChange}
                    checked={checked}
                    value={checked ? '1' : '0'}
                />
                {!!url && (
                    <a style={{ float: 'right' }} className="clearfix" href={url} target={'_blank'}>
                        <div className="external">
                            Preview Link <Icon name="external" />
                        </div>
                    </a>
                )}
                <ErrorLabel error={error} touched={touched} />
            </Form.Field>
        );
    }
}
