// @flow

import Modal from 'app/components/Modal/Modal';
import FormikField from 'app/fields/Formik/FormikField';
import { toast } from 'app/helpers';
import { uniqueId } from 'app/external/form-for-component-helpers';
import { Form, Formik } from 'formik';
import React from 'react';
import { Prompt } from 'react-router-dom';
import { Button, FormField, Input, Progress, Form as SemanticForm } from 'semantic-ui-react';
import useTusUpload from './useTusUpload';
import * as Yup from 'yup';

export default function ResumeTusUpload() {
    const [tusUploadData, setTusUploadData] = React.useState(null);

    const { progress, uploadStarted, uploadVideo, upload, setUploadStarted, uploadYoutubePaused, resumeUpload } =
        useTusUpload();

    React.useEffect(() => {
        const localTusUploadData = localStorage.getItem('tusUploadData');
        if (localTusUploadData) {
            try {
                const parsedLocalTusUploadData = JSON.parse(localTusUploadData);
                if (parsedLocalTusUploadData.expiresAt < Date.now()) {
                    localStorage.removeItem('tusUploadData');
                } else {
                    setTusUploadData(parsedLocalTusUploadData);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, []);

    const { filename, title, description } = tusUploadData || {};

    const preventCloseOnUpload = event => {
        if (uploadStarted) {
            const confirmationMessage = '';
            (event || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
        }
    };

    const onClose = removeItem => {
        const prevented = preventCloseOnUpload();
        if (!prevented) {
            setTusUploadData(null);
            removeItem && localStorage.removeItem('tusUploadData');
        }
    };

    const handleSubmit = async (values, actions) => {
        const videoFile: File = values.video;

        if (videoFile.name !== filename) {
            toast.warning('Please upload a file with the same name as the previous upload attempt');
            actions.resetForm();
            return;
        }

        const uploaded = await uploadVideo(videoFile, tusUploadData, {
            onSuccess: () => {
                setTusUploadData(null);
            },
            resume: true,
        });
        if (!uploaded) {
            return;
        }
        setTusUploadData(null);
    };

    return (
        <>
            <Modal
                size="tiny"
                open={Boolean(tusUploadData)}
                className="custom"
                title="Upload Found"
                closeOnDimmerClick={false}
            >
                <Modal.Content>
                    <Formik
                        initialValues={{ video: null }}
                        validationSchema={Yup.object().shape({
                            video: Yup.mixed().required('Please select a file to upload'),
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue }) =>
                            uploadStarted ? (
                                <Progress className="no-margin" percent={progress} size="big" progress indicating />
                            ) : (
                                <Form id={uniqueId(ResumeTusUpload)}>
                                    <SemanticForm>
                                        <h3>A previous upload attempt was found</h3>
                                        <FormField>
                                            <label>
                                                <b>File Name:</b> {filename}
                                            </label>
                                        </FormField>
                                        <FormField>
                                            <label>
                                                <b>Title:</b> {title}
                                            </label>
                                        </FormField>
                                        {description && (
                                            <FormField>
                                                <label>
                                                    <b>Description:</b> {description}
                                                </label>
                                            </FormField>
                                        )}
                                        <p>To resume the upload, please select the same file below.</p>
                                        <FormField>
                                            <FormikField
                                                as={Input}
                                                name="video"
                                                type="file"
                                                accept="video/*"
                                                disabled={uploadStarted}
                                                onChange={event => {
                                                    setFieldValue('video', event.currentTarget.files[0]);
                                                }}
                                                value={undefined}
                                            />
                                        </FormField>
                                    </SemanticForm>
                                </Form>
                            )
                        }
                    </Formik>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        icon="trash"
                        style={{ backgroundColor: '#1b183a', color: 'white' }}
                        content="Delete"
                        onClick={() => {
                            if (upload) {
                                upload.abort();
                                setUploadStarted(false);
                            }
                            onClose(true);
                        }}
                    />
                    {uploadYoutubePaused ? (
                        <Button
                            icon="play"
                            style={{ backgroundColor: '#1b183a', color: 'white' }}
                            content="Resume"
                            onClick={resumeUpload}
                        />
                    ) : (
                        <Button
                            disabled={uploadStarted}
                            form={uniqueId(ResumeTusUpload)}
                            icon="cloud upload"
                            style={{ backgroundColor: '#1b183a', color: 'white' }}
                            content="Upload"
                        />
                    )}
                </Modal.Actions>
            </Modal>
            <Prompt
                message="A video is currently being uploaded, are you sure you want to leave this page?"
                when={uploadStarted}
            />
        </>
    );
}
