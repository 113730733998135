// @flow

import { action, observable } from 'mobx';
import { field } from 'app/external/form-for';
import Model from 'models/Model';
import Place from 'models/Place';
import youtubeApi from 'api/youtubeApi';

import { dateTime } from 'helpers';

export default class Video extends Model {
    path = 'videos';

    user_name: string;
    user_email: string;

    @observable
    @field({ required: true })
    title: string;

    @observable
    @field
    description: string;

    @observable
    @field({ type: 'video', fileInput: true, accept: 'video/*' })
    video_file: string;

    @observable
    video_blob: any;

    youtube_id: string;

    thumbnail_url: string;

    file: string;

    share_uuid: string;

    place: Place;

    created_at: {
        date: string,
        timezone_type: number,
        timezone: string,
    };

    get shareUrl() {
        if (!!this.place && process.env.REACT_APP_DE_URL) {
            return `https://${process.env.REACT_APP_DE_URL}/${this.place.slug}/vi/${this.place.id}/${this.id}/${this.share_uuid}`;
        }

        return null;
    }

    get youtubeUrl(): string {
        return `https://www.youtube.com/watch?v=${this.youtube_id}`;
    }

    get youtubeEmbedUrl(): string {
        return `https://www.youtube.com/embed/${this.youtube_id}?vq=hd1080&modestbranding=1&rel=0&theme=light`;
    }

    async getYoutubeMp4Path(): Promise<string> {
        const stream = await youtubeApi.videoInfoStreamMp4(this.youtube_id);
        return stream || {};
    }

    get thumbnail(): string {
        return this.thumbnail_url || `https://img.youtube.com/vi/${this.youtube_id}/0.jpg`;
    }

    get datetime(): string {
        return this.created_at.date;
    }

    get date(): string {
        return dateTime.toDate(this.datetime);
    }

    get time(): string {
        return dateTime.toHour(this.datetime);
    }

    async destroyAndClearCache(options?: Options): Promise<any> {
        this.destroy(options);
        this.store.cache.clearLists();
    }

    @action
    setVideoBlob(file) {
        this.video_blob = file;
    }
}
