// @flow

import photo from 'photo/photo.app';
import video from 'video/video.app';
import reputation from 'reputation/reputation.app';
import chat from 'chat/chat.app';
import managedPublishing from 'publishing/managedPublishing.app';
import kenect from 'kenect/kenect.app';
import editPosts from 'publishing/editPosts.app';

const indexedFeatureApps = {};
[reputation, video, kenect, photo, chat, managedPublishing, editPosts].forEach(app => {
    indexedFeatureApps[app.feature] = app;
});

export default indexedFeatureApps;
