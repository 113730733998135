import React from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from 'semantic-ui-react';

export default function Media({ src, type, handleMediaSelect, index, selected }) {
    const [loading, setLoading] = React.useState(type === 'photo' ? true : false);

    const handleClick = () => {
        if (!loading) handleMediaSelect(index);
    };

    return (
        <>
            {type === 'photo' ? (
                <img
                    src={src}
                    alt="image not found"
                    onClick={handleClick}
                    onLoad={() => setLoading(false)}
                    style={{
                        outline: selected ? 'solid 2px #d86d27' : 'none',
                        padding: '2px',
                        userSelect: 'none',
                        cursor: loading ? 'default' : 'pointer',
                    }}
                />
            ) : (
                type === 'video' && (
                    <div className="relative">
                        <Icon className="type-icon" name="video camera" size="large" />
                        <video
                            src={src}
                            alt="video not found"
                            onClick={handleClick}
                            style={{
                                outline: selected ? 'solid 2px #d86d27' : 'none',
                                padding: '2px',
                                userSelect: 'none',
                                cursor: loading ? 'default' : 'pointer',
                            }}
                        />
                    </div>
                )
            )}
        </>
    );
}

Media.propTypes = {
    src: PropTypes.string,
    type: PropTypes.string,
    handleMediaSelect: PropTypes.func,
    index: PropTypes.number,
    selected: PropTypes.bool,
};
