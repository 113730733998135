// @flow

import { presets } from 'types';
import type { App } from 'types';

import VideosPage from './pages/Videos';
import VideoReportPage from './pages/VideoReport';
import YoutubeSettingsPage from './pages/YoutubeSettings';

import VideosSidebar from './sidebars/Videos';

const app: App = {
    feature: 'video_ignition',
    path: 'video',
    title: 'Catalyst Video',
    mobileTitle: 'Video',
    description: 'Take a Private Video',
    className: 'video',
    browserThemeColor: '#1b183a',
    icon: 'vi',
    // marketingMessages: [
    //     'Create personalized HD videos and build a library of content',
    //     'Viewable as an unlisted video on your YouTube page',
    //     'Send links via text to the customer',
    // ],
    // marketingMessageDisabled:
    //     'Capture video for sales and service and post to YouTube as a private video to share with prospects and customers. YouTube short links allow the videos to stay high definition and keep the videos off personal devices.',
    disabledLink: 'http://www.friendemic.com/catalyst-video/',
    routes: {
        videos: {
            path: 'videos',
            title: 'Library',
            component: VideosPage,
        },
        videoReport: {
            path: 'report',
            title: 'Report',
            preset: presets.report,
            component: VideoReportPage,
        },
        youtubeSettings: {
            path: 'youtube-settings',
            title: 'Settings',
            preset: presets.settings,
            component: YoutubeSettingsPage,
            role: 'place-manager',
        },
    },
    defaultRoute: 'videos',
    sidebars: {
        videos: VideosSidebar,
    },
};

export default app;
