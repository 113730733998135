// @flow

import * as React from 'react';

import VideoLeaderboard from 'models/VideoLeaderboard';

import videoLeaderboardStore from 'video/stores/leaderboard';
import ReportSection from 'components/Report/Section';
import DataPlaceholder from 'components/Data/Placeholder';
import Pagination from 'components/Pagination';
import Sortable from 'components/Sortable';

type Props = {
    videosCount: VideoLeaderboard[],
};

type State = {
    sortBy: string,
    sortDirection: string,
};

export default class UserInfoStatsPartial extends React.Component<Props, State> {
    meta: { current_page: number, last_page: number, total: number };

    state = {
        sortBy: 'videos',
        sortDirection: 'desc',
    };

    handleSort = (sortBy: string) => {
        videoLeaderboardStore.sort(sortBy);
    };

    handlePageChange = (_: Event, data: { activePage: number }) => {
        videoLeaderboardStore.setPage(data.activePage);
        videoLeaderboardStore.load(true);
    };

    render() {
        const { videosCount } = this.props;
        const { sortProperty, sortDirection, meta } = videoLeaderboardStore;

        return (
            <ReportSection id="userInfoStats">
                <DataPlaceholder for={videosCount.length}>
                    <div className="scroll-mobile">
                        <Sortable
                            className="ui basic table padded unstackable scrollable custom row-line"
                            data={videosCount}
                            onSort={this.handleSort}
                            hideSpacer
                            sortBy={sortProperty || ''}
                            sortDirection={sortDirection || ''}
                            async
                        >
                            <Sortable.Column title="User's Name" property="username" sortable />
                            <Sortable.Column title="Videos Created" property="videos" sortable />
                            <Sortable.Column title="Total Shares" property="total_shares" sortable />
                            <Sortable.Column title="Unique Views" property="unique_views" sortable />
                            <Sortable.Column title="Shared Videos not Viewed" property="not_viewed" sortable />
                        </Sortable>

                        <Pagination
                            className="custom top-spacer"
                            activePage={meta.current_page}
                            totalPages={meta.last_page}
                            total={meta.total}
                            onPageChange={this.handlePageChange}
                            loading={videoLeaderboardStore.loading}
                            compact
                        />
                    </div>
                </DataPlaceholder>
            </ReportSection>
        );
    }
}
