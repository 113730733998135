// @flow

import { observable, action } from 'mobx';
import { field } from 'app/external/mobx-form-for';
import { api } from '@friendemic/premiere';

import Model from './Model';
import PlaceNotifications from './PlaceNotifications';

import User from './User';

import extract from 'normalizers/extract';

export default class NotificationSettings extends Model {
    path = 'notifications-settings';
    keyColumn = 'user_id';

    // @observable global_notifications: Object;
    @observable user_id: number;

    @observable
    @field({ type: 'switch', label: 'Notifications' })
    @extract('global_notifications')
    notifications_active: boolean;

    @observable
    @field({
        type: 'select',
        options: [
            { value: 1, text: 'Hourly' },
            { value: 2, text: 'Daily' },
            { value: 3, text: 'Weekly' },
            { value: 4, text: 'Monthly' },
        ],
        label: 'Notification Frequency',
        search: false,
    })
    @extract('global_notifications')
    frequency: string;

    @observable
    @field({ type: 'switch', label: 'Send notifications for Positive Reviews' })
    @extract('global_notifications')
    positive_reviews: boolean;

    @observable
    @field({ type: 'switch', label: 'Send notifications for Negative Reviews' })
    @extract('global_notifications')
    negative_reviews: boolean;

    @observable
    @field({ type: 'switch', label: 'Send notifications for Feedback' })
    @extract('global_notifications')
    feedback: boolean;

    @observable
    @field({ type: 'placeNotification[]' })
    place_notifications: PlaceNotifications[];

    get isAllowingNotifications() {
        return this.notifications_active;
    }

    get mapChanged(): Object {
        return { ...this.map, place_notifications: this.place_notifications.map(place => place.map) };
    }

    get changed(): boolean {
        let changedValues = {};

        const originalMap = this.memorizedChanges;
        const currentMap = { ...this.map, place_notifications: this.place_notifications.map(place => place.map) };

        Object.keys(currentMap).forEach(key => {
            // $FlowFixMe
            const originalValue = originalMap[key] || '';
            const currentValue = currentMap[key] || '';

            if (key.endsWith('_at')) return;

            if (key === 'place_notifications') {
                let changedPlaces = [];

                originalValue.forEach((val, index) => {
                    const changes = Model.getObjectChanges(val, currentValue[index]);
                    if (Object.keys(changes).length > 0) changedPlaces.push(changes);
                });

                if (changedPlaces.length > 0) changedValues[key] = currentValue;
            }

            if (key === 'global_notifications') {
                const objectChanges = Model.getObjectChanges(currentValue, originalValue);
                if (Object.keys(objectChanges).length) changedValues[key] = currentValue;
            }
        });

        return Object.keys(changedValues).length > 0;
    }

    @action
    memorizeChanges(): this {
        this.memorizedChanges = {
            ...this.map,
            place_notifications: this.place_notifications.map(place => place.map),
        };
        return this.memorizedChanges;
    }

    @action.bound
    syncPlacesWithGlobal() {
        this.place_notifications = this.place_notifications.map(placeSettings => {
            if (placeSettings.global) {
                placeSettings.positive_reviews = this.positive_reviews;
                placeSettings.negative_reviews = this.negative_reviews;
                placeSettings.feedback = this.feedback;
            }
            return placeSettings;
        });
    }

    @action.bound
    setPlaceNotifications() {
        this.place_notifications = this.place_notifications.map(place =>
            new PlaceNotifications().set({ ...place, id: place.place_id })
        );
    }

    @action.bound
    setDefaultValues() {
        this.frequency = 'hourly';
        this.positive_reviews = true;
        this.negative_reviews = true;
        this.feedback = true;
        this.notifications_active = true;
    }

    async saveChanges() {
        // this.syncPlacesWithGlobal();
        const { data } = await api.http.post(`/${User.reflector.path}/${this.user_id}/${this.path}`, {
            data: this.mapChanged,
        });

        this.memorizeChanges();
        return data;
    }
}
