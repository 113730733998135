import { useMemo, useCallback, useEffect, useState } from 'react';

const UPGRADE_ACKNOWLEDGEMENTS_KEY = 'catalyst_upgrade_acknowledgements';

/**
 * Used to establish a local storage key for the acknowledgement of a place's upgrade.
 * - Either returns an empty array on first load, or an array of place ids that have acknowledged the upgrade
 * @returns {number[]}
 */
function getUpgradeAcknowledgements() {
    const storageAcknowledgements = localStorage.getItem(UPGRADE_ACKNOWLEDGEMENTS_KEY);
    if (!storageAcknowledgements) {
        localStorage.setItem(UPGRADE_ACKNOWLEDGEMENTS_KEY, '[]');
        return [];
    } else {
        return JSON.parse(storageAcknowledgements);
    }
}

/**
 * Utility hook to manage the acknowledgement of a place's upgrade.
 */
export function useLocalStorageAcknowledgements(place) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    /* Get the array of place ids that have acknowledged the upgrade */
    const storageAcknowledgements = useMemo(() => getUpgradeAcknowledgements(), [place]);

    /* Build some values that keep track of the upgrade status and scheduled date */
    const { upgradeStatus, upgradeScheduledDate, hasPlaceAcknowledged } = useMemo(() => {
        return {
            upgradeStatus: place.status,
            upgradeScheduledDate: place.scheduled_date,
            hasPlaceAcknowledged: Boolean(storageAcknowledgements && storageAcknowledgements.includes(place.id)),
        };
    }, [place]);

    /* Set the place id in local storage if it does not already exist */
    const setPlaceHasAcknowledged = useCallback(
        async placeId => {
            if (!storageAcknowledgements.includes(placeId)) {
                localStorage.setItem(
                    UPGRADE_ACKNOWLEDGEMENTS_KEY,
                    JSON.stringify([...storageAcknowledgements, placeId])
                );
            }
        },
        [storageAcknowledgements]
    );

    /* Set modal open value to true if the place has not acknowledged the upgrade. Set modal to closed during cleanup */
    useEffect(() => {
        if (!hasPlaceAcknowledged) {
            setIsModalOpen(true);
        }
        return () => {
            setIsModalOpen(false);
        };
    }, [place]);

    return {
        isModalOpen,
        setIsModalOpen,
        upgradeStatus,
        upgradeScheduledDate,
        setPlaceHasAcknowledged,
        storageAcknowledgements,
    };
}
