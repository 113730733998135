// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import routeStore from 'stores/route';

import ActionButton from 'components/ActionButton';

type Props = {
    text: ?(string | boolean),
};

@observer
export default class ActionButtonClose extends React.Component<Props> {
    render() {
        const { text } = this.props;
        const displayText = typeof text === 'string' ? text : 'Close';

        return <ActionButton link={routeStore.closeRoute} icon="remove" text={displayText} />;
    }
}
