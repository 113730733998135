// @flow

import { observer } from 'mobx-react';
import { Line } from 'react-chartjs-2';
import { yearMonth } from 'helpers';
import React from 'react';
import InviteAnalytics from 'models/InviteAnalytics';
import DataPlaceholder from 'components/Data/Placeholder';

type Props = {
    inviteAnalytics: InviteAnalytics,
    chart: string,
};

const options = {
    elements: {
        line: {
            tension: 0,
        },
    },
    maintainAspectRatio: false,
    legend: {
        position: 'bottom',
        labels: {
            fontColor: '#999',
            boxWidth: 10,
            useLineStyle: true,
            fontSize: 11,
            padding: 10,
        },
    },
    animation: {
        animateScale: true,
    },
    plugins: {
        datalabels: {
            display: false,
        },
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    suggestedMin: 1,
                    suggestedMax: 6,
                    callback: function(value) {
                        return Number(value).toFixed(0);
                    },
                },
            },
        ],
    },
};

@observer
export default class NumberOfInvitesPartial extends React.Component<Props> {
    getData() {
        const { inviteAnalytics, chart } = this.props;
        const datasets = [],
            chartData = inviteAnalytics.charts[chart];

        if (undefined !== chartData.total) {
            datasets.push(this.buildDataset('Sent', [58, 158, 247], Object.values(chartData.total)));
        }

        if (undefined !== chartData.sales) {
            datasets.push(this.buildDataset('Sales', [170, 206, 64], Object.values(chartData.sales)));
        }

        if (undefined !== chartData.service) {
            datasets.push(this.buildDataset('Service', [62, 191, 206], Object.values(chartData.service)));
        }

        if (undefined !== chartData.manual) {
            datasets.push(this.buildDataset('Real Time', [153, 62, 206], Object.values(chartData.manual)));
        }

        if (undefined !== chartData.automated) {
            datasets.push(this.buildDataset('Automated', [247, 160, 58], Object.values(chartData.automated)));
        }

        if (undefined !== chartData.email) {
            datasets.push(this.buildDataset('E-mail', [165, 58, 136], Object.values(chartData.email)));
        }

        if (undefined !== chartData.text) {
            datasets.push(this.buildDataset('Text', [191, 168, 62], Object.values(chartData.text)));
        }

        return {
            labels: yearMonth.namesFromObject(inviteAnalytics.charts[chart].total),
            datasets: datasets,
        };
    }

    buildDataset(label: string, rgb: Array<number>, data: Object) {
        return {
            label,
            borderColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.9)`,
            backgroundColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.9)`,
            pointHoverBackgroundColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},1)`,
            pointHoverBorderColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},1)`,
            data,
            pointRadius: 0,
            pointHitRadius: 5,
            fill: false,
        };
    }

    render() {
        const { inviteAnalytics, chart } = this.props;

        return (
            <DataPlaceholder for={inviteAnalytics.charts[chart]}>
                <div className="chart">
                    <Line data={this.getData()} options={options} />
                </div>{' '}
            </DataPlaceholder>
        );
    }
}
