// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import Rating from 'components/Rating';

type Props = {
    title: string,
    number: number,
    reviews?: any,
    rating?: boolean,
    monthly?: boolean,
};

@observer
export default class ScoreNumber extends React.Component<Props> {
    ratingColor = () => {
        let color;
        const { number } = this.props;
        const ranges = {
            '0.00-2.499': 'data-negative-text',
            '2.5-3.999': 'data-warning-text',
            '4.00-5.00': 'data-positive-text',
        };

        Object.keys(ranges).some(key => {
            const range = key.split('-');

            if (number >= +range[0] && number <= (+range[1] || +range[0])) color = ranges[key];
            return color;
        });

        return color;
    };

    render() {
        const { title, number, rating, reviews, monthly } = this.props;

        return (
            <div className="item-wrapper">
                <div className="dashboard-item card vertical-center-container">
                    <h3 className="titles dashboard-titles">{title.toUpperCase()}</h3>
                    <div className={`stat-number clearfix ${rating ? this.ratingColor() : ''}`}>{number || 0}</div>
                    <div className="stat-description clearfix">
                        {rating && (
                            <Rating value={number} type="for-dashboard" negative={number <= 2.5 ? 'negative' : ''} />
                        )}
                        {/* $FlowFixMe */}
                        {monthly && reviews !== null ? `Last Month: ${reviews} New Reviews` : null}
                    </div>
                </div>
            </div>
        );
    }
}
