// @flow

import Model from './Model';

import { dateTime } from 'helpers';
import { observable, action } from 'mobx';

export default class VideoData extends Model {
    path = 'video-data';

    video_id: number;
    title: string;
    created_by: string;
    created_at: string;
    unique_views: number;
    shared: number;

    @observable
    avg_video_watched_length: number;

    normalize_created_at(created_at: string): string {
        return `${dateTime.toDate(created_at)}`;
    }

    @action
    setAverageVideoWatchedLength(length: number) {
        this.avg_video_watched_length = length;
    }
}
