// @flow

import { observable } from 'mobx';
import { field } from 'app/external/form-for';
import Model from 'models/Model';

export default class Group extends Model {
    path = 'groups';

    id: number;

    @observable
    @field
    name: string;

    @observable
    @field
    user: number[];

    @observable
    @field
    open_group_management: boolean;
}
