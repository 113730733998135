// @flow

import React, { useEffect, useMemo } from 'react';
import indexedFeatureApps from 'app/indexedFeatureApps';

import Page from 'components/Page';
import AppCard from 'components/App/Card';
import AppArrayAvailable from 'components/App/ArrayAvailable';
import Resizer from 'components/Resizer';
import KenectUpgradeModal from 'app/components/KenectUpgradeModal';
import placeStore from 'app/stores/place/placeStore';
import { useLocalStorageAcknowledgements } from 'app/hooks/useLocalStorageAcknowledgements';
import KenectUpgradeCard from 'app/components/KenectUpgradeCard';
import { toast } from 'app/helpers';
import { PropTypes } from 'prop-types';
import { toast as toastify } from 'react-toastify';
import userInfoStore from 'app/stores/userInfo/userInfoStore';

const AppSelectionPage = props => {
    const { upgradeStatus } = useLocalStorageAcknowledgements(placeStore.place);
    const isActionReplace = useMemo(() => props.history.action === 'REPLACE', [props.history]);

    useEffect(() => {
        /* Admin are clear to access site settings and apps after upgrade, but are notified that changes should not be made */
        if (upgradeStatus === 'UPGRADED' && userInfoStore.isAdmin) {
            toast(
                "We've migrated this location to Kenect. Although admin users retain access to account information and apps, any adjustments to location settings will be ignored.",
                {
                    type: 'success',
                    position: toastify.POSITION.BOTTOM_LEFT,
                    autoClose: false,
                }
            );
        }
    }, [upgradeStatus]);

    useEffect(() => {
        if (isActionReplace && upgradeStatus === 'UPGRADED') {
            toast('Please update account settings', {
                type: 'error',
                position: toastify.POSITION.BOTTOM_LEFT,
            });
        }
    }, [isActionReplace]);

    return (
        <Page additionalBodyClass="app-selection">
            <KenectUpgradeModal />
            <div className="app-card-container clearfix">
                <div className="main-title">
                    <h1>Welcome to the Catalyst Suite.</h1>
                    <h2>What would you like to do today?</h2>
                </div>

                {upgradeStatus === 'UPGRADED' ? (
                    <KenectUpgradeCard />
                ) : (
                    <Resizer className="app-card-wrapper" minus={30} min={325}>
                        <AppArrayAvailable
                            render={apps =>
                                Object.keys(indexedFeatureApps).map(key => {
                                    if (key === 'general' || key === 'soci_enabled' || key === 'can_edit_posts')
                                        return null;

                                    const app = indexedFeatureApps[key];
                                    const enabled = apps.includes(app);

                                    return <AppCard key={key} app={app} enabled={enabled} />;
                                })
                            }
                        />
                    </Resizer>
                )}
            </div>
        </Page>
    );
};

AppSelectionPage.propTypes = {
    history: PropTypes.shape({
        action: PropTypes.string,
    }),
};

export default AppSelectionPage;
