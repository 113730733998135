// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import { Form, Field } from 'app/external/mobx-form-for';
import { Icon } from 'semantic-ui-react';
import Clipboard from 'react-clipboard.js';

import Place from 'models/Place';

import placeStore from 'stores/place';
import organizationStore from 'stores/organization';
import userInfoStore from 'stores/userInfo';

import { toast, route } from 'helpers';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import Sortable from 'components/Sortable';
import Modal from 'components/Modal';
import PromptUnsaved from 'components/Prompt/Unsaved';
import FormButton from 'components/FormButton';

import PlaceEditPartial from './partials/PlaceEditPartial';
import PlaceAppsPartial from './partials/PlaceAppsPartial';
import { getErrorMessageFromSaveModelError } from 'helpers';

type Props = {};

@observer
export default class LocationsPage extends React.Component<Props> {
    @observable newPlace: Place = new Place();
    @observable creating: boolean = false;
    newPlaceCounter: number = 0;

    @action
    addPlace(place: Place) {
        placeStore.add(place);
        this.newPlaceCounter++;
        this.newPlace = new Place();
    }

    @action
    setCreating(creating: boolean) {
        this.creating = creating;
    }

    handleCreate = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.newPlace.organization_id = organizationStore.organization.id;

        this.setCreating(true);

        try {
            const place = await this.newPlace.saveChanges();
            toast.success(`Location ${this.newPlace.name} created`);
            this.addPlace(place);
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError(error));
        } finally {
            this.setCreating(false);
        }
    };

    handleDelete = async (place: Place) => {
        await place.destroy();
        placeStore.remove(place);
        toast.success(`Location ${place.name} deleted`);
    };

    handleClick = () => {
        toast.success('RSS Feed copied to clipboard');
    };

    render() {
        // const hasElevatedPrivileges = userInfoStore.isAdmin || userInfoStore.isOrganizationManager;

        return (
            <Page headerTitle>
                <PromptUnsaved for={this.newPlace} />

                <Column position="left" aligned>
                    <SettingsPanel title="Add New Location">
                        <Form
                            key={this.newPlaceCounter}
                            for={this.newPlace}
                            onSubmit={this.handleCreate}
                            className="ui form tiny"
                        >
                            <Field name="name" />
                            <Field name="street" />

                            <SemanticForm.Group widths="two">
                                <Field name="city" />
                                <Field name="state" />
                            </SemanticForm.Group>

                            <SemanticForm.Group widths="two">
                                <Field name="zip" />
                                {/* {hasElevatedPrivileges && <Field name="external_id" />} */}
                            </SemanticForm.Group>

                            <SemanticForm.Field>
                                <FormButton
                                    type="submit"
                                    primary
                                    className="right floated"
                                    icon="plus"
                                    text="Add Location"
                                    saving={this.creating}
                                />
                            </SemanticForm.Field>
                        </Form>
                    </SettingsPanel>
                </Column>

                <Column position="right">
                    <Sortable data={placeStore.places} sortBy="name" sortDirection="asc">
                        <Sortable.Column
                            title="Location Name"
                            property="name"
                            sortable
                            render={place => (
                                <>
                                    <a
                                        href={`${route('general', 'appSelection')}?placeId=${place.id}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {place.name}
                                    </a>
                                    <div className="rss-feed">
                                        <h3>RSS Feed:</h3>
                                        <span>{`${place.ulid}`}</span>
                                        <Clipboard component="span" data-clipboard-text={place.ulid}>
                                            <Icon name="copy outline icon" onClick={this.handleClick} />
                                        </Clipboard>
                                    </div>
                                </>
                            )}
                        />

                        {userInfoStore.isAdmin && (
                            <Sortable.Column
                                title="Linked App(s)"
                                render={place => <PlaceAppsPartial place={place} />}
                            />
                        )}

                        <Sortable.Column
                            title="Action(s)"
                            render={place => (
                                <React.Fragment>
                                    <Modal.Confirm
                                        className="custom"
                                        title="Delete Location"
                                        buttonText="Delete Location"
                                        text={`Are you sure you want to delete ${place.name}?`}
                                        onConfirm={() => this.handleDelete(place)}
                                        trigger={<Sortable.Action title="Delete" icon="trash" />}
                                    />

                                    <PlaceEditPartial place={place} />
                                </React.Fragment>
                            )}
                        />
                    </Sortable>
                </Column>
            </Page>
        );
    }
}
