// @flow

import { observable } from 'mobx';
import { field } from 'app/external/form-for';

import Model from 'models/Model';
import * as Sentry from '@sentry/react';
export default class BulkInvite extends Model {
    path = 'invites/bulk';

    place_uuid: string;

    @observable
    @field({
        type: 'select',
        options: [
            { value: 'sales', text: 'Sales' },
            { value: 'service', text: 'Service' },
        ],
        label: 'Type',
        error: 'validateInviteType',
        required: true,
    })
    type: string;

    @observable
    @field({ type: 'csv', fileInput: true, accept: '.csv' })
    invite_file: string;

    static upload(requestOptions): Promise<Model> {
        return fetch(`${process.env.REACT_APP_INVITE_V2_URL}/invite/bulk`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data && data.code > 200) {
                    Sentry.captureException(`Bulk Invite: ${data.message}, code: ${data.code}`);
                    // console.error(data);
                    throw new Error(
                        JSON.stringify(data && data.errors ? data.errors : 'Something went wrong, Please try again!')
                    );
                }
                return data;
            })
            .catch(error => {
                throw new Error(error);
            });
        // return this.store.create(data, options);
    }

    validateInviteType() {
        if (this.type !== 'sales' && this.type !== 'service') return 'Please select invitation type';
    }
}
