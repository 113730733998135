import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import Consent from '../Consent';

export default function ConsentModal({ open, onCancel, onUpload }) {
    const [checked, setChecked] = React.useState(false);

    const handleChecked = isChecked => {
        setChecked(isChecked);
    };
    return (
        <Modal open={open} title="Add Photo" size="mini">
            <Modal.Content>
                <Consent onChecked={handleChecked} />
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={onCancel}>
                    Cancel
                </Button>
                <Button primary disabled={!checked} onClick={onUpload}>
                    Upload
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

ConsentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
};
