// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Label } from 'semantic-ui-react';

type Props = {
    error: ?string,
    touched: ?boolean,
};

@observer
export default class ErrorLabel extends React.Component<Props> {
    render() {
        const { error, touched } = this.props;
        if (!error || !touched) return null;

        return (
            <Label color="red" basic pointing className="prompt">
                {error}
            </Label>
        );
    }
}
