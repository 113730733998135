// @flow

import React from 'react';
import ReactDOM from 'react-dom';

type Props = {
    for?: any,
    render?: Function,
};

// $FlowFixMe
const loaderRoot: HTMLDivElement = document.getElementById('loader-root');

export default class LoaderPortal extends React.Component<Props> {
    el: HTMLDivElement;

    constructor(props: Props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        // $FlowFixMe
        loaderRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        // $FlowFixMe
        loaderRoot.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(this.renderContent(), this.el);
    }

    renderContent() {
        return (
            <div className="loading-overlay open">
                <span className="loader" />
            </div>
        );
    }
}
