// @flow

import * as React from 'react';
import { matchPath, withRouter } from 'react-router-dom';

type Props = {
    active?: boolean,
    activeLink?: string,
    exact: boolean,
    children: React.Node,
    onClick?: Function,
    disabled?: boolean,
};

type ConnectedProps = Props & {
    active: boolean,
    selectedSidebar: ?string,
    onClick: Function,
    activeLink: string,
    location: {
        pathname: string,
    },
};

class SidebarListItem extends React.Component<ConnectedProps> {
    isActive(): boolean {
        const { active, activeLink, exact, location } = this.props;
        return !!(
            active ||
            (activeLink &&
                matchPath(location.pathname, {
                    path: activeLink,
                    exact,
                }))
        );
    }

    render() {
        const classes = ['list-item inverted'];
        if (this.isActive()) {
            classes.push('active');
        }

        if (this.props.disabled) {
            classes.push('upgradeSidebar-listItem-disabled');
        }

        return (
            <div className={classes.join(' ')} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        );
    }
}

// $FlowFixMe
const composed: withRouter<SidebarListItem> = withRouter(SidebarListItem);
export default composed;
