// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { action, observable } from 'mobx';
import { Form, Field } from 'app/external/mobx-form-for';
import moment from 'moment';
import FormButton from 'components/FormButton';
import { DateRange } from 'react-date-range';
import DatePresets from 'components/DatePicker/DatePresets';
import Filters from 'models/Filters';
import { dateRangePreset } from 'helpers';

type Props = {
    onChange: Function,
    filters: Filters,
    trigger: React.Node,
    onReset: ?Function,
    resetPreset?: boolean,
    disablePreset?: boolean,
    availableFilterGroups?: Array<string>,
    labels?: Object,
};

type State = {
    modalOpen: boolean,
};

@observer
export default class FiltersPopup extends React.Component<Props, State> {
    @observable
    dates = {
        startDate: null,
        endDate: null,
        clearAll: false,
        presets: 'None',
        year: moment().year(),
    };

    state = {
        modalOpen: false,
    };

    componentDidMount() {
        const { startDate, endDate, presets } =
            this.defaultDates || ({ startDate: null, endDate: null, presets: 'None' }: Object);

        if (startDate && endDate) {
            this.handleChange({
                selection: { startDate: moment(startDate).toDate(), endDate: moment(endDate).toDate(), presets },
            });
        }
    }

    get defaultDates() {
        if (this.props.filters.endDate) {
            return {
                startDate: this.props.filters.startDate,
                endDate: this.props.filters.endDate,
                presets: this.props.filters.presets,
            };
        }

        return null;
    }

    get dateRangeOptions() {
        const defaultDates = this.defaultDates;
        const dateRangeOptions = {};

        if (defaultDates && undefined !== defaultDates.endDate) {
            dateRangeOptions.shownDate = moment(defaultDates.endDate).toDate();
        }

        return dateRangeOptions;
    }

    get isPresetDateSelectionChanged(): boolean {
        const { startDate, endDate } = this.props.filters;
        const presetDateRange = dateRangePreset.dateRangeFromPreset(this.dates.presets, this.dates.year);

        if ('None' === this.dates.presets) {
            return false;
        }

        if (!startDate && !endDate) {
            return true;
        }

        if (presetDateRange.startDate === null && presetDateRange.endDate === null) {
            return true;
        }

        return (
            moment(presetDateRange.startDate).format('YYYY-MM-DD') !== startDate &&
            moment(presetDateRange.endDate).format('YYYY-MM-DD') !== endDate
        );
    }

    @action.bound
    handleChange({ selection }: Object) {
        const { startDate, endDate } = selection;

        this.dates.presets = 'None';
        this.dates.startDate = startDate;
        this.dates.endDate = endDate;
    }

    @action.bound
    handleResetPreset(force: boolean = false) {
        if (this.props.resetPreset || force) {
            this.dates.startDate = null;
            this.dates.endDate = null;
            this.dates.presets = 'None';
        }
    }

    @action.bound
    handlePresetChange() {
        const { presets, year } = this.dates;

        if (presets !== 'None') {
            const { startDate, endDate } = dateRangePreset.dateRangeFromPreset(presets, year);
            this.dates.startDate = startDate;
            this.dates.endDate = endDate;
        }

        if (!!this.dates.endDate && this.dates.endDate > new Date()) {
            this.handleResetPreset(true);
        }

        if (presets === 'None') {
            const { defaultStartDate, defaultEndDate } = this.props.filters;
            this.dates.startDate = moment(defaultStartDate).toDate();
            this.dates.endDate = moment(defaultEndDate).toDate();
        }
    }

    @action.bound
    handleOpenClose = () => {
        const { startDate, endDate } = this.props.filters;
        if (!this.dates.startDate) this.dates.year = moment().year();

        this.dates.startDate = null;
        this.dates.endDate = null;

        if (this.isPresetDateSelectionChanged) {
            this.dates.presets = 'None';
        }

        if (startDate && endDate) {
            this.dates.startDate = moment(startDate).toDate();
            this.dates.endDate = moment(endDate).toDate();
        }

        this.setState(state => ({
            modalOpen: !state.modalOpen,
        }));
    };

    @action.bound
    handleReset() {
        this.props.onReset && this.props.onReset();

        this.dates.presets = 'None';
        this.dates.startDate = null;
        this.dates.endDate = null;

        if (this.props.filters.defaultStartDate && this.props.filters.defaultEndDate) {
            this.dates.startDate = moment(this.props.filters.startDate).toDate();
            this.dates.endDate = moment(this.props.filters.endDate).toDate();
        }

        this.submitDates();
    }

    @action.bound
    submitDates() {
        const { startDate, endDate } = this.dates;

        if (startDate && endDate) {
            this.props.filters.setDateRange(
                moment(startDate).format('YYYY-MM-DD'),
                moment(endDate).format('YYYY-MM-DD')
            );
        }

        this.props.onChange();

        this.dates.clearAll = false;

        this.setState(state => ({
            modalOpen: !state.modalOpen,
        }));
    }

    showFilterGroup(group: string): boolean {
        const { availableFilterGroups } = this.props;

        return undefined !== availableFilterGroups && availableFilterGroups.includes(group);
    }

    get filtersSchema() {
        const { filters, labels } = this.props;
        const schema = {};

        if (!filters || !filters.filterGroups) {
            return {};
        }

        filters.filterGroups.forEach(group => {
            schema[group.name] = {
                type: 'filterGroup',
                value: group,
                group,
            };

            if (group.name && labels && labels[group.name]) {
                schema[group.name].labels = labels[group.name];
            }
        });

        return schema;
    }

    @action.bound
    handleShownDateChange(date: Date) {
        this.dates.year = date.getFullYear();
    }

    renderPopup() {
        const { startDate, endDate } = this.dates;
        const { dateRangeOptions } = this;
        const { filters, disablePreset } = this.props;

        const selectionRange = {
            startDate,
            endDate,
            key: 'selection',
            showDateDisplay: false,
        };

        return (
            <React.Fragment>
                <div className="close-popup clearfix">
                    <a className="close-btn" onClick={this.handleOpenClose}>
                        Close <Icon name="close" />
                    </a>
                </div>
                <div className="scroll-wrapper-popup">
                    <div className="small-titles first">Date Range</div>
                    <DateRange
                        showSelectionPreview
                        showYearMonthDisplay={false}
                        moveRangeOnFirstSelection={false}
                        className="date-picker"
                        ranges={[selectionRange]}
                        direction="horizontal"
                        onChange={this.handleChange}
                        maxDate={new Date()}
                        minDate={new Date('2000-01-01')}
                        onShownDateChange={this.handleShownDateChange}
                        months={2}
                        {...dateRangeOptions}
                    />

                    {!disablePreset && <DatePresets to={this.dates} onChange={this.handlePresetChange} />}

                    <Form for={filters && filters.toObject()} schema={this.filtersSchema}>
                        {filters.filterGroups &&
                            filters.filterGroups.map(group =>
                                this.showFilterGroup(group.name) ? <Field key={group.name} name={group.name} /> : null
                            )}
                    </Form>
                </div>
                <div className="popup-controls">
                    <Button
                        size="tiny"
                        color="grey"
                        className="outline custom"
                        basic
                        circular
                        onClick={this.handleOpenClose}
                    >
                        Cancel
                    </Button>

                    <Button
                        size="tiny"
                        color="grey"
                        className="outline custom"
                        basic
                        circular
                        onClick={this.handleReset}
                    >
                        Reset
                    </Button>

                    <FormButton primary circular className="custom" onClick={this.submitDates} text="Filter" />
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { modalOpen } = this.state;

        return (
            <Popup
                className={`date-picker-popup vertical-scroll-adaptable ${modalOpen ? 'open' : ''}`}
                open={modalOpen}
                onMount={this.handleResetPreset}
                onOpen={this.handleOpenClose}
                onClose={this.handleOpenClose}
                trigger={this.props.trigger}
                content={this.renderPopup()}
                on="click"
                position="bottom right"
            />
        );
    }
}
