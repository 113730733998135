// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import Video from 'models/Video';
import VideoShare from 'models/VideoShare';
import Clipboard from 'react-clipboard.js';
import { toast } from 'helpers';

type Props = {
    video: Video,
    children: React.Node,
    timeout: Number,
    embedHTML: Boolean,
};

@observer
export default class VideoClipboardPopup extends React.Component<Props> {
    thumbnail_url: string;

    /* Convert html to new Blob(), then copy Blob to clipboard. */
    handleClickEmbed = () => {
        const { video } = this.props;
        const that = this;

        /* link around image and text. */
        const embedHTMLTemplate = `<table border="0" cellpadding="0" cellspacing="0" width="100%" style="width: 100%; max-width: 600px; border-collapse: collapse; height: 100% !important;"><tbody><tr><td><a style="display: block;" href="${video.shareUrl}"><table width="100%" cellpadding="0" cellspacing="0" style="border-collapse: collapse"><tbody><tr><td><img alt="video thumbnail" src="${video.thumbnail}" style="width: auto; max-width: 100%" /></td></tr><tr><td>Click here to view your video</td></tr></tbody></table></a></td></tr></tbody></table>`;

        var type = 'text/html';
        var blob = new Blob([embedHTMLTemplate], { type });
        var data = [new window.ClipboardItem({ [type]: blob })];

        /* Copy Blob to clipboard */
        navigator.clipboard.write(data).then(
            function() {
                /* success */
                that.handleClick();
                toast.success('Embed Code copied to clipboard');
            },
            function() {
                /* failure */
                toast.error('Embed Code not copied, please try again.');
            }
        );
    };

    handleClick = () => {
        this.handleVideoCopySuccess();

        this.timeout = setTimeout(() => {
            this.setState({ clipboardActive: false });
        }, this.props.timeout || 2000);
    };

    handleVideoCopySuccess = () => {
        const { video, embedHTML } = this.props;
        const videoShare = VideoShare.fromVideo(video, 'link');

        videoShare.notify();

        if (!embedHTML) {
            toast.success('Video URL copied to clipboard');
        }
    };

    render() {
        const { video, children, embedHTML } = this.props;
        if (!video.youtube_id) return null;

        return embedHTML ? (
            <button className="ui primary button option-btn" onClick={this.handleClickEmbed}>
                <i aria-hidden="true" className="linkify icon"></i> Copy Embed Code
            </button>
        ) : (
            <Clipboard component="span" data-clipboard-text={video.shareUrl}>
                <div onClick={this.handleClick}>{children}</div>
            </Clipboard>
        );
    }
}
