// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Field, Form } from 'app/external/mobx-form-for';
import { Button } from 'semantic-ui-react';

import User from 'models/User';
import Contact from 'models/Contact';

import userInfo from 'stores/userInfo';
import placeStore from 'stores/place';

import { toast, httpResponseErrors } from 'helpers';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import Loader from 'components/Loader';

type Props = {};
type State = { saving: boolean };

@observer
export default class ContactPage extends React.Component<Props, State> {
    @observable user: User;
    @observable contact: Contact;
    state = {
        saving: false,
    };

    @action.bound
    setUser(user: User) {
        if (user) user = user.clone();
        this.user = user;
        this.contact = new Contact();
        this.contact.set({ email: userInfo.user.email, name: userInfo.user.name, place_name: placeStore.place.name });
    }

    loadUser = async () => {
        this.setUser(await User.find(userInfo.user.id));
    };

    @action.bound
    handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            this.setState(state => ({ saving: !state.saving }));
            await this.contact.saveChanges();
            this.setUser(this.user);
            toast.success('Message received. Thank you!');
        } catch (error) {
            toast.error(httpResponseErrors(error));
        } finally {
            this.setState(state => ({ saving: !state.saving }));
        }
        return true;
    };

    render() {
        return <Loader onLoad={this.loadUser} render={this.renderContent} />;
    }

    renderContent = () => {
        const { saving } = this.state;
        const { contact_subject, contact_message } = this.contact;
        return (
            <Page>
                <Column>
                    <SettingsPanel title="Contact us">
                        <Form for={this.contact} onSubmit={this.handleSubmit}>
                            <Field name="contact_subject" required value={contact_subject} />
                            <Field name="contact_message" required textArea value={contact_message} />
                            <Button primary loading={saving}>
                                Submit
                            </Button>
                        </Form>
                    </SettingsPanel>
                </Column>
            </Page>
        );
    };
}
