import userInfoStore from 'app/stores/userInfo/userInfoStore';

const BRP_ORG_ID = '790';
const STATUS_UPGRADED = 'UPGRADED';

/**
 * Conditional logic ONLY for BRP
 * - Uses userInfoStore user places array instead of the placeStore places array
 * @param {object} userInfoStore
 * @return {boolean}
 */
const isAnyUserPlaceUpgraded = userInfoStore => {
    const places = userInfoStore && userInfoStore.places ? [...userInfoStore.places] : [];
    const isAnyUserPlaceUpgraded = places.some(p => p.status === STATUS_UPGRADED);
    return isAnyUserPlaceUpgraded;
};

/**
 * Checks if any place in the organization is upgraded
 * @param {object} placeStore
 * @param {object} userInfoStore
 * @return {boolean}
 */
export const isAnyLocationUpgraded = (placeStore, userInfoStore) => {
    /* if isAdmin, don't disable navigation */
    if (userInfoStore && userInfoStore.isAdmin) {
        return false;
    }
    /* If BRP, check user's places for upgrade status */
    if (userInfoStore && userInfoStore.organization && userInfoStore.organization.id === BRP_ORG_ID) {
        return isAnyUserPlaceUpgraded(placeStore, userInfoStore);
    }

    const isAnyLocationUpgraded =
        placeStore && placeStore.places && placeStore.places.some(p => p.status === STATUS_UPGRADED);
    return isAnyLocationUpgraded;
};

/**
 * Checks if routes should be redirected after upgrade
 * @param {object} placeStore
 * @param {string} pathname
 * @return {boolean}
 */
export const isRouteDisabledAfterUpgradeForPlace = (placeStore, pathname) => {
    /* if isAdmin, don't disable navigation */
    if (userInfoStore && userInfoStore.isAdmin) {
        return false;
    }

    return (
        placeStore &&
        placeStore.place &&
        placeStore.place.status === STATUS_UPGRADED &&
        pathname !== '/app-selection' &&
        pathname !== '/' &&
        !pathname.includes('system-admin')
    );
};

/**
 * Checks if place status is UPGRADED
 */
export const isPlaceUpgraded = place => {
    /* if isAdmin, don't disable navigation */
    if (userInfoStore && userInfoStore.isAdmin) {
        return false;
    }

    return place && place.status === STATUS_UPGRADED;
};
