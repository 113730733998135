import React, { useMemo, useCallback, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

/**
 * A simple toggle component that accepts ToggleOption components as children
 */
function Toggle({ children, onChange, groupName, value, ...otherProps }) {
    const handleChange = useCallback(event => onChange(event.target.value), [onChange]);

    const childrenWithProps = useMemo(
        () =>
            Children.map(children, child => {
                const childValue = child.props.value;
                const isActive = childValue === value;
                return cloneElement(child, { onClick: handleChange, isActive, name: groupName });
            }),
        [children, value, handleChange, groupName]
    );

    const wrapperClassName = 'kui-toggle-wrapper';

    return (
        <div className={wrapperClassName} {...otherProps}>
            <div className="kui-toggle-backdrop">{childrenWithProps}</div>
        </div>
    );
}

Toggle.propTypes = {
    /**
     * Components/elements to be rendered inside the toggle.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),

    /**
     * Fires when the selected values changes
     */
    onChange: PropTypes.func,

    /**
     * The current value of the toggle
     */
    value: PropTypes.string,

    /**
     * Passed down to children for use as a name attribute for radio buttons
     */
    groupName: PropTypes.string,
};

export default Toggle;
