// @flow

import { observable } from 'mobx';
import Model from './Model';
import { field } from 'app/external/mobx-form-for';

export default class CustomLinks extends Model {
    @observable
    @field({ label: 'Label:', placeholder: 'Example' })
    label: string;

    @observable
    @field({ label: 'URL:', placeholder: 'https://www.example.com/', error: 'validateURL' })
    url: string;

    denormalize_url(url: string): ?string {
        const protocolRegex = /^(https:\/\/)|(http:\/\/)/;
        if ((url || '').match(protocolRegex)) return url;
        if (url) return `http://${url}`;
        return undefined;
    }

    validateURL() {
        const urlRegex = /\.[a-zA-Z]{2,6}/;
        if (this.url && !this.url.match(urlRegex)) return 'Please enter a URL.';
    }
}
