// @flow

import { action, observable } from 'mobx';

import placeStore from 'stores/place';
import { CancelToken, CancelTokenSource } from 'axios';
import { api } from '@friendemic/premiere';

class VideosWatchedLengthStore {
    cancelSource: ?CancelTokenSource;
    @observable lastError: ?Object;

    @action
    setLastError(error: Object) {
        this.lastError = error;
    }
    @action
    clearError() {
        this.lastError = null;
    }

    async load(startDate: ?string, endDate: ?string, ids: number[]): Promise<Object> {
        this.cancelSource = CancelToken.source();
        this.clearError();

        const url = `/places/${placeStore.place.id}/videos-watched-length`;
        const params = {
            start_date: startDate,
            end_date: endDate,
            video_ids: ids.join(','),
        };

        try {
            let lastResponse = await api.http.get(url, { params, cancelToken: this.cancel.token });
            return lastResponse.data.data;
        } catch (error) {
            this.setLastError(error);
        } finally {
            this.cancelSource = null;
        }
    }

    cancel() {
        this.cancelSource && this.cancelSource.cancel();
    }
}

export default new VideosWatchedLengthStore();
