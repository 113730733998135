// @flow

import * as React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';
import routeStore from 'stores/route';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

const ANIMATION_SPEED = 200;

type Props = {
    actions: ?React.Node,
    saveable: boolean,
    saving?: boolean,
    disableSave?: boolean,
    closeable: ?(string | boolean),
    filters?: React.Node,
};

type State = {
    activeTab: string,
    mobileMenuHeight: number | string,
};

@observer
export default class MobileActionsMenu extends React.Component<Props, State> {
    state = {
        activeTab: 'none',
        mobileMenuHeight: 0,
    };

    toggleHandler = (tabClicked: string) => (event: Event) => {
        event.preventDefault();
        const { activeTab } = this.state;
        const toggleAction = () => {
            if (activeTab !== tabClicked) {
                this.setState({
                    activeTab: tabClicked,
                    mobileMenuHeight: 'auto',
                });
            } else {
                this.setState({
                    activeTab: 'none',
                    mobileMenuHeight: 0,
                });
            }
        };

        if (activeTab !== 'none') this.setState({ mobileMenuHeight: 0 });
        setTimeout(toggleAction, activeTab === 'none' ? 0 : ANIMATION_SPEED);
    };

    render() {
        const { activeTab, mobileMenuHeight } = this.state;
        const { saveable, actions, closeable, saving, disableSave, filters } = this.props;

        return (
            <div className="mobile-actions-menu-container clearfix">
                {(saveable || closeable) && (
                    <div className="toggle-button-container clearfix">
                        {saveable && (
                            <Button
                                className="custom toggle-btn"
                                type="submit"
                                disabled={disableSave || saving}
                                loading={saving}
                            >
                                <Icon name="save" />
                                Save
                            </Button>
                        )}
                        {closeable && (
                            <Button className="custom toggle-btn" as={Link} to={routeStore.closeRoute}>
                                <Icon name="delete" />
                                Close
                            </Button>
                        )}
                    </div>
                )}
                {(actions || filters) && (
                    <React.Fragment>
                        <div className={`toggle-button-container ${!actions || !filters ? 'single' : ''} clearfix`}>
                            {filters && (
                                <Button
                                    className={`custom toggle-btn ${activeTab === 'filters' ? 'active' : ''}`}
                                    onClick={this.toggleHandler('filters')}
                                >
                                    <Icon name="filter" />
                                    Filters
                                </Button>
                            )}
                            {actions && (
                                <Button
                                    className={`custom toggle-btn ${activeTab === 'options' ? 'active' : ''}`}
                                    onClick={this.toggleHandler('options')}
                                >
                                    <Icon name="sliders" />
                                    Options
                                </Button>
                            )}
                        </div>
                        <AnimateHeight
                            easing="ease"
                            animateOpacity={false}
                            height={mobileMenuHeight}
                            duration={ANIMATION_SPEED}
                        >
                            {actions && activeTab === 'options' && actions}
                            {filters && activeTab === 'filters' && filters}
                        </AnimateHeight>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
