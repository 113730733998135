// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import CheckboxField, { type Props } from 'fields/Checkbox';

@observer
export default class SwitchField extends React.Component<Props> {
    render() {
        return (
            <div className="field switch labeled clearfix">
                <CheckboxField {...this.props} toggle />
            </div>
        );
    }
}
