// @flow

import * as React from 'react';
import { Button } from 'semantic-ui-react';
import useMediaRecorder from '@wmik/use-media-recorder';
import { VideoPreview } from './VideoPreview';
import { VideoPlayer } from './VideoPlayer';
import Modal from 'components/Modal';
import Countdown from 'react-countdown';
import { toast } from 'helpers';

type Props = {
    onClose: Function,
    startRecording: boolean,
    updateVideo: Function,
    type: string,
    prefferedCam: string,
};

let videoStream;
export function CaptureVideo(props: Props) {
    const [chunks, setChunks] = React.useState([]);
    const [paused, setPaused] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [counter, setCounter] = React.useState(props.type === 'video' ? true : false);
    const counterRef = React.useRef();
    const [recordScreen] = React.useState(props.type === 'video' ? false : true);
    const {
        status,
        liveStream,
        mediaBlob,
        pauseRecording,
        resumeRecording,
        stopRecording,
        getMediaStream,
        startRecording,
        clearMediaStream,
    } = useMediaRecorder({
        recordScreen,
        blobOptions: {
            type: 'video/mp4',
        },
        mediaRecorderOptions: { mimeType: 'video/webm;codecs=vp8,opus' },
        mediaStreamConstraints: { audio: true, video: { facingMode: props.prefferedCam } },
        onDataAvailable: chunk => {
            if (chunk.size) {
                setChunks(prevChunk => prevChunk.concat(chunk)); // Update state instead
            }
        },
    });

    const startRecordingVideo = async () => {
        await setChunks([]);
        await clearMediaStream();
        await getMediaStream();
        startRecording();
    };

    React.useEffect(() => {
        if (chunks.length) {
            watchChunks();
        }

        async function watchChunks() {
            try {
                const context = new AudioContext();
                let [sampleChunk] = chunks;
                let blob = new Blob(chunks, { type: sampleChunk.type });
                let name = '';
                const date = new Date();
                name = `${JSON.stringify(date)}.mp4`;
                name = name.replaceAll('"', '');
                const file = blobToFile(blob, name);
                props.updateVideo(file);
                await context.close();
            } catch (e) {
                console.warn(e);
            }
        }

        function blobToFile(theBlob, fileName) {
            theBlob.lastModified = Date.now();
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        }
    }, [chunks]);

    // eslint-disable-next-line react/prop-types
    const renderer = ({ seconds }) => <span style={{ fontSize: '8rem', color: '#ffffff80' }}>{seconds}</span>;

    React.useEffect(() => {
        if (props.type === 'screen') startRecordingVideo();
        else getLiveStream();
    }, []);

    const getLiveStream = async () => {
        if (window.navigator) {
            window.navigator.getUserMedia =
                window.navigator.getUserMedia ||
                window.navigator.webkitGetUserMedia ||
                window.navigator.mozGetUserMedia ||
                window.navigator.msGetUserMedia;
            window.navigator.getUserMedia(
                { audio: false, video: { facingMode: props.prefferedCam } },
                function(stream) {
                    var video = document.querySelector('#videoCountDown');
                    if (video) {
                        video.srcObject = stream;
                        videoStream = stream;

                        // eslint-disable-next-line no-unused-vars
                        video.onloadedmetadata = function(e) {
                            video.play();
                        };
                    }
                },
                function(err) {
                    console.warn('The following error occurred: ' + err.name);
                }
            );
        } else {
            // console.log('getUserMedia not supported');
        }
    };

    const getStatus = () => {
        if (props.type === 'video') {
            switch (status) {
                case 'acquiring_media':
                    return 'Video is processing...';
                case 'recording':
                    return 'Video is recording...';
                case 'stopped':
                    return 'Recording stopped.';
                case 'paused':
                    return 'Recording is paused.';
                case 'failed':
                    return 'Camera permission denied. Allow it manually.';
                default:
                    return status;
            }
        } else {
            switch (status) {
                case 'acquiring_media':
                    return 'Screen video is processing...';
                case 'recording':
                    return 'Screen is recording...';
                case 'stopped':
                    return 'Recording stopped.';
                case 'paused':
                    return 'Screen recording is paused.';
                case 'failed':
                    return 'Screen capture permission denied. Allow it manually.';
                default:
                    return status;
            }
        }
    };

    React.useEffect(() => {
        if (status === 'failed') {
            toast.error(getStatus());
            if (props.type === 'video') stopVideoStream();
            props.onClose();
        }
    }, [status]);

    const stopVideoStream = () => {
        if (videoStream) videoStream.getTracks().forEach(track => track.stop());
    };

    return (
        <div style={{ textAlign: 'end' }}>
            <Modal.Content style={{ textAlign: 'center', paddingBottom: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1 style={{ textAlign: 'left', color: '#1b183a', marginBottom: 10 }}>
                        {counter ? 'Recording starts in...' : getStatus()}
                    </h1>
                </div>

                {counter && props.type === 'video' && (
                    <div id="videoParentView">
                        <video id="videoCountDown" width={'100%'} />
                        <div id="countDown">
                            <Countdown
                                ref={counterRef}
                                renderer={renderer}
                                date={Date.now() + 5000}
                                onComplete={async () => {
                                    setCounter(false);
                                    startRecordingVideo();
                                }}
                            />
                        </div>
                    </div>
                )}

                {liveStream ? (
                    <VideoPreview recordScreen={recordScreen} stream={liveStream} />
                ) : !counter ? (
                    <VideoPlayer recordScreen={recordScreen} srcBlob={mediaBlob} />
                ) : (
                    undefined
                )}
            </Modal.Content>

            <Modal.Actions>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {!counter && (
                        <Button
                            basic
                            color="black"
                            onClick={() => {
                                props.onClose();
                                stopRecording();
                                stopVideoStream();
                                props.updateVideo(null);
                            }}
                        >
                            Cancel
                        </Button>
                    )}

                    {!counter && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            {!done && (
                                <Button
                                    style={{ backgroundColor: '#EB7D16', color: 'white' }}
                                    onClick={() => {
                                        if (paused) {
                                            resumeRecording();
                                            setPaused(false);
                                        } else {
                                            setPaused(true);
                                            pauseRecording();
                                        }
                                    }}
                                >
                                    {`${paused ? 'Resume' : 'Pause'} Recording`}
                                </Button>
                            )}
                            {done && (
                                <Button
                                    basic
                                    color="black"
                                    onClick={() => {
                                        setDone(false);
                                        setPaused(false);
                                        props.updateVideo(null);
                                        if (props.type === 'video') {
                                            getLiveStream();
                                            counterRef.current && counterRef.current.start();
                                            setCounter(true);
                                        } else {
                                            startRecordingVideo();
                                        }
                                    }}
                                >
                                    Reset
                                </Button>
                            )}
                            <Button
                                style={{ backgroundColor: '#1b183a', color: 'white' }}
                                onClick={async () => {
                                    if (done) {
                                        props.onClose();
                                    } else {
                                        await setDone(true);
                                        stopRecording();
                                    }
                                    if (props.type === 'video') stopVideoStream();
                                }}
                            >
                                {done ? 'Save' : 'Done'}
                            </Button>
                        </div>
                    )}
                </div>
            </Modal.Actions>
        </div>
    );
}
