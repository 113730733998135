// @flow

import React from 'react';

// $FlowFixMe
import logo from 'static/semantic/dist/themes/catalyst/assets/images/catalyst_logo.svg';

// $FlowFixMe
import suiteLogo from 'static/semantic/dist/themes/catalyst/assets/images/catalyst_suite_logo.svg';

type Props = {
    suite?: boolean,
};

export default class Logo extends React.Component<Props> {
    render() {
        const { suite, ...props } = this.props;
        const src = suite ? suiteLogo : logo;

        return <img src={src} alt="Catalyst logo" {...props} />;
    }
}
