// @flow

import { observable } from 'mobx';
import { field } from 'app/external/form-for';

import indexedFeatureApps from 'indexedFeatureApps';

import Model from 'models/Model';
import Profile from 'models/Profile';
import Video from 'models/Video';
import InviteAnalytics from 'models/InviteAnalytics';
import VideoLeaderboard from 'models/VideoLeaderboard';
import ReputationLeaderboard from 'models/ReputationLeaderboard';
import Integration from 'models/Integration';
import Review from 'models/Review';
import ReputationSnapshot from 'models/ReputationSnapshot';
import customLinks from 'models/VIPCustomLinks';

import extract from 'normalizers/extract';

import type { Features } from 'types';

import ViewMetric from './ViewMetric';
import VideoData from './VideoData';
import PublishedContent from './PublishedContent';
import PhotoLeaderboard from './PhotoLeaderboard';

export type ProfilesByChannel = { [channel: string]: Profile };

export default class Place extends Model {
    path = 'places';

    displayName = 'Location';

    @observable
    @field({ required: true })
    name: string;

    @observable
    @field({ type: 'email' })
    email: string;

    @observable
    @field
    website: string;

    @observable
    @field({
        label: 'OEM dealer code(s)',
        helpText: 'OEM dealer code(s)',
    })
    @extract('settings')
    oem_codes: string;

    @observable
    @field({
        label: 'HubSpot Company ID',
        helpText: 'HubSpot Company ID',
    })
    @extract('settings')
    hubspot_id: string;

    @observable
    @field({ required: true })
    street: string;

    @observable
    @field
    extra: string;

    @observable
    @field({ required: true })
    city: string;

    @observable
    @field({ label: 'State/Province', helpText: 'State/Province', type: 'state', required: true })
    state: string;

    @observable
    @field({ required: true, placeholder: 'zip code' })
    zip: string;

    @observable
    @field({ type: 'tel' })
    phone: string;

    @observable
    @field({ type: 'image', width: 500, height: 500, label: 'Logo' })
    @extract('settings')
    logo_url: string;

    @observable
    @field({
        type: 'image',
        label: 'Logo Rectangle',
        height: 300,
        width: 500,
        adaptable: false,
        helpText:
            'Preferred logo height is between a minimum of 200 and a maximum of 300 pixels, JPG, GIF & PNG are accepted.',
    })
    @extract('settings')
    logo_url_rectangle: string;

    @observable
    @field({
        label: 'E-mails',
        type: 'select',
        options: [
            { value: 'square', text: 'Square' },
            { value: 'rectangle', text: 'Rectangle' },
        ],
    })
    @extract('settings')
    emails_page_logo_format: string;

    @observable
    @field({
        label: 'VIP Page',
        type: 'select',
        options: [
            { value: 'square', text: 'Square' },
            { value: 'rectangle', text: 'Rectangle' },
        ],
    })
    @extract('settings')
    vip_page_logo_format: string;

    @observable
    @field({ type: 'email' })
    @extract('settings')
    contact_email: string;

    @observable
    @field
    @extract('settings')
    feedback_notification_emails: string;

    @observable
    @field({ type: 'image', width: 500, height: 500, label: 'Text Graphic' })
    @extract('settings')
    invite_graphic_url: string;

    @observable
    @extract('settings')
    invite_graphic_source: string;

    @observable
    @field({ label: 'Review Invite Message', required: true, minLength: 25, maxLength: 320, textArea: true })
    @extract('settings')
    invite_message_sms: string;

    @observable
    @extract('settings')
    geocode: Object;

    @observable
    @field({
        type: 'taggedInput',
        label: 'CRM Leads Address (BCC)',
        error: 'validateBccEmails',
        placeholder: ' ',
        tooltip: 'Adding an email here will add a BCC email to every Video Video sent to a customer',
    })
    @extract({
        property: 'settings',
        denormalize: bcc_email_addresses => {
            if (bcc_email_addresses) return [...bcc_email_addresses].join(',');
            return '';
        },
    })
    bcc_email_addresses: string[];

    @observable
    @field({
        type: 'select',
        label: 'Customization options',
        tooltip: `Location defined means the BCC email(s) entered below will be used every time a video is sent via email for your location.
      User defined allows each Catalyst user to apply one or more BCC emails for each of the videos they send.`,
    })
    @extract('settings')
    bcc_email_user_customizable: boolean;

    @observable
    @field({
        type: 'emails',
        label: 'Reply to e-mail',
        tooltip: 'This is the email address that all responses will go to',
    })
    @extract('settings')
    reply_to_email: string;

    @observable
    @field({ type: 'switch', label: 'Use Custom E-mail Template' })
    @extract({
        property: 'settings',
        denormalize: video_ignition_settings_custom_email_template =>
            video_ignition_settings_custom_email_template ? 'true' : 'false',
    })
    video_ignition_settings_custom_email_template: string;

    @observable
    @field({ label: 'E-mail Subject', placeholder: ' ', required: true })
    @extract('settings')
    video_ignition_settings_email_subject: string;

    @observable
    @field({ label: 'E-mail Heading', placeholder: ' ', required: true })
    @extract('settings')
    video_ignition_settings_email_heading: string;

    @observable
    @field({ label: 'E-mail Body', textArea: true, placeholder: ' ', required: true })
    @extract('settings')
    video_ignition_settings_email_body: string;

    @observable
    @field({ type: 'tel', label: 'Phone:' })
    @extract({
        property: 'settings',
        denormalize: video_ignition_settings_phone => {
            if (!video_ignition_settings_phone) return '';
            return video_ignition_settings_phone;
        },
    })
    video_ignition_settings_phone: string;

    @observable
    @field({ type: 'email', label: 'E-mail:', placeholder: 'example@example.com' })
    @extract({
        property: 'settings',
        denormalize: video_ignition_settings_email => {
            if (!video_ignition_settings_email) return '';
            return video_ignition_settings_email;
        },
    })
    video_ignition_settings_email: string;

    @observable
    @field({ type: 'switch', label: 'Use Custom Contact Info' })
    @extract({
        property: 'settings',
        denormalize: video_ignition_settings_use_custom_info =>
            video_ignition_settings_use_custom_info ? 'true' : 'false',
    })
    video_ignition_settings_use_custom_info: string;

    @observable
    @field({
        type: 'select',
        options: [
            { value: 'location', text: 'Location defined' },
            { value: 'user', text: 'User defined' },
            { value: 'custom', text: 'Custom' },
        ],
        search: false,
        label: 'Customization Options',
    })
    @extract('settings')
    video_ignition_settings_contact_info_source: string;

    @observable
    @extract('settings')
    video_ignition_settings_custom_links: customLinks[];

    @observable
    @field({ type: 'switch', label: 'Cars.com Integration', verbose: true })
    @extract('settings')
    carscom_syndication_active: string;

    @observable
    youtube_quota: number;

    @observable
    @field({ type: 'switch', label: 'Allow public YouTube videos' })
    @extract('settings')
    allow_public_youtube_videos: string;

    @extract('settings') youtube_channel_avatar: string;
    @extract('settings') youtube_channel_name: string;
    @extract('settings') youtube_channel_id: string;
    @extract('settings') youtube_refresh_token: string;

    @extract('settings') facebook_user_refresh_token: string;
    @extract('settings') facebook_user_id: string;
    @extract('settings') facebook_user_avatar: string;
    @extract('settings') facebook_user_name: string;
    @extract('settings') facebook_user_email: string;
    @extract('settings') facebook_channel_refresh_token: string;
    @extract('settings') facebook_channel_id: string;
    @extract('settings') facebook_channel_name: string;
    @extract('settings') facebook_channel_picture: string;
    @extract('settings') facebook_channel_status: string;

    @extract('settings') google_channel_id: string;
    @extract('settings') google_channel_name: string;
    @extract('settings') google_channel_picture: string;
    @extract('settings') google_channel_status: string;

    @observable
    @field({ type: 'switch', label: 'Allow editing posts', verbose: true })
    @extract('settings')
    can_edit_posts: string;

    @observable
    @field({
        type: 'radioGroup',
        options: [
            { value: 'recommended', text: '(Recommended) No more than once every 60 days' },
            { value: 'always', text: 'Send a new invite with every transaction' },
            { value: 'never', text: 'Send an invite with the first transaction and never again' },
            { value: 'custom', text: '(Custom) No more than once every _______ days' },
        ],
    })
    @extract('settings')
    review_invite_frequency: string;

    @observable
    @field({ type: 'number', min: 1, label: ' ' })
    @extract('settings')
    review_invite_frequency_length: number;

    @observable
    @field({ type: 'switch', label: 'Allow duplicate invites', verbose: true })
    @extract('settings')
    invite_duplicate_force_allowed: string;

    @observable
    @field({ type: 'features' })
    features: Features;

    @observable
    @field({ placeholder: '12345' })
    external_id: number;

    @observable group_ids: number[];

    integrations: Object[];

    get isUsingCustomEmailTemplate() {
        return this.video_ignition_settings_custom_email_template;
    }

    get isUsingCustomContactInfo() {
        return 'custom' === this.video_ignition_settings_contact_info_source;
    }

    async hasCarsComSyndication() {
        const profiles = await this.profilesByChannel();
        return !!profiles['carscom'];
    }

    async integration(): Promise<?Integration> {
        if ((this.integrations || []).length) return await Integration.find(this.integrations[0].id);
        return new Integration();
    }

    profiles(): Promise<Profile[]> {
        return this.hasMany(Profile, { completeItems: true });
    }

    async profilesByChannel(): Promise<ProfilesByChannel> {
        const profiles = await this.profiles();

        const profilesByChannel = {};

        profiles.forEach(profile => {
            if (profile.is_custom) return (profilesByChannel.custom = profile);
            profilesByChannel[profile.channel] = profile;
        });

        const hasCustom = Object.keys(profilesByChannel).find(key => key === 'custom');
        if (!hasCustom) {
            profilesByChannel.custom = new Profile()
                .set({ place_id: this.id, channel: '', url: '', is_custom: true })
                .memorizeChanges();
        }

        return profilesByChannel;
    }

    videos(options: Object): Promise<Video[]> {
        return this.hasMany(Video, options);
    }

    videoLeaderboard(options: Object = {}): Promise<VideoLeaderboard[]> {
        return this.hasMany(VideoLeaderboard, options);
    }

    rLeaderboard(options: Object = {}): Promise<ReputationLeaderboard[]> {
        return this.hasMany(ReputationLeaderboard, options);
    }

    inviteAnalytics(options: Object): Promise<InviteAnalytics> {
        return this.hasOne(InviteAnalytics, options);
    }

    viewMetrics(options: Object = {}): Promise<ViewMetric> {
        return this.hasOne(ViewMetric, options);
    }

    videoDatas(options: Object = {}): Promise<VideoData[]> {
        return this.hasMany(VideoData, options);
    }

    PhotoLeaderboards(options: Object = {}): Promise<PhotoLeaderboard> {
        return this.hasOne(PhotoLeaderboard, options);
    }

    publishedContent(options: Object = {}): Promise<PublishedContent> {
        return this.hasMany(PublishedContent, options);
    }

    reviews(options: Object): Promise<Review[]> {
        return this.hasMany(Review, options);
    }

    reputationSnapshot(options: Object = {}): Promise<ReputationSnapshot> {
        return this.hasOne(ReputationSnapshot, options);
    }

    validateBccEmails() {
        if (this.bcc_email_addresses) {
            let valid;
            this.bcc_email_addresses.forEach(
                email =>
                    (valid = email.match(
                        /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
                    ))
            );
            if (!valid) return 'must be a valid email';
        }
    }

    clearYoutube() {
        this.youtube_channel_avatar = '';
        this.youtube_channel_name = '';
        this.youtube_channel_id = '';
        this.youtube_refresh_token = '';
    }

    clearFacebook() {
        this.facebook_user_refresh_token = '';
        this.facebook_user_id = '';
        this.facebook_user_avatar = '';
        this.facebook_user_name = '';
        this.facebook_user_email = '';
        this.facebook_channel_refresh_token = '';
        this.facebook_channel_id = '';
        this.facebook_channel_name = '';
        this.facebook_channel_picture = '';
        this.facebook_channel_status = '';
    }

    changedFacebook(): boolean {
        if (!this.mapChanged.settings) return false;

        const fbSettings = [
            'facebook_channel_id',
            'facebook_channel_name',
            'facebook_channel_picture',
            'facebook_channel_refresh_token',
            'facebook_channel_status',
        ];

        return Object.keys(this.mapChanged.settings).filter(item => fbSettings.includes(item)).length > 0;
    }

    clearGoogle() {
        this.google_channel_id = '';
        this.google_channel_name = '';
        this.google_channel_picture = '';
        this.google_channel_status = '';
    }

    changedGoogle(): boolean {
        if (!this.mapChanged.settings) return false;

        const gmbSettings = [
            'google_channel_id',
            'google_channel_name',
            'google_channel_picture',
            'google_channel_status',
        ];

        return Object.keys(this.mapChanged.settings).filter(item => gmbSettings.includes(item)).length > 0;
    }

    normalize_features(features: ?Object) {
        if (!features) features = {};
        Object.values(indexedFeatureApps).forEach(app => {
            // $FlowFixMe
            if (typeof features[app.feature] !== 'boolean') features[app.feature] = false;
        });

        return features;
    }

    normalize_phone(phone: string): string {
        if (phone) return phone.replace(/-/g, '');
        return '';
    }

    normalize_can_edit_posts(can_edit_posts: string): boolean {
        return can_edit_posts === 'true';
    }

    normalize_invite_duplicate_force_allowed(invite_duplicate_force_allowed: string): boolean {
        return invite_duplicate_force_allowed === 'true';
    }

    normalize_video_ignition_settings_custom_email_template(
        video_ignition_settings_custom_email_template: string
    ): boolean {
        return video_ignition_settings_custom_email_template === 'true';
    }

    normalize_allow_public_youtube_videos(allow_public_youtube_videos: string): boolean {
        return allow_public_youtube_videos === 'true';
    }

    hasYoutubeAuth(): boolean {
        return !!this.youtube_channel_id;
    }

    isYoutubePublic(): boolean {
        return !!this.youtube_channel_id && this.allow_public_youtube_videos;
    }

    normalize_video_ignition_settings_use_custom_info(video_ignition_settings_use_custom_info: string): boolean {
        return video_ignition_settings_use_custom_info === 'true';
    }

    normalize_reply_to_email(reply_to_email: string): string {
        if (!reply_to_email) return 'user';
        return reply_to_email;
    }

    normalize_bcc_email_user_customizable(bcc_email_user_customizable: string): string {
        if (!bcc_email_user_customizable) return 'false';
        return bcc_email_user_customizable;
    }

    normalize_bcc_email_addresses(bcc_email_addresses: string[]): string {
        // $FlowFixMe
        if (bcc_email_addresses) return bcc_email_addresses.split(',');
        return bcc_email_addresses;
    }

    normalize_video_ignition_settings_email(video_ignition_settings_email: string) {
        if (video_ignition_settings_email) return video_ignition_settings_email;
    }

    normalize_video_ignition_settings_phone(video_ignition_settings_phone: string) {
        if (video_ignition_settings_phone) return video_ignition_settings_phone.replace(/-/g, '');
        return '';
    }

    normalize_video_ignition_settings_email_subject(video_ignition_settings_email_subject: string) {
        if (video_ignition_settings_email_subject) return video_ignition_settings_email_subject;
        return '{user} at {location} has shared a video with you!';
    }

    normalize_video_ignition_settings_email_heading(video_ignition_settings_email_heading: string) {
        if (video_ignition_settings_email_heading) return video_ignition_settings_email_heading;
        return 'Dear {recipient},';
    }

    normalize_video_ignition_settings_email_body(video_ignition_settings_email_body: string) {
        if (video_ignition_settings_email_body) return video_ignition_settings_email_body;
        return '{user} shared a video with you. Click on the button below to open the video.';
    }

    normalize_invite_message_sms(invite_message_sms: string): string {
        if (invite_message_sms) return invite_message_sms;
        return `Thank you for visiting ${this.name}!  We’d love to hear your feedback, {customer}.`;
    }

    normalize_invite_message_email(invite_message_email: string): string {
        if (invite_message_email) return invite_message_email;
        return `You’ve been invited to leave feedback for: ${this.name}.`;
    }

    calculateChanges(): Object {
        const map = super.calculateChanges();

        if (map.settings && 'custom' === map.settings.video_ignition_settings_contact_info_source) {
            const { video_ignition_settings_phone, video_ignition_settings_email } = this;

            return {
                ...map,
                settings: { ...map.settings, video_ignition_settings_email, video_ignition_settings_phone },
            };
        }

        return map;
    }
}
