// @flow

import { action, computed, observable } from 'mobx';
import queryString from 'query-string';

import Organization from 'models/Organization';

import placeStore from 'stores/place';
import tokenStore from 'stores/token';
import userInfoStore from 'stores/userInfo';

const ORGANIZATION_STORAGE = 'selectedOrganizationId';

class OrganizationStore {
    @observable adminOrganization: Organization;
    @observable restored: boolean = false;

    @computed
    get organization(): Organization {
        if (userInfoStore.isAdmin) return this.adminOrganization;
        // $FlowFixMe
        return userInfoStore.organization;
    }

    get storageOrganization(): ?Organization {
        if (!userInfoStore.isAdmin) return null;

        const query = queryString.parse(window.location.search);
        const value = query.organization || localStorage.getItem(ORGANIZATION_STORAGE);
        return value ? new Organization().set(JSON.parse(value)) : null;
    }

    @action
    async selectAdminOrganization(organization: Organization) {
        const currentId = (this.organization || {}).id;
        if (currentId === organization.id) return;

        localStorage.setItem(ORGANIZATION_STORAGE, JSON.stringify(organization));

        this.adminOrganization = organization;
        placeStore.set(await organization.places());
    }

    @action
    clear() {
        placeStore.clear();
        localStorage.removeItem(ORGANIZATION_STORAGE);

        // $FlowFixMe
        this.adminOrganization = null;
    }

    @action
    restore() {
        const storageOrganization = this.storageOrganization;
        this.restored = true;

        if (!tokenStore.jwtToken || !storageOrganization) this.clear();
        else if (storageOrganization) this.selectAdminOrganization(storageOrganization);
    }
}

export default new OrganizationStore();
