// @flow

import { action, observable } from 'mobx';

class SidebarStore {
    @observable sidebar: ?string;

    @action
    open(sidebar: string): void {
        this.sidebar = sidebar;
    }

    @action.bound
    close(): void {
        this.sidebar = null;
    }
}

export default new SidebarStore();
