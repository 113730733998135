import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default function Consent({ onChecked }) {
    const [checked, setChecked] = React.useState(false);

    const handleCheck = () => {
        onChecked(!checked);
        setChecked(!checked);
    };
    return (
        <>
            <div>
                By selecting this toggle, I confirm that I have obtained permission to use a customer’s name,
                photographic likeness, or vehicle in all forms and media for advertising, trade, and any other lawful
                purposes. This includes popular online social networking sites: Facebook, YouTube, Twitter, Instagram,
                Cars.com, etc. If requested, the customer has also signed documentation to this effect.
            </div>
            <div style={{ marginTop: '24px' }}>
                <Checkbox label="Customer Provided Consent" onChange={handleCheck} toggle />
            </div>
        </>
    );
}

Consent.propTypes = {
    onChecked: PropTypes.func.isRequired,
};
