// @flow

import * as React from 'react';
import { observer } from 'mobx-react';

import Page from 'components/Page';
import PhotoLeaderboardStore from 'photo/stores/photoLeaderboard/photoLeaderboardStore';
import placeStore from 'stores/place';
import PlaceMonitor from 'components/Place/Monitor';
import ReportHeader from 'components/Report/Header';
import ReportBody from 'components/Report/Body';
import DataPlaceholder from 'components/Data/Placeholder';
import Sortable from 'components/Sortable';
import InlineLoader from 'components/InlineLoader';
import Filters from 'models/Filters';
import { action, observable } from 'mobx';
import { Button, Icon, Label, Segment } from 'semantic-ui-react';
import ActionButton from 'components/ActionButton';
import FiltersPopup from 'components/Filters/Popup';
import FiltersBar from 'components/Filters/Bar';
import MediaQueryDesktop from 'components/MediaQuery/Desktop';
import moment from 'moment';
import ReportStats from 'components/Report/Stats';
import ReportStatsData from 'components/Report/Stats/Data';
import TriggerDownload from 'components/TriggerDownload';

type Props = {};

@observer
export default class LeaderboardsPage extends React.Component<Props> {
    @observable filters: Filters;

    load = async () => {
        this.setFilters(this.buildFiltersFromDefaults());

        await PhotoLeaderboardStore.filter(this.filters);
    };

    @action
    setFilters(filters: Filters) {
        this.filters = filters;
    }

    buildFiltersFromDefaults(): Filters {
        const filters = new Filters();
        const defaultStartDate = moment()
            .subtract(1, 'year')
            .format('YYYY-MM-DD');
        const defaultEndDate = moment().format('YYYY-MM-DD');

        filters
            .addFilterGroup('content_type', 'Content Type', 'All Content Types')
            .addFilter('customer', 'Customer', true)
            .addFilter('product', 'Product', true);

        filters.setDefaultDateRange(defaultStartDate, defaultEndDate);
        filters.resetDateRange();

        return filters;
    }

    handleFiltering = () => {
        PhotoLeaderboardStore.filter(this.filters);
    };

    handleReset = () => {
        this.setFilters(this.buildFiltersFromDefaults());
    };

    handleSort = (sortBy: string) => {
        PhotoLeaderboardStore.sort(sortBy);
    };

    get actions(): React.Node {
        return (
            <React.Fragment>
                <TriggerDownload
                    filename="cf_leaderboards.xlsx"
                    link={PhotoLeaderboardStore.getExportLink(this.filters)}
                >
                    {(handleOnClick, saving) => (
                        <ActionButton
                            onClick={handleOnClick}
                            text="Export XLSX"
                            icon="file excel outline"
                            saving={saving}
                        />
                    )}
                </TriggerDownload>
                <FiltersPopup
                    onChange={this.handleFiltering}
                    availableFilterGroups={[]}
                    onReset={this.handleReset}
                    filters={this.filters}
                    trigger={<ActionButton icon="filter" text="Filters" />}
                />
            </React.Fragment>
        );
    }

    get mobileActions(): React.Node {
        return (
            <TriggerDownload filename="cf_leaderboards.xlsx" link={PhotoLeaderboardStore.getExportLink(this.filters)}>
                {(handleOnClick, saving) => (
                    <Button className="option-btn" onClick={handleOnClick} saving={saving}>
                        <Icon name="file excel outline" /> Export XLSX
                    </Button>
                )}
            </TriggerDownload>
        );
    }

    get mobileFilters(): React.Node {
        return (
            <FiltersBar
                filters={this.filters}
                onClick={this.handleFiltering}
                availableFilterGroups={[]}
                withPopup={
                    <FiltersPopup
                        onChange={this.handleFiltering}
                        onReset={this.handleReset}
                        filters={this.filters}
                        availableFilterGroups={[]}
                        trigger={
                            <Label className="tags-field label-btn no-icon clickable">
                                <Icon name="filter" /> Filters
                            </Label>
                        }
                    />
                }
            />
        );
    }

    get reportHeader(): React.Node {
        return <ReportHeader title={placeStore.place.name} dates={this.filters.dateRange} hideSideSection />;
    }

    render() {
        return <PlaceMonitor render={this.renderContent} onChange={this.load} />;
    }

    renderContent = () => {
        const { leaderboards, loading, sortedBy, sortDirection } = PhotoLeaderboardStore;

        return (
            <Page
                className="reports tabs"
                actions={this.actions}
                mobileActions={this.mobileActions}
                mobileFilters={this.mobileFilters}
                beforeInner={this.reportHeader}
            >
                <Segment attached="bottom" className="tab custom simple-tabs active">
                    {leaderboards && (
                        <ReportStats four>
                            <ReportStatsData
                                title="Total Submissions"
                                value={leaderboards.total_submissions}
                                tooltip="Number of submissions created in the selected time range"
                            />
                            <ReportStatsData
                                title="Total Videos"
                                value={leaderboards.total_videos}
                                tooltip="Number of videos uploaded in the selected time range"
                            />
                            <ReportStatsData
                                title="Total Photos"
                                value={leaderboards.total_photos}
                                tooltip="Number of photos uploaded in the selected time range"
                            />
                            <ReportStatsData
                                title="Total Testimonials"
                                value={leaderboards.total_testimonials}
                                tooltip="Number of testimonials added in the selected time range"
                            />
                        </ReportStats>
                    )}
                    <ReportBody>
                        {loading && (
                            <div className="no-data">
                                <InlineLoader />
                            </div>
                        )}

                        {!loading && (
                            <React.Fragment>
                                <MediaQueryDesktop>
                                    <FiltersBar
                                        filters={this.filters}
                                        onClick={this.handleFiltering}
                                        availableFilterGroups={[]}
                                    />
                                </MediaQueryDesktop>
                                <div className="section clearfix">
                                    <DataPlaceholder
                                        for={leaderboards && leaderboards.data && leaderboards.data.length}
                                    >
                                        <div className="scroll-mobile">
                                            {leaderboards && (
                                                <Sortable
                                                    className="ui basic table padded unstackable scrollable custom row-line"
                                                    data={leaderboards.data}
                                                    onSort={this.handleSort}
                                                    hideSpacer
                                                    sortBy={sortedBy}
                                                    sortDirection={sortDirection}
                                                    async
                                                >
                                                    <Sortable.Column title="User Name" property="user_name" sortable />
                                                    <Sortable.Column
                                                        title="Total Submissions"
                                                        property="submissions"
                                                        sortable
                                                    />
                                                    <Sortable.Column title="Videos" property="videos" sortable />
                                                    <Sortable.Column title="Photos" property="photos" sortable />
                                                    <Sortable.Column
                                                        title="Testimonials"
                                                        property="testimonials"
                                                        sortable
                                                    />
                                                </Sortable>
                                            )}
                                        </div>
                                    </DataPlaceholder>
                                </div>
                            </React.Fragment>
                        )}
                    </ReportBody>
                </Segment>
            </Page>
        );
    };
}
