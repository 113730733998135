// @flow

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Field } from 'app/external/mobx-form-for';
import { Button, Form as SemanticForm } from 'semantic-ui-react';

import Password from 'models/Password';

import authApi from 'api/authApi';
import GuestForm from 'components/Guest/Form';
import { toast, httpResponseErrors } from 'helpers';

type Props = {};

type State = {
    loading: boolean,
};

const inputProps = {
    required: true,
    transparent: true,
    inverted: true,
    iconPosition: 'left',
};

@observer
export default class ForgotPasswordPage extends React.Component<Props, State> {
    @observable password: Password = new Password();
    state = { loading: false };

    handleSubmit = async (e: any, data: { email: string }) => {
        this.setState(() => ({ loading: true }));

        try {
            const { message } = await authApi.requestPasswordReset(data.email);
            toast.success(message);
        } catch (e) {
            toast.error(httpResponseErrors(e));
        } finally {
            this.setState(() => ({ loading: false }));
        }
    };

    render() {
        return (
            <GuestForm for={this.password} onSubmit={this.handleSubmit}>
                <SemanticForm.Field>
                    <Field name="email" icon="mail" {...inputProps} />
                </SemanticForm.Field>

                <SemanticForm.Field>
                    <Button primary className="submit">
                        Reset Password
                    </Button>
                </SemanticForm.Field>
            </GuestForm>
        );
    }
}
