// @flow

export default function getErrorMessageFromSaveModelError(error: Object, defaultMessage?: string): string {
    let message = defaultMessage || 'Failed to save changes check provided information and try again';

    if (error.response && error.response.data.error) {
        message = error.response.data.error;
    }

    return message;
}
