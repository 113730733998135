// @flow

import { action, computed, observable } from 'mobx';

import placeStore from 'stores/place';
import sidebarStore from 'stores/sidebar';

import PublishedContent from 'models/PublishedContent';

class PublishedContentStore {
    @observable content: ?PublishedContent;
    @observable contentCollection: ?(PublishedContent[]);
    @observable loading: boolean = false;

    @action
    startLoading() {
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
    }

    @action
    setContentCollection(content: PublishedContent[]) {
        this.contentCollection = content;

        const sorted = this.sortedContent;
        if (sorted && sorted.length) this.content = sorted[0];
    }

    @action
    select(content: PublishedContent) {
        this.content = content;
        sidebarStore.close();
    }

    @action
    add(content: PublishedContent) {
        this.contentCollection && this.contentCollection.push(content);
        this.content = content;
    }

    @action
    resetState() {
        this.contentCollection = null;
        this.content = null;
    }

    async load(ignoreCache?: boolean) {
        this.startLoading();

        if (this.contentCollection) this.resetState();
        this.setContentCollection(await placeStore.place.publishedContent({ ignoreCache }));

        this.stopLoading();
    }

    async forceLoad() {
        await this.load(true);
    }

    @computed
    get sortedContent(): ?(PublishedContent[]) {
        return this.contentCollection;
    }
}

export default new PublishedContentStore();
