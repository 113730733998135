// @flow

import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

type Props = {
    src: string,
};

const FlexImage = (props: Props) => {
    const [loading, setLoading] = useState(true);

    const onImageLoad = () => {
        setLoading(false);
    };

    useEffect(() => {
        if (!loading) setLoading(true);
    }, [props.src]);

    return (
        <React.Fragment>
            <div className="flex-image-fit clearfix" onLoad={onImageLoad}>
                <img src={props.src} alt="media" className={loading ? '' : 'visible'} />
            </div>
            {loading && (
                <div className="flex-image-fit-loader">
                    <Icon name="spinner" loading />
                </div>
            )}
        </React.Fragment>
    );
};

export default FlexImage;
