// @flow

import moment from 'moment';

type DateRange = {
    startDate: ?Date,
    endDate: ?Date,
};

export function dateRangeFromPreset(preset: string, year: number): DateRange {
    switch (true) {
        case /quarter/i.test(preset): {
            const quarterStartDate = moment(`${year}-${preset[0]}`, 'YYYY-Q').toDate();
            const quarterEndDate = moment(`${year}-${preset[0]}`, 'YYYY-Q')
                .add(2, 'months')
                .endOf('month')
                .toDate();
            const today = moment().toDate();

            return {
                startDate: quarterStartDate,
                endDate: quarterEndDate > today ? today : quarterEndDate,
            };
        }
        case /this month/i.test(preset):
            return {
                startDate: moment()
                    .startOf('month')
                    .toDate(),
                endDate: moment().toDate(),
            };
        case /last month/i.test(preset):
            return {
                startDate: moment()
                    .subtract(1, 'month')
                    .startOf('month')
                    .toDate(),
                endDate: moment()
                    .subtract(1, 'month')
                    .endOf('month')
                    .toDate(),
            };
        case /(\d)+( )+overview/i.test(preset):
            return {
                startDate: moment(preset.split(' ')[0], 'YYYY')
                    .startOf('year')
                    .toDate(),
                endDate: moment(preset.split(' ')[0], 'YYYY')
                    .endOf('year')
                    .toDate(),
            };
        default:
            return { startDate: null, endDate: null };
    }
}
