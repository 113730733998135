// @flow

import * as React from 'react';

import ReputationLeaderboard from 'models/ReputationLeaderboard';

import reputationLeaderboardStore from 'reputation/stores/reputationLeaderboard';

import DataPlaceholder from 'components/Data/Placeholder';
import Sortable from 'components/Sortable';

type Props = {
    data: ReputationLeaderboard[],
};

type State = {
    sortBy: string,
    sortDirection: string,
};
export default class Leaderboard extends React.Component<Props, State> {
    handleSort = (sortBy: string) => {
        reputationLeaderboardStore.sort(sortBy);
    };

    render() {
        const { data } = this.props;
        const { sortProperty, sortDirection } = reputationLeaderboardStore;
        let totalInvites,
            totalRecommend,
            totalNotRecommend,
            totalNoResponse = 0;
        if (data) {
            totalInvites = data.reduce((total, leaderboard) => total + leaderboard.invites_sent, 0);
            totalRecommend = data.reduce((total, leaderboard) => total + leaderboard.would_recommend, 0);
            totalNotRecommend = data.reduce((total, leaderboard) => total + leaderboard.would_not_recommend, 0);
            totalNoResponse = data.reduce((total, leaderboard) => total + leaderboard.no_response, 0);
        }
        const updatedData = data.filter(item => item !== undefined);
        updatedData.push({
            user_name: 'TOTALS',
            invites_sent: totalInvites,
            would_recommend: totalRecommend,
            would_not_recommend: totalNotRecommend,
            no_response: totalNoResponse,
            sent_via_email: 0,
            sent_via_text: 0,
            user_id: 1234567890,
        });

        return (
            <React.Fragment>
                <DataPlaceholder for={updatedData.length}>
                    <div className="section clearfix">
                        <h3 className="titles">Leaderboards</h3>
                        <div className="scroll-mobile">
                            <Sortable
                                className="ui basic striped table padded unstackable scrollable custom"
                                data={updatedData}
                                onSort={this.handleSort}
                                hideSpacer
                                sortBy={sortProperty || ''}
                                sortDirection={sortDirection || ''}
                                async
                            >
                                <Sortable.Column title="Username" property="user_name" sortable />
                                <Sortable.Column title="Total Invites Sent" property="invites_sent" sortable />
                                <Sortable.Column title="Yes, I would recommend" property="would_recommend" sortable />
                                <Sortable.Column
                                    title="No, I would not recommend"
                                    property="would_not_recommend"
                                    sortable
                                />
                                <Sortable.Column title="No response" property="no_response" sortable />
                            </Sortable>
                        </div>
                    </div>
                </DataPlaceholder>
            </React.Fragment>
        );
    }
}
