// @flow

import * as React from 'react';
import { Prompt } from 'react-router-dom';
import Model from 'models/Model';

type Props = {
    when?: boolean,
    for?: Model,
};

export default class PromptUnsaved extends React.Component<Props> {
    render() {
        const { when, for: object } = this.props;

        return (
            <Prompt
                message="You have unsaved changes. Are you sure you want to leave?"
                when={object ? object.changed : when}
            />
        );
    }
}
