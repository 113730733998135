// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Pagination as SemanticPagination } from 'semantic-ui-react';

import InlineLoader from 'components/InlineLoader';

type Props = {
    activePage: number,
    totalPages: number,
    total: number,
    onPageChange: Function,
    loading: boolean,
};

@observer
export default class Pagination extends React.Component<Props> {
    get displayFirstArrows() {
        return this.props.activePage === 1 ? null : undefined;
    }

    get displayLastArrows() {
        const { activePage, totalPages } = this.props;
        return activePage === totalPages ? null : undefined;
    }

    render() {
        const { total, totalPages, loading, ...otherProps } = this.props;

        if (loading) return <InlineLoader />;
        if (!total) return 'No results returned';
        if (totalPages <= 1) return null;

        return (
            <SemanticPagination
                {...otherProps}
                totalPages={totalPages}
                firstItem={this.displayFirstArrows}
                prevItem={this.displayFirstArrows}
                nextItem={this.displayLastArrows}
                lastItem={this.displayLastArrows}
            />
        );
    }
}
