// @flow

import Model from './Model';

type LeaderboardsData = {
    user_id: number,
    user_name: string,
    submissions: number,
    videos: number,
    photos: number,
    testimonials: number,
};

export default class PhotoLeaderboard extends Model {
    path = 'cfleaderboard';

    total_submissions: number;
    total_videos: number;
    total_photos: number;
    total_testimonials: number;

    data: LeaderboardsData[];
}
