import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Dropdown, Popup } from 'semantic-ui-react';
import Calendar from '../Calendar';
import TimePicker from '../TimePicker';

const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

const formatDateTime = date => moment(date).tz(timeZoneString).format('MM/DD/YYYY hh:mm A z');
const formatDate = date => moment(date).tz(timeZoneString).format('MM/DD/YYYY');

const DatePickerSelect = ({
    id,
    value,
    maxDate,
    minDate,
    onDateChange,
    onTimeChange,
    position,
    disableTime,
    ...props
}) => {
    return (
        <Popup
            style={{ padding: 0, height: 'fit-content' }}
            position={position || 'bottom left'}
            pinned
            on="click"
            content={
                <>
                    <Calendar
                        date={value}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={onDateChange}
                        className="kui-react-date-picker"
                    />
                    {!disableTime && (
                        <div className="kui-date-picker-action-footer">
                            <TimePicker value={value} onChange={onTimeChange} />
                        </div>
                    )}
                </>
            }
            trigger={
                <Dropdown
                    id={id}
                    text={formatDate(value)}
                    value={formatDateTime(value)}
                    fluid
                    selection
                    noResultsMessage={null}
                    {...props}
                />
            }
        />
    );
};

DatePickerSelect.defaultProps = {
    label: 'Date',
    placement: 'bottom-end',
    onChange: Function.prototype,
};

DatePickerSelect.propTypes = {
    id: PropTypes.string,
    onDateChange: PropTypes.func,
    onTimeChange: PropTypes.func,
    className: PropTypes.string,
    position: PropTypes.oneOf([
        'top left',
        'top center',
        'top right',
        'left center',
        'right center',
        'bottom left',
        'bottom center',
        'bottom right',
    ]),
    value: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    disableTime: PropTypes.bool,
};

export default DatePickerSelect;
