// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { route } from 'helpers';
import Sidebar from 'components/Sidebar';

export default class NotificationSidebar extends React.Component<*> {
    render() {
        return (
            <Sidebar id="notifications-menu" title="Notifications" direction="right">
                <div className="content-group clearfix">
                    <div className="content-group-title">Updates</div>

                    <div className="list-item inverted two-lines icon unseen clearfix">
                        <div className="thumb no-select outlined filled">
                            <i className="announcement icon" />
                        </div>

                        <Link className="main" to={route('general', 'changeLog')}>
                            <span className="top-text">Catalyst update</span>
                            <span className="main-text">See what&apos;s new in V1.0.0</span>
                        </Link>
                    </div>
                </div>
            </Sidebar>
        );
    }
}
