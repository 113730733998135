// @flow

import { observable } from 'mobx';
import { field } from 'app/external/mobx-form-for';

import Model from './Model';

export default class PlaceNotifications extends Model {
    @observable place_id: Number;
    @observable place_name: string;

    @observable
    @field({ type: 'checkbox', label: ' ' })
    active: boolean;

    @observable
    @field({
        type: 'select',
        options: [
            { value: 1, text: 'Hourly' },
            { value: 2, text: 'Daily' },
            { value: 3, text: 'Weekly' },
            { value: 4, text: 'Monthly' },
        ],
        label: 'Notification Frequency',
        search: false,
    })
    frequency: number = 2;

    @observable
    @field({ type: 'switch', label: 'Send notifications for Positive Reviews' })
    positive_reviews: boolean;

    @observable
    @field({ type: 'switch', label: 'Send notifications for Negative Reviews' })
    negative_reviews: boolean;

    @observable
    @field({ type: 'switch', label: 'Send notifications for Feedback' })
    feedback: boolean;

    @observable
    @field({ type: 'switch', label: 'Send notifications for Feedback from Service Invites' })
    service_feedback: boolean;

    @observable
    @field({ type: 'switch', label: 'Send notifications for Feedback from Sales Invites' })
    sales_feedback: boolean;

    @observable
    @field({ type: 'switch', label: 'Send notifications for Unsubscribes' })
    unsubscribes: boolean;
}
