// @flow

import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import Review from 'models/Review';
import SiteInfo, { type SitesInfoByChannel } from 'models/SiteInfo';
import { ellipsis } from 'helpers';

import Rating from 'components/Rating';
import ReviewThumbnail from 'reputation/components/Review/Thumbnail';

type Props = {
    review: Review,
    ellipsisBreakpoint?: number,
};

@observer
export default class ReviewMain extends React.Component<Props> {
    @observable sitesInfoByChannel: ?SitesInfoByChannel;

    @action
    setSitesInfoByChannel(sitesInfoByChannel: SitesInfoByChannel) {
        this.sitesInfoByChannel = sitesInfoByChannel;
    }

    async loadSitesInfo() {
        this.setSitesInfoByChannel(await SiteInfo.allByChannel());
    }

    componentWillMount() {
        this.loadSitesInfo();
    }

    @computed
    get channelDisplayName(): string {
        const channel = this.props.review.channel;
        if (!this.sitesInfoByChannel) return channel;

        const siteInfo = this.sitesInfoByChannel[channel];
        return siteInfo ? siteInfo.display_name : channel;
    }

    render() {
        const { review, ellipsisBreakpoint } = this.props;

        return (
            <React.Fragment>
                <ReviewThumbnail review={review} />

                <div className="main">
                    <span className="top-text">
                        Review from <b>{this.channelDisplayName}</b>
                    </span>

                    <span className="main-text">
                        {ellipsisBreakpoint ? ellipsis(review.customer, ellipsisBreakpoint) : review.customer}
                    </span>

                    {review.rating && (
                        <Rating
                            type="for-list"
                            value={review.rating}
                            negative={review.hasNegativeRating ? 'negative' : ''}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }
}
