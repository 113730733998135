// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

type Props = {
    src?: string,
    captureVideoRef?: Function,
};

@observer
export default class FlexVideo extends React.Component<Props> {
    videoRef: { current: null | HTMLVideoElement };
    flexVideoRef: { current: null | HTMLDivElement };

    constructor(props: Props) {
        super(props);

        this.videoRef = React.createRef();
        this.flexVideoRef = React.createRef();
    }

    componentDidMount() {
        this.props.captureVideoRef && this.props.captureVideoRef(this.videoRef);
        window.addEventListener('resize', this.updateVideoSize);

        if (this.videoRef.current) {
            this.videoRef.current.addEventListener('loadeddata', this.updateVideoSize);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateVideoSize);
    }

    updateVideoSize = () => {
        if (this.videoRef.current && this.flexVideoRef.current)
            this.videoRef.current.style.height = `${this.flexVideoRef.current.offsetHeight}px`;
    };

    render() {
        const { src } = this.props;
        return (
            <div className="flex-video-fit clearfix" ref={this.flexVideoRef}>
                <div className="video-wrapper">
                    <video controlsList="nodownload" disablepictureinpicture controls ref={this.videoRef}>
                        <source src={src} type="video/mp4" />
                    </video>
                </div>
            </div>
        );
    }
}
