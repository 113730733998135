// @flow
import React from 'react';

type Props = {
    stream: any,
};

export const VideoPreview = (props: Props) => {
    let videoPreviewRef = React.useRef();

    React.useEffect(() => {
        if (videoPreviewRef.current && props.stream) {
            videoPreviewRef.current.srcObject = props.stream;
        }
    }, [props.stream]);

    if (!props.stream) {
        return null;
    }

    return <video id="videoView" ref={videoPreviewRef} width={'100%'} autoPlay />;
};
