// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';
import FilterGroup from 'models/FilterGroup';
import { Field, FieldGroup } from 'app/external/mobx-form-for';
import { observable, action } from 'mobx';

type Props = ComponentProps & {
    group: FilterGroup,
    labels: ?Object,
};

@observer
export default class FilterGroupField extends React.Component<Props> {
    @observable form: Object;

    constructor(props) {
        super(props);

        this.setForm(props.value);
    }

    @action
    setForm(form: Object) {
        this.form = form;
    }

    onChange = () => {
        const { group, value } = this.props;

        group.fromObject(value);
    };

    get schema() {
        const { group, value, labels } = this.props;
        const schema = {};

        Object.keys(value).forEach(filter => {
            const filterObject = group.getFilter(filter);

            if (!filterObject) {
                return;
            }

            schema[filter] = {
                type: 'checkbox',
                className: 'full custom',
                value: filterObject.value,
                label: filterObject.label,
                disabled: !group.canRemoveFilter && group.getFilterValue(filter),
            };

            if (labels && labels[filter]) {
                schema[filter].label = labels[filter];
            }
        });

        return schema;
    }

    filtersColumn(col: number): Array<string> {
        const { value } = this.props;
        const all = Object.keys(value);
        const halfSize = Math.ceil(all.length / 2);

        return all.slice(col * halfSize, (col + 1) * halfSize);
    }

    render() {
        const { group, value } = this.props;

        return (
            <FieldGroup for={value} schema={this.schema}>
                <div className="popup-section clearfix">
                    <div className="small-titles">{group.label}</div>
                    {[0, 1].map(i => (
                        <div key={i} className="half-section clearfix">
                            {this.filtersColumn(i).map(filter => (
                                <Field key={filter} name={filter} onChange={() => this.onChange()} />
                            ))}
                        </div>
                    ))}
                </div>
            </FieldGroup>
        );
    }
}
