// @flow

import Model from './Model';
import { dateTime } from 'helpers';
import ContentCustomer from 'models/ContentCustomer';
import { action, observable } from 'mobx';

export default class PublishedContent extends Model {
    path = 'content-submissions/published';

    title: string;
    description: string;
    content_testimonials: Object[];
    content_videos: Object[];
    content_photos: Object[];
    created_at: string;

    @observable
    content_customer: Object;

    get createdAtDate(): string {
        return dateTime.toDate(this.created_at);
    }

    get createdAtTime(): string {
        return dateTime.toHour(this.created_at);
    }

    get publishedAtDate(): string {
        return dateTime.toDate(this.published_at);
    }

    get publishedAtTime(): string {
        return dateTime.toHour(this.published_at);
    }

    get testimonial(): string {
        if (this.content_testimonials && this.content_testimonials.length) {
            return this.content_testimonials[0].text;
        }
        return '';
    }

    get hasMedia(): boolean {
        return (
            (this.content_videos && this.content_videos.length > 0) ||
            (this.content_photos && this.content_photos.length > 0)
        );
    }

    @action
    setContentConstumer(customer: ContentCustomer) {
        this.content_customer = customer;
    }

    getCustomerForPhotoId(id: number): ?ContentCustomer {
        if (!this.content_customer || this.content_customer.content_photo_id !== id) return;
        return this.content_customer;
    }

    normalize_content_customer(content_customer: Object): ?ContentCustomer {
        if (content_customer) {
            const customer = new ContentCustomer();
            customer.name = content_customer.name;
            customer.email = content_customer.email;
            customer.phone = content_customer.phone;
            customer.content_story_id = content_customer.content_story_id;
            customer.content_photo_id = content_customer.content_photo_id;
            customer.share_media = content_customer.share_media === 1;
            customer.send_invite = content_customer.send_invite === 1;
            return customer;
        }
    }
}
