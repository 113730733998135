// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import SubmissionMediaToolbar from 'photo/components/Submission/Media/Toolbar';
import ContentCustomer from 'models/ContentCustomer';

type Props = {
    children: React.Node,
    mediaType: string,
    mediaURL: string,
    locked?: boolean,
    photo?: string,
    product?: boolean,
    editable?: boolean,
    onRemoveClick?: Function,
    onShare?: Function,
    customer?: ContentCustomer,
    disabled?: boolean,
};

const SubmissionMedia = observer((props: Props) => {
    const {
        locked,
        mediaType,
        mediaURL,
        product,
        editable,
        onRemoveClick,
        onShare,
        customer,
        disabled,
        children,
    } = props;

    return (
        <div className="media-item clearfix">
            <div className="media-item-wrapper clearfix">
                <SubmissionMediaToolbar
                    product={!!product}
                    editable={editable}
                    locked={locked}
                    mediaType={mediaType}
                    mediaURL={mediaURL}
                    onRemoveClick={onRemoveClick}
                    onShare={onShare}
                    customer={customer}
                    disabled={!!disabled}
                />
                {children}
            </div>
        </div>
    );
});

export default SubmissionMedia;
