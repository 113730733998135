// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Sidebar } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Form } from 'app/external/mobx-form-for';
import queryString from 'query-string';

import sidebarStore from 'stores/sidebar';
import routeStore from 'stores/route';

import { type App, type AppRoute } from 'types';
import * as apps from 'apps';
import pagePresets from './pagePresets';

import { toast, historyPusher } from 'helpers';

import Resizer from 'components/Resizer';
import AppSidebar from 'components/App/Sidebar';
import Header from 'components/Header';
import Breadcrumbs from 'components/Breadcrumbs';
import PromptUnsaved from 'components/Prompt/Unsaved';
import MobileActionsMenu from 'components/MobileActionsMenu';
import MediaQueryMobile from 'components/MediaQuery/Mobile';

type Props = {
    for?: Object,
    children: React.Node,
    preset?: string,
    onSubmit?: Function,
    onSearch?: Function,
    onSearchSelect?: Function,
    actions?: React.Node,
    mobileActions?: React.Node,
    beforeInner?: React.Node,
    headerTitle?: boolean,
    headerActions?: React.Node,
    className?: string,
    bodyClass?: string,
    additionalBodyClass?: string,
    title?: string,
    mobileTitle?: string,
    breadcrumbs?: string[],
    closeable?: boolean | string,
    breakpoint?: number,
    basic?: boolean,
    history?: Object,
    mobileFilters?: React.Node,
};

@observer
export default class Page extends React.Component<Props> {
    @observable saving: boolean = false;

    static contextTypes = {
        app: PropTypes.object.isRequired,
        route: PropTypes.string.isRequired,
    };

    @action
    setSaving(saving: boolean) {
        this.saving = saving;
    }

    get propsWithPreset(): Props {
        const { app, route } = this.context;

        const preset = app.routes[route].preset;
        const presetValues = preset ? pagePresets[preset] : {};

        return { preset, ...presetValues, ...this.props };
    }

    autoCloseSidebar() {
        if (sidebarStore.sidebar) setTimeout(sidebarStore.close, 1);
    }

    setCloseRoute() {
        if (!this.propsWithPreset.closeable) routeStore.setCloseRoute(this.context.app, this.context.route);
    }

    displayMessageParams() {
        const { status, message } = queryString.parse(window.location.search);
        if (!!status && this.props.history) {
            // $FlowFixMe
            toast[status](message);
            historyPusher.removeQueryParams(this.props.history);
        }
    }

    handleSubmit = async (event: Event, data: Object) => {
        event.preventDefault();

        this.setSaving(true);

        try {
            // $FlowFixMe
            await this.propsWithPreset.onSubmit(event, data);
        } finally {
            this.setSaving(false);
        }
    };

    componentDidMount() {
        this.autoCloseSidebar();
        this.setCloseRoute();
        this.displayMessageParams();
    }

    render() {
        const props = this.propsWithPreset;
        const app: App = this.context.app;
        const route: AppRoute = app.routes[this.context.route];

        const bodyClasses = [];
        if (props.bodyClass) bodyClasses.push(props.bodyClass);
        else if (app.className) bodyClasses.push(app.className);
        if (props.additionalBodyClass) bodyClasses.push(props.additionalBodyClass);

        if (sidebarStore.sidebar) bodyClasses.push('disable-scroll');

        const Wrapper = props.onSubmit ? Form : 'div';
        const wrapperProps = props.onSubmit ? { for: props.for, className: 'ui form' } : {};

        return (
            <Sidebar.Pushable>
                <Helmet>
                    <title>{route.title ? `Catalyst - ${route.title}` : 'Catalyst'}</title>
                    <meta name="theme-color" content={app.browserThemeColor} />
                    <body className={bodyClasses.join(' ')} />
                </Helmet>

                {this.renderAppSidebars(apps.general, app)}
                {app && app !== apps.general && this.renderAppSidebars(app, app)}

                <Sidebar.Pusher dimmed={!!sidebarStore.sidebar} onClick={sidebarStore.close}>
                    <Wrapper onSubmit={props.onSubmit && this.handleSubmit} {...wrapperProps}>
                        <div className="dimmer pusher">
                            <Header
                                actions={props.actions}
                                closeable={!!props.closeable}
                                saveable={!!props.onSubmit}
                                saving={this.saving}
                                disableSave={props.for && !props.for.changed}
                                title={props.title}
                                mobileTitle={props.mobileTitle}
                                basic={props.basic}
                                hideLocationSelection={route.notLocationDependent}
                                onSearch={props.onSearch}
                                onSearchSelect={props.onSearchSelect}
                            />

                            <Resizer breakpoint={props.breakpoint} className="scroll-wrapper" minHeightMobile>
                                <MediaQueryMobile>
                                    {(!!this.props.mobileActions ||
                                        !!props.closeable ||
                                        !!props.onSubmit ||
                                        !!this.props.mobileFilters) && (
                                        <MobileActionsMenu
                                            actions={this.props.mobileActions}
                                            closeable={!!props.closeable}
                                            saveable={!!props.onSubmit}
                                            saving={this.saving}
                                            disableSave={props.for && !props.for.changed}
                                            filters={this.props.mobileFilters}
                                        />
                                    )}
                                </MediaQueryMobile>
                                <div id="content-wrapper">
                                    {props.beforeInner}
                                    {props.breadcrumbs && <Breadcrumbs routes={props.breadcrumbs} />}
                                    {props.headerActions && <div style={{ float: 'right' }}>{props.headerActions}</div>}
                                    {props.headerTitle && <h1 className="title">{route.title}</h1>}

                                    <div id="content-inner-wrapper" className={props.className}>
                                        {props.for && <PromptUnsaved for={props.for} />}
                                        {props.children}
                                    </div>
                                </div>
                            </Resizer>
                        </div>
                    </Wrapper>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }

    renderAppSidebars(app: App, currentApp: App) {
        const sidebars = app.sidebars;
        if (!sidebars) return null;
        // $FlowFixMe
        return Object.keys(sidebars).map(key => (
            <AppSidebar key={key} sidebar={`${app.feature}.${key}`}>
                {React.createElement(sidebars[key], { currentApp })}
            </AppSidebar>
        ));
    }
}
