// @flow

import * as apps from 'apps';
import type { App, AppRoute } from 'types';

export default function route(appValue: string | App, routeValue: string | AppRoute): string {
    const app = typeof appValue === 'string' ? apps[appValue] : appValue;
    const route = typeof routeValue === 'string' ? app.routes[routeValue] : routeValue;

    // $FlowFixMe
    if (!route) throw new Error(`Route ${app.path}.${routeValue} does not exist`);

    return `/${app.path ? app.path + '/' : ''}${route.path}`;
}
