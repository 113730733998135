// @flow

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import SortableAction from './Action';
import SortableColumn from './Column';

import SortableBody from './Body';
import SortableHead from './Head';

type Props = {
    children: React.Node,
    data: Object[],
    renderAdjacent?: Function,
    className?: string,
    hideSpacer?: boolean,
    onSort?: Function,
    sortBy?: string,
    sortDirection?: string,
    async?: boolean,
};

type State = {
    sortBy: ?string,
    sortDirection: 'asc' | 'desc',
};

@observer
export default class Sortable extends React.Component<Props, State> {
    @observable
    sortProperties = {
        sortBy: this.props.sortBy || null,
        sortDirection: this.props.sortDirection || 'asc',
    };

    static Column = SortableColumn;
    static Action = SortableAction;

    @action.bound
    handleClick(property: string) {
        if (property === this.sortProperties.sortBy) {
            this.sortProperties.sortDirection = this.sortProperties.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortProperties = { sortBy: property, sortDirection: 'asc' };
        }
        if (this.props.onSort) this.props.onSort(property, this.sortProperties.sortDirection);
    }

    get className() {
        return (
            this.props.className ||
            'ui basic sortable-table nested shadowed single line table padded unstackable scrollable custom small'
        );
    }

    render() {
        const { async, children, data, renderAdjacent, hideSpacer } = this.props;
        const { sortBy, sortDirection } = this.sortProperties;

        const columnsCount = React.Children.count(children);
        const multiplier = sortDirection === 'asc' ? 1 : -1;
        const sortedData = async
            ? data
            : data
                  .concat()
                  .slice()
                  .sort((a, b) => {
                      const valueA = a[sortBy];
                      const valueB = b[sortBy];

                      return valueA < valueB ? -multiplier : valueA > valueB ? multiplier : 0;
                  });

        return (
            <div className="scroll-mobile">
                <table className={this.className}>
                    <thead>
                        <tr>
                            {React.Children.map(
                                children,
                                column =>
                                    column && (
                                        <SortableHead
                                            key={column.title}
                                            {...column.props}
                                            onClick={this.handleClick}
                                            sortBy={sortBy}
                                            sortDirection={sortDirection}
                                        />
                                    )
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {sortedData.map((row, index) => {
                            return (
                                <React.Fragment key={row.key || index}>
                                    <tr>
                                        {React.Children.map(
                                            children,
                                            column =>
                                                column && (
                                                    <SortableBody
                                                        key={`${column.title}-${index}`}
                                                        {...column.props}
                                                        data={row}
                                                    />
                                                )
                                        )}
                                    </tr>

                                    {renderAdjacent && (
                                        <tr>
                                            <td className="nested-row" colSpan={columnsCount}>
                                                <div className="nested-label">Assigned Location(s)</div>
                                                {renderAdjacent(row)}
                                            </td>
                                        </tr>
                                    )}
                                    {!hideSpacer && (
                                        <tr className="spacer">
                                            <td colSpan={columnsCount} />
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}
