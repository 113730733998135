// @flow

import * as React from 'react';
import { CSVLink } from 'react-csv';

type Props = {
    columns: { [key: string]: string },
    data: Object[],
    children: React.Node,
    className?: string,
};

export default class DataTableCsvButton extends React.Component<Props> {
    render() {
        const { columns, data, className, ...props } = this.props;
        const columnKeys = Object.keys(columns);
        const dataWithHeaders = [
            Object.values(columns),
            ...data.map(item => columnKeys.map(key => (item[key] !== undefined ? item[key] : ''))),
        ];

        if (!data) return null;

        return (
            <CSVLink className={className} data={dataWithHeaders} {...props}>
                {this.props.children}
            </CSVLink>
        );
    }
}
