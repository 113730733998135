// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

type Props = {
    breakpoint?: number,
    min?: number,
    minus?: number,
    multiply?: number,
    calculatedMobile?: boolean,
    minHeightMobile?: boolean,
    children: React.Node,
};

@observer
export default class Resizer extends React.Component<Props> {
    el: ?HTMLDivElement;

    handleResize = () => {
        const { breakpoint, min, minus, multiply, calculatedMobile, minHeightMobile } = this.props;

        const el = this.el;
        if (!el) return null;

        const top = el.getBoundingClientRect().top;
        const height = (min || window.innerHeight - top - (minus || 0)) * (multiply || 1);
        const isMobile = window.innerWidth < (breakpoint || 1024);

        if (!isMobile || calculatedMobile) {
            el.style.height = `${height}px`;
        } else {
            el.style.height = 'auto';
        }

        if (isMobile && minHeightMobile) {
            el.style.minHeight = `${height}px`;
        } else {
            el.style.minHeight = 'auto';
        }
    };

    componentDidMount() {
        setTimeout(() => this.handleResize());
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        const { children, ...props } = this.props;
        delete props.breakpoint;
        delete props.minus;
        delete props.calculatedMobile;
        delete props.minHeightMobile;

        return (
            <div ref={el => (this.el = el)} {...props}>
                {children}
            </div>
        );
    }
}
