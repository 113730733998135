// @flow

import { observable } from 'mobx';
import Model from './Model';
import Video from './Video';

export default class VideoShare extends Model {
    path = 'videos';

    @observable
    video_id: number;

    @observable
    name: string;

    @observable
    email: string;

    @observable
    share_via: string;

    @observable
    uuid: string;

    get url() {
        return `${this.video_id}/shares`;
    }

    notify() {
        this.create({ url: this.url });
    }

    static fromVideo(video: Video, via: string): VideoShare {
        const videoShare = new this();

        videoShare.video_id = video.id;
        videoShare.name = video.title;
        videoShare.uuid = video.share_uuid;
        videoShare.share_via = via;

        return videoShare;
    }
}
