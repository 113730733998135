// @flow

import * as React from 'react';

type Props = {
    id?: string,
    title?: string,
    children?: React.Node,
    className?: string,
    columns?: number,
};

const QUANTITY_CLASSES = ['', 'one', 'two', 'three', 'four'];

export default class ReportSection extends React.Component<Props> {
    static isReportSection(child: Object) {
        if (child.type === ReportSection) return true;

        const childClasses = (child.props.className || '').split(' ');
        return childClasses.includes('section');
    }

    getChildSectionCount(): number {
        if (this.props.columns) return this.props.columns;

        const { children } = this.props;
        if (!Array.isArray(children)) return 0;

        return children.filter(child => ReportSection.isReportSection(child)).length;
    }

    getClasses() {
        const count = this.getChildSectionCount();
        const classes: string[] = ['section'];

        if (count > 1) classes.push(QUANTITY_CLASSES[count], 'no-padding', 'clearfix');
        if (this.props.className) classes.push(this.props.className);

        return classes.join(' ');
    }

    getTitleClasses() {
        const titleClasses = ['titles'];
        if (this.getChildSectionCount() > 1) titleClasses.push('padded-title');
        return titleClasses.join(' ');
    }

    render() {
        const { id, title } = this.props;

        return (
            <div id={id} className={this.getClasses()}>
                {!title ? null : <h3 className={this.getTitleClasses()}>{title}</h3>}

                {this.props.children}
            </div>
        );
    }
}
