// @flow

import { type App } from 'types';

const app: App = {
    feature: 'can_edit_posts',
    path: '',
    title: 'Edit Posts',
    mobileTitle: 'EP',
    description: 'The ability to edit posts',
    className: 'editPosts',
    browserThemeColor: '#673999',
    icon: 'rs',
    routes: {},
    defaultRoute: '',
    // marketingMessageDisabled:
    //     'COMING SOON: Reach out to your Friendemic Client Success Representatives to join the pilot program!',
    disabledLink: 'http://www.friendemic.com/catalyst-publishing/',
};

export default app;
