// @flow

import React from 'react';
import { Button, Form, Icon, Input, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { action } from 'mobx';
import { formatPhoneNumber, validateEmail } from 'helpers';
import SettingsPanel from 'components/Settings/Panel';
import MaskedInput from 'react-maskedinput';
import { toast } from 'helpers';

import noContactListStore from 'stores/noContactList';

type Props = {};

type State = {
    email: string,
    emailName: string,
    emailValid: boolean,
    phone: string,
    phoneName: string,
    phoneValid: boolean,
};

@observer
export default class NoContactList extends React.Component<Props, State> {
    state = {
        email: '',
        emailName: '',
        emailValid: true,
        phone: '',
        phoneName: '',
        phoneValid: true,
    };

    handleEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
        if (validateEmail(event.target.value)) {
            this.setState({ email: event.target.value, emailValid: true });
            return;
        }

        this.setState({ email: event.target.value });
    };

    handlePhoneChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const phone = event.target.value.replace(/[^\d]/g, '');

        if (phone.length === 10) {
            this.setState({ phone, phoneValid: true });
            return;
        }

        this.setState({ phone });
    };

    handlePhoneNameChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ phoneName: event.target.value });
    };

    handleEmailNameChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ emailName: event.target.value });
    };

    @action
    handleAddEmailClick = (event: SyntheticEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!validateEmail(this.state.email)) {
            this.setState({ emailValid: false });
            return;
        }

        if (!this.hasDuplicates('email'))
            noContactListStore.stageEmail({
                email: this.state.email,
                name: this.state.emailName !== '' ? this.state.emailName : null,
            });

        this.setState({ email: '', emailName: '' });
    };

    @action
    handleAddPhoneClick = (event: SyntheticEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (this.state.phone.length !== 10) {
            this.setState({ phoneValid: false });
            return;
        }

        if (!this.hasDuplicates('phone'))
            noContactListStore.stagePhone({
                phone: this.state.phone,
                name: this.state.phoneName !== '' ? this.state.phoneName : null,
            });

        this.setState({ phone: '', phoneName: '' });
    };

    @action
    handleRemoveEmailClick = (event: MouseEvent, index: number) => {
        event.preventDefault();
        noContactListStore.unstageEmail(index);
    };

    @action
    handleRemovePhoneClick = (event: MouseEvent, index: number) => {
        event.preventDefault();
        noContactListStore.unstagePhone(index);
    };

    hasDuplicates = (type: string) => {
        this[type] = noContactListStore[`getStage${type.replace(/^\w/, c => c.toUpperCase())}`]();

        if (this[type].length === 0) return false;

        return this[type].find(item => {
            if (item[type].includes(this.state[type])) this.toggleDuplicatesWarning(type);
            return item[type].includes(this.state[type]);
        });
    };

    toggleDuplicatesWarning = (type: string) => {
        toast.error(
            `You have already added that ${
                type === 'phone' ? 'phone number' : 'e-mail address'
            } to the No Contact List!`
        );
    };

    render() {
        return (
            <SettingsPanel title="No Contact List" className="no-contact-list-panel">
                <h5>Email</h5>
                <Form.Group widths={2} unstackable>
                    <Form.Field>
                        <Input
                            type="email"
                            onChange={this.handleEmailChange}
                            value={this.state.email}
                            placeholder="example@domain.com"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Input
                            placeholder="Customer's name (optional)"
                            onChange={this.handleEmailNameChange}
                            value={this.state.emailName}
                        />
                    </Form.Field>
                    <Button onClick={this.handleAddEmailClick} disabled={!this.state.emailValid} size="tiny" primary>
                        <Icon name="plus" className="no-margin" />
                    </Button>
                </Form.Group>
                {!this.state.emailValid && <Message error visible content="E-Mail provided is invalid" />}
                <div className="scroll-wrapper">
                    {noContactListStore.stagedEmails &&
                        noContactListStore.stagedEmails.map((e, i) => {
                            return (
                                <div className="field switch labeled clearfix" key={i}>
                                    {e.email} {e.name ? ` - ${e.name}` : ''}
                                    <a onClick={(event: MouseEvent) => this.handleRemoveEmailClick(event, i)}>
                                        <Icon name="delete" color="red" />
                                    </a>
                                </div>
                            );
                        })}

                    {noContactListStore.emails &&
                        noContactListStore.emails.map((e, i) => (
                            <div className="field switch labeled clearfix" key={i}>
                                {e.email} {e.name ? ` - ${e.name}` : ''}
                            </div>
                        ))}
                </div>
                <h5>Phone</h5>
                <Form.Group widths={2} unstackable>
                    <Form.Field>
                        <MaskedInput
                            mask="(111) 111 - 1111"
                            type="text"
                            placeholder="(123) 456-7890"
                            onChange={this.handlePhoneChange}
                            value={this.state.phone}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Input
                            onChange={this.handlePhoneNameChange}
                            value={this.state.phoneName}
                            placeholder="Customer's name (optional)"
                        />
                    </Form.Field>
                    <Button onClick={this.handleAddPhoneClick} size="tiny" primary>
                        <Icon name="plus" className="no-margin" />
                    </Button>
                </Form.Group>
                {!this.state.phoneValid && <Message error visible content="Phone number provided is invalid" />}
                <div className="scroll-wrapper">
                    {noContactListStore.stagedPhones &&
                        noContactListStore.stagedPhones.map((p, i) => (
                            <div className="field switch labeled clearfix" key={i}>
                                {formatPhoneNumber(p.phone)} {p.name ? ` - ${p.name}` : ''}
                                <a onClick={(event: MouseEvent) => this.handleRemovePhoneClick(event, i)}>
                                    <Icon name="delete" color="red" />
                                </a>
                            </div>
                        ))}
                    {noContactListStore.phones &&
                        noContactListStore.phones.map((p, i) => (
                            <div className="field switch labeled clearfix" key={i}>
                                {formatPhoneNumber(p.phone)} {p.name ? ` - ${p.name}` : ''}
                            </div>
                        ))}
                </div>
            </SettingsPanel>
        );
    }
}
