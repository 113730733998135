// @flow

import * as React from 'react';

type Props = {
    name: string,
    className?: string,
};

export default class UserInitials extends React.Component<Props> {
    render() {
        const { name, className } = this.props;

        const initials = name
            .split(' ')
            .map(str => str[0])
            .join('');

        const classes = ['user-initials'];
        if (className) classes.push(className);

        return (
            <div className={classes.join(' ')}>
                <div className="letters">{initials}</div>
            </div>
        );
    }
}
