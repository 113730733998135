// @flow
import * as React from 'react';
import { Form, Field } from 'app/external/mobx-form-for';
import { observer } from 'mobx-react';
import moment from 'moment';

type Props = {
    onChange: Function,
    to: Object,
};

@observer
export default class DatePresets extends React.Component<Props> {
    get presetOptions() {
        const { year } = this.props.to;
        const prevYear = moment(year, 'YYYY').subtract(1, 'year').year();

        return [
            {
                key: 'None',
                text: 'None',
                value: 'None',
            },
            {
                key: 'This month',
                text: 'This month',
                value: 'This month',
            },
            {
                key: 'Last month',
                text: 'Last month',
                value: 'Last month',
            },
            {
                key: `${prevYear} Overview`,
                text: `${prevYear} Overview`,
                value: `${prevYear} Overview`,
            },
        ];
    }

    get quarterOptions() {
        const { to } = this.props;

        const quarterOptions = [
            {
                key: '1st Quarter',
                text: '1st Quarter',
                value: '1st Quarter',
            },
            {
                key: '2nd Quarter',
                text: '2nd Quarter',
                value: '2nd Quarter',
            },
            {
                key: '3rd Quarter',
                text: '3rd Quarter',
                value: '3rd Quarter',
            },
            {
                key: '4th Quarter',
                text: '4th Quarter',
                value: '4th Quarter',
            },
        ];

        const isCurrentYear = to.year === moment().year();
        const isFirstQuarter = moment().quarter() < 2;

        if (isCurrentYear && isFirstQuarter) return [];

        if (isCurrentYear) {
            return [quarterOptions[0], ...quarterOptions.slice(1, moment().quarter() - 1)];
        }

        return quarterOptions;
    }

    render() {
        const { to, onChange } = this.props;
        const presets = this.presetOptions.concat(this.quarterOptions);

        return (
            <div className="popup-section no-border clearfix">
                <div className="small-titles">Preset Date Range</div>
                <div className="full-section clearfix">
                    <Form
                        for={to}
                        schema={{
                            presets: {
                                className: 'icon pointing bottom left circular tiny custom',
                                icon: 'sliders',
                                labeled: true,
                                button: true,
                                dropdown: true,
                                upward: false,
                                basic: true,
                                type: 'select',
                                inlineLabel: true,
                            },
                        }}
                        onChange={onChange}
                    >
                        <Field name="presets" text={to.presets} options={presets} />
                    </Form>
                </div>
            </div>
        );
    }
}
