// @flow

import { action, computed, observable } from 'mobx';

import placeStore from 'stores/place';
import sidebarStore from 'stores/sidebar';

import Video from 'models/Video';

import { api } from '@friendemic/premiere';

class VideoStore {
    @observable video: ?Video;
    @observable videos: ?(Video[]);
    @observable activeVideoInListDom: ReactNode;

    @observable page: number = 0;
    @observable perPage: number = 15;
    @observable totalPages: number;
    @observable totalVideos: number;
    @observable loading: boolean;

    @observable searchText: string = '';
    @observable sortProperty: string = 'date';
    @observable sortDirection: string = 'desc';

    @computed
    get hasMore(): boolean {
        return this.totalVideos > (this.videos || []).length;
    }

    @action
    setPage(page: number) {
        this.page = page;
    }

    @computed
    get videosLoaded(): number {
        return this.page * this.perPage;
    }

    @action
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    @action
    setTotalVideos(total: number) {
        this.totalVideos = total;
    }

    @action
    setTotalPages(totalPages: number) {
        this.totalPages = totalPages;
    }

    @action
    pushVideos(videos: Video[]) {
        if (!this.videos) this.videos = [];
        this.videos = this.videos.concat(videos);

        if (!this.video && !!this.videos.length) this.video = this.videos[0];
    }

    @action
    select(video: Video) {
        this.video = video;
        sidebarStore.close();
    }

    @action
    add(video: Video) {
        // $FlowFixMe
        this.pushVideoToCollection(video);
        this.video = video;
    }

    @action.bound
    async update() {
        const videos = await this.fetchPage(1, this.videosLoaded, this.page, true);

        if (videos) {
            this.resetVideos();
            this.pushVideos(videos.map(video => Object.assign(new Video(), video)));
            this.select(this.videos[0]);
        }
    }

    @action
    pushVideoToCollection(video: Video) {
        // $FlowFixMe
        this.videos.push(video);
    }

    @action
    async remove(video: Video) {
        // $FlowFixMe - MobX observable array
        this.videos.remove(video);

        const videoIds = this.videos.map(video => video.id);
        const newVideos = await this.fetchPage(1, this.videosLoaded);
        const remainingVideo = newVideos.filter(newVideo => videoIds.indexOf(newVideo.id) === -1);

        if (remainingVideo.length > 0) this.pushVideoToCollection(remainingVideo[0]);
        if (this.videos[0]) this.select(this.videos[0]);
    }

    @action
    sort(property: string, direction: 'asc' | 'desc') {
        if (this.sortProperty === property && this.sortDirection === direction) return;

        this.sortProperty = property;
        this.sortDirection = direction;

        this.resetState();
        this.loadNext();
    }

    @action
    search(text: string) {
        if (this.searchText === text) return;

        this.searchText = text;
        this.resetState();
        this.loadNext();
    }

    @action
    resetState() {
        this.page = 0;
        this.videos = null;
        this.video = null;
    }

    @action
    resetVideos() {
        this.videos = null;
    }

    @action.bound
    async loadNext() {
        this.loading = true;

        const videos = await this.fetchPage(this.page + 1);

        this.setLoading(false);
        this.pushVideos(videos);
    }

    @action.bound
    async fetchPage(page: number, perPage: number, currentPage: number, ignoreCache: boolean = false) {
        return placeStore.place.videos({
            ignoreCache,
            queryParams: {
                page: page,
                per_page: perPage ? perPage : this.perPage,
                search: this.searchText.length ? this.searchText : '',
                order_by: this.sortProperty,
                order: this.sortDirection,
                paginated: true,
            },
            callback: response => {
                const { data, meta } = response.data;
                const videosWithPlaces = data.map(video => ({ ...video, place: placeStore.place }));
                this.setPage(currentPage ? currentPage : meta.current_page);
                this.setTotalVideos(meta.total);
                this.setTotalPages(meta.last_page);

                return { data: videosWithPlaces };
            },
        });
    }

    @computed
    get sortedVideos(): ?(Video[]) {
        if (!this.videos) return this.videos;

        const factor = this.sortDirection === 'asc' ? 1 : -1;

        return this.videos.sort((a: Video, b: Video) => {
            const valueA = a[this.sortProperty];
            const valueB = b[this.sortProperty];

            return valueA < valueB ? -factor : factor;
        });
    }

    createVideo(videoData) {
        const { title, description, placeId, youtubeStatus, extension } = videoData;
        const formData = new FormData();
        formData.append('data[place_id]', placeId);
        formData.append('data[title]', title);
        formData.append('data[description]', description);
        formData.append('data[youtube_status]', youtubeStatus);
        formData.append('data[file_ext]', extension);

        return api.http.post('upload-videos', formData);
    }
}

export default new VideoStore();
