/**
 * mobx-form-for
 * Removed from github and unpublished from npm by author
 * https://github.com/pedsmoreira/form-for (Not Found)
 * https://registry.npmjs.org/mobx-form-for
 */
import { BaseForm, Field, FieldGroup, field } from './form-for';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import 'react';

var Field$1 = observer(Field);

var classCallCheck = function (instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
};

var createClass = (function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor) descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }

    return function (Constructor, protoProps, staticProps) {
        if (protoProps) defineProperties(Constructor.prototype, protoProps);
        if (staticProps) defineProperties(Constructor, staticProps);
        return Constructor;
    };
})();

var inherits = function (subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
        throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true,
        },
    });
    if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass);
};

var possibleConstructorReturn = function (self, call) {
    if (!self) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === 'object' || typeof call === 'function') ? call : self;
};

var FieldGroup$1 = (function (_BaseFieldGroup) {
    inherits(FieldGroup$$1, _BaseFieldGroup);

    function FieldGroup$$1() {
        classCallCheck(this, FieldGroup$$1);
        return possibleConstructorReturn(
            this,
            (FieldGroup$$1.__proto__ || Object.getPrototypeOf(FieldGroup$$1)).apply(this, arguments)
        );
    }

    createClass(FieldGroup$$1, [
        {
            key: 'onChange',
            value: function onChange(name, value) {
                var object = this.props.for;

                if (object[name] !== value) {
                    var mutator = function mutator() {
                        return (object[name] = value);
                    };
                    action('Update form value ' + this.getPrefix() + '[' + name + ']', mutator)();
                }

                this.dispatchFormChange();
            },
        },
    ]);
    return FieldGroup$$1;
})(FieldGroup);

var FieldGroup$2 = observer(FieldGroup$1);

var Form = (function (_BaseForm) {
    inherits(Form, _BaseForm);

    function Form() {
        classCallCheck(this, Form);
        return possibleConstructorReturn(this, (Form.__proto__ || Object.getPrototypeOf(Form)).apply(this, arguments));
    }

    return Form;
})(BaseForm);

Form.fieldGroupComponent = FieldGroup$2;

var Form$1 = observer(Form);

export { field, Field$1 as Field, FieldGroup$2 as FieldGroup, Form$1 as Form };
