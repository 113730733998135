import React from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from 'semantic-ui-react';

export default function Preview({ src, type }) {
    const [paused, setPaused] = React.useState(true);
    const [playing, setPlaying] = React.useState(false);

    return (
        <>
            {type === 'photo' ? (
                <img key={src} src={src} />
            ) : (
                type === 'video' && (
                    <div>
                        {paused ? (
                            <Icon className="play-icon" name="play circle" size="big" />
                        ) : (
                            <Icon className="play-icon" name="pause circle" size="big" />
                        )}
                        <video
                            onClick={event => {
                                if (playing) return;

                                if (paused) {
                                    setPlaying(true);
                                    event.currentTarget
                                        .play()
                                        .then(() => setPaused(false))
                                        .finally(() => setPlaying(false));
                                } else {
                                    event.currentTarget.pause();
                                    setPaused(true);
                                }
                            }}
                            onEnded={() => setPaused(true)}
                            src={src}
                            muted
                        />
                    </div>
                )
            )}
        </>
    );
}

Preview.propTypes = {
    src: PropTypes.string,
    type: PropTypes.string,
};
