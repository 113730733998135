// @flow

import * as React from 'react';
import Modal from 'components/Modal';
import { Button, Icon, Modal as SemanticModal, Progress } from 'semantic-ui-react';
import { Field, FieldGroup, Form } from 'app/external/mobx-form-for';
import DraftContentMediaConsent from 'models/DraftContentMediaConsent';
import FormButton from 'components/FormButton';
import DraftContent from 'models/DraftContent';
import useDraft from './useDraft';
import { Observer } from 'mobx-react';
import publishedContentStore from 'app/namespaces/photo/stores/submission/publishedContentStore';
import { toast } from 'app/helpers';
import * as Sentry from '@sentry/react';

type Props = {
    trigger: React.Node,
};

export default function SubmissionModal({ trigger }: Props) {
    const [consent, setConsent] = React.useState(null);
    const [step, setStep] = React.useState('form');
    const [busy, setBusy] = React.useState(false);
    const uploadPhotoRef = React.useRef(null);
    const uploadVideoRef = React.useRef(null);

    const { draft, createDraft, progress, updateDraft } = useDraft();

    const canSubmit = () => {
        if (!draft.title) {
            return false;
        }

        if (
            (!draft.content_photos || !draft.content_photos.length) &&
            (!draft.content_videos || !draft.content_videos.length) &&
            !draft.testimonial
        ) {
            return false;
        }

        return true;
    };

    const handleUploadVideoClick = (event: Event) => {
        event.stopPropagation();

        setConsent(new DraftContentMediaConsent());
        setStep('uploadVideo');
    };

    const handleUploadPhotoClick = (event: Event) => {
        event.stopPropagation();

        setConsent(new DraftContentMediaConsent());

        if (draft.hasCustomerPhoto) {
            uploadPhotoRef.current && uploadPhotoRef.current.open();
        } else {
            setStep('uploadPhoto');
        }
    };

    const handleAddPhotoClick = (event: Event) => {
        event.preventDefault();

        setConsent(new DraftContentMediaConsent());

        if (draft.hasCustomerPhoto) {
            uploadPhotoRef.current && uploadPhotoRef.current.open();
        } else {
            setStep('uploadPhoto');
        }
    };

    const handleOnClose = () => {
        updateDraft(new DraftContent());
        setStep('form');
        setBusy(false);
    };

    const handleCloseUpload = () => {
        setStep('form');
    };

    const handleUploadAction = () => {
        if (step === 'uploadVideo') {
            uploadVideoRef.current && uploadVideoRef.current.open();
        } else {
            uploadPhotoRef.current && uploadPhotoRef.current.open();
        }

        setStep('form');
    };

    const renderForm = () => {
        return (
            <Modal.Action
                className="custom"
                title="New Content"
                action="Save"
                onAction={() =>
                    createDraft()
                        .then(() => {
                            publishedContentStore.forceLoad();
                            toast.success('Submission successful.');
                        })
                        .catch(e => {
                            Sentry.captureException(e);
                            toast.error('Submission failed, Try again.');
                        })
                }
                size="small"
                onClose={handleOnClose}
                trigger={trigger}
                encType="multipart/form-data"
                closeOnDimmerClick={false}
                actionDisabled={!canSubmit}
            >
                {busy && !!progress && (
                    <div style={{ marginBottom: '14px' }}>
                        <h2>Content Uploading in progress...</h2>
                        <Progress className="no-margin" percent={progress} size="large" progress indicating />
                    </div>
                )}
                <FieldGroup for={draft} prefix="data">
                    <div className="modal-content-inner-wrapper two-column clearfix">
                        <div className="column clearfix">
                            <Field name="title" disabled={busy} />
                            <Field name="description" disabled={busy} />
                            <h4 className="modal-section-titles">
                                <Icon name="bullhorn" />
                                Testimonial
                            </h4>
                            <Field name="testimonial" disabled={busy} />
                        </div>
                        <div className="column clearfix">
                            <h4 className="modal-section-titles">
                                <Icon name="video" />
                                Video(s)
                            </h4>
                            <Field
                                name="content_videos"
                                onClick={handleUploadVideoClick}
                                dropzoneRef={ref => (uploadVideoRef.current = ref.current)}
                                product={!consent || !consent.isCustomer}
                                disabled={busy}
                                helpText="Maximum of one (1) video"
                            />
                            <h4 className="modal-section-titles">
                                <Icon name="photo" />
                                Photo(s)
                            </h4>
                            <Field
                                name="content_photos"
                                onClick={handleUploadPhotoClick}
                                dropzoneRef={ref => (uploadPhotoRef.current = ref.current)}
                                product={!consent || !consent.isCustomer}
                                disabled={busy}
                                helpText="Maximum of one (1) customer photo and any number of product photos"
                            />
                            {draft.content_photos && draft.content_photos.length > 0 && (
                                <Button primary icon fluid onClick={handleAddPhotoClick} disabled={busy}>
                                    <Icon name="plus" /> Add Photo
                                </Button>
                            )}
                        </div>
                    </div>
                </FieldGroup>
            </Modal.Action>
        );
    };

    const renderUpload = () => {
        if (!consent) {
            return;
        }

        return (
            <SemanticModal
                className="custom"
                size="mini"
                open={step === 'uploadPhoto' || step === 'uploadVideo'}
                onClose={handleOnClose}
            >
                <SemanticModal.Header>Add Photo</SemanticModal.Header>
                <SemanticModal.Content>
                    <Form for={consent} className="ui form tiny">
                        <Field name="isCustomerMedia" />
                        {consent.isCustomerMedia && (
                            <React.Fragment>
                                <Field name="isCustomerConsentProvided" />
                                <p>
                                    By selecting this toggle, I agree that I was given permission to use this customers
                                    name, photographic likeness, or vehicle in all forms and media for advertising,
                                    trade, and any other lawful purposes. This includes popular online social networking
                                    sites: Facebook, YouTube, Twitter, Instagram, Cars.com, etc. If requested the
                                    customer has also signed documentation to this effect.
                                </p>
                            </React.Fragment>
                        )}
                    </Form>
                </SemanticModal.Content>
                <SemanticModal.Actions>
                    <Button type="button" onClick={handleCloseUpload} size="tiny" color="grey" basic>
                        Cancel
                    </Button>
                    <FormButton primary onClick={handleUploadAction} disabled={!consent.canUpload} text="Upload" />
                </SemanticModal.Actions>
            </SemanticModal>
        );
    };

    return (
        <Observer
            render={() => (
                <>
                    {renderForm()}
                    {renderUpload()}
                </>
            )}
        />
    );
}
