// @flow

import { observer } from 'mobx-react';
import React from 'react';

import Sidebar from 'components/Sidebar';

import VideoCardList from 'video/components/Video/Card/List';
import VideoSort from 'video/components/Video/Sort';

type Props = {};

@observer
export default class VideosSidebar extends React.Component<Props> {
    render() {
        return (
            <Sidebar
                title="Videos"
                className="fit"
                menus={<VideoSort className="inverted basic circular custom option outline" />}
            >
                <VideoCardList ellipsisBreakpoint={22} inverted />
            </Sidebar>
        );
    }
}
