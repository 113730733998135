// @flow

import * as React from 'react';

import InviteAnalytics from 'models/InviteAnalytics';

import ReportSection from 'components/Report/Section';
import NumberOfInvitesPartial from './NumberOfInvitesPartial';
import Overall from './Overall';
import YesResponse from './YesResponse';
import NoResponse from './NoResponse';

type Props = {
    inviteAnalytics: InviteAnalytics,
};

export default class ReviewInvitesAnalytics extends React.Component<Props> {
    render() {
        const { inviteAnalytics } = this.props;

        return (
            <React.Fragment>
                <ReportSection id="wyruInvitesSent" title="# of invites sent">
                    <NumberOfInvitesPartial inviteAnalytics={inviteAnalytics} chart="wyru_invites_sent" />
                </ReportSection>
                <ReportSection id="wryuResults" title='Results of "Would you recommend us?"'>
                    <ReportSection className={'no-border'}>
                        <Overall inviteAnalytics={inviteAnalytics} />
                    </ReportSection>
                    <ReportSection className={'no-border'}>
                        <YesResponse inviteAnalytics={inviteAnalytics} />
                    </ReportSection>
                    <ReportSection className={'no-border'}>
                        <NoResponse inviteAnalytics={inviteAnalytics} />
                    </ReportSection>
                </ReportSection>
            </React.Fragment>
        );
    }
}
