import React from 'react';
import Modal from 'components/Modal';
import { Button, Checkbox, Form, FormField, Icon, Progress, TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FormikField from 'app/fields/Formik/FormikField';
import DatePickerSelect from 'app/components/DatePickerSelect';
import { useFormikContext } from 'formik';
import ErrorLabel from 'app/components/ErrorLabel/ErrorLabel';
import { NETWORKS_MAP } from '../SocialPostModal';
import MediaPreview from 'app/components/MediaPreview';

export default function EditPost({
    loading,
    media = [],
    onCancel,
    onDeletePost,
    edit,
    handleEditMedia,
    network_name,
    placeNetworks,
    createdBy,
    publishOn,
    busy,
    progress,
}) {
    const [date, setDate] = React.useState(publishOn);
    const dateTime = React.useMemo(() => new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59), [date]);

    const { handleSubmit, setFieldValue, errors, values, touched } = useFormikContext();

    React.useEffect(() => {
        setFieldValue('publishOn', dateTime);
    }, [dateTime]);

    return (
        <>
            <Modal.Content>
                {busy && !!progress && (
                    <div style={{ marginBottom: '14px' }}>
                        <h2>Content Uploading in progress...</h2>
                        <Progress className="no-margin" percent={progress} size="large" progress indicating />
                    </div>
                )}
                <div className="modal-content-inner-wrapper two-column clearfix social-post-modal">
                    <Form>
                        <div className="column clearfix">
                            <FormikField
                                as={DatePickerSelect}
                                label="Suggested Publish Date"
                                onDateChange={date => {
                                    setDate(date);
                                }}
                                disableTime={true}
                                value={dateTime}
                                minDate={new Date()}
                                error={errors.publishOn}
                            />
                            {edit ? (
                                <Form.Field>
                                    <label>Network</label>
                                    {network_name ? NETWORKS_MAP[network_name] : 'Unknown'}
                                </Form.Field>
                            ) : (
                                <FormField>
                                    <label className="">Social Networks</label>
                                    <div className="checkbox-group">
                                        {Object.entries(placeNetworks)
                                            .filter(([, value]) => value.status === 'true')
                                            .map(([key, value]) => (
                                                <FormikField
                                                    name={`networks.${key}`}
                                                    key={key}
                                                    as={Checkbox}
                                                    defaultChecked={Boolean(values.networks[key])}
                                                    fieldLabel={NETWORKS_MAP[key]}
                                                    onChange={(e, { checked }) => {
                                                        if (checked) {
                                                            setFieldValue(`networks.${key}`, value.id);
                                                        } else {
                                                            setFieldValue(`networks.${key}`, null);
                                                        }
                                                    }}
                                                />
                                            ))}
                                    </div>
                                    <ErrorLabel error={errors.networks} touched={touched.networks} />
                                </FormField>
                            )}
                            {edit && (
                                <Form.Field>
                                    <label>Created By</label>
                                    {createdBy || 'Unknown'}
                                </Form.Field>
                            )}
                            <FormikField name="caption" as={TextArea} label="Caption" fluid />
                            <FormikField name="instructions" as={TextArea} label="Instructions" fluid />
                        </div>
                        <div className="column clearfix">
                            <Form.Field>
                                <MediaPreview
                                    label={currentMedia =>
                                        `Media (${media.length ? currentMedia + 1 : 0}/${media.length})`
                                    }
                                    media={media}
                                    onEdit={handleEditMedia}
                                />
                                <ErrorLabel error={errors.media} touched={touched.media} />
                            </Form.Field>
                        </div>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {edit ? (
                        <Button style={{ backgroundColor: 'rgba(0,0,0,0.0)', padding: 0 }} onClick={onDeletePost}>
                            <Icon name="trash alternate" /> Delete Post
                        </Button>
                    ) : (
                        <div />
                    )}
                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', gap: '12px' }}>
                        <Button basic disabled={loading} onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button
                            primary
                            onClick={handleSubmit}
                            type="submit"
                            disabled={loading || media.length === 0}
                            loading={loading}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal.Actions>
        </>
    );
}

EditPost.propTypes = {
    loading: PropTypes.bool.isRequired,
    media: PropTypes.arrayOf(PropTypes.object).isRequired,
    onDeletePost: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    edit: PropTypes.bool,
    handleEditMedia: PropTypes.func.isRequired,
    selectedNetworks: PropTypes.object,
    network_name: PropTypes.string,
    placeNetworks: PropTypes.object.isRequired,
    createdBy: PropTypes.string,
    publishOn: PropTypes.instanceOf(Date),
    busy: PropTypes.bool,
    progress: PropTypes.number,
};
