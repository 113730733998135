// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import placeStore from 'stores/place';

import Sidebar from 'components/Sidebar';
import SidebarListItem from 'components/Sidebar/ListItem';

type Props = {};

type State = {
    search: string,
};

@observer
export default class LevelSidebar extends React.Component<Props, State> {
    state = { search: '' };

    handleSearchChange = (event: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ search: event.currentTarget.value });
    };

    render() {
        if (!placeStore.places) return null;

        const filteredPlaces = placeStore.sortedPlaces.filter(place =>
            place.name.toLowerCase().includes(this.state.search.toLowerCase())
        );

        return (
            <Sidebar title="Locations" className="fit">
                <div className="ui fluid right left icon custom transparent inverted input">
                    <Icon name="search" />{' '}
                    <input
                        type="text"
                        placeholder="Search"
                        value={this.state.search}
                        onChange={this.handleSearchChange}
                    />
                </div>

                {filteredPlaces.map(place => (
                    // $FlowFixMe
                    <SidebarListItem
                        key={place.id}
                        active={placeStore.place === place}
                        onClick={() => placeStore.select(place)}
                    >
                        <a className="main">{place.name}</a>
                    </SidebarListItem>
                ))}
            </Sidebar>
        );
    }
}
