// @flow

import userInfoStore from 'stores/userInfo';

import { roles, type Role } from 'types';

export default function hasRole(role: ?Role, userRole: ?Role = userInfoStore.roleSlug) {
    if (!userRole) return false;
    if (!role) return true;

    const userRoleIndex = roles.indexOf(userRole);
    if (userRoleIndex === -1) throw new Error(`Role ${userRole} does not exist`);

    const roleIndex = roles.indexOf(role);
    if (roleIndex === -1) throw new Error(`Role ${userRole} does not exist`);

    return userRoleIndex >= roleIndex;
}
