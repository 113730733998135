import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

export default function Delete({ open, onNo, onDelete, loading }) {
    return (
        <Modal open={open} title="Add Photo" size="mini">
            <Modal.Content>
                <div>Are you sure you want to delete this post?</div>
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={onNo}>
                    No
                </Button>
                <Button primary onClick={onDelete} disabled={loading} loading={loading}>
                    Delete
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

Delete.propTypes = {
    open: PropTypes.bool.isRequired,
    onNo: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
