// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { withScriptjs, GoogleMap as ReactGoogleMaps, Marker, withGoogleMap } from 'react-google-maps';

type Props = { coords: Object, marker: boolean };

@observer
class GoogleMap extends React.Component<Props> {
    render() {
        const { coords, marker } = this.props;
        return <ReactGoogleMaps {...coords}>{marker && <Marker position={{ ...coords.center }} />}</ReactGoogleMaps>;
    }
}

export default withScriptjs(withGoogleMap(props => <GoogleMap {...props} />));
