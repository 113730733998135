import React from 'react';
import PropTypes from 'prop-types';
import ErrorLabel from 'components/ErrorLabel';
import { Field, useFormikContext } from 'formik';
import { Form } from 'semantic-ui-react';

export default function FormikField({ name, children, label, fieldLabel, error, ...props }) {
    const { errors, touched } = useFormikContext();
    return (
        <Form.Field error={error || (touched[name] && errors[name])}>
            {label && <label>{label}</label>}
            <Field name={name} label={fieldLabel} {...props}>
                {children}
            </Field>
            <ErrorLabel error={error || errors[name]} touched={name ? touched[name] : true} />
        </Form.Field>
    );
}

FormikField.propTypes = {
    component: PropTypes.node,
    label: PropTypes.string,
    fieldLabel: PropTypes.string,
    error: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    children: PropTypes.node,
};
