// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import PropTypes from 'prop-types';

import { number } from 'helpers';

type Props = {
    data: Object,
    title: string,
    property?: string,
    render?: (data: Object) => React.Node,
};

@observer
export default class SortableBody extends React.Component<Props> {
    actions: number;
    element: ?HTMLElement;

    static childContextTypes = {
        incrementActions: PropTypes.func,
    };

    handleIncrementActions = () => this.actions++;

    getChildContext() {
        return {
            incrementActions: this.handleIncrementActions,
        };
    }

    componentDidMount() {
        this.addActionContainerClasses();
    }

    componentDidUpdate() {
        this.addActionContainerClasses();
    }

    addActionContainerClasses() {
        if (this.actions && this.element) this.element.className += ` action-container ${number.toName(this.actions)}`;
    }

    render() {
        this.actions = 0;
        const { property, render, data } = this.props;
        const node = render ? render(data) : data[property];

        return (
            <td ref={el => (this.element = el)} className="aligned left">
                {node}
            </td>
        );
    }
}
