import MediaLibrarySelect from 'app/components/MediaLibrarySelect';
import MediaPreview from 'app/components/MediaPreview';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';

export default function EditMedia({
    mediaLibrary,
    onCancel,
    handleFileUploadClick,
    handleMediaDelete,
    handleMediaSelect,
    media = [],
    handleNewMedia,
    onNext,
    edit,
    inputRef,
    loadNext,
    hasMore,
}) {
    const handleFileUpload = e => {
        handleNewMedia(e.currentTarget.files);
    };

    return (
        <>
            <Modal.Content>
                {!edit && (
                    <Message color="green" style={{ color: 'black' }}>
                        Choose a maximum of one (1) video and ten (10) photos. Customer consent is required.
                    </Message>
                )}
                <div className="modal-content-inner-wrapper two-column clearfix social-post-modal">
                    <div className="column clearfix">
                        <Form>
                            <Form.Field>
                                <label>Choose from Library</label>
                                <MediaLibrarySelect
                                    media={mediaLibrary}
                                    handleMediaSelect={handleMediaSelect}
                                    loadNext={loadNext}
                                    hasMore={hasMore}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Upload from Computer</label>
                                <Button as="label" type="button" basic htmlFor="file" onClick={handleFileUploadClick}>
                                    <Icon name="images" /> Browse Files
                                </Button>
                                <input
                                    ref={inputRef}
                                    id="file"
                                    type="file"
                                    multiple
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                    accept="image/*,video/*"
                                />
                            </Form.Field>
                        </Form>
                    </div>
                    <div className="column clearfix">
                        <MediaPreview
                            label={currentMedia => `Preview (${media.length ? currentMedia + 1 : 0}/${media.length})`}
                            media={media}
                            onDelete={handleMediaDelete}
                        />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={onCancel}>
                    Cancel
                </Button>
                <Button primary disabled={media.length === 0} onClick={onNext}>
                    Next
                </Button>
            </Modal.Actions>
        </>
    );
}

EditMedia.propTypes = {
    mediaLibrary: PropTypes.arrayOf(PropTypes.object),
    onCancel: PropTypes.func,
    handleFileUploadClick: PropTypes.func,
    handleMediaDelete: PropTypes.func,
    handleMediaSelect: PropTypes.func,
    media: PropTypes.arrayOf(PropTypes.object),
    handleNewMedia: PropTypes.func,
    onNext: PropTypes.func,
    edit: PropTypes.bool,
    inputRef: PropTypes.shape({ current: PropTypes.object }),
    loadNext: PropTypes.func,
    hasMore: PropTypes.bool,
};
