// @flow

import { action, observable } from 'mobx';

import placeStore from 'stores/place';

import VideoLeaderboard from 'models/VideoLeaderboard';
import Filters from 'models/Filters';

type meta = {
    current_page: number,
    first_page_url: string,
    last_page: number,
    last_page_url: string,
    next_page_url: string,
    per_page: number,
    prev_page_url: string,
    to: number,
    total: number,
};

class VideoLeaderboardStore {
    @observable videosLeaderboard: VideoLeaderboard[];
    @observable filters: ?Filters;
    @observable sortDirection: ?string = 'desc';
    @observable sortProperty: ?string = 'videos';
    @observable searchText: ?string = '';
    @observable page: number = 1;
    @observable meta: meta;
    @observable loading: boolean = false;

    @action
    set(videosLeaderboard: VideoLeaderboard[]) {
        this.videosLeaderboard = videosLeaderboard;
    }

    @action
    setMeta(meta: meta) {
        this.meta = meta;
    }

    @action
    setPage(page: number) {
        this.page = page;
    }

    @action
    startLoading() {
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
    }

    get queryParams() {
        let queryParams = {
            page: this.page,
            per_page: 10,
            search: this.searchText,
            order_by: this.sortProperty,
            order: this.sortDirection,
            paginated: true,
        };

        let filters = {};

        if (this.filters && undefined !== this.filters.startDate) {
            filters.start_date = this.filters.startDate;
        }

        if (this.filters && undefined !== this.filters.endDate) {
            filters.end_date = this.filters.endDate;
        }

        filters.email = true;
        filters.link = true;

        return {
            ...queryParams,
            ...filters,
        };
    }

    @action.bound
    search(text: string) {
        this.searchText = text;
        this.resetState();
        this.load();
    }

    @action.bound
    searchByDateRange({ startDate, endDate }: Object) {
        if (this.filters.startDate === startDate && this.filters.endDate === endDate) return;

        this.filters.startDate = startDate;
        this.filters.endDate = endDate;

        this.load();
    }

    @action.bound
    filter(filters: Filters) {
        this.filters = filters;
        this.load();
    }

    @action
    resetState() {
        this.videosLeaderboard = [];
    }

    @action.bound
    resetParams() {
        this.sortDirection = 'desc';
        this.sortProperty = 'videos';
        this.searchText = '';
    }

    @action.bound
    async load(ignoreCache: boolean = false) {
        this.startLoading();

        this.resetState();
        const leaderboard = await placeStore.place.videoLeaderboard({
            queryParams: this.queryParams,
            ignoreCache,
            callback: response => {
                const { data, ...rest } = response.data;
                this.setMeta({ ...rest });
                return { data };
            },
        });

        this.set(leaderboard);

        this.stopLoading();
    }

    @action
    sort(property: string) {
        if (property === this.sortProperty) {
            this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
        } else {
            this.sortProperty = property;
            this.sortDirection = 'asc';
        }

        this.resetState();
        this.load();
    }

    @action
    getExportLink(filters: Filters): string {
        if (this.filters) this.filters = undefined;
        this.filters = filters;

        const params = new URLSearchParams({
            xls: '1',
            ...this.queryParams,
        });

        return `/places/${placeStore.place.id}/video-leaderboard?${params.toString()}`;
    }
}

export default new VideoLeaderboardStore();
