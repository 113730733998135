// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';
import { Field, FieldGroup } from 'app/external/form-for';
import moment from 'moment';

type Props = ComponentProps & {
    addedAction: Function,
    disableDelete: ?boolean,
    startDate: ?string,
    endDate: ?string,
};

@observer
export default class TagDateRangeField extends React.Component<Props> {
    input: HTMLElement;

    componentDidMount() {
        this.props.onMount(this.input);
    }

    get label() {
        const { startDate, endDate } = this.props;

        if (!startDate && !endDate) return 'All time';

        return `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`;
    }

    get schema() {
        return {
            dateRange: { type: 'tags', addedAction: this.props.addedAction, disableDelete: !this.props.disableDelete },
        };
    }

    render() {
        return (
            <FieldGroup for={{ dateRange: true }} schema={this.schema}>
                <Field name="dateRange" label={this.label} />
            </FieldGroup>
        );
    }
}
