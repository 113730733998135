// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Form, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { humanized, uniqueId } from 'app/external/form-for-component-helpers';
import type { ComponentProps } from 'app/external/form-for';

import ErrorLabel from 'components/ErrorLabel';

export type Props = ComponentProps & {
    // $FlowFixMe
    options: string[] | { value: string, text: string }[],
    label?: string,
    placeholder?: string,
    required?: boolean,
    tooltip?: string,
};

type State = {
    options: Object[],
};

@observer
export default class TaggedInputField extends React.Component<Props, State> {
    input: ?HTMLInputElement;

    state = {
        options: [],
    };

    handleRef = (input: ?HTMLInputElement) => {
        this.input = input;
    };

    handleChange = (event: SyntheticEvent<HTMLInputElement>, meta: { value: string }) => {
        this.props.onChange(event, meta.value);
    };

    handleNew = (event: SyntheticEvent<HTMLInputElement>, meta: { value: string }) => {
        this.setState(state => ({
            options: [...state.options, { text: meta.value, value: meta.value }],
        }));
    };

    handleHiddenFocus = () => {
        const select = document.getElementById(uniqueId(this));
        if (select) select.focus();
    };

    componentDidMount() {
        this.props.onMount(this.input);
        this.setState({ options: [...this.props.value].map(val => ({ text: val, value: val })) });
    }

    render() {
        const { value, error, touched, label, required, tooltip, ...rest } = this.props;
        const { options } = this.state;
        delete rest.onMount;
        delete rest.onChange;

        const id = uniqueId(this);
        const humanizedName = humanized(this);
        const Tooltip = tooltip ? (
            <Popup trigger={<Icon name="info circle" />} content={tooltip} inverted position="right center" />
        ) : null;

        return (
            <Form.Field error={touched && !!error} required={required} style={{ position: 'relative' }}>
                <label htmlFor={id}>
                    {`${label || humanizedName} `}
                    {Tooltip}
                </label>
                {!value && required && (
                    <input
                        ref={this.handleRef}
                        type="text"
                        style={{ opacity: '0', height: 0, padding: 0, position: 'absolute', bottom: 0 }}
                        tabIndex="-1"
                        onFocus={this.handleHiddenFocus}
                        required
                    />
                )}
                <Dropdown
                    className="custom"
                    id={id}
                    onChange={this.handleChange}
                    onAddItem={this.handleNew}
                    error={touched && !!error}
                    options={options}
                    value={[...value]}
                    {...rest}
                    search
                    selection
                    allowAdditions
                    multiple
                />
                <ErrorLabel error={error} touched={touched} />
            </Form.Field>
        );
    }
}
