// @flow

import { observer } from 'mobx-react';
import React from 'react';
import FilePreview from 'components/FilePreview';
import FlexVideo from 'components/FlexVideo';

type Props = {
    value: string | File,
};

@observer
export default class VideoFieldPreview extends React.Component<Props> {
    videoRef: { current: null | HTMLVideoElement };

    constructor(props: Props) {
        super(props);
        this.videoRef = React.createRef();
    }

    onVideoLoad = () => {
        if (this.videoRef.current) {
            this.videoRef.current.load();
        }
    };

    renderPreview(data: string) {
        let src = typeof this.props.value === 'string' ? this.props.value : data;

        return <FlexVideo src={src} captureVideoRef={ref => (this.videoRef = ref)} />;
    }

    render() {
        return (
            <FilePreview input={this.props.value} onLoad={this.onVideoLoad}>
                {data => this.renderPreview(data)}
            </FilePreview>
        );
    }
}
