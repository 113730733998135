// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { FieldGroup, Field } from 'app/external/mobx-form-for';
import { Button, Form, Icon } from 'semantic-ui-react';

import CustomLinks from 'models/VIPCustomLinks';

type Props = { customLinks: CustomLinks[], onDelete: Function, onAdd: Function };

@observer
export default class CustomLinksPartial extends React.Component<Props> {
    handleAddNewFields = (event: SyntheticEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { customLinks, onAdd } = this.props;

        if (customLinks.length > 4) return;

        onAdd();
    };

    handleAddDeleteFields = (event: SyntheticEvent<HTMLInputElement>, { id }: Object) => {
        event.preventDefault();
        this.props.onDelete(id);
    };

    renderCustomLinkForm = (customLinks: CustomLinks, index: number) => {
        return (
            <FieldGroup for={customLinks} key={`custom-links-${index}`}>
                <Form.Group widths={2} unstackable className="custom">
                    <Field id={`label-${customLinks.id}`} name="label" required={!!customLinks.url} />
                    <Field id={`url-${customLinks.id}`} name="url" />
                    <Button
                        id={customLinks.id}
                        className="attached custom delete-field"
                        attached="right"
                        color="red"
                        size="tiny"
                        compact
                        icon
                        onClick={this.handleAddDeleteFields}
                    >
                        <Icon name="delete" />
                    </Button>
                </Form.Group>
            </FieldGroup>
        );
    };

    render() {
        const linkLength = this.props.customLinks.length;
        if (!linkLength) return null;
        return (
            <React.Fragment>
                <h5>Menu links</h5>
                {this.props.customLinks.map(this.renderCustomLinkForm)}
                {linkLength < 5 && (
                    <Button onClick={this.handleAddNewFields} primary size="tiny">
                        Add more Links
                    </Button>
                )}
            </React.Fragment>
        );
    }
}
