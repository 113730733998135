// @flow

import type { Preset } from 'types';
import { presets } from 'types';

const pagePresets: { [key: Preset]: Object } = {
    [presets.settings]: {
        className: 'three-column',
        bodyClass: 'settings',
        closeable: 'Close Settings',
    },
    [presets.adminSettings]: {
        headerTitle: true,
        className: 'two-column-right-flex clearfix',
        bodyClass: 'settings',
        closeable: 'Close Admin Settings',
    },
    [presets.superAdminSettings]: {
        headerTitle: true,
        className: 'two-column-right-flex clearfix',
        bodyClass: 'settings',
    },
    [presets.report]: {
        additionalBodyClass: 'reports',
    },
    [presets.dashboard]: {
        additionalBodyClass: 'dashboard',
    },
    [presets.info]: {
        closeable: true,
    },
};

export default pagePresets;
