// @flow

import React from 'react';
import { Icon } from 'semantic-ui-react';

type Props = {
    inviteDate: string,
    feedbackDate: string,
    title: string,
    text: string,
    phone?: string,
    email?: string,
};

export default class SocialCard extends React.Component<Props> {
    formatPhoneNumber(phone: string) {
        if (!phone) return '';
        let phoneCleared = ('' + phone).replace(/\D/g, '');
        let splitPhone = phoneCleared.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !splitPhone ? null : '(' + splitPhone[1] + ') ' + splitPhone[2] + '-' + splitPhone[3];
    }

    render() {
        const { inviteDate, feedbackDate, title, text, phone, email } = this.props;
        return (
            <div className="report-feedback-box panel shadow clearfix">
                <div className="card-title">{title}</div>
                <div className="date-info-wrapper">
                    <div className="date-info">
                        <Icon name="calendar alternate outline" />
                        Invite sent: {inviteDate}
                    </div>
                    <div className="date-info">
                        <Icon name="calendar alternate outline" />
                        Feedback received: {feedbackDate}
                    </div>
                </div>
                <div className="contact-wrapper">
                    {email && (
                        <div className="contact-info">
                            <Icon name="envelope" />
                            {email}
                        </div>
                    )}
                    {phone && (
                        <div className="contact-info">
                            <Icon name="mobile" />
                            {this.formatPhoneNumber(phone)}
                        </div>
                    )}
                </div>
                <div className="text-wrapper">
                    <div className="feedback-text">
                        <p>{text}</p>
                    </div>
                </div>
            </div>
        );
    }
}
