// @flow

import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view';

type Props = {
    title: string,
    value: any,
    tooltip: string,
    scrollToId?: string,
};

export default class ReportStatsData extends React.Component<Props> {
    handleClick = () => {
        const { scrollToId } = this.props;
        if (scrollToId) {
            const element = document.getElementById(scrollToId);
            if (element) {
                scrollIntoView(element, {
                    align: {
                        left: 1,
                        top: 0,
                    },
                });
            }
        }
    };

    render() {
        const { value, title, tooltip } = this.props;

        return (
            <div className="stat" onClick={this.handleClick}>
                <div className="data"> {value} </div>
                <div className="label">
                    {' '}
                    {title}{' '}
                    <Popup trigger={<Icon name="info circle" />} content={tooltip} inverted position="right center" />
                </div>{' '}
            </div>
        );
    }
}
