import Modal from 'app/components/Modal/Modal';
import React, { useMemo } from 'react';
import * as FullStory from '@fullstory/browser';
import { Button } from 'semantic-ui-react';
import sharedLogo from 'images/catalyst_kenect_logos.png';
import placeStore from 'stores/place';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useLocalStorageAcknowledgements } from 'app/hooks/useLocalStorageAcknowledgements';
import { toast as toastify } from 'react-toastify';
import { toast } from 'app/helpers';
import './KenectUpgradeModal.css';

const KenectUpgradeModal = () => {
    const { isModalOpen, setIsModalOpen, setPlaceHasAcknowledged, upgradeStatus, upgradeScheduledDate } =
        useLocalStorageAcknowledgements(placeStore.place);

    /* Format date for modal text. If no date, use new Date(null) */
    const upgradeModalText = useMemo(() => {
        const sanitizedDate = upgradeScheduledDate ? parseISO(upgradeScheduledDate) : null;
        const formattedDate = format(new Date(sanitizedDate), 'PPP');
        return `Starting ${formattedDate}, your favorite Catalyst Suite tools will be powered by Kenect. There is nothing you need to do right now. We'll walk you through the upgrade process when it's time. Until then, don't hesitate to reach out to your CSM if you have any questions.`;
    }, [upgradeScheduledDate]);

    /* If the place has acknowledged the upgrade, add place id to localstorage and close the modal */
    async function handleClose() {
        try {
            await setPlaceHasAcknowledged(placeStore.place.id);
            FullStory.isInitialized() &&
                FullStory.event('Kenect Upgrade - Modal Closed', {
                    catalyst_upgrade_placeId_int: placeStore.place.id,
                });
            setIsModalOpen(false);
        } catch (err) {
            toast('Something went wrong, please try again', {
                type: 'error',
                position: toastify.POSITION.BOTTOM_LEFT,
            });
        }
    }

    /* Only render for status of SCHEDULED */
    if (!upgradeScheduledDate || upgradeStatus !== 'SCHEDULED') {
        return null;
    }

    return (
        <Modal
            className="custom upgradeModal"
            title="Upcoming Catalyst Suite Upgrade"
            size="tiny"
            onClose={handleClose}
            onOpen={() => {
                FullStory.isInitialized() &&
                    FullStory.event('Kenect Upgrade - Modal Open', {
                        catalyst_upgrade_placeId_int: placeStore.place.id,
                    });
                return setIsModalOpen(true);
            }}
            open={isModalOpen}
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
        >
            <Modal.Content>
                <div className="upgradeModal-imageWrapper">
                    <img src={sharedLogo} />
                </div>
                <p className="upgradeModal-text">{upgradeModalText}</p>
            </Modal.Content>
            <Modal.Actions className="custom">
                <Button type="button" size="tiny" primary onClick={handleClose}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default KenectUpgradeModal;
