// @flow

export const presets = {
    dashboard: 'dashboard',
    report: 'report',
    settings: 'settings',
    adminSettings: 'admin-settings',
    superAdminSettings: 'super-admin-settings',
    info: 'info',
};

export type Preset = $Values<typeof presets>;
