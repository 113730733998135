// @flow

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Place from 'models/Place';

import indexedFeatureApps from 'indexedFeatureApps';
import type { App } from 'types';

import AppIcon from 'components/App/Icon';

type Props = {
    place: Place,
};

@observer
export default class PlaceAppsPartial extends React.Component<Props> {
    @observable features: Object;

    static contextTypes = {
        incrementActions: PropTypes.func,
    };

    constructor(props: Props) {
        super(props);
        this.features = props.place.features;
    }

    @action.bound
    handleToggle(event: SyntheticEvent<*>, feature: string) {
        event.preventDefault();
        this.features[feature] = !this.features[feature];
        Place.update(this.props.place.id, { features: this.features });
    }

    render() {
        // $FlowFixMe
        return Object.values(indexedFeatureApps).map(app => this.renderApp(app));
    }

    renderApp(app: App) {
        this.context.incrementActions();

        const classes = ['app-triggers'];
        classes.push(app.icon);

        let turnStatus = 'on';
        if (this.features[app.feature]) {
            turnStatus = 'off';
            classes.push('active');
        }

        if (app.feature === 'kenect') {
            return <div />;
        }

        return (
            <Popup
                key={app.feature}
                trigger={
                    <a
                        className={classes.join(' ')}
                        onClick={e =>
                            app.title === 'Catalyst Chat' ? e.preventDefault() : this.handleToggle(e, app.feature)
                        }
                    >
                        <AppIcon app={app} turnStatus={turnStatus} />
                    </a>
                }
                content={
                    app.title === 'Catalyst Chat'
                        ? `${app.title} is coming soon.`
                        : `${turnStatus === 'off' ? 'Disable' : 'Enable'} ${app.title}`
                }
                inverted
                size="tiny"
            />
        );
    }
}
