// @flow

import placeStore from 'stores/place';

import DraftContent from 'models/DraftContent';
import { api } from '@friendemic/premiere';

class DraftContentStore {
    async create(draft: DraftContent) {
        const form = new FormData();

        form.append('data[title]', draft.title);

        if (draft.description) {
            form.append('data[description]', draft.description);
        }

        const response = await api.http.post(`places/${placeStore.place.id}/content-submissions/pending`, form);

        draft.setId(response.data.id);

        return response;
    }

    createTestimonial(draft: DraftContent) {
        const form = new FormData();

        form.append('data[title]', draft.title);
        form.append('data[text]', draft.testimonial);
        form.append('data[place_id]', placeStore.place.id.toString());
        form.append('data[content_story_id]', draft.id.toString());
        form.append('data[rating]', '5');

        return api.http.post('content-testimonials', form);
    }

    createVideo(draft: DraftContent, onUploadProgress) {
        const form = new FormData();

        form.append('data[type]', draft.content_videos[0].product ? 'product' : 'customer');
        form.append('data[place_id]', placeStore.place.id.toString());
        form.append('data[content_story_id]', draft.id.toString());
        if (draft.content_videos[0].file) form.append('data[video_file]', draft.content_videos[0].file);
        else if (draft.content_videos[0].src) form.append('data[video_url]', draft.content_videos[0].src);

        return api.http.post('content-videos', form, { onUploadProgress });
    }

    createPhoto(draft: DraftContent, photo: Object, onUploadProgress) {
        const form = new FormData();

        form.append('data[type]', photo.product ? 'product' : 'customer');
        form.append('data[place_id]', placeStore.place.id.toString());
        form.append('data[content_story_id]', draft.id.toString());
        if (photo.file) form.append('data[photo_file]', photo.file);
        else if (photo.src) form.append('data[photo_url]', photo.src);

        return api.http.post('content-photos', form, { onUploadProgress });
    }

    publish(draft: DraftContent) {
        return api.http.put(
            `places/${placeStore.place.id}/content-submissions/pending/${draft.id}`,
            { data: { status: 'published' } },
            { headers: { 'content-type': 'application/json' } }
        );
    }
}

export default new DraftContentStore();
