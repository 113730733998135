// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';
import { Field } from 'app/external/mobx-form-for';
import userInfoStore from 'stores/userInfo';

import Accordion from 'components/Accordion';

import PlaceNotifications from 'models/PlaceNotifications';

type Props = ComponentProps & {};

@observer
export default class PlaceNotificationField extends React.Component<Props> {
    input: HTMLElement;

    componentDidMount() {
        this.props.onMount(this.input);
    }

    renderPlaceForm(place: PlaceNotifications, index: number) {
        return (
            <Accordion.Item key={index} place={place}>
                <div className="panel-inner-title">Review Notifications</div>
                <div className="wrapper">
                    <Field name="frequency" value={place.frequency} />
                    <Field name="positive_reviews" value={place.positive_reviews} />
                    <Field name="negative_reviews" value={place.negative_reviews} />
                </div>
                <div className="panel-inner-title">Feedback Notifications</div>
                <div className="wrapper">
                    <Field name="service_feedback" value={place.service_feedback} />
                    <Field name="sales_feedback" value={place.sales_feedback} />
                </div>
                {(userInfoStore.isPlaceManager || userInfoStore.isOrganizationManager) && (
                    <React.Fragment>
                        <div className="panel-inner-title">Unsubscribe Notifications</div>
                        <div className="wrapper">
                            <Field name="unsubscribes" value={place.unsubscribes} />
                        </div>
                    </React.Fragment>
                )}
            </Accordion.Item>
        );
    }

    render() {
        const { value } = this.props;
        return (
            <Accordion className="custom settings-accordion">
                {value.map((place, index) => this.renderPlaceForm(place, index))}
            </Accordion>
        );
    }
}
