// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';

import Place from 'models/Place';

import SelectField from 'fields/Select';

type Props = ComponentProps & {
    places: Place[],
};

@observer
export default class PlaceField extends React.Component<Props> {
    get options(): Object[] {
        return this.props.places.map(place => ({
            value: place.id,
            text: place.name,
        }));
    }

    handleChange = (event: SyntheticInputEvent<HTMLSelectElement>, value: any) => {
        this.props.onChange(event, [value]);
    };

    render() {
        const { value, ...rest } = this.props;
        delete rest.places;

        // $FlowFixMe
        return <SelectField {...rest} options={this.options} value={(value || [])[0]} onChange={this.handleChange} />;
    }
}
