// @flow

import { type App } from 'types';

const app: App = {
    feature: 'kenect',
    path: 'https://app.kenect.com/inbox',
    title: 'Sign in to Kenect',
    mobileTitle: 'K',
    description: 'Reach Customers via Text',
    className: 'kenect',
    browserThemeColor: '#1477f8',
    icon: 'icon_kenect',
    routes: [],
    defaultRoute: 'search',
    // marketingMessageDisabled:
    //     'COMING SOON: Reach out to your Friendemic Client Success Representatives to join the pilot program!',
    disabledLink: '',
};

export default app;
