// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import PublishedContent from 'models/PublishedContent';

type Props = {
    content: PublishedContent,
    onClick?: Function,
    active?: boolean,
    noMenu?: boolean,
    inverted?: boolean,
    className?: string,
};

@observer
export default class SubmissionCard extends React.Component<Props> {
    get classNames() {
        const { active, inverted, className } = this.props;
        const classes = ['list-item', 'main-first', 'icon', 'clearfix', 'two-lines'];

        if (active) classes.push('active');
        if (inverted) classes.push('inverted');

        return `${classes.join(' ')} ${className || ''}`;
    }

    handleClick = () => {
        this.props.onClick && this.props.onClick(this.props.content);
    };

    render() {
        const { noMenu, content } = this.props;

        if (!content) {
            return null;
        }

        return (
            <div className={this.classNames} onClick={this.handleClick}>
                <div className="thumb no-select outlined filled themed">
                    <Icon className="edit" name="edit" />
                </div>
                {!noMenu && (
                    <div className="item-menu">
                        <Dropdown icon="ellipsis vertical" pointing="top right">
                            <Dropdown.Menu>
                                <Dropdown.Item text="Hide" icon="hide" />
                                <Dropdown.Item text="Archive" icon="archive" />
                                <Dropdown.Item text="Delete" icon="delete" />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
                <div className="main">
                    <span className="top-text">{`Submission #${content.id}`}</span>
                    <span className="main-text">{content.title}</span>
                </div>
            </div>
        );
    }
}
