import * as React from 'react';
import Modal from 'components/Modal';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
// import image_placeholder from 'images/logo_placeholder.png';
import MediaPreview from 'app/components/MediaPreview';
import SocialPostFormModal from './SocialPostFormModal';
import { proxyS3Image } from 'app/helpers';
import { sanitize } from 'dompurify';

export const STATUS_ICON_MAP = {
    pending: ['calendar outline', 'grey', 'Pending'],
    approved: ['calendar outline', 'grey', 'Scheduled'],
    posted: ['check circle', 'green', 'Posted'],
    failed: [
        'exclamation triangle',
        'red',
        'Failed - Post failed to post to the social network. Please reach out to your Kenect representative',
    ],
};

export const NETWORKS_MAP = {
    facebook: 'Facebook',
    twitter: 'Twitter',
    instagram: 'Instagram',
    youtube: 'Youtube',
    gmb: 'Google',
    linkedin: 'Linkedin',
};

export default function SocialPostModal({ event, setOpen, open, edit, refetch }) {
    const [photos, setPhotos] = React.useState([]);

    React.useEffect(() => {
        const photosTemp = [];
        if (event.photos) {
            event.photos.forEach(item => {
                photosTemp.push({
                    src: proxyS3Image(item.s3_path) || item,
                    type: 'photo',
                });
            });
        }
        if (event.videos) {
            event.videos.forEach(item => {
                photosTemp.push({
                    src: proxyS3Image(item.s3_path) || item,
                    type: 'video',
                });
            });
        }
        setPhotos(photosTemp);
    }, [event.photos, event.videos]);

    return (
        <Modal
            className="custom"
            title="Social Post Details"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Content>
                <div className="modal-content-inner-wrapper two-column clearfix social-post-modal">
                    <div className="column clearfix">
                        <div className="additional-info">
                            <div className="item">
                                <div className="title">Status</div>
                                <Icon
                                    name={STATUS_ICON_MAP[event.soci_status][0]}
                                    color={STATUS_ICON_MAP[event.soci_status][1]}
                                />{' '}
                                {STATUS_ICON_MAP[event.soci_status][2]}
                            </div>
                            <div className="item">
                                <div className="title">Suggested Date & Time</div>
                                {moment(event.start).format('HH:mm A z MM/DD/YYYY')}
                            </div>
                            {event.network_name && (
                                <div className="item">
                                    <div className="title">Social network</div>
                                    {NETWORKS_MAP[event.network_name]}
                                </div>
                            )}
                            {event.created_by && (
                                <div className="item">
                                    <div className="title">Created by</div>
                                    {event.created_by}
                                </div>
                            )}
                            <div className="item">
                                <div className="title">Caption</div>
                                <span dangerouslySetInnerHTML={{ __html: sanitize(event.caption) }} />
                            </div>
                            {event.instructions && (
                                <div className="item">
                                    <div className="title">Instructions</div>
                                    {event.instructions}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="column clearfix">
                        <div className="additional-info">
                            <div className="item">
                                <MediaPreview
                                    label={currentMedia =>
                                        `Media (${photos.length ? currentMedia + 1 : 0}/${photos.length})`
                                    }
                                    media={photos}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions className="custom">
                {open && edit && event.soci_status !== 'posted' && (
                    <SocialPostFormModal
                        edit
                        event={event}
                        photos={photos}
                        onClose={() => {
                            refetch();
                            setOpen(false);
                        }}
                        trigger={
                            <Button type="button" size="tiny" secondary>
                                Edit
                            </Button>
                        }
                    />
                )}
                <Button type="button" size="tiny" primary onClick={() => setOpen(false)}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

SocialPostModal.propTypes = {
    edit: PropTypes.bool,
    event: PropTypes.object,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    refetch: PropTypes.func,
};
