// @flow

import * as React from 'react';

import SidebarButton, { type SidebarButtonRender } from 'components/Sidebar/Button';
import Panel from 'components/Panel';

type Props = {
    id: string,
    title: string,
    sidebar: string,
    children: React.Node,
};

export default class PanelSelected extends React.Component<Props> {
    render() {
        return <SidebarButton sidebar={this.props.sidebar} render={this.renderContent} />;
    }

    renderContent = ({ onClick }: SidebarButtonRender) => {
        const { id, title } = this.props;

        return (
            <div className="mobile-item-container" onClick={onClick}>
                <Panel title={`Selected ${title}`}>
                    <div id={id} className="current-selected-item panel shadow">
                        <div className="items-menu-sidebar">
                            <div className="toggle-icon">
                                <i className="arrow left icon" />
                            </div>
                        </div>

                        {this.props.children}
                    </div>
                </Panel>
            </div>
        );
    };
}
