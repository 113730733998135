// @flow

import type { App } from 'types';
import { presets } from 'types';

import AppSelectionPage from 'pages/AppSelection';
import ProfilePage from 'pages/Profile';
import LocationInfoPage from 'pages/LocationInfo';
// import PublishingPage from 'pages/Publishing';
import OnlineReviewSitesPage from 'pages/OnlineReviewSites';
import UsersPage from 'pages/Users';
import LocationsPage from 'pages/Locations';
import ChangeLogPage from 'pages/ChangeLog';
import EmailPreviewPage from 'video/pages/EmailPreview';

import MainSidebar from 'sidebars/Main';
import LevelSidebar from 'sidebars/Level';
import SettingsSidebar from 'sidebars/Settings';
import NotificationsSidebar from 'sidebars/Notifications';
import NotificationsettingsPage from './pages/NotificationSettings';
import ContactPage from './pages/Contact';
import KenectUpgradeCardBackup from './components/KenectUpgradeCard/KenectUpgradeCardBackup';

const app: App = {
    feature: 'general',
    path: '',
    title: 'General',
    mobileTitle: 'General',
    icon: 'setting',
    semanticIcon: true,
    browserThemeColor: '#3f3f3f',
    routes: {
        notificationsetting: {
            path: 'notification-settings',
            title: 'Notificationsetting',
            component: NotificationsettingsPage,
        },
        appSelection: {
            path: 'app-selection',
            title: 'App Selection',
            component: AppSelectionPage,
            exact: true,
        },
        profile: {
            path: 'profile',
            title: 'Profile',
            preset: presets.settings,
            component: ProfilePage,
            exact: true,
            notLocationDependent: true,
            notOrganizationDependent: true,
        },
        locationInfo: {
            path: 'location-info',
            title: 'Location Info',
            preset: presets.settings,
            component: LocationInfoPage,
            exact: true,
            role: 'place-manager',
        },
        onlineReviewSites: {
            path: 'review-sites',
            title: 'Review Sites',
            preset: presets.settings,
            component: OnlineReviewSitesPage,
            exact: true,
            role: 'place-manager',
        },
        // publishing: {
        //     path: 'publishing',
        //     title: 'Publishing',
        //     preset: presets.settings,
        //     component: PublishingPage,
        //     exact: true,
        //     role: 'place-manager',
        // },
        users: {
            path: 'users',
            title: 'Users',
            preset: presets.adminSettings,
            component: UsersPage,
            exact: true,
            role: 'place-manager',
            notLocationDependent: true,
        },
        locations: {
            path: 'locations',
            title: 'Locations',
            preset: presets.adminSettings,
            component: LocationsPage,
            exact: true,
            role: 'organization-manager',
            notLocationDependent: true,
        },
        changeLog: {
            path: 'change-log',
            title: 'Change Log',
            preset: presets.info,
            component: ChangeLogPage,
            exact: true,
        },
        emailPreview: {
            path: 'email-preview',
            title: 'Email Preview',
            component: EmailPreviewPage,
            exact: true,
        },
        contact: {
            path: 'contact',
            title: 'Contact Us',
            preset: presets.settings,
            exact: true,
            component: ContactPage,
        },
        kenectUpgradeCardEmailTest: {
            title: 'Kenect Upgrade Card Email Test',
            path: 'kenect-upgrade-card-email-test',
            preset: presets.settings,
            component: KenectUpgradeCardBackup,
            exact: true,
            hidden: true,
        },
    },
    defaultRoute: 'appSelection',
    sidebars: {
        main: MainSidebar,
        level: LevelSidebar,
        settings: SettingsSidebar,
        notifications: NotificationsSidebar,
    },
};

export default app;
