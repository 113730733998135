/* eslint-disable no-console */
// @flow

import { action, observable } from 'mobx';
import { Model as PremiereModel } from '@friendemic/premiere';
import axiosStore from 'stores/axios';
import * as Sentry from '@sentry/react';

export default class Model extends PremiereModel {
    @observable memorizedChanges: ?Object;

    get original(): this {
        if (!this.key) return new this.self();
        return this.store.cache.objects.get(this.key);
    }

    get originalMap(): Object {
        return this.memorizedChanges || this.original.map;
    }

    static getObjectChanges(newObject: Object, oldObject: any) {
        if (typeof oldObject !== 'object') return newObject;

        const changedValues = {};
        Object.keys(newObject).forEach(key => {
            const newValue = newObject[key];
            if (newValue !== oldObject[key]) changedValues[key] = newValue;
        });

        return changedValues;
    }

    calculateChanges(): Object {
        let changedValues = {};

        const originalMap = this.originalMap;
        const currentMap = this.map;

        Object.keys(currentMap).forEach(key => {
            const originalValue = originalMap[key] || '';
            const currentValue = currentMap[key] || '';

            if (key.endsWith('_at')) return;

            if (typeof currentValue === 'object') {
                const objectChanges = Model.getObjectChanges(currentValue, originalValue);
                if (Object.keys(objectChanges).length) changedValues[key] = objectChanges;
            } else if (originalValue !== currentValue) {
                changedValues[key] = currentValue;
            }
        });

        return changedValues;
    }

    get mapChanged(): Object {
        return this.calculateChanges();
    }

    get changed(): boolean {
        return Object.keys(this.mapChanged).length > 0;
    }

    @action
    memorizeChanges(): this {
        this.memorizedChanges = this.map;
        return this;
    }

    clone(): this {
        return Object.assign(new this.self(), this);
    }

    static get displayName(): string {
        return this.reflector.className || this.reflector.path;
    }

    static create(values: Object, options?: Object): Promise<Model> {
        return this.store.create({ data: values }, options).catch(error => {
            throw error;
        });
    }

    static update(key: any, values: Object, options?: Object): Promise<Model> {
        return this.store.update(key, { data: values }, options).catch(error => {
            throw error;
        });
    }

    async saveChanges(options: Object = {}, type: string = ''): Promise<Model> {
        const mappedChanges = this.mapChanged;
        (options.with || []).forEach(property => {
            mappedChanges[property] = this[property];
        });
        let newObject;
        if (type === 'realtimeInvite') {
            const response = await axiosStore.axiosApi
                .post(`/invite/dispatch`, { data: mappedChanges })
                .catch(error => {
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        const { errors } = error.response.data;
                        const message = Object.keys(errors)
                            .map(x => errors[x])
                            .join(', ');
                        throw new Error(message);
                    } else {
                        Sentry.captureException(error);
                        throw new Error('Something went wrong, please try again');
                    }
                });
            newObject = response.data;
        } else {
            const promise = this.key
                ? this.self.update(this.key, mappedChanges, options)
                : this.self.create(mappedChanges, options);

            newObject = await promise;
        }

        this.memorizeChanges();
        return newObject;
    }
}
