// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';
import Video from 'models/Video';
import videoStore from 'video/stores/video';
import { ellipsis, hasRole } from 'helpers';
import VideoClipboardPopup from 'video/components/Video/ClipboardPopup';
import VideoThumbnail from 'video/components/Video/Thumbnail';

type Props = {
    video: Video,
    style?: Object,
    inverted?: boolean,
    ellipsisBreakpoint: number,
    handleOpenDeleteConfirmationModalOpened?: Function,
};

@observer
export default class VideoCard extends React.Component<Props, State> {
    state = {
        isDropdownOpen: false,
    };

    handleClick = () => {
        videoStore.select(this.props.video);
    };

    handleDropdownItemClick = callback => {
        this.handleDropdownClose();

        if (callback) {
            callback(this.props.video);
        }
    };

    handleDropdownClose = () => {
        this.setState({
            isDropdownOpen: false,
        });
    };

    render() {
        const { video, style, ellipsisBreakpoint, inverted, handleOpenDeleteConfirmationModalOpened } = this.props;
        const { isDropdownOpen } = this.state;

        const classes = ['list-item two-lines main-first icon clearfix'];
        if (video === videoStore.video) {
            classes.push('active');
            videoStore.activeVideoInListDom = this.activeVideo;
        }
        if (inverted) classes.push('inverted');

        const triggerIcon = (
            <Icon name="ellipsis vertical" onClick={() => this.setState({ isDropdownOpen: !isDropdownOpen })} />
        );

        return (
            <div
                className={classes.join(' ')}
                onClick={this.handleClick}
                style={style}
                ref={el => {
                    this.activeVideo = el;
                }}
            >
                <VideoThumbnail video={video} />

                <div className="more-options" onClick={e => e.preventDefault()}>
                    <Dropdown
                        pointing="top right"
                        inline
                        icon={null}
                        trigger={triggerIcon}
                        open={isDropdownOpen}
                        onBlur={this.handleDropdownClose}
                    >
                        <Dropdown.Menu className={'columns'}>
                            <Dropdown.Item onClick={() => this.handleDropdownItemClick()}>
                                <VideoClipboardPopup video={video}>
                                    <Icon name="linkify" />
                                    Copy URL
                                </VideoClipboardPopup>
                            </Dropdown.Item>
                            {(hasRole('system-admin') ||
                                hasRole('organization-manager') ||
                                hasRole('place-manager')) && (
                                <Dropdown.Item
                                    onClick={() =>
                                        this.handleDropdownItemClick(handleOpenDeleteConfirmationModalOpened)
                                    }
                                >
                                    <span>
                                        <Icon name="trash" />
                                        Delete Video
                                    </span>
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className="main">
                    <span className="main-text">{ellipsis(video.title, ellipsisBreakpoint)}</span>
                    <span className="bottom-text">{video.user_name}</span>
                </div>
            </div>
        );
    }
}
