// @flow

import { computed, observable } from 'mobx';
import { field } from 'app/external/form-for';
import Model from 'models/Model';

import extract from 'normalizers/extract';

export default class Integration extends Model {
    path = 'integrations';

    id: number;
    organization_id: number;
    type: string;

    @observable
    @field
    external_id: string;

    @observable
    @field({ type: 'switch', label: 'Send Invites for Sales Transactions' })
    @extract({ property: 'settings', denormalize: sales => (sales ? 'true' : 'false') })
    sales: boolean;

    @observable
    @field({
        type: 'number',
        label: 'Delay Sending Sales Invites by:',
        placeholder: '0',
        step: 1,
        min: 0,
        fieldLabel: 'day(s)',
        fieldLabelPosition: 'right',
    })
    @extract('settings')
    sales_delay: number;

    @observable
    @field({ type: 'switch', label: 'Send Invites for Service Transactions' })
    @extract({ property: 'settings', denormalize: service => (service ? 'true' : 'false') })
    service: boolean;

    @observable
    @field({
        type: 'number',
        label: 'Delay Sending Service Invites by:',
        placeholder: '0',
        step: 1,
        min: 0,
        fieldLabel: 'day(s)',
        fieldLabelPosition: 'right',
    })
    @extract('settings')
    service_delay: number;

    @computed
    get hasExternalId(): boolean {
        return !!(this.external_id && this.external_id.length);
    }

    normalize_sales(sales: string): boolean {
        return sales === 'true';
    }

    normalize_sales_delay(sales_delay: string): string {
        return sales_delay || '0';
    }

    normalize_service(service: string): boolean {
        return service === 'true';
    }

    normalize_service_delay(service_delay: string): string {
        return service_delay || '0';
    }
}
