// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Accordion as SemanticAccordion } from 'semantic-ui-react';
import { FieldGroup, Field } from 'app/external/mobx-form-for';

type Props = {
    active: boolean,
    index: number,
    onClick: Function,
    place: Object,
    children: React.Node,
};

@observer
export default class AccordionItem extends React.Component<Props> {
    render() {
        const { place, active, index, onClick, children, ...otherProps } = this.props;
        const checked = place.active;

        return (
            <React.Fragment>
                <FieldGroup for={place} index={index}>
                    <SemanticAccordion.Title active={active && checked} index={index} onClick={onClick} {...otherProps}>
                        <div className="ui toggle checkbox clearfix">
                            <Field name="active" value={checked ? '1' : '0'} />
                        </div>
                        <span className="label">{place.place_name}</span>
                        <i className="dropdown custom icon">
                            <div className="menu" />
                        </i>
                    </SemanticAccordion.Title>
                    <SemanticAccordion.Content active={active && checked} {...otherProps} className="no-padding">
                        {children}
                    </SemanticAccordion.Content>
                </FieldGroup>
            </React.Fragment>
        );
    }
}
