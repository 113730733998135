// @flow

import * as React from 'react';
import { ToastContainer as ToastifyContainer, toast, style } from 'react-toastify';
import Transition from 'react-transition-group/Transition';

type Props = {
    children: React.Node,
};

const toastAnimation = ({ children, ...props }: Props) => (
    <Transition
        {...props}
        timeout={800}
        onEnter={node => node.classList.add('zoomIn', 'animate')}
        onExit={node => {
            node.classList.remove('zoomIn', 'animate');
            node.classList.add('zoomOut', 'animate');
        }}
    >
        {children}
    </Transition>
);

const options = {
    transition: toastAnimation,
    toastClassName: {
        borderRadius: '5px',
        padding: '10px 20px',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.4)',
    },
    closeButton: false,
    autoClose: 5000,
};

style({
    colorInfo: '#333333',
    colorSuccess: '#8bc34a',
    colorWarning: '#ffc107',
    colorError: '#f44336',
    colorProgressDefault: 'linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.6))',
});

export default class ToastContainer extends React.Component<{}> {
    render() {
        return <ToastifyContainer position={toast.POSITION.TOP_CENTER} {...options} />;
    }
}
