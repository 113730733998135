// @flow

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import debounce from 'debounce';

type Props = {
    onSearch?: Function,
    onSearchSelect?: Function,
};

const DEBOUNCE_INTERVAL_MS = 500;

@observer
export default class OmniSearch extends React.Component<Props> {
    @observable result: Object;
    search: string;

    dispatchSearch = () => {
        if (this.search.length > 0 && this.search.length < 3) return;

        // $FlowFixMe
        this.props.onSearch(this.search);
    };

    debouncedSearch = debounce(this.dispatchSearch, DEBOUNCE_INTERVAL_MS);

    handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
        const search: string = event.currentTarget.value;
        this.search = search;

        this.debouncedSearch();
    };

    render() {
        const { onSearch } = this.props;

        return (
            <div id="omni-search" className="ui right left icon input">
                {onSearch && (
                    <React.Fragment>
                        <input type="text" placeholder="Search..." onChange={this.handleChange} />
                        <i className="search icon" />
                    </React.Fragment>
                )}
            </div>
        );
    }
}
