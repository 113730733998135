// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Button, Modal as SemanticModal } from 'semantic-ui-react';
import { Form } from 'app/external/mobx-form-for';
import { uniqueId } from 'app/external/form-for-component-helpers';

import { toast, httpResponseErrors } from 'helpers';

import Modal from 'components/Modal';
import FormButton from 'components/FormButton';

type Props = {
    forObject?: Object,
    encType?: string,
    title: React.Node,
    icon?: string,
    size?: string,
    trigger: React.Node,
    action: string,
    onAction: Function,
    formClassName?: string,
    children?: React.Node,
    mixedAction?: Function,
    onClose?: Function,
    actionDisabled?: boolean,
    closeOnDimmerClick?: boolean,
};

@observer
export default class ModalAction extends React.Component<Props> {
    button: ?HTMLButtonElement;
    open: Function;
    close: Function;

    @observable saving: boolean;

    @action
    setSaving(saving: boolean) {
        this.saving = saving;
    }

    get form(): HTMLFormElement {
        // $FlowFixMe
        return document.getElementById(uniqueId(this));
    }

    handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setSaving(true);

        try {
            await this.props.onAction({ currentTarget: this.form });
            this.handleClose();
        } catch (e) {
            toast.error(`Failed to ${this.props.action.toLowerCase()} : ${httpResponseErrors(e)}`);
        } finally {
            this.setSaving(false);
        }
    };

    handleClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose(false);
        this.close();
    };

    submit = () => {
        // $FlowFixMe
        this.button.click();
    };

    render() {
        const { forObject: object, encType, icon, action, size, children, formClassName, mixedAction } = this.props;
        const formClassNameArray = ['ui form tiny'];

        if (formClassName) formClassNameArray.push(formClassName);

        return (
            <Modal
                className="custom"
                icon={icon}
                size={size || 'small'}
                title={this.props.title}
                trigger={this.props.trigger}
                closeOnDimmerClick={this.props.closeOnDimmerClick}
                render={({ onOpen, onClose }) => {
                    this.open = onOpen;
                    this.close = onClose;
                    if (mixedAction) {
                        return (
                            <React.Fragment>
                                <SemanticModal.Content>
                                    <Form
                                        id={uniqueId(this)}
                                        for={object}
                                        onSubmit={this.handleSubmit}
                                        encType={encType}
                                        className={formClassNameArray.join(' ')}
                                    >
                                        {mixedAction({ submit: this.submit, saving: this.saving })}

                                        <button ref={el => (this.button = el)} style={{ display: 'none' }} />
                                    </Form>
                                </SemanticModal.Content>

                                <SemanticModal.Actions>
                                    {!this.saving && (
                                        <Button type="button" onClick={onClose} size="tiny" color="grey" basic>
                                            Close
                                        </Button>
                                    )}
                                </SemanticModal.Actions>
                            </React.Fragment>
                        );
                    }
                    return (
                        <React.Fragment>
                            <SemanticModal.Content>
                                <Form
                                    id={uniqueId(this)}
                                    for={object}
                                    onSubmit={this.handleSubmit}
                                    encType={encType}
                                    className={formClassNameArray.join(' ')}
                                >
                                    {children}

                                    <button ref={el => (this.button = el)} style={{ display: 'none' }} />
                                </Form>
                            </SemanticModal.Content>

                            <SemanticModal.Actions>
                                {!this.saving && (
                                    <Button type="button" onClick={this.handleClose} size="tiny" color="grey" basic>
                                        Cancel
                                    </Button>
                                )}

                                <FormButton
                                    icon={icon}
                                    primary
                                    onClick={this.submit}
                                    saving={this.saving}
                                    disabled={this.props.actionDisabled}
                                    text={action}
                                />
                            </SemanticModal.Actions>
                        </React.Fragment>
                    );
                }}
            />
        );
    }
}
