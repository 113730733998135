// @flow

import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Field, FieldGroup } from 'app/external/mobx-form-for';
import { Message, Form, Popup, Icon, Button } from 'semantic-ui-react';
import * as Sentry from '@sentry/react';

import SiteInfo, { type SitesInfoByGroup, type SitesInfoGroup } from 'models/SiteInfo';
import Profile from 'models/Profile';
import type { ProfilesByChannel } from 'models/Place';
import Place from 'models/Place';

import placeStore from 'stores/place';
import userInfoStore from 'stores/userInfo/userInfoStore';

import { toast } from 'helpers';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import PlaceMonitor from 'components/Place/Monitor';
import ConnectButton from 'components/ConnectButton';
import { api } from '@friendemic/premiere';

type Props = { history: Object };

@observer
export default class OnlineReviewSitesPage extends React.Component<Props> {
    @observable sitesInfoByGroup: SitesInfoByGroup;
    @observable profilesByChannel: ProfilesByChannel;
    @observable place: Place;

    @observable errors: { [channel: string]: string } = {};

    state = {
        isAxiosLoading: false,
        combineIdsInputHasValue: false,
        combineIdsInputError: '',
    };

    get profiles(): Profile[] {
        // $FlowFixMe
        return Object.values(this.profilesByChannel);
    }

    @computed
    get changed(): boolean {
        if (!this.profilesByChannel) return false;
        return !!this.profiles.find(profile => profile.changed) || this.place.changed;
    }

    @action
    resetErrors() {
        this.errors = {};
    }

    @action
    setError(channel: string, url: string) {
        this.errors = { ...this.errors, [channel]: url };
    }

    @action
    fulfillProfiles() {
        if (!this.sitesInfoByGroup || !this.profilesByChannel) return;
        // $FlowFixMe
        const siteInfoGroups: SiteInfo[][] = Object.values(this.sitesInfoByGroup);
        siteInfoGroups.forEach(siteInfoGroup => {
            siteInfoGroup.forEach(siteInfo => {
                const { channel } = siteInfo;
                if (!this.profilesByChannel[channel]) {
                    this.profilesByChannel[channel] = new Profile()
                        .set({ place_id: placeStore.place.id, channel: siteInfo.channel, url: '', is_custom: false })
                        .memorizeChanges();
                }
            });
        });
    }

    @action
    setSitesInfoGroup(data: SitesInfoByGroup) {
        this.sitesInfoByGroup = data;
        this.fulfillProfiles();
    }

    @action
    setProfilesByChannel(profiles: ?ProfilesByChannel) {
        // $FlowFixMe
        if (profiles) Object.keys(profiles).forEach(channel => (profiles[channel] = profiles[channel].clone()));

        // $FlowFixMe
        this.profilesByChannel = profiles;
        this.fulfillProfiles();
    }

    @action
    setPlace(place: ?Place) {
        if (place) place = place.clone();
        // $FlowFixMe
        this.place = place;
    }

    @action
    handleDelete = async profile => {
        if (profile.channel === 'facebook') {
            this.place.clearFacebook();
        } else if (profile.channel === 'google' || profile.channel === 'googleplace') {
            this.place.clearGoogle();
        }

        if (profile) profile.set({ url: '' });

        this.place = this.place.clone();
    };

    async loadSitesInfoGroup() {
        this.setSitesInfoGroup(await SiteInfo.allByGroup());
    }

    async loadProfilesByChannel() {
        this.setProfilesByChannel(await placeStore.place.profilesByChannel());
    }

    async loadPlace(place: Place) {
        this.setPlace(await Place.find(place.id));
    }

    load = async (place: Place) => {
        if (this.place) this.setPlace(null);
        if (this.profilesByChannel) this.setProfilesByChannel(null);
        await Promise.all([this.loadSitesInfoGroup(), this.loadProfilesByChannel(), this.loadPlace(place)]);
    };

    isAFacebookChange(profile: Profile) {
        return (
            (profile.changed || this.place.changed) && profile.channel === 'facebook' && this.place.changedFacebook()
        );
    }

    isAGoogleChange(profile: Profile) {
        return (
            (profile.changed || this.place.changed) &&
            (profile.channel === 'google' || profile.channel === 'googleplace') &&
            this.place.changedGoogle()
        );
    }

    handleSubmit = async (event: Event) => {
        event.preventDefault();
        const promises = [];
        this.resetErrors();
        if (this.place.changed && !this.place.changedGoogle) promises.push(this.place.saveChanges());

        this.profiles.forEach((profile: Profile) => {
            if (this.isAFacebookChange(profile)) {
                profile.invite_only = true;
                return promises.push(profile.persist().catch(() => this.setError(profile.channel, profile.url)));
            }

            if (this.isAGoogleChange(profile)) {
                profile.url_only = true;
                return promises.push(profile.persist().catch(() => this.setError(profile.channel, profile.url)));
            }

            if (profile.changed)
                return promises.push(profile.persist().catch(() => this.setError(profile.channel, profile.url)));
        });

        await Promise.all(promises);
        if (!Object.keys(this.errors).length)
            toast.success(
                'Review sites updated. If a new URL was added, go to Invite Settings to add it to your invite message.'
            );
    };

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        return (
            <Page
                for={{ changed: this.changed, schema: this.place }}
                onSubmit={this.handleSubmit}
                history={this.props.history}
            >
                <Column>
                    {this.renderGroup('Most Popular', true)}
                    {this.renderGroup('Automotive')}
                </Column>

                <Column>
                    {this.renderGroup('Other')}
                    {this.renderGroup('Monitoring Only')}
                    {this.renderGroup('Invite Only')}
                    {this.renderGroup('Combine Profile IDs')}
                </Column>
                <Column>{this.renderGroup('Basic Response')}</Column>
            </Page>
        );
    };

    renderButton(profile: Profile, siteInfo: SiteInfo) {
        if (profile.channel === 'facebook') {
            return (
                <div>
                    <ConnectButton
                        network={profile.channel}
                        connected={!!this.place.facebook_channel_id}
                        connectedName={this.place.facebook_channel_name}
                        avatar={this.place.facebook_channel_picture}
                        actionRequired={this.place.facebook_channel_status}
                        onDelete={() => this.handleDelete(profile)}
                    />
                    {this.place.facebook_channel_id && profile.url && (
                        <>
                            <a href={profile.url} target="_blank" rel="noopener noreferrer">
                                <Field
                                    className="disabled" /* disabled prop was double applying */
                                    name="url"
                                    label={`Facebook URL:`}
                                    placeholder={profile.url}
                                />
                            </a>
                            <div style={{ marginTop: '16px' }}>
                                {profile.combine_id ? (
                                    <Field
                                        className="disabled" /* disabled prop was double applying */
                                        name="combine_id"
                                        label={`Combine Profile ID:`}
                                        placeholder={profile.combine_id}
                                        value={profile.combine_id}
                                    />
                                ) : null}
                            </div>
                        </>
                    )}
                </div>
            );
        } else if (profile.channel === 'google' || profile.channel === 'googleplace') {
            const hasUrl = (profile.url || {}).length;
            return (
                <div>
                    <div className={'ui clearing divider'} />
                    {!this.place.google_channel_id && (
                        <Field
                            name="url"
                            label={`${siteInfo.display_name} URL:`}
                            placeholder={siteInfo.invite_url_example}
                            error={hasUrl && profile.url === this.errors[profile.channel] && 'Invalid url'}
                        />
                    )}
                    <ConnectButton
                        network={profile.channel}
                        connected={!!this.place.google_channel_id}
                        connectedName={this.place.google_channel_name}
                        avatar={this.place.google_channel_picture}
                        actionRequired={this.place.google_channel_status}
                        onDelete={() => this.handleDelete(profile)}
                    />
                    {this.place.google_channel_id && profile.url && (
                        <>
                            <a href={profile.url} target="_blank" rel="noopener noreferrer">
                                <Field
                                    className="disabled" /* disabled prop was double applying */
                                    name="url"
                                    label={`Google URL:`}
                                    placeholder={profile.url}
                                />
                            </a>
                            <div style={{ marginTop: '16px' }}>
                                {profile.combine_id ? (
                                    <Field
                                        className="disabled" /* disabled prop was double applying */
                                        name="combine_id"
                                        label={`Combine Profile ID:`}
                                        placeholder={profile.combine_id}
                                        value={profile.combine_id}
                                    />
                                ) : null}
                            </div>
                        </>
                    )}
                </div>
            );
        }
    }

    async handleReviewResponseToggle() {
        if (userInfoStore.isAdmin || userInfoStore.isOrganizationManager) {
            try {
                const flag = !placeStore.place.features.review_response;
                const updatedFeatures = {
                    ...placeStore.place.features,
                    review_response: flag,
                };
                placeStore.updateFeatures(updatedFeatures);
                await Place.update(placeStore.place.id, { features: updatedFeatures });
                toast.success(`Review Response ${flag ? 'enabled' : 'disabled'} successfully.`);
            } catch (e) {
                toast.error('Something went wrong.');
                Sentry.captureException(e);
            }
        }
    }

    async submitCombineIds(requestParams: Object): Promise<Object> {
        try {
            this.setState({ isAxiosLoading: true });
            const { data } = await api.http.post('/ext_combine_ids', {
                data: requestParams,
            });
            this.setState({ isAxiosLoading: false });
            return data;
        } catch (e) {
            this.setState({ isAxiosLoading: false });
            throw e;
        }
    }

    handleCombineIdsSubmit = async (event: Event) => {
        event.preventDefault();
        this.setState({ combineIdsInputError: '' });
        const input = document.getElementById('CombineIdsInput');
        if (input instanceof HTMLInputElement) {
            const combineIdsSplit = input.value.split(',').map(combineId => combineId.trim());
            const combineIds = combineIdsSplit
                .map(combineId => !Number.isNaN(parseInt(combineId)) && parseInt(combineId))
                .filter(elm => elm);
            try {
                if (combineIdsSplit.map(combineId => parseInt(combineId)).includes(NaN)) {
                    this.setState({
                        combineIdsInputError: `Invalid Combine Profile IDs: ${combineIdsSplit
                            .map(combineId => isNaN(parseInt(combineId)) && combineId)
                            .filter(elm => elm)
                            .join(', ')}`,
                    });
                    return;
                }
                const result = await this.submitCombineIds({
                    ext_combine_ids: combineIds.join(','),
                    place_id: placeStore.place.id,
                });
                const { code, message } = result;
                if (code === 200) {
                    toast.success('Combine Profile IDs saved successfully.');
                    this.setState({ combineIdsInputError: '' });
                } else if (code === 204) {
                    toast.error('Some Combine IDs were not saved. Please check the provided list.');
                    this.setState({ combineIdsInputError: message });
                }
                input.value = '';
                this.setState({ combineIdsInputHasValue: false });
                return combineIds;
            } catch (e) {
                toast.error(e.message);
                Sentry.captureException(e);
            }
        }
    };

    handleCombineIdsOnChange = (event: Event) => {
        const input = event.target;
        input.value
            ? this.setState({ combineIdsInputHasValue: true })
            : this.setState({ combineIdsInputHasValue: false });
    };

    renderGroup(group: SitesInfoGroup, renderButton: ?boolean) {
        const groupSitesInfo = this.sitesInfoByGroup[group];
        if ((!groupSitesInfo || !groupSitesInfo.length) && group !== 'Combine Profile IDs') return null; // Combine IDs group is special and doesn't require groupSitesInfo
        if (group === 'Invite Only') {
            return <SettingsPanel title={group}>{this.renderInviteOnly(groupSitesInfo)}</SettingsPanel>;
        }

        if (group === 'Combine Profile IDs' && userInfoStore.isAdmin) {
            return (
                <SettingsPanel title={group}>
                    <h5>
                        Combine Profile IDs{' '}
                        <Popup
                            trigger={<Icon name="info circle" />}
                            content="This will allow you to add multiple profile ids at once. It is saved with the provided button, not the save button at the top of the page."
                            inverted
                            position="right center"
                        />
                    </h5>
                    <div className="field">
                        <label htmlFor="CombineIdsInput">{'Combine Profile IDs:'}</label>
                        <div className="ui input">
                            <input
                                onChange={this.handleCombineIdsOnChange}
                                id="CombineIdsInput"
                                type="text"
                                placeholder="Enter Profile IDs separated by commas. Ex: 123, 456, 789"
                            />
                        </div>
                        {this.state.combineIdsInputError && (
                            <Message className="custom" info color="red">
                                {this.state.combineIdsInputError}
                            </Message>
                        )}
                    </div>
                    <Button
                        size="tiny"
                        disabled={!this.state.combineIdsInputHasValue || this.state.isAxiosLoading}
                        loading={this.state.isAxiosLoading}
                        type="button"
                        primary
                        className="right floated"
                        onClick={this.handleCombineIdsSubmit}
                    >
                        <Icon name="save" /> Save Combine IDs
                    </Button>
                </SettingsPanel>
            );
        }

        if (group === 'Basic Response') {
            return (
                <SettingsPanel title={group}>
                    <h5>
                        Basic Review Response{' '}
                        <Popup
                            trigger={<Icon name="info circle" />}
                            content="Enable Review Response feature to be turned on/off for dealers in Reputation"
                            inverted
                            position="right center"
                        />
                    </h5>
                    <label className="toggle-switch">
                        <input
                            disabled={userInfoStore.isPlaceManager || userInfoStore.isPlaceManager}
                            type="checkbox"
                            checked={placeStore.place.features.review_response}
                            onChange={this.handleReviewResponseToggle}
                        />
                        <span
                            id={userInfoStore.isPlaceManager || userInfoStore.isPlaceManager ? 'disabled' : undefined}
                            className="switch"
                        />
                    </label>
                </SettingsPanel>
            );
        }
        if (groupSitesInfo && groupSitesInfo.length) {
            return (
                <SettingsPanel title={group}>
                    {groupSitesInfo.map(siteInfo => {
                        const profile = this.profilesByChannel[siteInfo.channel];
                        const hasUrl = (profile.url || {}).length;
                        const hideInputField =
                            (profile.channel === 'facebook' ||
                                profile.channel === 'googleplace' ||
                                profile.channel === 'google') &&
                            renderButton;
                        return (
                            <FieldGroup key={siteInfo.channel} for={profile} prefix={profile.channel}>
                                {!hideInputField && (
                                    <>
                                        <Field
                                            name="url"
                                            label={`${siteInfo.display_name} URL:`}
                                            placeholder={siteInfo.invite_url_example}
                                            error={
                                                hasUrl && profile.url === this.errors[profile.channel] && 'Invalid url'
                                            }
                                        />
                                        {profile.combine_id ? (
                                            <>
                                                <Field
                                                    className="disabled" /* disabled prop was double applying */
                                                    name="combine_id"
                                                    label={`Combine Profile ID:`}
                                                    placeholder={profile.combine_id}
                                                    value={profile.combine_id}
                                                />
                                            </>
                                        ) : null}
                                        {profile.channel !== 'carfax' && profile.channel !== 'yelp' ? (
                                            <div className={'ui clearing divider'} />
                                        ) : null}
                                    </>
                                )}
                                {hideInputField && this.renderButton(profile, siteInfo)}
                            </FieldGroup>
                        );
                    })}
                </SettingsPanel>
            );
        }
    }

    renderInviteOnly = (groupSitesInfo: Array<SiteInfo>) => {
        // $FlowFixMe
        return groupSitesInfo && groupSitesInfo.length
            ? groupSitesInfo.map(siteInfo => {
                  const profile = this.profilesByChannel[siteInfo.channel];

                  if (profile.channel === 'facebook') return this.renderInviteOnlyFacebook(siteInfo);
                  return (
                      <FieldGroup key={siteInfo.channel} for={profile} prefix={profile.channel}>
                          <h5>
                              Custom Review site{' '}
                              <Popup
                                  trigger={<Icon name="info circle" />}
                                  content="This custom URL will allow you to send your customers to the review site of your choice during the invite process to leave a review. This URL is strictly for invites only; no reporting will be available for the additional site chosen."
                                  inverted
                                  position="right center"
                              />
                          </h5>
                          <Form.Group widths="equal">
                              <Field
                                  label="Site Name:"
                                  name="channel"
                                  placeholder="Website name"
                                  error={profile.url && !profile.channel && 'This field is required!'}
                              />

                              <Field
                                  label="Site URL:"
                                  name="url"
                                  placeholder="https://www.example.com/your-dealership/write-review"
                                  error={!profile.url && profile.channel && 'This field is required!'}
                              />
                              {profile.combine_id ? (
                                  <>
                                      <Field
                                          className="disabled" /* disabled prop was double applying */
                                          name="combine_id"
                                          label={`Combine Profile ID:`}
                                          placeholder={profile.combine_id}
                                          value={profile.combine_id}
                                      />
                                  </>
                              ) : null}
                          </Form.Group>
                      </FieldGroup>
                  );
              })
            : null;
    };

    renderInviteOnlyFacebook = (siteInfo: SiteInfo) => {
        const profile = this.profilesByChannel[siteInfo.channel];
        const hasUrl = (profile.url || {}).length;
        const hasAuthentication = !!this.place.facebook_channel_id;
        return (
            <FieldGroup key={siteInfo.channel} for={profile} prefix={profile.channel}>
                <Field
                    name="url"
                    disabled={hasAuthentication}
                    label={`${siteInfo.display_name} URL:`}
                    placeholder={siteInfo.invite_url_example}
                    error={hasUrl && profile.url === this.errors[profile.channel] && 'Invalid url'}
                />
                {!hasAuthentication && (
                    <Message className="custom" info color="green">
                        When setting the URL manually in this field, reviews will not be collected. Please use the
                        &quot;Connect Facebook&quot; button, to enable both review invites and collection.
                    </Message>
                )}
            </FieldGroup>
        );
    };
}
