// @flow

import { observer } from 'mobx-react';
import React from 'react';

type Props = {
    input: string | File,
    children: Function,
    onLoad?: Function,
};

type State = {
    data: ?string,
};

@observer
export default class FilePreview extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { data: '' };
    }

    componentDidMount() {
        this.readFile(this.props.input);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.readFile(nextProps.input);
    }

    getReader(): FileReader {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            this.setState({ data: event.target.result });
            this.props.onLoad && this.props.onLoad(event);
        };

        return reader;
    }

    readFile(input: string | File) {
        const reader = this.getReader();
        if (input && typeof input === 'object') {
            reader.readAsDataURL(input);
        } else if (this.state.data) {
            this.setState({ data: null });
        }
    }

    render() {
        return this.props.children(this.state.data);
    }
}
