// @flow

import React from 'react';
import { Accordion } from 'semantic-ui-react';

import * as apps from 'apps';

import Sidebar from 'components/Sidebar';
import AppArrayAvailable from 'components/App/ArrayAvailable';
import SettingsSidebarApp from './App';

type Props = {};

type State = {
    activeIndex: string,
};

export default class SettingsSidebar extends React.Component<Props, State> {
    render() {
        return (
            <Sidebar id="settings-menu" title="Settings" direction="right" className="fit">
                <Accordion className="custom">
                    <SettingsSidebarApp app={apps.systemAdmin} />
                    <SettingsSidebarApp app={apps.organization} />
                    <SettingsSidebarApp app={apps.general} />

                    <AppArrayAvailable
                        render={apps => apps.map(app => <SettingsSidebarApp key={app.feature} app={app} />)}
                    />
                </Accordion>
            </Sidebar>
        );
    }
}
