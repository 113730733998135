import React from 'react';
import { api } from '@friendemic/premiere';
import placeStore from 'stores/place';
import DraftContent from 'models/DraftContent';
import publishedContentStore from 'photo/stores/submission/publishedContentStore';
import { toast } from 'app/helpers';
import * as Sentry from '@sentry/react';
import moment from 'moment-timezone';
import useDraft from '../useDraft';
import _ from 'lodash';

export default function useSociForm(eventId) {
    const [mediaLibrary, setMediaLibrary] = React.useState([]);
    const [loadingMore, setLoadingMore] = React.useState(false);
    const [hasMore, setHasMore] = React.useState(true);
    const [page, setPage] = React.useState(1);
    const [progress, setProgress] = React.useState(0);

    const { createDraft, progress: draftProgress } = useDraft();

    React.useEffect(() => {
        if (draftProgress !== 0) {
            setProgress(Math.floor(draftProgress * 0.99));
        }
    }, [draftProgress]);

    const loadNext = async () => {
        if (!hasMore || loadingMore) {
            return;
        }
        setLoadingMore(true);
        await api.http.get(`/soci/library/photos/${placeStore.place.id}?page=${page}`).then(resp => {
            if (!resp.data.data) {
                setLoadingMore(false);
                return;
            }
            resp.data.data.next_page_url && setPage(prevPage => prevPage + 1);
            resp.data.data.next_page_url ? setHasMore(true) : setHasMore(false);

            resp.status &&
                setMediaLibrary(
                    resp.data.data.data.map(s3_path => ({
                        src: s3_path,
                        type: /\.(gif|jpg|jpeg|tiff|png)$/i.test(s3_path) ? 'photo' : 'video',
                    }))
                );
        });
        setLoadingMore(false);
    };

    React.useEffect(() => {
        loadNext(true);
    }, [placeStore.place.id]);

    const handleClose = () => {
        setMediaLibrary([]);
        setPage(1);
        setHasMore(true);
        setLoadingMore(false);
    };

    const handleDelete = async () => {
        const sociResponse = await api.http.delete(`/soci/schedule/post/${eventId}`);
        if (sociResponse.data.status === 'success') {
            await publishedContentStore.forceLoad();
            toast.success('Delete successful.');
            return true;
        }
        toast.error(sociResponse.data.message);
        Sentry.captureException(sociResponse);
    };

    const handleSubmit = async (values, actions) => {
        try {
            const photos = [];
            const videos = [];
            values.media.forEach(media => {
                if (media.type === 'photo') photos.push(media);

                if (media.type === 'video') videos.push(media);
            });
            const networks = { ...values.networks };
            const networkNames = [];
            Object.keys(networks).forEach(key => {
                if (networks[key] === null) {
                    delete networks[key];
                } else {
                    networkNames.push(key);
                }
            });
            const draft = new DraftContent({
                title: values.caption,
                description: values.instructions + ` Suggested Networks: ${networkNames.join(', ')}`,
                content_videos: videos,
                content_photos: photos,
            });
            const responseCreate = await createDraft(!eventId, draft, false);

            const sociResponse = eventId
                ? await api.http.put(`/soci/schedule/post/${eventId}`, {
                      content_story_id: responseCreate.id,
                      place_id: placeStore.place.id,
                      publish_on: moment(values.publishOn).utc().format('YYYY-MM-DD HH:mm:ss'),
                      title: values.caption,
                      caption: values.caption,
                      instructions: values.instructions,
                  })
                : await api.http.post('/soci/schedule/post', {
                      content_story_id: responseCreate.id,
                      place_id: placeStore.place.id,
                      publish_on: moment(values.publishOn).utc().format('YYYY-MM-DD HH:mm:ss'),
                      title: values.caption,
                      caption: values.caption,
                      instructions: values.instructions,
                      networks,
                  });
            setProgress(100);

            if (sociResponse.data.status === 'success') {
                actions.resetForm();
                toast.success('Submission successful.');
                setProgress(0);
                publishedContentStore.forceLoad();
                return true;
            } else {
                throw sociResponse;
            }
        } catch (e) {
            const { media, ...formValues } = values;
            Sentry.captureException(e, {
                contexts: {
                    'Form Values': {
                        // reduce media to an object to make it easier to read in Sentry
                        ...media.reduce((acc, { src, file, type }) => {
                            const splitSrc = src.split('/');
                            const fileName = (file && file.name) || splitSrc[splitSrc.length - 1];

                            acc[`${_.capitalize(type)} ${fileName}`] = {
                                src,
                                // only include file info if it's a new file. Otherwise, it's already in the DB
                                ...(file && {
                                    name: file.name,
                                    size: file.size,
                                    mediaType: file.type,
                                    lastModified: file.lastModified,
                                }),
                            };
                            return acc;
                        }, {}),
                        ...formValues,
                    },
                },
            });
            toast.error(
                'Failed to upload content. There was an issue with the network. Please try again later or reach out to your Kenect representative.'
            );
            setProgress(0);
            return false;
        }
    };

    return {
        handleSubmit,
        mediaLibrary,
        loadNext,
        hasMore,
        handleDelete,
        handleClose,
        progress,
    };
}
