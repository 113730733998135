// @flow

import { Api } from '@friendemic/premiere';
import queryString from 'query-string';

class YoutubeApi extends Api {
    base = '/youtube';

    async videoInfo(youtubeId: string): Promise<Object> {
        const { data } = await this.http.get(`/get_video_info?html5=1&video_id=${youtubeId}`);
        return queryString.parse(data);
    }

    async videoInfoStreamMp4(youtubeId: string) {
        const info = await this.videoInfo(youtubeId);
        const playerResponse = JSON.parse(info.player_response);

        if (
            playerResponse.playabilityStatus.status === 'ERROR' ||
            playerResponse.playabilityStatus.status === 'UNPLAYABLE' ||
            !playerResponse.streamingData.formats
        )
            return 'error';

        const streams = playerResponse.streamingData.formats;
        // we need to set this up somewhere to get better quality than 720p
        // https://github.com/ytdl-org/youtube-dl
        const [bestQualityStream] = streams.slice(-1);

        return bestQualityStream.url;
    }
}

export default new YoutubeApi();
