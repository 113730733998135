// @flow
import React from 'react';
import SettingsPanel from 'components/Settings/Panel';
import { Message } from 'semantic-ui-react';
import { Field, FieldGroup } from 'app/external/mobx-form-for';
import Place from 'models/Place';
import { observer } from 'mobx-react';

type Props = {
    place: Place,
    disabled?: boolean,
};

const CUSTOM_LABEL = '(Custom) No more than once every _______ days';

const customLabel = (place: Place) => {
    if (!place.review_invite_frequency_length) return CUSTOM_LABEL;

    return CUSTOM_LABEL.replace('_______', `${place.review_invite_frequency_length}`);
};

const ReviewInviteFrequency = observer(({ place, disabled }: Props) => (
    <SettingsPanel title="Review invite frequency">
        <h5>Automated Review Invites</h5>
        <Message className="custom" info color="green">
            To avoid potentially overwhelming frequent customers with repeated invites, you may choose to limit how
            frequently customers receive an invite.
        </Message>
        <FieldGroup for={place}>
            <Field name="review_invite_frequency" labels={{ custom: customLabel(place) }} disabled={disabled} />
            {place.review_invite_frequency === 'custom' && (
                <Field name="review_invite_frequency_length" disabled={disabled} />
            )}
        </FieldGroup>
        <h5>Real-Time Invites</h5>
        <FieldGroup for={place}>
            <Field
                name="invite_duplicate_force_allowed"
                label="Allow sending invites to customers who have previously received one"
            />
        </FieldGroup>
    </SettingsPanel>
));

export default ReviewInviteFrequency;
