// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import SubmissionMediaToolbarShare from 'photo/components/Submission/Media/Toolbar/Share';
import * as mediaType from 'enum/mediaType';
import ContentCustomer from 'models/ContentCustomer';
import { observable, action, computed } from 'mobx';

type Props = {
    product: boolean,
    onRemoveClick?: Function,
    onDownloadClick?: Function,
    onShare?: Function,
    customer?: ContentCustomer,
    consented?: boolean,
    locked?: boolean,
    disabled?: boolean,
    mediaType: string,
    mediaURL: string,
};

type State = {
    shareOpen: boolean,
};

@observer
export default class SubmissionMediaToolbar extends React.Component<Props, State> {
    @observable sharedCustomer: ?ContentCustomer;

    state = {
        shareOpen: false,
    };

    componentWillReceiveProps() {
        this.resetCustomer();
    }

    @action
    resetCustomer() {
        this.sharedCustomer = null;
    }

    @action
    setCustomer(customer: ContentCustomer) {
        this.sharedCustomer = customer;
    }

    @computed
    get customer(): ?ContentCustomer {
        if (this.props.customer) return this.props.customer;

        return this.sharedCustomer;
    }

    get canShare(): boolean {
        return (
            !this.props.locked &&
            mediaType.PHOTO === this.props.mediaType &&
            !this.props.product &&
            (this.props.onShare || !!this.customer)
        );
    }

    handleShareClick = (event: Event) => {
        event.preventDefault();
        this.setState({
            shareOpen: true,
        });
    };

    handleShareClose = () => {
        this.setState({
            shareOpen: false,
        });
    };

    handleOnShare = (customer: ContentCustomer) => {
        this.setCustomer(customer);

        this.props.onShare && this.props.onShare(customer);
    };

    isBrowserIE = (): boolean => {
        const agent = window.navigator.userAgent;
        const oldIEvertionIndex = agent.indexOf('MSIE');
        let ieVersion = 0;

        if (oldIEvertionIndex > 0) ieVersion = parseInt(agent.split(';')[1].slice(5), 10);
        if (navigator.userAgent.match(/Trident\/7\./)) ieVersion = 11;

        return ieVersion > 0;
    };

    forceIEDownload = (event: Event, fileURL: string): void => {
        event.preventDefault();

        const newMediaWindow = window.open(fileURL, '_blank');

        newMediaWindow.document.execCommand('SaveAs', true, fileURL);
        newMediaWindow.close();
    };

    render() {
        const {
            product,
            onRemoveClick,
            onDownloadClick,
            locked,
            disabled,
            mediaType: contentMediaType,
            mediaURL,
        } = this.props;

        return (
            <div className="controls absolute top clearfix">
                <span className="control-label no-margin no-margin float-left">{product ? 'Product' : 'Customer'}</span>
                {this.isBrowserIE() ? (
                    <a
                        className="control-button float-right"
                        href={mediaURL}
                        onClick={event => this.forceIEDownload(event, mediaURL)}
                    >
                        <i className="cloud download icon">&nbsp;</i>Download{' '}
                        {contentMediaType === 'photo' ? 'photo' : 'video'}
                    </a>
                ) : (
                    <a
                        className="control-button float-right"
                        download
                        href={mediaURL}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <i className="cloud download icon">&nbsp;</i>Download{' '}
                        {contentMediaType === 'photo' ? 'photo' : 'video'}
                    </a>
                )}
                {onRemoveClick && (
                    <Button disabled={disabled} className="control-button icon float-right" onClick={onRemoveClick}>
                        <Icon name="times" />
                    </Button>
                )}
                {this.canShare && (
                    <Popup
                        className={`ui custom popup color-popup ${!this.customer ? 'shared-popup' : ''} bottom right`}
                        open={this.state.shareOpen}
                        onClose={this.handleShareClose}
                        on="click"
                        position="bottom right"
                        trigger={
                            <Button
                                disabled={disabled}
                                className="control-button share icon float-right"
                                onClick={this.handleShareClick}
                            >
                                <Icon name="share alternate" />
                            </Button>
                        }
                        content={<SubmissionMediaToolbarShare onShare={this.handleOnShare} customer={this.customer} />}
                    />
                )}
                {locked && (
                    <Popup
                        className="control-label locked icon float-right"
                        content="Permision not granted!"
                        position="bottom right"
                        inverted
                        size="tiny"
                        trigger={
                            <span className="control-label locked icon float-right">
                                <Icon name="lock" />
                            </span>
                        }
                    />
                )}
                {!locked && onDownloadClick && (
                    <a className="control-button float-right" onClick={onDownloadClick}>
                        <Icon name="cloud download" />{' '}
                        {mediaType.VIDEO === contentMediaType ? 'Download Video' : 'Download Photo'}
                    </a>
                )}
            </div>
        );
    }
}
