/**
 * form-for-component-helpers
 * Removed from github and unpublished from npm by author
 * https://github.com/pedsmoreira/form-for (Not Found)
 * https://registry.npmjs.org/form-for-component-helpers/
 */
import casex from 'casex';

function uniqueId(component) {
    var id = component.props && component.props.id;
    if (id) return id;

    if (component.__uniqueGeneratedId) return component.__uniqueGeneratedId;

    return (component['__uniqueGeneratedId'] = component.constructor.name + '-' + uniqueId.idCounter++);
}

uniqueId.idCounter = 1;

function help(Component, text) {
    if (typeof text === 'undefined' && Component.props) {
        text = Component.props.help;
    }

    if (!text) return {};

    var id = uniqueId(Component) + '-help';
    return { id: id, text: text };
}

function simplifyIdName(str) {
    return str.split('_id')[0];
}

function simplifyNestedName(str) {
    var lastIndexOfBracket = str.lastIndexOf('[');
    if (lastIndexOfBracket === -1) return str;

    return str.substring(lastIndexOfBracket + 1, str.length - 1);
}

function humanize(str) {
    return casex(simplifyIdName(simplifyNestedName(str)), 'Na me');
}

var stringHelpers = Object.freeze({
    simplifyIdName: simplifyIdName,
    simplifyNestedName: simplifyNestedName,
    humanize: humanize,
});

function humanized(Component) {
    return humanize(Component.props.name);
}

export { help, humanized, uniqueId, stringHelpers };
