// @flow

import * as React from 'react';
import DataPlaceholder from 'components/Data/Placeholder';
import Sortable from 'components/Sortable';
import VideoData from 'models/VideoData';
import videoDataStore from 'video/stores/videoData';
import Pagination from 'components/Pagination';

/* Disable Avg Watched Length Column */
// import videosWatchedLengthStore from 'video/stores/videosWatchedLength';
// import { dateTime } from 'helpers';
// import { Icon } from 'semantic-ui-react';

type Props = {
    videoDatas: VideoData[],
};

export default class VideoDataTablePartial extends React.Component<Props> {
    meta: { current_page: number, last_page: number, total: number };

    handleSort = (sortBy: string) => {
        videoDataStore.sort(sortBy);
    };

    handlePageChange = async (_: Event, data: { activePage: number }) => {
        videoDataStore.setPage(data.activePage);
        await videoDataStore.load(true);
        await videoDataStore.loadAvgWatchedLength();
    };

    render() {
        const { videoDatas } = this.props;
        const { sortProperty, sortDirection, meta } = videoDataStore;

        return (
            <React.Fragment>
                <DataPlaceholder for={videoDatas.length}>
                    <Sortable
                        className="ui basic table padded unstackable scrollable custom row-line"
                        data={videoDatas}
                        onSort={this.handleSort}
                        hideSpacer
                        sortBy={sortProperty || ''}
                        sortDirection={sortDirection || ''}
                        async
                    >
                        <Sortable.Column title="Video Title" property="title" sortable />
                        <Sortable.Column title="Created By" property="created_by" sortable />
                        <Sortable.Column title="Created At" property="created_at" sortable />
                        <Sortable.Column title="Unique Views" property="unique_views" sortable />
                        <Sortable.Column title="Shared (e-mail & text)" property="shared" sortable />
                        {/* Disable Avg Watched Length Column */}
                        {/* <Sortable.Column
                            title="Avg Watched Length"
                            sortable
                            textAlign="center"
                            render={video => {
                                if (videosWatchedLengthStore.lastError) {
                                    return (
                                        <Icon
                                            color="red"
                                            name="exclamation triangle"
                                            title={videosWatchedLengthStore.lastError.message}
                                        />
                                    );
                                }

                                if (undefined === video.avg_video_watched_length) return '';
                                return dateTime.fromSecondsToMinutesAndSeconds(video.avg_video_watched_length);
                            }}
                        /> */}
                    </Sortable>
                    <Pagination
                        className="custom top-spacer"
                        activePage={meta.current_page}
                        totalPages={meta.last_page}
                        total={meta.total}
                        onPageChange={this.handlePageChange}
                        loading={videoDataStore.loading}
                        compact
                    />
                </DataPlaceholder>
            </React.Fragment>
        );
    }
}
