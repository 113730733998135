// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import SidebarButton, { type SidebarButtonRender } from 'components/Sidebar/Button';

import placeStore from 'stores/place';

type Props = {};

@observer
export default class HeaderLocationMenu extends React.Component<Props> {
    render() {
        return <SidebarButton sidebar="general.level" render={this.renderContent} />;
    }

    renderContent = ({ onClick }: SidebarButtonRender) => {
        const place = placeStore.place;

        return (
            <div id="locations-menu-toggle" className="clearfix" onClick={place && onClick}>
                <span id="current">{place ? place.name : 'No locations available'}</span>
                {place && (
                    <span id="change-toggle">
                        Change <Icon name="angle down" />
                    </span>
                )}
            </div>
        );
    };
}
