// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Button, Icon, Label, Menu, Segment } from 'semantic-ui-react';
import MediaQuery from 'react-responsive';
import moment from 'moment';

import SiteInfo from 'models/SiteInfo';

import Profile from 'models/Profile';
import placeStore from 'stores/place';
import inviteAnalyticsStore from 'reputation/stores/inviteAnalytics';
import reputationLeaderboardStore from 'reputation/stores/reputationLeaderboard';

import userInfoStore from 'stores/userInfo';
import Page from 'components/Page';
import PlaceMonitor from 'components/Place/Monitor';
import ReportHeader from 'components/Report/Header';
import ReportBody from 'components/Report/Body';
import ReportStats from 'components/Report/Stats';
import ReportStatsData from 'components/Report/Stats/Data';

import DataExporter from 'components/Data/Exporter';
import SendManualInvite from 'reputation/components/SendManualInvite';

import UploadInviteFilePartial from 'reputation/components/UploadInviteFile';
import ReviewInvitesAnalytics from './partials/ReviewInvitesAnalyticsPartial';
import ReviewsRecievedAnalytics from './partials/ReviewsRecievedAnalyticsPartial';
import CustomerFeedback from './partials/CustomerFeedbackPartial';
import Leaderboard from './partials/LeaderboardPartial';
import TabsMenu from './partials/TabsMenuMobilePartial';
import InlineLoader from 'components/InlineLoader';
import Filters from 'models/Filters';
import FiltersBar from 'components/Filters/Bar';
import FiltersPopup from 'components/Filters/Popup';
import ActionButton from 'components/ActionButton';
import MediaQueryDesktop from 'components/MediaQuery/Desktop';

type Props = {};

const CSV_COLUMNS = {
    user_name: 'User',
    invites_sent: 'Total Invites Sent',
    would_recommend: 'Yes, I would recommend',
    would_not_recommend: 'No, I would not recommend',
    no_response: 'No response',
};

@observer
export default class ReputationReportPage extends React.Component<Props> {
    @observable profiles: Profile[];
    @observable activeItem = 'Review Invites';
    @observable filters: Filters;

    // Preload site info by channel

    componentWillMout() {
        SiteInfo.allByChannel();
    }

    @action
    setFilters(filters: Filters) {
        this.filters = filters;
    }

    @action
    setProfiles(profiles: ?(Profile[])) {
        // $FlowFixMe
        this.profiles = profiles;
    }

    async loadProfiles() {
        this.setProfiles(await placeStore.place.profiles());
    }

    load = async () => {
        this.setFilters(this.buildFilters());

        await Promise.all([
            this.loadProfiles(),
            inviteAnalyticsStore.filter(this.filters),
            reputationLeaderboardStore.filter(this.filters),
        ]);
    };

    buildFilters(): Filters {
        const filters = new Filters();
        const defaultStartDate = moment()
            .subtract(1, 'year')
            .format('YYYY-MM-DD');
        const defaultEndDate = moment().format('YYYY-MM-DD');

        filters.setDefaultDateRange(defaultStartDate, defaultEndDate);
        filters.startDate = defaultStartDate;
        filters.endDate = defaultEndDate;

        filters
            .addFilterGroup('invite_source', 'Invite Source', 'All Invite Sources')
            .addFilter('automated', 'Automated', true)
            .addFilter('manual', 'Real Time', true);

        filters
            .addFilterGroup('delivery_method', 'Delivery Method', 'All Delivery Methods')
            .addFilter('email', 'E-Mail', true)
            .addFilter('text', 'Text', true);

        filters
            .addFilterGroup('department', 'Department', 'All Departments')
            .addFilter('sales', 'Sales', true)
            .addFilter('service', 'Service', true);

        return filters;
    }

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    @action.bound
    handleItemClick(event: SyntheticEvent<HTMLFormElement>, { name }: Object) {
        this.activeItem = name;
    }

    handleResetFilters = () => {
        this.setFilters(this.buildFilters());
    };

    handleFiltering = () => {
        inviteAnalyticsStore.filter(this.filters);
        reputationLeaderboardStore.filter(this.filters);
    };

    get selectedDates() {
        return this.filters.dateRange;
    }

    get availableFilterGroups() {
        switch (this.activeItem) {
            case 'Review Invites':
                return ['invite_source', 'delivery_method', 'department'];
            case 'Reviews Received':
                return [];
            case 'Customer Feedback':
                return ['invite_source', 'delivery_method', 'department'];
            case 'Leaderboards':
                return ['delivery_method'];
            default:
                return [];
        }
    }

    renderActions = () => {
        return (
            <React.Fragment>
                {!!this.profiles.length && (
                    <React.Fragment>
                        <SendManualInvite trigger={<ActionButton icon="send" text="Send Real Time Invite" />} />
                        {userInfoStore.isManager && (
                            <UploadInviteFilePartial
                                trigger={<ActionButton icon="cloud upload" text="Upload invite file" />}
                            />
                        )}
                    </React.Fragment>
                )}

                {this.activeItem === 'Leaderboards' && (
                    <React.Fragment>
                        <DataExporter
                            className="ui tiny basic circular inverted button custom outline option"
                            columns={CSV_COLUMNS}
                            data={reputationLeaderboardStore.reputationLeaderboard || []}
                            filename={`${placeStore.place.name}_ra_leaderboards.csv`}
                        >
                            <Icon name="file excel outline" /> Export CSV
                        </DataExporter>
                    </React.Fragment>
                )}

                <React.Fragment>
                    <FiltersPopup
                        onChange={this.handleFiltering}
                        onReset={this.handleResetFilters}
                        filters={this.filters}
                        availableFilterGroups={this.availableFilterGroups}
                        trigger={<ActionButton icon="filter" text="Filters" />}
                    />
                </React.Fragment>
            </React.Fragment>
        );
    };

    get mobileActions(): React.Node {
        return (
            <React.Fragment>
                <SendManualInvite
                    trigger={
                        <Button className="option-btn">
                            <Icon name="send" /> Send Real Time Invite
                        </Button>
                    }
                />
                {userInfoStore.isManager && (
                    <UploadInviteFilePartial
                        trigger={
                            <Button className="option-btn">
                                <Icon name="cloud upload" /> Upload invite file
                            </Button>
                        }
                    />
                )}
                {this.activeItem === 'Leaderboards' && (
                    <React.Fragment>
                        <DataExporter
                            className="option-btn"
                            columns={CSV_COLUMNS}
                            data={reputationLeaderboardStore.reputationLeaderboard || []}
                            filename={`${placeStore.place.name}_ra_leaderboards.csv`}
                        >
                            <Icon name="file excel outline" /> Export CSV
                        </DataExporter>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    get mobileFiltersBar(): React.Node {
        return (
            <FiltersBar
                filters={this.filters}
                onClick={this.handleFiltering}
                availableFilterGroups={this.availableFilterGroups}
                withPopup={
                    <FiltersPopup
                        onChange={this.handleFiltering}
                        onReset={this.handleResetFilters}
                        filters={this.filters}
                        availableFilterGroups={this.availableFilterGroups}
                        trigger={
                            <Label className="tags-field label-btn no-icon clickable">
                                <Icon name="filter" /> Filters
                            </Label>
                        }
                    />
                }
            />
        );
    }

    renderContent = () => {
        const { inviteAnalytics, loading } = inviteAnalyticsStore;
        const search = this.activeItem === 'Leaderboards' ? reputationLeaderboardStore.search : undefined;
        return (
            <Page
                onSearch={search}
                actions={this.renderActions()}
                className="reports tabs"
                mobileFilters={this.mobileFiltersBar}
                mobileActions={this.mobileActions}
            >
                {loading && (
                    <div className="no-data">
                        <InlineLoader />
                    </div>
                )}

                {inviteAnalytics && Object.keys(inviteAnalytics).length && (
                    <React.Fragment>
                        <ReportHeader
                            title={placeStore.place.name}
                            dates={this.selectedDates}
                            hideSideSection
                            noBorder
                        />
                        <MediaQuery minWidth={769}>
                            <div className="section info clearfix">
                                <Menu attached="top" tabular className="simple custom clearfix">
                                    <Menu.Item
                                        name="Review Invites"
                                        active={this.activeItem === 'Review Invites'}
                                        onClick={this.handleItemClick}
                                    />
                                    <Menu.Item
                                        name="Reviews Received"
                                        active={this.activeItem === 'Reviews Received'}
                                        onClick={this.handleItemClick}
                                    />
                                    <Menu.Item
                                        name="Customer Feedback"
                                        active={this.activeItem === 'Customer Feedback'}
                                        onClick={this.handleItemClick}
                                    />
                                    <Menu.Item
                                        name="Leaderboards"
                                        active={this.activeItem === 'Leaderboards'}
                                        onClick={this.handleItemClick}
                                    />
                                </Menu>
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={768}>
                            <TabsMenu handleItemClick={this.handleItemClick} />
                        </MediaQuery>
                        <MediaQueryDesktop>
                            <FiltersBar
                                filters={this.filters}
                                onClick={this.handleFiltering}
                                availableFilterGroups={this.availableFilterGroups}
                            />
                        </MediaQueryDesktop>
                        <Segment attached="bottom" className="tab custom simple-tabs active">
                            {this.activeItem !== 'Leaderboards' && (
                                <ReportStats>
                                    <ReportStatsData
                                        title="Total Invites"
                                        value={inviteAnalytics.total_invites}
                                        tooltip={`Invites sent out by ${placeStore.place.name}`}
                                        scrollToId="numberOfInvites"
                                    />
                                    <ReportStatsData
                                        title="Total Reviews"
                                        value={inviteAnalytics.total_reviews}
                                        tooltip={`Reviews for ${placeStore.place.name}`}
                                        scrollToId="reviewChannels"
                                    />
                                    <ReportStatsData
                                        title="Total Feedback"
                                        value={inviteAnalytics.total_feedback}
                                        tooltip={`Feedback from Customers who would not recommend ${placeStore.place.name}`}
                                    />
                                </ReportStats>
                            )}
                            <ReportBody>
                                {this.activeItem === 'Review Invites' && (
                                    <ReviewInvitesAnalytics inviteAnalytics={inviteAnalytics} />
                                )}
                                {this.activeItem === 'Reviews Received' && (
                                    <ReviewsRecievedAnalytics inviteAnalytics={inviteAnalytics} />
                                )}
                                {this.activeItem === 'Customer Feedback' && (
                                    <CustomerFeedback inviteAnalytics={inviteAnalytics} />
                                )}
                                {this.activeItem === 'Leaderboards' && (
                                    <Leaderboard data={reputationLeaderboardStore.reputationLeaderboard || []} />
                                )}
                            </ReportBody>
                        </Segment>
                    </React.Fragment>
                )}
            </Page>
        );
    };
}
