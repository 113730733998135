import React from 'react';
import PropTypes from 'prop-types';

/**
 * A toggle option intended to be used a child of the Toggle component.
 */
function ToggleOption({ value, label, isActive, onClick, name, ...otherProps }) {
    return (
        <div className="kui-toggle-option-wrapper">
            <input
                type="radio"
                id={value}
                value={value}
                onChange={onClick}
                checked={isActive}
                name={name}
                {...otherProps}
            />
            <label htmlFor={value} className={`kui-toggle-option ${isActive ? 'kui-toggle-option-active' : ''}`}>
                {label}
            </label>
        </div>
    );
}

ToggleOption.propTypes = {
    /**
     * A value to be returned when clicked
     */
    value: PropTypes.string,
    /**
     * A label to be displayed
     */
    label: PropTypes.string,
    /**
     * Dont manually set. This will be passed down from the Toggle Component.
     * A boolean value letting the component know if it is active.
     */
    isActive: PropTypes.bool,
    /**
     * Dont manually set. This will be passed down from the Toggle Component.
     * If used as a child of Toggle, this will be a curried function with values predefined.
     */
    onClick: PropTypes.func,
    /**
     * Dont manually set. This will be passed down from the Toggle Component.
     * A string specifying what group the radio button is a part of.
     */
    name: PropTypes.string,
};

export default ToggleOption;
