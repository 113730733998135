import * as Yup from 'yup';
import moment from 'moment-timezone';

export const SUPPORTED_FORMATS = [
    'video/mp4',
    'video/avi',
    'video/mpeg',
    'video/quicktime',
    'application/octet-stream',
    'video/3gpp',
    'video/x-m4v',
];

export const editSchema = Yup.object().shape({
    caption: Yup.string().required('Caption is required'),
    publishOn: Yup.date()
        .required('Publish date is required')
        .test('past', 'Publish date must be in the future', value => {
            const now = moment(new Date()).add(1, 'm').toDate();
            return value > now;
        }),

    createdBy: Yup.string().notRequired(),
    instructions: Yup.string().notRequired(),
    media: Yup.array(Yup.object())
        .required('Photos or videos are required')
        .min(1, 'At least one photo or video is required')
        .test('oneVideo', 'Only one video is allowed', value => {
            const videoCount = value.filter(({ type }) => type === 'video').length;
            return videoCount <= 1;
        }),
});

export const createSchema = editSchema.concat(
    Yup.object().shape({
        networks: Yup.object()
            .test(
                'count',
                'At least one network is required',
                value => Object.entries(value) && Object.entries(value).filter(([, value]) => value).length > 0
            )
            .required('Networks are required'),
    })
);
