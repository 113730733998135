// @flow

import placeStore from 'stores/place';
import tokenStore from 'stores/token';

const encodeOptions = (options: Object) => {
    let encodedProperties = '';
    Object.keys(options).forEach(key => (encodedProperties = `${encodedProperties}${key}=${options[key]}&`));
    return encodedProperties;
};

class OauthApi {
    auth(network: string, options: Object): void {
        // $FlowFixMe
        const token: string = tokenStore.jwtToken;
        const params = options ? encodeOptions(options) : `place_id=${placeStore.place.id}&`;
        const networkStr = network === 'googleplace' ? 'google' : network;
        // $FlowFixMeAccordion.js
        window.location.href = `${process.env.REACT_APP_API_URI}/oauth/${networkStr}?${params}token=${token}`;
    }
}

export default new OauthApi();
