/* eslint-disable no-console */
// @flow

import * as React from 'react';
import MediaQuery from 'react-responsive';

import InviteAnalytics from 'models/InviteAnalytics';

import ReportSection from 'components/Report/Section';
import SocialCard from 'components/Social/Card';
import Pagination from 'components/Pagination';

type Props = {
    inviteAnalytics: InviteAnalytics,
};

type State = {
    activePage: number,
};

const PER_PAGE: number = 50;

export default class CustomerFeedback extends React.Component<Props, State> {
    state = {
        activePage: 1,
    };

    paginate = (array: Array<Object>, page_size: number, page_number: number): Array<Object> => {
        --page_number;
        return array.slice(page_number * page_size, (page_number + 1) * page_size);
    };

    handlePageChange = (event: SyntheticEvent<HTMLFormElement>, { activePage }: Object) => {
        this.setState({ activePage });
    };

    get totalPages(): number {
        return Math.ceil(this.props.inviteAnalytics.feedback.length / PER_PAGE);
    }

    get sortedFeedback(): Array<Object> {
        const { inviteAnalytics } = this.props;

        return inviteAnalytics.feedback.sort((a: Object, b: Object) => (a.invite_date < b.invite_date ? 1 : -1));
    }

    renderSocialCardList = (item: Object, index: number) => {
        const { invite_date, feedback_date } = item || {};
        const value: { name: string, text: string, phone: string, email: string } = item || {};

        return (
            <div key={index} className="column-item">
                <SocialCard
                    inviteDate={this.formatDate(invite_date)}
                    feedbackDate={this.formatDate(feedback_date)}
                    title={value.name}
                    text={value.text}
                    phone={value.phone}
                    email={value.email}
                />
            </div>
        );
    };

    formatDate(date: string): string {
        const [year, month, day] = date.split('-');

        return `${month}-${day}-${year}`;
    }

    render() {
        const { inviteAnalytics } = this.props;
        const { activePage } = this.state;
        const paginatedArray = this.paginate(this.sortedFeedback, PER_PAGE, activePage);
        const feedbackLeft = paginatedArray.filter((e, i) => !(i % 2));
        const feedbackRight = paginatedArray.filter((e, i) => i % 2);

        return (
            <ReportSection id="customerFeedback" title="Customer Feedback" columns={2}>
                {inviteAnalytics.feedback.length ? (
                    <>
                        <div className="column-container">
                            <MediaQuery maxWidth={850}>{paginatedArray.map(this.renderSocialCardList)}</MediaQuery>
                        </div>
                        <MediaQuery minWidth={851}>
                            <div className="flex-column-container ">
                                <div className="column">{feedbackLeft.map(this.renderSocialCardList)}</div>
                                <div className="column">{feedbackRight.map(this.renderSocialCardList)}</div>
                            </div>
                        </MediaQuery>
                        <div style={{ margin: '0 0 30px 30px' }}>
                            <Pagination
                                activePage={activePage}
                                onPageChange={this.handlePageChange}
                                total={this.totalPages}
                                totalPages={this.totalPages}
                                loading={false}
                            />
                        </div>
                    </>
                ) : (
                    <div className="no-data">No Data Available</div>
                )}
            </ReportSection>
        );
    }
}
