// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';

import videoStore from 'video/stores/video';

type Props = {
    className?: string,
};

@observer
export default class VideoSort extends React.Component<Props> {
    render() {
        const { className } = this.props;

        const classes = ['button icon', className];
        if (className) classes.push(className);

        return (
            <Dropdown pointing="top right" icon="sort" className={classes.join(' ')}>
                <Dropdown.Menu>
                    {this.renderSort('title', 'By Title')}
                    {this.renderSort('username', 'By Username')}
                    {this.renderSort('date', 'By Date & Time')}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    renderSort(property: string, title: string) {
        return (
            <React.Fragment>
                <Dropdown.Header content={title} />
                <Dropdown.Divider />

                <Dropdown.Item onClick={() => videoStore.sort(property, 'asc')}>
                    <Icon name="sort alphabet ascending" /> Ascending
                </Dropdown.Item>

                <Dropdown.Item onClick={() => videoStore.sort(property, 'desc')}>
                    <Icon name="sort alphabet descending" /> Descending
                </Dropdown.Item>
            </React.Fragment>
        );
    }
}
