// @flow

import Model from 'models/Model';

export default class ReputationSnapshot extends Model {
    path = 'reputation-snapshot';

    charts: {
        channel: string,
        reviews: number,
        avg_rating: number,
    }[];

    total: {
        total_reviews: number,
        avg_rating: string,
    };

    last_month: { new_reviews: number }[];
}
