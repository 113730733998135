// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';

import { route, hasRole } from 'helpers';
import * as apps from 'apps';
import type { App } from 'types';

import tokenStore from 'stores/token';
import placeStore from 'stores/place';
import organizationStore from 'stores/organization';
import userInfoStore from 'stores/userInfo';

import AppRoute from 'components/App/Route';
import AppArrayAvailable from 'components/App/ArrayAvailable';
import Loader from 'components/Loader';

import LoginPage from 'pages/Login';
import ForgotPassowrdPage from 'pages/ForgotPassword';
import NewPasswordPage from 'pages/NewPassword';
import PropTypes from 'prop-types';
import { isRouteDisabledAfterUpgradeForPlace } from './helpers/kenectUpgrade';

@withRouter
@observer
export default class Main extends React.Component {
    constructor(props: Props) {
        super(props);
    }
    getSearchParams(): { [name: string]: string } {
        return queryString.parse(window.location.search);
    }

    render() {
        if (!tokenStore.jwtToken) return this.renderGuestRoutes();

        if (userInfoStore.isAdmin) {
            if (!organizationStore.restored || (organizationStore.organization && !placeStore.places))
                return <Loader />;
        } else if (!placeStore.places) return <Loader />;

        return this.renderUserRoutes();
    }

    renderGuestRoutes() {
        return (
            <Switch>
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/forgot-password" component={ForgotPassowrdPage} />
                <Route exact path="/new-password" component={NewPasswordPage} />

                <Route
                    render={({ location }) => {
                        return <Redirect to={`/login?referrer=${location.pathname}`} />;
                    }}
                />
            </Switch>
        );
    }

    renderUserRoutes = () => {
        const { referrer } = this.getSearchParams();
        if (referrer) return <Redirect to={referrer} />;

        return (
            <AppArrayAvailable
                render={availableApps => (
                    <Switch>
                        {availableApps.map(this.renderAppRoutes)}
                        {this.renderAppRoutes(apps.systemAdmin)}
                        {this.renderAppRoutes(apps.organization)}
                        {this.renderAppRoutes(apps.general)}
                    </Switch>
                )}
            />
        );
    };

    renderAppRoutes = (app: App) => {
        if (!hasRole(app.role)) return null;
        if (!app.defaultRoute) {
            console.warn(`App ${app.feature} does not have a default route`);
            return null;
        }

        const routes = app.routes;
        const mappedRoutes = Object.keys(routes).map(key => {
            const routeItem = routes[key];
            if (!routeItem.component) throw new Error(`Route ${app.feature}.${key} does not have a component`);
            if (!hasRole(routeItem.role)) return null;

            const requiresOrganization = !routeItem.notOrganizationDependent;
            if (requiresOrganization && !organizationStore.organization) return null;

            const requiresLocation = !routeItem.notLocationDependent;
            if (requiresLocation && !placeStore.place) return null;

            const path = route(app, key);
            return <AppRoute key={key} path={path} component={routeItem.component} app={app} route={key} />;
        });

        return (
            <Route key={app.feature} path={`/${app.path}`}>
                <Switch>
                    {isRouteDisabledAfterUpgradeForPlace(placeStore, this.props.location.pathname) && (
                        <Redirect to="/app-selection" component={apps.general.routes.appSelection.component} />
                    )}
                    {mappedRoutes}
                    {this.renderAppDefaultRoute(app)}
                </Switch>
            </Route>
        );
    };

    renderAppDefaultRoute(app: App) {
        let defaultRoute = route(app, app.defaultRoute);
        if (userInfoStore.isAdmin) {
            if (app === apps.general) {
                defaultRoute = `/${apps.systemAdmin.path}`;
            }
        } else if (!organizationStore.organization && !placeStore.place) {
            defaultRoute = route('general', 'profile');
        }
        if (window.location.pathname === '/content-fuel/submissions') {
            defaultRoute = `/${apps.photo.path}`;
        }
        if (window.location.pathname === '/video-ignition/videos') {
            defaultRoute = `/${apps.video.path}`;
        }
        if (window.location.pathname === '/reputation-accelerator/response-console') {
            defaultRoute = `/${apps.reputation.path}`;
        }
        return <Redirect key="redirect" to={defaultRoute} />;
    }
}

Main.propTypes = {
    location: PropTypes.objectOf({
        pathname: PropTypes.string,
    }),
};
