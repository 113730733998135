// @flow

import { observable } from 'mobx';
import Model from './Model';
import { field } from 'app/external/mobx-form-for';

export default class DraftContentMediaConsent extends Model {
    @observable
    @field({ type: 'switch', label: 'Customer Media' })
    isCustomerMedia: boolean;

    @observable
    @field({ type: 'switch', label: 'Customer Provided Consent' })
    isCustomerConsentProvided: boolean;

    get canUpload(): boolean {
        return !this.isCustomerMedia || (this.isCustomerMedia && this.isCustomerConsentProvided);
    }

    get isCustomer(): boolean {
        return this.isCustomerMedia && this.isCustomerConsentProvided;
    }
}
