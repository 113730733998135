// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon, Button } from 'semantic-ui-react';

type Props = {
    type?: string,
    icon?: string,
    text: string,
    saving?: boolean,
    disabled?: boolean,
};

@observer
export default class FormButton extends React.Component<Props> {
    render() {
        const { saving, disabled, icon, text, ...props } = this.props;

        return (
            <Button type="button" size="tiny" {...props} disabled={disabled || saving} loading={saving}>
                {icon && <Icon name={icon} />} {text}
            </Button>
        );
    }
}
