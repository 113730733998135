// @flow

import { observable, action } from 'mobx';
import { Field } from 'app/external/mobx-form-for';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Message } from 'semantic-ui-react';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import Loader from 'components/Loader';

import NotificationSettings from 'models/NotificationSettings';

import notificationSettingsStore from 'stores/notificationSettings';
import userInfoStore from 'stores/userInfo';

import { toast } from 'helpers';

// import NotifcationSettingsForm from './partials/NotificationSettingsForm';

type Props = {};

@observer
export default class NotificationSettingsPage extends React.Component<Props> {
    @observable notificationSettings: NotificationSettings;

    @action
    setNotificationSettings() {
        this.notificationSettings = notificationSettingsStore.notificationSettings;
    }

    load = async () => {
        await notificationSettingsStore.load();
        this.setNotificationSettings();
    };

    render() {
        return <Loader onLoad={this.load} render={this.renderContent} />;
    }

    handleSubmit = async () => {
        await this.notificationSettings.saveChanges();
        toast.success('Notifications settings updated');
        this.load();
    };

    renderContent = () => {
        const { isAllowingNotifications, place_notifications } = this.notificationSettings;

        if (userInfoStore.isAdmin) {
            return (
                <Page>
                    <div className="no-data">Feature currently unavailable for system admins.</div>
                </Page>
            );
        } else {
            return (
                <Page for={this.notificationSettings} onSubmit={this.handleSubmit} className="three-column clearfix">
                    <Column key="columns-1">
                        <SettingsPanel title="Notifications">
                            <Field name="notifications_active" value={isAllowingNotifications} />
                            {isAllowingNotifications && (
                                <Message className="custom" info color="green">
                                    <p>
                                        Notifications will be sent by email to:{' '}
                                        <strong>{userInfoStore.user.email}</strong>
                                    </p>
                                    <p>
                                        Consider adding no-reply@catalystapp.net to your contacts or safe senders list
                                        to ensure notifications get delivered to your inbox.
                                    </p>
                                </Message>
                            )}
                        </SettingsPanel>

                        {isAllowingNotifications && (
                            <React.Fragment>
                                {place_notifications.length && (
                                    <SettingsPanel title="Location Notifications" noPadding>
                                        <Field name="place_notifications" className="no-padding" />
                                    </SettingsPanel>
                                )}
                            </React.Fragment>
                        )}
                    </Column>
                </Page>
            );
        }
    };
}
