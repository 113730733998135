// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

type Props = {
    title: string,
    children: React.Node,
    noPadding?: boolean,
    tooltip?: string,
    className?: string,
};

@observer
export default class SettingsPanel extends React.Component<Props> {
    get classes(): string[] {
        const { noPadding, className } = this.props;
        const classes = ['ui', 'tiny', 'form', 'panel', 'settings-panel', 'shadow', 'clearfix'];

        if (noPadding) classes.push('no-padding');

        if (className) classes.push(className);

        return classes;
    }

    render() {
        const { noPadding, title, children, tooltip } = this.props;
        return (
            <div className={this.classes.join(' ')}>
                <h2 className={`panel-title ${noPadding ? 'no-margin' : ''}`}>
                    {title}{' '}
                    {tooltip && (
                        <Popup
                            trigger={<Icon name="info circle" />}
                            content={tooltip}
                            inverted
                            position="right center"
                        />
                    )}
                </h2>

                {children}
            </div>
        );
    }
}
