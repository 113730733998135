// @flow

import * as React from 'react';
import { observer } from 'mobx-react';
import type { ComponentProps } from 'app/external/form-for';
import { Form, Radio } from 'semantic-ui-react';

type Option = {
    value: string,
    text: string,
};

type Props = ComponentProps & {
    options: Option[],
    labels?: Object,
    disabled?: boolean,
};

@observer
export default class RadioGroupField extends React.Component<Props> {
    input: HTMLElement;

    componentDidMount() {
        this.props.onMount(this.input);
    }

    handleChange = (event: SyntheticEvent<HTMLInputElement>, meta: { value: string }) => {
        this.props.onChange(event, meta.value);
    };

    renderRadio = (option: Option) => {
        const { name, value, labels } = this.props;

        return (
            <Radio
                label={labels && labels[option.value] !== undefined ? labels[option.value] : option.text}
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={this.handleChange}
            />
        );
    };

    render() {
        const { options, disabled } = this.props;

        return (
            <React.Fragment>
                {options.map((option: Option, i) => (
                    <Form.Field key={i} disabled={disabled}>
                        {this.renderRadio(option)}
                    </Form.Field>
                ))}
            </React.Fragment>
        );
    }
}
