// @flow

import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Bar } from 'react-chartjs-2';

import SiteInfo, { type SitesInfoByChannel } from 'models/SiteInfo';

type Props = {
    data: Object,
    title: string,
    rating?: boolean,
};

@observer
export default class ScoreChart extends React.Component<Props> {
    @observable sitesInfoByChannel: SitesInfoByChannel;

    constructor(props: Props) {
        super(props);
        this.loadSitesInfo();
    }

    @action
    setSitesInfoByChannel(sitesInfoByChannel: SitesInfoByChannel) {
        this.sitesInfoByChannel = sitesInfoByChannel;
    }

    async loadSitesInfo() {
        this.setSitesInfoByChannel(await SiteInfo.allByChannel());
    }

    get data() {
        if (!this.sitesInfoByChannel) return {};
        const { data, rating } = this.props;
        let channelsData = data.charts || [];

        if (!channelsData.length) return {};

        channelsData = channelsData.filter(channel => !!channel.channel && !!channel.reviews);
        const datasets = [];
        const labels = [];
        channelsData.forEach((channel, index) => {
            const siteInfo = this.sitesInfoByChannel[channel.channel];
            if (!siteInfo) {
                console.warn(`Incoming channel ${channel} not listed on sites info`);
                return;
            }
            const dataArray = [...Array(channelsData.length)];
            dataArray[index] = rating ? channel.avg_rating : channel.reviews;
            datasets.push({
                data: dataArray,
                label: siteInfo.display_name,
                backgroundColor: siteInfo.backgroundColor,
                hoverBackgroundColor: siteInfo.hoverBackgroundColor,
            });
            labels.push(siteInfo.display_name);
        });
        return {
            labels,
            datasets,
        };
    }

    get options() {
        return {
            maintainAspectRatio: false,
            responsive: true,
            aspectRatio: 1,
            legend: {
                position: 'bottom',
                labels: {
                    fontColor: '#999',
                    boxWidth: 10,
                    useLineStyle: true,
                    fontSize: 11,
                    padding: 10,
                },
            },
            animation: {
                animateScale: true,
            },
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        display: false,
                    },
                ],
                yAxes: [this.yAxisOptions],
            },
            plugins: {
                datalabels: {
                    display: false,
                },
            },
        };
    }

    get yAxisOptions() {
        const { rating } = this.props;
        if (rating) {
            return {
                stacked: false,
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: 5,
                    padding: 0,
                },
            };
        } else {
            return {
                stacked: false,
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: 10,
                    callback: function(value: any) {
                        return Number(value).toFixed(0);
                    },
                },
            };
        }
    }

    render() {
        const { title } = this.props;
        const chartData = this.data;

        return (
            <div className="item-wrapper">
                <div className="dashboard-item card vertical-center-container">
                    <h3 className="titles dashboard-titles">{title.toUpperCase()}</h3>
                    <div className="chart">
                        <Bar data={chartData} options={this.options} />
                    </div>
                </div>
            </div>
        );
    }
}
