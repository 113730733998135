// @flow

import * as React from 'react';

type Props = {
    for: any,
    children: React.Node,
};

export default class DataPlaceholder extends React.Component<Props> {
    render() {
        const { children } = this.props;
        const data = this.props.for;

        const hasData = typeof data === 'object' ? Object.keys(data).length : data;
        return hasData ? children : <div className="no-data">No Data Available</div>;
    }
}
