// @flow

import React from 'react';
import { Line } from 'react-chartjs-2';

import ViewMetric from 'models/ViewMetric';
import DataPlaceholder from 'components/Data/Placeholder';
import { yearMonth } from 'helpers';

type Props = {
    viewMetrics: ?ViewMetric,
};

const options = {
    elements: {
        line: {
            tension: 0,
        },
    },
    maintainAspectRatio: false,
    legend: {
        position: 'bottom',
        labels: {
            fontColor: '#999',
            boxWidth: 10,
            useLineStyle: true,
            fontSize: 11,
            padding: 10,
        },
    },
    animation: {
        animateScale: true,
    },
    plugins: {
        datalabels: {
            display: false,
        },
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    suggestedMin: 1,
                    suggestedMax: 6,
                    callback: function(value) {
                        return Number(value).toFixed(0);
                    },
                },
            },
        ],
    },
};

export default class VideoMetricsChartPartial extends React.Component<Props> {
    buildDataset(property: string, label: string, rgb: Array<number>): Object {
        const { viewMetrics } = this.props;

        if (!viewMetrics || !viewMetrics.charts || !viewMetrics.charts.unique_views) {
            return;
        }

        const data = Object.keys(viewMetrics.charts.unique_views).map(month => {
            return viewMetrics.charts.unique_views[month][property];
        });

        return {
            label,
            borderColor: `rgba(${rgb.join(',')},0.5)`,
            backgroundColor: `rgba(${rgb.join(',')},0.5)`,
            pointHoverBackgroundColor: `rgba(${rgb.join(',')},1)`,
            pointHoverBorderColor: `rgba(${rgb.join(',')},1)`,
            pointRadius: 0,
            pointHitRadius: 5,
            data,
            fill: false,
        };
    }

    get labels() {
        const { viewMetrics } = this.props;

        if (!viewMetrics || !viewMetrics.charts || !viewMetrics.charts.unique_views) {
            return null;
        }

        return yearMonth.namesFromObject(viewMetrics.charts.unique_views);
    }

    get data() {
        return {
            labels: this.labels,
            datasets: [
                this.buildDataset('all_videos', 'Total Unique Views', [187, 213, 103]),
                this.buildDataset('shared_via_link', 'Shared Via Link', [113, 207, 218]),
                this.buildDataset('shared_via_email', 'Shared Via E-Mail', [172, 106, 213]),
            ],
        };
    }

    render() {
        const { viewMetrics } = this.props;

        return (
            <DataPlaceholder for={viewMetrics && viewMetrics.charts && viewMetrics.charts.unique_views}>
                <div className="chart">
                    <Line data={this.data} options={options} />
                </div>
            </DataPlaceholder>
        );
    }
}
