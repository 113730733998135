// @flow

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import SocialPostModal from 'namespaces/photo/components/Submission/Modal/SocialPostModal';
import { api } from '@friendemic/premiere';
import placeStore from 'stores/place';
import moment from 'moment-timezone';
import { toast } from 'app/helpers';
import InlineLoader from 'components/InlineLoader';
import Event from '../CalenderEvent/index';
import PlaceMonitor from 'components/Place/Monitor';
import publishedContentStore from 'photo/stores/submission/publishedContentStore';
import PropTypes from 'prop-types';
import userInfoStore from 'app/stores/userInfo/userInfoStore';

const locales = {
    'en-US': enUS,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const ManagementCalendar = ({ refetch, setRefetch }) => {
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        fetchEvents();
    }, [placeStore.place]);

    useEffect(() => {
        if (refetch) {
            fetchEvents();
            setRefetch(false);
        }
    }, [refetch]);

    const load = async () => {
        await publishedContentStore.load();
    };

    const fetchEvents = async () => {
        try {
            setLoading(true);
            const response = await api.http.get(`/soci/posts/${placeStore.place.id}`);
            if (response.data) {
                if (response.data.status === 'success') {
                    const tempArray = [];
                    response.data.data.forEach(item => {
                        const endDate = moment.utc(item.publish_on);
                        const event = {
                            id: item.id,
                            content_story_id: item.content_story_id,
                            title: item.title | '',
                            network_name: item.network_name,
                            place_id: item.place_id,
                            soci_status: item.soci_status,
                            status: item.status,
                            thumbnail_path: item.thumbnail_path,
                            photos: JSON.parse(item.photos),
                            videos: JSON.parse(item.videos),
                            caption: item.caption,
                            instructions: item.instructions,
                            created_by: item.user ? item.user.name : null,
                            start: moment.utc(item.publish_on).local().toDate(),
                            end: moment(endDate).local().toDate(),
                        };
                        tempArray.push(event);
                    });
                    setEvents(tempArray);
                } else {
                    setEvents([]);
                    toast.warning('No scheduled posts found for this location.');
                }
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            toast.error('Something went wrong.');
        }
    };

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            const title = window.prompt('New Event name');
            if (title) {
                setEvents(prev => [...prev, { start, end, title }]);
            }
        },
        [setEvents]
    );

    const handleSelectEvent = event => {
        setSelectedEvent(event);
        setOpen(true);
    };

    const { defaultDate, scrollToTime } = useMemo(
        () => ({
            defaultDate: new Date(),
            scrollToTime: new Date(1970, 1, 1, 6),
        }),
        []
    );

    const renderContent = () => (
        <>
            {loading ? (
                <div id="no-data">
                    <InlineLoader />
                </div>
            ) : (
                <>
                    <Calendar
                        components={{
                            event: Event,
                        }}
                        defaultDate={defaultDate}
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        events={events}
                        onSelectEvent={handleSelectEvent}
                        onSelectSlot={handleSelectSlot}
                        selectable={false}
                        scrollToTime={scrollToTime}
                        showAllEvents={true}
                    />
                    {selectedEvent && open && (
                        <SocialPostModal
                            event={selectedEvent}
                            open={open}
                            setOpen={setOpen}
                            edit={
                                placeStore.place.features.can_edit_posts &&
                                (userInfoStore.isPlaceManager ||
                                    userInfoStore.isOrganizationManager ||
                                    userInfoStore.isAdmin)
                            }
                            refetch={fetchEvents}
                        />
                    )}
                </>
            )}
        </>
    );
    return <PlaceMonitor onChange={load} render={renderContent} />;
};

ManagementCalendar.propTypes = {
    refetch: PropTypes.bool,
    setRefetch: PropTypes.func,
};

export default ManagementCalendar;
