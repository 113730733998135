// @flow

import { observer } from 'mobx-react';
import React from 'react';

import Sidebar from 'components/Sidebar';
import ReviewCardList from 'reputation/components/Review/Card/List';

type Props = {};

@observer
export default class ReviewsSidebar extends React.Component<Props> {
    render() {
        return (
            <Sidebar title="Reviews" className="fit">
                <ReviewCardList ellipsisBreakpoint={22} inverted />
            </Sidebar>
        );
    }
}
