// @flow

import { type App } from 'types';

const app: App = {
    feature: 'chat',
    path: 'https://chat.catalystapp.net',
    title: 'Catalyst Chat',
    mobileTitle: 'CC',
    description: 'Chat with Website Visitors',
    className: 'chat',
    browserThemeColor: '#6790b6',
    icon: 'rc',
    routes: [],
    defaultRoute: 'search',
    // marketingMessageDisabled:
    //     'COMING SOON: Reach out to your Friendemic Client Success Representatives to join the pilot program!',
    disabledLink: 'http://www.friendemic.com/catalyst-chat/',
};

export default app;
