// @flow

import Model from 'models/Model';

type TableData = { text: number, email: number, total: number };

export default class InviteAnalytics extends Model {
    path = 'invite-analytics';

    charts: {
        wyru_invites_sent: Object,
        invites_sent: Object,
        reviews_received: Object,
        wyru_results_overall: Object,
        wyru_results_yes: Object,
        wyru_results_no: Object,
    };

    feedback: { [key: string]: string }[];

    table: {
        clicked_link_count: TableData,
        clicked_link_percent: TableData,
        invites_sent: TableData,
        left_feedback_count: TableData,
        left_feedback_percent: TableData,
        no_response_count: TableData,
        no_response_percent: TableData,
        would_no_further_count: TableData,
        would_no_further_percent: TableData,
        would_not_no_further_count: TableData,
        would_not_no_further_percent: TableData,
        would_not_recommend_count: TableData,
        would_not_recommend_percent: TableData,
        would_recommend_count: TableData,
        would_recommend_percent: TableData,
    };

    total_invites: number;
    total_reviews: number;
    total_feedback: number;
}
