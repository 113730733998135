// @flow
import React from 'react';

type Props = {
    srcBlob: any,
};

export const VideoPlayer = (props: Props) => {
    if (!props.srcBlob) {
        return null;
    }
    return <video id="videoView" src={URL.createObjectURL(props.srcBlob)} width={'100%'} height="100%" controls />;
};
