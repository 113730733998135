import * as React from 'react';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import EditPost from './EditPost';
import EditMedia from './EditMedia';
import ConsentModal from './ConsentModal';
import Delete from './Delete';
import useSociForm from './useSociForm';
import { createSchema, editSchema, SUPPORTED_FORMATS } from './schema';
import { toast } from 'app/helpers';
import _ from 'lodash';
import moment from 'moment-timezone';

export default function SocialPostFormModal({
    edit,
    event = {},
    photos,
    placeNetworks,
    trigger,
    refetchEvents,
    onClose,
}) {
    const [firstOpen, setFirstOpen] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [consentOpen, setConsentOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [mediaLibrary, setMediaLibrary] = React.useState([]);
    const [modalState, setModalState] = React.useState(edit ? 'EDIT_POST' : 'EDIT_MEDIA');
    const [consentGiven, setConsentGiven] = React.useState(false);
    const [mediaLibraryState, setMediaLibraryState] = React.useState([]);
    const [busy, setBusy] = React.useState(false);

    const input = React.useRef(null);
    const formRef = React.useRef(null);
    const selectedMedia = React.useMemo(() => mediaLibrary.filter(({ selected }) => selected), [mediaLibrary]);
    const selectedCount = selectedMedia.length;

    const {
        handleSubmit: handleSociPost,
        mediaLibrary: placeMediaLibrary,
        handleDelete,
        loadNext,
        hasMore,
        handleClose: handleSociClose,
        progress,
    } = useSociForm(event.id);

    React.useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue('media', selectedMedia);
        }
    }, [formRef, selectedMedia]);

    React.useEffect(() => {
        let selectedMedia = [];
        if (photos && mediaLibrary.length === 0) {
            selectedMedia = photos.map((photo, index) => ({ ...photo, selected: true, index }));
        }

        const media = placeMediaLibrary.map(({ src, type }, index) => ({
            src,
            type,
            index: index + selectedMedia.length,
        }));
        setMediaLibrary([...selectedMedia, ...mediaLibrary, ...media]);
    }, [photos, placeMediaLibrary]);

    const handleClose = close => {
        if (busy) return;
        setMediaLibrary([]);
        setMediaLibraryState([]);
        setDeleteOpen(false);
        setOpen(false);
        setModalState(edit ? 'EDIT_POST' : 'EDIT_MEDIA');
        setFirstOpen(true);
        handleSociClose();
        formRef.current && formRef.current.resetForm();
        close && onClose && onClose();
    };

    const handleStateChange = (state, cancelled = false) => {
        if (state === 'EDIT_MEDIA') {
            setMediaLibraryState(_.cloneDeep(mediaLibrary));
        }
        if (state === 'EDIT_POST') {
            if (cancelled) {
                setMediaLibrary(mediaLibraryState);
            }
            setMediaLibraryState([]);
        }
        firstOpen && setFirstOpen(false);
        setModalState(state);
    };

    const handleNewMedia = newMedia => {
        const files = [];
        for (const file of newMedia) {
            if (!file.type.startsWith('image') && !file.type.startsWith('video')) {
                toast.warning('Some files are not supported so they were removed from the selection');
                continue;
            }
            if (file.type.startsWith('video') && !SUPPORTED_FORMATS.includes(file.type)) {
                toast.warning(`Video type ${file.type} is not supported`);
                continue;
            }
            files.push({
                src: URL.createObjectURL(file),
                file,
                type: file.type.startsWith('image') ? 'photo' : file.type.startsWith('video') ? 'video' : 'other',
            });
        }
        setMediaLibrary([
            ...files.map(({ src, file, type }, index) => ({ src, selected: true, index, file, type })),
            ...mediaLibrary,
        ]);
    };

    const handleMediaDelete = index => {
        const newMediaLibrary = [...mediaLibrary];
        newMediaLibrary[selectedMedia[index].index].selected = false;
        setMediaLibrary(newMediaLibrary);
    };

    const handleMediaSelect = index => {
        if (!mediaLibrary[index].selected && selectedCount >= 10) return;
        const newMediaLibrary = [...mediaLibrary];
        newMediaLibrary[index].selected = !newMediaLibrary[index].selected;
        setMediaLibrary(newMediaLibrary);
    };

    const handleFileUploadClick = e => {
        if (!consentGiven) {
            e.preventDefault();
            setConsentOpen(true);
        }
    };

    const handleUpload = () => {
        setConsentGiven(true);
        setConsentOpen(false);
        input.current.click();
    };

    const handleSubmit = async (values, actions) => {
        setBusy(true);
        await handleSociPost(values, actions).then(success => {
            setBusy(false);
            if (success) {
                refetchEvents && refetchEvents(true);
                handleClose(true);
            }
            setConsentGiven(false);
        });
    };

    const handleDeletePost = () => {
        setDeleteOpen(true);
    };

    const states = {
        EDIT_POST: {
            title: 'Social Post Details',
            element: (
                <EditPost
                    loading={busy}
                    media={selectedMedia}
                    network_name={event.network_name}
                    placeNetworks={placeNetworks}
                    onDeletePost={handleDeletePost}
                    edit={edit}
                    onCancel={() => handleClose(false)}
                    handleEditMedia={() => handleStateChange('EDIT_MEDIA')}
                    createdBy={event.created_by}
                    publishOn={formRef.current ? formRef.current.values.publishOn : event.start}
                    progress={progress}
                    busy={busy}
                />
            ),
        },
        EDIT_MEDIA: {
            title: edit ? 'Edit Media' : 'Choose Media',
            element: (
                <EditMedia
                    media={selectedMedia}
                    mediaLibrary={mediaLibrary}
                    handleFileUploadClick={handleFileUploadClick}
                    handleMediaDelete={handleMediaDelete}
                    handleMediaSelect={handleMediaSelect}
                    handleNewMedia={handleNewMedia}
                    onCancel={() => (!edit && firstOpen ? handleClose(false) : handleStateChange('EDIT_POST', true))}
                    onNext={() => handleStateChange('EDIT_POST')}
                    edit={edit}
                    inputRef={input}
                    loadNext={loadNext}
                    hasMore={hasMore}
                />
            ),
        },
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                caption: event.caption || '',
                publishOn: event.start || moment(new Date()).add(30, 'm').toDate(),
                networks: {},
                instructions: event.instructions || '',
                media: photos ? photos.map(photo => ({ ...photo, selected: true })) : [],
            }}
            onSubmit={handleSubmit}
            validationSchema={edit ? editSchema : createSchema}
        >
            <Modal
                className="custom"
                trigger={trigger}
                title={states[modalState].title}
                onOpen={() => setOpen(true)}
                open={open}
                size={'small'}
            >
                {states[modalState].element}
                <ConsentModal open={consentOpen} onCancel={() => setConsentOpen(false)} onUpload={handleUpload} />
                <Delete
                    open={deleteOpen}
                    onNo={() => setDeleteOpen(false)}
                    onDelete={async () => {
                        setBusy(true);
                        await handleDelete().then(success => {
                            if (success) {
                                setBusy(false);
                                refetchEvents && refetchEvents(true);
                                setDeleteOpen(false);
                                handleClose(true);
                            }
                        });
                    }}
                    loading={busy}
                />
            </Modal>
        </Formik>
    );
}

SocialPostFormModal.propTypes = {
    edit: PropTypes.bool,
    event: PropTypes.object,
    photos: PropTypes.array,
    placeNetworks: PropTypes.object,
    trigger: PropTypes.node,
    refetchEvents: PropTypes.func,
    onClose: PropTypes.func,
};
