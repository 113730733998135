import React from 'react';
import * as tus from 'tus-js-client';
import { toast } from 'helpers';
import * as Sentry from '@sentry/react';

export default function useTusUpload() {
    const [upload, setUpload] = React.useState(null);
    const [uploadStarted, setUploadStarted] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [connected, setConnected] = React.useState(true);
    const [uploadYoutubePaused, setUploadYoutubePaused] = React.useState(false);

    const resetState = () => {
        setProgress(0);
        setUploadStarted(false);
        setUploadYoutubePaused(false);
    };

    React.useEffect(() => {
        setConnected(navigator.onLine);

        if (window.navigator.onLine && uploadYoutubePaused) {
            toast.success('Connection restored! Upload is resuming!');
            upload.start();
            setUploadYoutubePaused(false);
        }
    }, [navigator.onLine]);

    const resumeUpload = () => {
        if (upload) {
            toast.success('Upload is resuming!');
            upload.start();
            setUploadYoutubePaused(false);
        }
    };

    const pauseUpload = () => {
        if (upload) {
            toast.success('Upload is paused!');
            upload.abort();
            setUploadYoutubePaused(true);
        }
    };

    const cancelUpload = () => {
        if (upload) {
            toast.success('Upload cancelled!');
            upload.abort();
            localStorage.removeItem('tusUploadData');
            resetState();
        }
    };

    const uploadVideo = async (videoFile, tusVideoData, { onError, onProgress, onSuccess, resume } = {}) => {
        const tusUpload = () =>
            new Promise((resolve, reject) => {
                const upload = new tus.Upload(videoFile, {
                    endpoint: process.env.REACT_APP_TUS_CLIENT_URL,
                    chunkSize: 5 * 1024 * 1024,
                    retryDelays: [0, 3000, 5000, 10000, 20000],
                    metadata: tusVideoData,
                    onError: error => {
                        onError && onError(error);
                        reject(error);
                    },
                    onProgress: (bytesUploaded, bytesTotal) => {
                        if (!connected) {
                            toast.error(
                                'You lost your internet connection.. Upload will resume when your connection is restored!'
                            );
                            upload.abort();
                            setUploadYoutubePaused(true);
                        }
                        onProgress && onProgress(bytesUploaded, bytesTotal);
                        return setProgress(parseFloat(((bytesUploaded / bytesTotal) * 100).toFixed(2)));
                    },

                    onSuccess: () => {
                        setUpload(null);
                        localStorage.removeItem('tusUploadData');
                        onSuccess && onSuccess();
                        resolve('success');
                    },
                });

                if (resume) {
                    const uploadFound = upload.findPreviousUploads().then(previousUploads => {
                        if (previousUploads.length > 0) {
                            upload.resumeFromPreviousUpload(previousUploads[0]);
                            return true;
                        } else {
                            toast.warning('No previous upload found for this file, please try again.');
                        }
                    });
                    if (!uploadFound) {
                        return;
                    }
                }

                setUpload(upload);

                setUploadStarted(true);

                localStorage.setItem(
                    'tusUploadData',
                    JSON.stringify({ ...tusVideoData, expiresAt: Date.now() + 1000 * 60 * 60 * 24 })
                );

                upload.start();
            });

        const success = await tusUpload().catch(error => {
            toast.error('Something went wrong');
            Sentry.captureException(error, {
                contexts: { video: { ...tusVideoData, title: 'video', videoTitle: tusVideoData.title } },
            });
        });
        if (!success) {
            return;
        }
        toast.success('Your video was uploaded.');
        return true;
    };

    return {
        uploadVideo,
        upload,
        progress,
        connected,
        uploadYoutubePaused,
        resetState,
        uploadStarted,
        setUploadStarted,
        setUpload,
        resumeUpload,
        pauseUpload,
        cancelUpload,
    };
}
