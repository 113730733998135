// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import Dropzone, { DropzoneRef } from 'react-dropzone';

type Props = {
    onChange: Function,
    captureRef: Function,
    style?: Object,
    uploadText: string,
    uploadIcon?: string,
};

@observer
export default class ImageFieldDropzone extends React.Component<Props> {
    dropzoneRef: { current: null | DropzoneRef };

    constructor(props: Props) {
        super(props);

        this.dropzoneRef = React.createRef();
    }

    componentDidMount() {
        this.props.captureRef && this.props.captureRef(this.dropzoneRef);
    }

    handleDrop = (files: File[]) => {
        this.props.onChange(files[0]);
    };

    render() {
        const { uploadIcon, uploadText, style } = this.props;

        return (
            <Dropzone
                className="image-holder upload-image"
                onDrop={this.handleDrop}
                multiple={false}
                ref={this.dropzoneRef}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className="image-holder upload-image rectangle" {...getRootProps({ style })}>
                        <div className="label-wrapper">
                            <input {...getInputProps()} />
                            {uploadIcon && <Icon name={uploadIcon} />}
                            <div className="text">{uploadText}</div>
                        </div>
                    </div>
                )}
            </Dropzone>
        );
    }
}
