// @flow

import * as React from 'react';

type Props = {
    children: React.Node,
    four?: boolean,
};

export default class ReportStats extends React.Component<Props> {
    render() {
        return (
            <div className={`stat-sidebar ${this.props.four ? 'four-stats' : ''}`}>
                <div className="stats clearfix">{this.props.children}</div>
            </div>
        );
    }
}
