// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon } from 'semantic-ui-react';

type Props = {
    title: string,
    sortable?: boolean,
    property?: string,
    sortBy?: string,
    sortDirection?: string,
    render?: (data: Object) => React.Node,
    onClick: Function,
};

@observer
export default class SortableHead extends React.Component<Props> {
    get sortable(): boolean {
        const { sortable, property } = this.props;
        return !!(sortable && property);
    }

    get selected(): boolean {
        const { sortBy, property } = this.props;
        return sortBy === property;
    }

    get sortableIcon(): string {
        const { sortDirection } = this.props;

        let icon = ['sort'];
        if (this.selected) icon.push(sortDirection === 'asc' ? 'ascending' : 'descending');

        return icon.join(' ');
    }

    get className(): string {
        const classes = ['top'];
        if (this.sortable) classes.push('sortable-column');
        if (this.selected) classes.push('sort-control-active');

        return classes.join(' ');
    }

    handleClick = () => {
        if (this.sortable) this.props.onClick(this.props.property);
    };

    render() {
        return (
            <th className={this.className} onClick={this.handleClick}>
                {this.props.title}

                {this.sortable && (
                    <span className="sort-control">
                        <Icon name={this.sortableIcon} />
                    </span>
                )}
            </th>
        );
    }
}
