// @flow

import * as React from 'react';
import { Observer, PropTypes } from 'mobx-react';
import { Prompt } from 'react-router-dom';
import { Field, FieldGroup, Form } from 'app/external/mobx-form-for';
import { Progress, Checkbox, Dropdown, Message, Button, Icon } from 'semantic-ui-react';
import { CaptureVideo } from 'app/components/CaptureVideo/CaptureVideo';
import { uniqueId } from 'app/external/form-for-component-helpers';

import Video from 'models/Video';
import Place from 'models/Place';

import userInfoStore from 'stores/userInfo';
import videoStore from 'video/stores/video';

import { toast } from 'helpers';

import PlaceMonitor from 'components/Place/Monitor';
import Modal from 'components/Modal';
import { isMobile } from 'react-device-detect';
import * as Sentry from '@sentry/react';
import useTusUpload from '../useTusUpload';

const YOUTUBE_OPTIONS = [
    {
        key: 'unlisted',
        text: 'Unlisted',
        value: 'unlisted',
    },
    {
        key: 'public',
        text: 'Public',
        value: 'public',
    },
];

// eslint-disable-next-line react/prop-types
export default function UploadVideoPartial({ trigger, closeModal, openUploadPartial, type }) {
    const [openVideoModal, setOpenVideoModal] = React.useState(false);
    const [prefferedCam, setPrefferedCam] = React.useState(isMobile ? 'environment' : 'user');
    const [switchCamera, setSwitchCamera] = React.useState(false);
    const [youtubeStatus, setYoutubeStatus] = React.useState('0');
    const [uploadYoutube, setUploadYoutube] = React.useState(false);
    const [uploadYoutubeType, setUploadYoutubeType] = React.useState(YOUTUBE_OPTIONS[0].value);
    const [video, setVideo] = React.useState(new Video());
    const [place, setPlace] = React.useState(null);
    const buttonRef = React.useRef(null);

    const {
        uploadVideo,
        upload,
        progress,
        uploadStarted,
        setUploadStarted,
        resetState: resetTusState,
        uploadYoutubePaused,
        resumeUpload,
        cancelUpload,
    } = useTusUpload();

    React.useEffect(() => {
        if (place) {
            if (!place.hasYoutubeAuth() || !uploadYoutube) {
                setYoutubeStatus('0');
            }
            if (place.hasYoutubeAuth() && place.isYoutubePublic() && uploadYoutube && uploadYoutubeType === 'public') {
                setYoutubeStatus('2');
            }
            if (place.hasYoutubeAuth() && uploadYoutube && uploadYoutubeType === 'unlisted') {
                setYoutubeStatus('1');
            }
        }
    }, [place, uploadYoutube, uploadYoutubeType]);

    const updatePlace = (place: ?Place) => {
        if (place) place = place.clone();
        setPlace(place);
    };

    const load = async (place: Place) => {
        updatePlace(await Place.find(place.id));
    };

    const resetVideo = () => {
        setVideo(new Video());
    };

    const resetState = () => {
        resetVideo();
        resetTusState();
        setUploadYoutube(false);
        setUploadYoutubeType(YOUTUBE_OPTIONS[0].value);
        setVideo(new Video());
        setOpenVideoModal(false);
        setPrefferedCam(isMobile ? 'environment' : 'user');
        setSwitchCamera(false);
    };

    const preventCloseOnUpload = event => {
        if (uploadStarted) {
            const confirmationMessage = '';
            (event || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
        }
    };

    const onClose = () => {
        const prevented = preventCloseOnUpload();
        if (!prevented) {
            resetState();
            closeModal();
        }
    };

    const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        let videoFile;

        videoFile = formData.get('data[video_file]');

        if (!videoFile || !videoFile.name) videoFile = video.video_blob;

        if (!videoFile) {
            const isUpload = type === 'upload';
            const isVideo = type === 'video';
            const errorMsg = isUpload
                ? 'Choose a video first!'
                : isVideo
                ? 'Record a video first!'
                : 'Record a screen video first!';
            toast.error(errorMsg);
            return;
        }
        const { title, description } = video;
        const { name: filename, type: filetype } = videoFile;
        const { id: userId } = userInfoStore.user;
        const { id: placeId } = place;

        if (title.trim() === '') {
            toast.error('Empty spaces not allowed.');
            return;
        }

        const videoData = {
            title,
            description,
            placeId,
            youtubeStatus,
            extension: `.${filetype.split('/')[1]}`,
        };
        const response = await videoStore.createVideo(videoData);

        const tusVideoData = {
            media_ulid: response.data.media_ulid,
            filename,
            filetype,
            title,
            description,
            userId,
            placeId,
            youtubeStatus,
            destination: 'media-service',
        };

        if (response.data && response.data.success) {
            const success = await uploadVideo(videoFile, tusVideoData);
            if (!success) {
                return;
            }
            await videoStore.update();

            for (let i = 0; i < videoStore.totalPages; i++) {
                if (findNewVideo().length === 0) {
                    await videoStore.loadNext();
                } else {
                    videoStore.select(findNewVideo()[0]);
                    if (videoStore.activeVideoInListDom && videoStore.activeVideoInListDom.scrollIntoView) {
                        videoStore.activeVideoInListDom.scrollIntoView();
                    }
                    break;
                }
            }
            resetState();
            resetVideo();
            closeModal();
        } else {
            toast.error(response.data.message);
            Sentry.captureException(response.data.message, {
                contexts: { video: { ...tusVideoData, title: 'video', videoTitle: title } },
            });
            closeModal();
        }
    };

    const findNewVideo = () => {
        return videoStore.videos.filter(
            video => video.title === video.title && video.description === video.description
        );
    };

    const handleUploadYoutube = () => {
        setUploadYoutube(!uploadYoutube);
    };

    const handleUploadYoutubeType = (event, data) => {
        setUploadYoutubeType(data.value);
    };

    const renderContent = () => {
        const { id: placeId, youtube_quota } = place;
        const isUpload = type === 'upload';
        const isVideo = type === 'video';
        const title = isUpload ? 'Upload Video' : isVideo ? 'Record Video' : 'Record Screen';

        return (
            <React.Fragment>
                <Modal
                    open={openUploadPartial}
                    trigger={trigger}
                    className="custom"
                    title={title}
                    closeOnDimmerClick={false}
                >
                    <Modal.Content>
                        <Form
                            id={uniqueId(UploadVideoPartial)}
                            for={video}
                            onSubmit={handleSubmit}
                            className="ui form tiny"
                            encType="multipart/form-data"
                        >
                            <input type="hidden" name="data[place_id]" value={placeId} />
                            <FieldGroup for={video} prefix="data">
                                <div className={`animate-hide ${uploadStarted ? 'hidden' : ''}`}>
                                    {isUpload || isVideo ? (
                                        <Message info>
                                            Please use Catalyst Photo to upload files to your library for social sharing
                                        </Message>
                                    ) : null}
                                    <Field name="title" disabled={uploadStarted} autoFocus />
                                    <Field name="description" disabled={uploadStarted} style={{ marginBottom: 10 }} />
                                    {type === 'upload' && <Field name="video_file" disabled={uploadStarted} />}
                                </div>
                            </FieldGroup>
                            {uploadStarted && (
                                <Progress className="no-margin" percent={progress} size="big" progress indicating />
                            )}
                            <button ref={el => (buttonRef.current = el)} style={{ display: 'none' }} />
                        </Form>
                        {type !== 'upload' && !uploadStarted && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Modal
                                    open={openVideoModal}
                                    trigger={
                                        <Button
                                            id="disbale-hover"
                                            basic
                                            disabled={uploadStarted}
                                            style={{
                                                border: '1px solid #B81D2C',
                                                marginBottom: '1rem',
                                                marginTop: '1rem',
                                            }}
                                            onClick={() => setOpenVideoModal(true)}
                                        >
                                            <Icon
                                                style={{ color: '#B81D2C' }}
                                                name={type === 'video' ? 'camera' : 'record'}
                                            />
                                            <span style={{ color: '#B81D2C' }}>{`Start ${
                                                isVideo ? 'Video' : 'Screen'
                                            } Recording`}</span>
                                        </Button>
                                    }
                                    className="custom"
                                    title={type === 'video' ? 'Record Video' : 'Record Screen'}
                                    closeOnDimmerClick={false}
                                >
                                    <CaptureVideo
                                        updateVideo={file => video.setVideoBlob(file)}
                                        startRecording={openVideoModal}
                                        prefferedCam={prefferedCam}
                                        type={type}
                                        onClose={() => setOpenVideoModal(false)}
                                    />
                                </Modal>
                                {video.video_blob && (
                                    <span style={{ marginLeft: 10, fontSize: 17 }}>{video.video_blob.name}</span>
                                )}
                            </div>
                        )}
                        {place && place.hasYoutubeAuth() && !uploadStarted && youtube_quota > 0 && (
                            <React.Fragment>
                                {isMobile && type === 'video' && (
                                    <Checkbox
                                        toggle
                                        checked={switchCamera}
                                        className="custom"
                                        label="Use Front Camera (Default is back camera)"
                                        onChange={() => {
                                            setPrefferedCam(switchCamera ? 'environment' : 'user');
                                            setSwitchCamera(!switchCamera);
                                        }}
                                    />
                                )}
                                {type === 'screen' && (
                                    <Message info>Tab recording does not capture microphone audio</Message>
                                )}
                                <div
                                    style={{ marginTop: 0 }}
                                    className="youtube-options modal-content-inner-wrapper two-column clearfix"
                                >
                                    <div className="column toggle">
                                        <Checkbox
                                            toggle
                                            checked={uploadYoutube}
                                            disabled={uploadStarted}
                                            className="custom"
                                            label="Upload videos to YouTube"
                                            onChange={handleUploadYoutube}
                                        />
                                    </div>
                                    {uploadYoutube && (
                                        <div className="column">
                                            <Message info>
                                                <strong>{youtube_quota}</strong> YouTube upload(s) remaining today
                                            </Message>
                                        </div>
                                    )}
                                </div>
                                {place.isYoutubePublic() && uploadYoutube && (
                                    <div className="youtube-upload-type">
                                        <label>Youtube upload type</label>
                                        <Dropdown
                                            selection
                                            fluid
                                            onChange={handleUploadYoutubeType}
                                            options={YOUTUBE_OPTIONS}
                                            defaultValue={uploadYoutubeType}
                                            disabled={uploadStarted}
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            basic
                            color="black"
                            onClick={() => {
                                if (upload) {
                                    cancelUpload();
                                }
                                setUploadStarted(false);
                                onClose();
                                closeModal();
                            }}
                        >
                            Cancel
                        </Button>
                        {uploadYoutubePaused ? (
                            <Button
                                icon="play"
                                style={{ backgroundColor: '#1b183a', color: 'white' }}
                                content="Resume"
                                onClick={resumeUpload}
                            />
                        ) : (
                            <Button
                                disabled={uploadStarted}
                                icon="cloud upload"
                                style={{ backgroundColor: '#1b183a', color: 'white' }}
                                content="Upload"
                                onClick={() => buttonRef.current.click()}
                            />
                        )}
                    </Modal.Actions>
                </Modal>
                <Prompt
                    message="A video is currently being uploaded, are you sure you want to leave this page?"
                    when={uploadStarted}
                />
            </React.Fragment>
        );
    };

    return <Observer render={() => <PlaceMonitor onChange={load} render={renderContent} />} />;
}

UploadVideoPartial.propTypes = {
    type: PropTypes.string,
    trigger: PropTypes.node,
    closeModal: PropTypes.func,
    openUploadPartial: PropTypes.bool,
};
