// @flow

import * as React from 'react';

type Props = {
    children: React.Node,
};

export default class ReportBody extends React.Component<Props> {
    render() {
        return <div className="main-section">{this.props.children}</div>;
    }
}
