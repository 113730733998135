// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

type Props = {
    className?: string,
    children: React.Node,
};

@observer
export default class SubColumn extends React.Component<Props> {
    render() {
        const { className } = this.props;

        const classes = ['column sub-column'];

        if (className) classes.push(className);

        return <div className={classes.join(' ')}>{this.props.children}</div>;
    }
}
