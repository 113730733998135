// @flow

import { Field } from 'app/external/mobx-form-for';

import CheckboxField from './Checkbox';
import ImageField from './Image';
import InputField from './Input';
import MaskedField from './Masked';
import PhoneField from './Phone';
import SelectField from './Select';
import StateField from './State';
import SwitchField from './Switch';
import VideoField from './FileInput';
import ZipcodeField from './Zipcode';
import PlaceField from './Place';
import RoleField from './Role';
import CsvField from './FileInput';
import PlaceNotificationField from './PlaceNotification';
import EmailsField from './Emails';
import TaggedInputField from './TaggedInput';
import TagsField from './Tags';
import TagGroupField from './TagGroup';
import TagDateRangeField from './TagDateRange';
import FilterGroupField from './FilterGroup';
import SelectMediaField from './SelectMedia';
import RadioGroupField from './RadioGroup';

Field.connect('text', InputField);
Field.connect('email', InputField);
Field.connect('password', InputField);
Field.connect('number', InputField);
Field.connect('url', InputField);

Field.connect('select', SelectField);
Field.connect('state', StateField);

Field.connect('checkbox', CheckboxField);
Field.connect('switch', SwitchField);

Field.connect('masked', MaskedField);
Field.connect('tel', PhoneField);
Field.connect('zip', ZipcodeField);

Field.connect('image', ImageField);
Field.connect('video', VideoField);
Field.connect('csv', CsvField);

Field.connect('Place', PlaceField);
Field.connect('Role', RoleField);
Field.connect('placeNotification[]', PlaceNotificationField);
Field.connect('emails', EmailsField);
Field.connect('taggedInput', TaggedInputField);
Field.connect('tags', TagsField);
Field.connect('tagGroup', TagGroupField);
Field.connect('tagDateRange', TagDateRangeField);
Field.connect('filterGroup', FilterGroupField);
Field.connect('selectMedia', SelectMediaField);
Field.connect('radioGroup', RadioGroupField);
