// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import moment from 'moment';
import MediaQuery from 'react-responsive';

import { Button, Icon, Label, Menu, Segment } from 'semantic-ui-react';
import placeStore from 'stores/place';

import videoLeaderboardStore from 'video/stores/leaderboard';
import Page from 'components/Page';
import PlaceMonitor from 'components/Place/Monitor';
import ReportHeader from 'components/Report/Header';
import ReportBody from 'components/Report/Body';
import ReportStats from 'components/Report/Stats';
import ReportStatsData from 'components/Report/Stats/Data';

import UserInfoStatsPartial from './partials/UserInfoStatsPartial';
import VideoMetricsPartial from './partials/VideoMetricsPartial';
import viewMetricStore from 'video/stores/viewMetric';
import videoDataStore from 'video/stores/videoData';
import ReportSection from 'components/Report/Section';
import TabsMenu from './partials/TabsMenuMobilePartial';
import InlineLoader from 'components/InlineLoader';
import Filters from 'models/Filters';
import FiltersBar from 'components/Filters/Bar';
import FiltersPopup from 'components/Filters/Popup';
import ActionButton from 'components/ActionButton';
import MediaQueryDesktop from 'components/MediaQuery/Desktop';
import TriggerDownload from 'components/TriggerDownload';

type Props = {};

@observer
export default class videoReportPage extends React.Component<Props> {
    @observable activeItem = 'Video Metrics';
    @observable filters: Filters;

    load = async () => {
        this.setFilters(this.buildFiltersFromDefaults());

        await this.fetch();
    };

    async fetch() {
        await Promise.all([
            videoLeaderboardStore.filter(this.filters),
            viewMetricStore.searchByDate(this.filters.startDate, this.filters.endDate),
            videoDataStore.searchByDate(this.filters.startDate, this.filters.endDate),
        ]);

        videoDataStore.loadAvgWatchedLength();
    }

    buildFiltersFromDefaults(): Filters {
        const filters = new Filters();
        const defaultStartDate = moment()
            .subtract(1, 'year')
            .format('YYYY-MM-DD');
        const defaultEndDate = moment().format('YYYY-MM-DD');

        filters.setDefaultDateRange(defaultStartDate, defaultEndDate);
        filters.resetDateRange();

        return filters;
    }

    get availableFilters() {
        switch (this.activeItem) {
            case 'Leaderboards':
            case 'Video Metrics':
            default:
                return [];
        }
    }

    get actions(): React.Node {
        return (
            <React.Fragment>
                {'Leaderboards' === this.activeItem && (
                    <TriggerDownload
                        filename="vi_leaderboards.xlsx"
                        link={videoLeaderboardStore.getExportLink(this.filters)}
                    >
                        {(handleOnClick, saving) => (
                            <ActionButton
                                onClick={handleOnClick}
                                text="Export XLSX"
                                icon="file excel outline"
                                saving={saving}
                                link={videoLeaderboardStore.getExportLink(this.filters)}
                            />
                        )}
                    </TriggerDownload>
                )}
                <FiltersPopup
                    onChange={this.handleFiltersChange}
                    filters={this.filters}
                    availableFilters={this.availableFilters}
                    onReset={this.handleResetFilters}
                    trigger={<ActionButton icon="filter" text="Filters" />}
                />
            </React.Fragment>
        );
    }

    get mobileActions(): React.Node {
        if ('Leaderboards' !== this.activeItem) return null;

        return (
            <React.Fragment>
                <TriggerDownload
                    filename="vi_leaderboards.xlsx"
                    link={videoLeaderboardStore.getExportLink(this.filters)}
                >
                    {(handleOnClick, saving) => (
                        <Button
                            className="option-btn"
                            onClick={handleOnClick}
                            saving={saving}
                            link={videoLeaderboardStore.getExportLink(this.filters)}
                        >
                            <Icon name="file excel outline" /> Export XLSX
                        </Button>
                    )}
                </TriggerDownload>
            </React.Fragment>
        );
    }

    get mobileFiltersBar(): React.Node {
        return (
            <FiltersBar
                filters={this.filters}
                onClick={this.handleFiltersChange}
                withPopup={
                    <FiltersPopup
                        onChange={this.handleFiltersChange}
                        onReset={this.handleResetFilters}
                        filters={this.filters}
                        trigger={
                            <Label className="tags-field label-btn no-icon clickable">
                                <Icon name="filter" /> Filters
                            </Label>
                        }
                    />
                }
            />
        );
    }

    @action.bound
    handleItemClick(event: SyntheticEvent<HTMLFormElement>, { name }: Object) {
        this.activeItem = name;
    }

    @action.bound
    setFilters(filters: Filters) {
        this.filters = filters;
    }

    handleFiltersChange = () => {
        this.fetch();
    };

    handleResetFilters = () => {
        this.setFilters(this.buildFiltersFromDefaults());
    };

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        const { viewMetrics } = viewMetricStore;
        const { videoDatas } = videoDataStore;

        if (!viewMetrics) {
            return <ReportSection>Report is not ready</ReportSection>;
        }

        const search = 'Leaderboards' === this.activeItem ? videoLeaderboardStore.search : undefined;

        return (
            <Page
                onSearch={search}
                actions={this.actions}
                mobileActions={this.mobileActions}
                mobileFilters={this.mobileFiltersBar}
                className="reports tabs"
            >
                {viewMetricStore.loading ? (
                    <div className="no-data">
                        <InlineLoader />
                    </div>
                ) : (
                    <React.Fragment>
                        <ReportHeader
                            title={placeStore.place.name}
                            dates={this.filters.dateRange}
                            hideSideSection
                            noBorder
                        />
                        <MediaQuery minWidth={769}>
                            <div className="section info clearfix">
                                <Menu attached="top" tabular className="simple custom clearfix">
                                    <Menu.Item
                                        name="Video Metrics"
                                        active={'Video Metrics' === this.activeItem}
                                        onClick={this.handleItemClick}
                                    />
                                    <Menu.Item
                                        name="Leaderboards"
                                        active={'Leaderboards' === this.activeItem}
                                        onClick={this.handleItemClick}
                                    />
                                </Menu>
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={768}>
                            <TabsMenu handleItemClick={this.handleItemClick} />
                        </MediaQuery>
                        <MediaQueryDesktop>
                            <FiltersBar filters={this.filters} onClick={this.handleFiltersChange} />
                        </MediaQueryDesktop>
                        <Segment attached="bottom" className="tab custom simple-tabs active">
                            <ReportStats>
                                <ReportStatsData
                                    title="Total Videos"
                                    value={viewMetrics.total_videos}
                                    tooltip="Number of videos created in the selected time range"
                                    scrollToId="userInfoStats"
                                />
                                <ReportStatsData
                                    title="Total Shares"
                                    value={viewMetrics.total_shares}
                                    tooltip="Number of times a video was shared in the selected time range"
                                    scrollToId="userInfoStats"
                                />
                                <ReportStatsData
                                    title="Total Unique Views"
                                    value={viewMetrics.total_unique_views}
                                    tooltip="Number of unique views in the selected time range"
                                    scrollToId="userInfoStats"
                                />
                            </ReportStats>
                            <ReportBody>
                                {this.activeItem === 'Video Metrics' && (
                                    <VideoMetricsPartial viewMetrics={viewMetrics} videoDatas={videoDatas} />
                                )}
                                {this.activeItem === 'Leaderboards' && (
                                    <React.Fragment>
                                        {videoLeaderboardStore.loading ? (
                                            <div className="no-data">
                                                <InlineLoader />
                                            </div>
                                        ) : (
                                            <UserInfoStatsPartial
                                                videosCount={videoLeaderboardStore.videosLeaderboard}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                            </ReportBody>
                        </Segment>
                    </React.Fragment>
                )}
            </Page>
        );
    };
}
