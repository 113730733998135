// @flow

import { observer } from 'mobx-react';
import React from 'react';

import Sidebar from 'components/Sidebar';
import SubmissionCardList from 'photo/components/Submission/Card/List';
import PublishedContent from 'models/PublishedContent';
import publishedContentStore from 'photo/stores/submission/publishedContentStore';

type Props = {};

@observer
export default class SubmissionsSidebar extends React.Component<Props> {
    handleCardClick = (submission: PublishedContent) => {
        if (!publishedContentStore.content) {
            return;
        }

        publishedContentStore.select(submission);
    };

    render() {
        if (!publishedContentStore.contentCollection || !publishedContentStore.content) {
            return null;
        }

        return (
            <Sidebar title="Submitted Content" className="fit">
                <SubmissionCardList
                    invertedCards
                    contentCollection={publishedContentStore.contentCollection}
                    selected={publishedContentStore.content}
                    onCardClick={this.handleCardClick}
                />
            </Sidebar>
        );
    }
}
