// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import { Field } from 'app/external/mobx-form-for';

import { toast } from 'helpers';

import User from 'models/User';

import Sortable from 'components/Sortable';
import Modal from 'components/Modal';
import FormButton from 'components/FormButton';
import { getErrorMessageFromSaveModelError } from 'helpers';

type Props = {
    user: User,
};

@observer
export default class UserEditPartial extends React.Component<Props> {
    @observable user: User;

    constructor(props: Props) {
        super(props);
        this.user = this.props.user.clone();
        this.user.memorizeChanges();
    }

    @action
    updateUser() {
        // $FlowFixMe
        Object.assign(this.props.user, this.user);
    }

    handleSave = async () => {
        try {
            await this.user.saveChanges();
            this.updateUser();
            toast.success(`User ${this.user.name} updated`);
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError(error));
        }
    };

    render() {
        return (
            <Modal.Action
                className="custom"
                forObject={this.user}
                title="Edit User"
                action="Save"
                onAction={this.handleSave}
                trigger={<Sortable.Action title="Edit user" icon="setting" />}
                mixedAction={({ submit, saving }) => {
                    return (
                        <React.Fragment>
                            <h4>User info</h4>
                            <div className="field">
                                <Field name="name" autoFocus />

                                <SemanticForm.Group widths="two">
                                    <Field name="role" />
                                    <Field
                                        id="editInput"
                                        readOnly={true}
                                        onFocus={() => {
                                            document.getElementById('editInput').readOnly = false;
                                        }}
                                        name="external_id"
                                    />
                                </SemanticForm.Group>

                                <SemanticForm.Group widths="two">
                                    <Field name="email" />
                                    <Field name="phone" />
                                </SemanticForm.Group>
                                <FormButton primary onClick={submit} saving={saving} text="Update User Info" />
                            </div>
                            <h4>Change password</h4>
                            <div className="field">
                                <SemanticForm.Group widths="two">
                                    <Field name="password" required={false} />
                                    <Field name="password_confirmation" required={false} />
                                </SemanticForm.Group>
                                <FormButton primary onClick={submit} saving={saving} text="Change Password" />
                            </div>
                        </React.Fragment>
                    );
                }}
            />
        );
    }
}
