// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Sidebar as SemanticSidebar } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import sidebarStore from 'stores/sidebar';

import Resizer from 'components/Resizer';
import './Sidebar.css';

type Props = {
    title: string,
    children: React.Node,
    className?: string,
    direction?: 'left' | 'right',
    menus?: React.Node,
    link?: string,
};

@observer
export default class Sidebar extends React.Component<Props> {
    static contextTypes = {
        sidebar: PropTypes.any,
    };

    render() {
        const { title, children, className, direction, menus, link } = this.props;

        const visible = this.context.sidebar === sidebarStore.sidebar;
        if (visible && window.innerWidth < 1024) {
            window.scrollTo(0, 0);
        }

        return (
            <SemanticSidebar visible={visible} animation="push" direction={direction} className={className}>
                <div className="menu-control">
                    {link ? (
                        <Link to={link} className="title-panel">
                            {title}
                        </Link>
                    ) : (
                        <div className="title-panel">{title}</div>
                    )}

                    <div className="more-options">
                        {menus}

                        <Button
                            id="close-settings-menu"
                            type="button"
                            circular
                            icon
                            className="custom outline close-panel option"
                            onClick={sidebarStore.close}
                        >
                            <Icon name="remove" />
                        </Button>
                    </div>
                </div>

                <Resizer className="menu-wrapper" calculatedMobile style={{ overflow: 'auto' }}>
                    {children}
                </Resizer>
            </SemanticSidebar>
        );
    }
}
