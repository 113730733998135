// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Field } from 'app/external/mobx-form-for';
import { Link } from 'react-router-dom';
import { Icon, Button, Form as SemanticForm } from 'semantic-ui-react';

import User from 'models/User';

import tokenStore from 'stores/token';
import authApi from 'api/authApi';

import { toast, httpResponseErrors } from 'helpers';

import GuestForm from 'components/Guest/Form';

type Props = {};

const inputProps = {
    required: true,
    transparent: true,
    inverted: true,
    iconPosition: 'left',
};

@observer
export default class LoginPage extends React.Component<Props> {
    @observable user: User = new User();
    @observable loading: boolean;

    @action
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    handleSubmit = async (e: any, data: { email: string, password: string }) => {
        this.setLoading(true);

        try {
            const token = await authApi.login(data.email, data.password);
            await tokenStore.set(token);
        } catch (e) {
            const error = httpResponseErrors(e);
            toast.error(error);
        } finally {
            this.setLoading(false);
        }
    };

    render() {
        return (
            <GuestForm for={this.user} onSubmit={this.handleSubmit}>
                <Field name="email" {...inputProps} icon="user" autoFocus />
                <Field name="password" {...inputProps} icon="lock" />

                <SemanticForm.Field>
                    <Link to="/forgot-password">Forgot your password?</Link>
                </SemanticForm.Field>

                <SemanticForm.Field>
                    {!this.loading && (
                        <Button primary className="submit">
                            Log in
                        </Button>
                    )}

                    {this.loading && (
                        <Button primary className="submit" disabled>
                            <Icon name="spinner" loading /> Authenticating
                        </Button>
                    )}
                </SemanticForm.Field>
            </GuestForm>
        );
    }
}
