// @flow
import moment from 'moment-timezone';
import momentDate from 'moment';

function getDateObject(dateTime: string) {
    const [date, time] = dateTime.split(' ');
    return moment.tz(`${date}T${time}+00:00`, 'America/Chicago');
}

export function toHour(dateTime: string) {
    const time = getDateObject(dateTime);
    return time.format('hh:mm A z');
}

export function toDate(dateTime: string) {
    const date = getDateObject(dateTime);
    return date.format('MM/DD/YYYY');
}

export function getISOString(date: string) {
    return new Date(date).toISOString();
}

export function fromSecondsToMinutesAndSeconds(seconds: number): string {
    const m = Math.floor(seconds / 60);
    const s = seconds - m * 60;

    if (m > 0) return `${m}m ${s}s`;

    return `${s}s`;
}

export function validateDate(date) {
    const currentTime = new Date();
    const selectedDate = moment(date);
    // if the date is in the past ignoring time
    if (selectedDate.isBefore(currentTime, 'day')) {
        return {
            hasDateError: true,
            hasTimeError: false,
        };
    }
    // else if the date and time are in the past
    if (selectedDate.isBefore(currentTime, 'minute')) {
        return {
            hasDateError: false,
            hasTimeError: true,
        };
    }
    return {
        hasDateError: false,
        hasTimeError: false,
    };
}
