// @flow

import Model from 'models/Model';

export default class VideoLeaderboard extends Model {
    path = 'video-leaderboard';

    username: string;
    videos: number;
    total_shares: number;
    unique_views: number;
    not_viewed: number;
    avg_percentage_viewed: string;
}
