// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import Filters from 'models/Filters';
import { Field, Form } from 'app/external/mobx-form-for';
import { Label, Icon } from 'semantic-ui-react';

type Props = {
    filters: Filters,
    onClick: Function,
    availableFilterGroups?: Array<string>,
    inLayout?: boolean,
    withPopup?: React.Node,
};

@observer
export default class FiltersBar extends React.Component<Props> {
    onDateRangeClick = () => {
        this.props.filters.resetDateRange();
        this.props.onClick();
    };

    onResetClick = () => {
        this.props.filters.resetAll();
        this.props.onClick();
    };

    showFilterGroup(group: string): boolean {
        const { availableFilterGroups } = this.props;

        return group === 'dateRange' || (undefined !== availableFilterGroups && availableFilterGroups.includes(group));
    }

    get schema() {
        const { onClick, filters } = this.props;
        const schema = { dateRange: { type: 'tagDateRange' } };

        if (filters.filterGroups) {
            filters.filterGroups.forEach(group => {
                schema[group.name] = { type: 'tagGroup', addedAction: onClick, label: group.alias, group };
            });
        }

        return schema;
    }

    get for() {
        return Object.assign({}, this.props.filters.toObject(), { dateRange: true });
    }

    get classNames() {
        const { inLayout } = this.props;
        const classes = ['filter-tags-container', 'clearfix'];

        if (inLayout) classes.push('in-layout');

        return classes.join(' ');
    }

    render() {
        return (
            <div className={this.classNames}>
                <div className="filter-tags-title">Filters:</div>
                <div className="ui labels color custom">
                    <Form for={this.for} schema={this.schema}>
                        {Object.keys(this.schema).map(key => {
                            if (key === 'dateRange') {
                                return (
                                    <Field
                                        key={key}
                                        name="dateRange"
                                        type="tagDateRange"
                                        addedAction={this.onDateRangeClick}
                                        disableDelete={this.props.filters.canResetDates}
                                        startDate={this.props.filters.startDate}
                                        endDate={this.props.filters.endDate}
                                    />
                                );
                            }

                            return this.showFilterGroup(key) ? <Field key={key} name={key} /> : null;
                        })}
                        {this.props.filters.canResetAll && (
                            <Label onClick={this.onResetClick} className="tags-field label-btn no-icon clickable">
                                <Icon name="redo" /> Reset All
                            </Label>
                        )}
                        {this.props.withPopup}
                    </Form>
                </div>
            </div>
        );
    }
}
