// @flow

import { observer } from 'mobx-react';
import React from 'react';
import type { App } from 'types';

type Props = {
    app: App,
    className?: string,
    turnStatus?: string,
};

@observer
export default class AppIcon extends React.Component<Props> {
    render() {
        const { app, className, turnStatus } = this.props;

        const classes = [`app-icons icon-${app.icon}`];
        if (className) classes.push(className);

        if (app.feature === 'soci_enabled') {
            classes.push(turnStatus === 'off' ? 'icon_soci' : 'icon_soci_disabled');
        }

        return <i className={classes.join(' ')} />;
    }
}
