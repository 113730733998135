// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

type Props = {
    value: number,
    max?: number,
    negative?: string,
    showNumberRating?: boolean,
    margin?: boolean,
    type?: string,
};

const DEFAULT_MAX = 5;

const Rating = (props: Props) => {
    const max_value = props.max || DEFAULT_MAX;
    const percentage = (props.value / max_value) * 100;

    if (props.value === -1) return 'Rating not provided';

    return (
        <span
            className={`rating-stars-container clearfix ${props.negative ? props.negative : ''} ${
                props.type ? props.type : ''
            } ${props.margin ? 'margin' : ''}`}
        >
            {props.showNumberRating && <span className="rating-number clearfix">{props.value.toFixed(1)}</span>}
            <span className="stars-container">
                <span className="block-container slider" style={{ width: `${percentage}%` }}>
                    <span className="block-container overlayed-stars">
                        <Icon name="star" />
                        <Icon name="star" />
                        <Icon name="star" />
                        <Icon name="star" />
                        <Icon name="star" />
                    </span>
                </span>
                <span className="block-container grey-stars">
                    <Icon name="star" />
                    <Icon name="star" />
                    <Icon name="star" />
                    <Icon name="star" />
                    <Icon name="star" />
                </span>
            </span>
        </span>
    );
};

export default Rating;

Rating.propTypes = {
    value: PropTypes.number,
    max: PropTypes.number,
    negative: PropTypes.string,
    showNumberRating: PropTypes.bool,
    margin: PropTypes.bool,
    type: PropTypes.string,
};

Rating.defaultProps = {
    type: 'for-review-info',
    showNumberRating: false,
    margin: false,
};
