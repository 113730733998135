import React, { useState } from 'react';
import { toast as toastify } from 'react-toastify';
import { Icon } from 'semantic-ui-react';

import { toast } from 'app/helpers';
import kenect from 'kenect/kenect.app';
import AppIcon from 'components/App/Icon/AppIcon';
import User from 'models/User';

import './KenectUpgradeCard.css';

const PENDING_TITLE = 'this is a direct copy of the previous version of the upgrade card';
const PENDING_BUTTON_TEXT = 'Send Setup Email';
const PENDING_SUBTITLE = 'this is a direct copy of the previous version of the upgrade card';

const UPGRADED_TITLE = 'Email Sent!';
const UPGRADED_BUTTON_TEXT = 'Resend Email';
const UPGRADED_SUBTITLE = (
    <span>
        {"An email with setup instructions has been sent from kenect.com. If you haven't received it in 2-3 minutes, "}
        <br />
        {'please check your spam folder or resend the email.'}
    </span>
);

const KenectUpgradeCardBackup = () => {
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const sendSetupEmail = async () => {
        setIsLoading(true);
        try {
            await User.act({ url: 'send-catalyst-upgrade-email', method: 'post' });
            toast('Email sent!', {
                type: 'success',
                position: toastify.POSITION.BOTTOM_LEFT,
            });
            setIsEmailSent(true);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            toast('Something went wrong, please try again', {
                type: 'error',
                position: toastify.POSITION.BOTTOM_LEFT,
            });
        }
    };

    return (
        <div
            className="upgradeCard-wrapper"
            style={{
                marginTop: '2rem',
            }}
        >
            <button
                className={`upgradeCard ${isLoading ? 'disabled' : ''}`}
                onClick={sendSetupEmail}
                disabled={isLoading}
            >
                <div className="upgradeCard-description">
                    <h3 className="upgradeCard-title">{isEmailSent ? UPGRADED_TITLE : PENDING_TITLE}</h3>
                    <AppIcon app={kenect} className="main-icon" />
                    <h3 className="upgradeCard-subtitle">{isEmailSent ? UPGRADED_SUBTITLE : PENDING_SUBTITLE}</h3>
                </div>
                <h2 className="upgradeCard-callToAction">
                    {isLoading ? (
                        <div>
                            <Icon name="spinner" loading />
                        </div>
                    ) : isEmailSent ? (
                        UPGRADED_BUTTON_TEXT
                    ) : (
                        PENDING_BUTTON_TEXT
                    )}
                </h2>
            </button>
        </div>
    );
};

export default KenectUpgradeCardBackup;
