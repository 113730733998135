// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Form } from 'app/external/mobx-form-for';
import { Helmet } from 'react-helmet';

import Loader from 'components/Loader';
import Logo from 'components/Logo';

type Props = {
    for: Object,
    onSubmit: Function,
    children: React.Node,
};

@observer
export default class GuestForm extends React.Component<Props> {
    handleSubmit = (event: SyntheticEvent<HTMLFormElement>, data: Object) => {
        event.preventDefault();
        this.props.onSubmit(event, data);
    };

    render() {
        const { for: object, children } = this.props;

        return (
            <div id="main-wrapper" className="vertical-center-container">
                <Helmet>
                    <meta name="theme-color" content="#293135" />
                    <body className="login full-height" />
                </Helmet>

                <Loader
                    for={true}
                    render={() => (
                        <div className="login-wrapper vertical-center clearfix">
                            <div id="main-logo" className="flex-image">
                                <Logo suite />
                            </div>

                            <Form for={object} onSubmit={this.handleSubmit} className="ui form inverted">
                                {children}
                            </Form>
                        </div>
                    )}
                />
            </div>
        );
    }
}
