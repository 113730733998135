import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'semantic-ui-react';

export const minuteOptions = Array(60)
    .fill(0)
    .map((v, index) => {
        return {
            value: index,
            text: index < 10 ? `0${index}` : index,
        };
    });

export const hourOptions = Array(12)
    .fill(0)
    .map((v, index) => {
        const value = index + 1;
        return {
            value: value === 12 ? 0 : value,
            text: `${value}`,
        };
    });

export const periodOptions = [
    {
        label: 'AM',
        value: 'AM',
    },
    {
        label: 'PM',
        value: 'PM',
    },
];

export function getMinuteOptionFromDate(date) {
    if (!date) {
        return undefined;
    }
    const value = date.getMinutes();
    return {
        value,
        text: value < 10 ? `0${value}` : value,
    };
}

export function getHoursFromDate(date) {
    if (!date) {
        return undefined;
    }
    const value = date.getHours() % 12;
    return {
        value,
        text: value === 0 ? 12 : value, // we want 0 to appear as 12
    };
}

export function getAMPMFromDate(date) {
    if (!date) {
        return undefined;
    }
    const hours = date.getHours();
    const value = hours >= 12 ? 'PM' : 'AM';
    return {
        value,
        text: value,
    };
}

function TimeSelector({ value, onChange, errorMessage }) {
    const [minutes, setMinutes] = useState(getMinuteOptionFromDate(value));
    const [hours, setHours] = useState(getHoursFromDate(value));
    const [amPm, setAmPm] = useState(getAMPMFromDate(value));

    const onTimeChange = useCallback(
        ({ hours, minutes, amPm }) => {
            if (hours && minutes && amPm) {
                const hours24 = amPm.value === 'AM' ? hours.value : hours.value + 12;
                const newDate = value ? new Date(value.getTime()) : new Date();
                newDate.setHours(hours24);
                newDate.setMinutes(minutes.value);
                newDate.setSeconds(0);
                onChange(newDate);
            }
        },
        [onChange, value]
    );

    const onChangeMinutes = useCallback(
        newMinutes => {
            setMinutes(newMinutes);
            onTimeChange({ minutes: newMinutes, hours, amPm: amPm });
        },
        [onTimeChange, hours, amPm]
    );

    const onChangeHours = useCallback(
        newHours => {
            setHours(newHours);
            onTimeChange({ minutes, hours: newHours, amPm: amPm });
        },
        [onTimeChange, minutes, amPm]
    );

    const onChangePeriod = useCallback(
        newPeriod => {
            setAmPm(newPeriod);
            onTimeChange({ minutes, hours, amPm: newPeriod });
        },
        [onTimeChange, minutes, hours]
    );

    return (
        <>
            <div className={`kui-datetime-selector ${{ 'kui-selector-error': errorMessage }}`}>
                <div className="kui-datetime-selector-select">
                    <Select id="Hour" value={hours} text="Hour" options={hourOptions} onChange={onChangeHours} />
                </div>
                <div className="kui-datetime-selector-select">
                    <Select
                        id="Minute"
                        value={minutes}
                        text="Minute"
                        options={minuteOptions}
                        onChange={onChangeMinutes}
                    />
                </div>
                <div className="kui-datetime-selector-select kui-datetime-selector-select-end">
                    <Select id="AM/PM" value={amPm} text="AM/PM" options={periodOptions} onChange={onChangePeriod} />
                </div>
            </div>
            <p className="kui-datetime-selector-error">{errorMessage || ''}</p>
        </>
    );
}

TimeSelector.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
};

export default TimeSelector;
