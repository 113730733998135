// @flow

import { action, observable } from 'mobx';

import type { App, AppRoute } from 'types';
import { route } from 'helpers';

class RouteStore {
    @observable closeRoute: string = '/';

    @action
    setCloseRoute(appValue: App | string, routeValue: AppRoute | string) {
        this.closeRoute = route(appValue, routeValue);
    }
}

export default new RouteStore();
