// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import reviewStore from 'reputation/stores/review';

import SidebarButton from 'components/Sidebar/Button';
import ReviewMain from 'reputation/components/Review/Main';

type Props = {};

@observer
export default class SelectedContentPartial extends React.Component<Props> {
    render() {
        return <SidebarButton sidebar="reputation.reviews" render={this.renderContent} />;
    }

    renderContent = () => {
        const review = reviewStore.review;
        if (!review) return null;

        return (
            <div className={`list-item three-lines inverted active clearfix`}>
                <ReviewMain review={review} />
            </div>
        );
    };
}
