// @flow

import { action, computed, observable } from 'mobx';
import { observer, Observer } from 'mobx-react';
import * as React from 'react';
import { Form, Input } from 'semantic-ui-react';

import { toast } from 'helpers';

import Place from 'models/Place';
import User from 'models/User';

import Sortable from 'components/Sortable';
import Modal from 'components/Modal';
import { getErrorMessageFromSaveModelError } from 'helpers';

type Props = {
    user: Object,
    places: Place[],
};

@observer
export default class UserManagementPartial extends React.Component<Props> {
    @observable places: number[];
    @observable availableSearch: string = '';
    @observable assignedSearch: string = '';
    @observable savedPlace: number;

    constructor(props: Props) {
        super(props);
        this.places = props.user.places.concat([]);
    }

    @computed
    get placesAvailable(): Place[] {
        const search = this.availableSearch.toLowerCase();
        const { places } = this.props;
        return places.filter(place => place.name.toLowerCase().includes(search) && !this.places.includes(place.id));
    }

    @computed
    get placesAssigned(): Place[] {
        const search = this.assignedSearch.toLowerCase();
        const { places } = this.props;
        return places.filter(place => place.name.toLowerCase().includes(search) && this.places.includes(place.id));
    }

    @action
    assign(placeId: number) {
        this.places.push(placeId);
        if (this.savedPlace) this.savedPlace = 0;
    }

    @action
    remove(placeId: number) {
        if (this.places.length === 1) this.savedPlace = placeId;
        // $FlowFixMe - MobX array
        this.places.remove(placeId);
    }

    handleSearchChange = (event: any, meta: { name: string, value: string }) => {
        // $FlowFixMe
        action(() => (this[meta.name] = meta.value))();
    };

    handleSave = async () => {
        if (!this.places.length) {
            this.assign(this.savedPlace);
            return toast.warning('Users must be associated with at least one location');
        }
        try {
            await User.update(this.props.user.id, { places: this.places });
            action(() => this.props.user.set({ places: this.places }))();
            toast.success('User locations updated');
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError(error));
        }
    };

    render() {
        return (
            <Modal.Action
                className="custom"
                title="User Management"
                size="small"
                action="Save"
                onAction={this.handleSave}
                formClassName="assignment-panel"
                trigger={<Sortable.Action title="Manage assigned locations" icon="exchange" />}
            >
                <Form.Group widths="two">
                    <Form.Field>
                        <div className="titles">Available Locations</div>
                        <Observer
                            render={() => (
                                <Input
                                    name="availableSearch"
                                    icon="search"
                                    iconPosition="left"
                                    placeholder="Search All Locations"
                                    fluid
                                    transparent
                                    className="custom"
                                    value={this.availableSearch}
                                    onChange={this.handleSearchChange}
                                />
                            )}
                        />

                        <div className="list-items">
                            <div className="scroll-wrapper">
                                <Observer
                                    render={() =>
                                        this.placesAvailable.map(place => (
                                            <div key={place.id} className="item" onClick={() => this.assign(place.id)}>
                                                {place.name}
                                                <i className="plus circle icon data-positive-text" />
                                            </div>
                                        ))
                                    }
                                />
                            </div>
                        </div>
                    </Form.Field>

                    <Form.Field>
                        <div className="titles">Assigned to {this.props.user.name}</div>
                        <Observer
                            render={() => (
                                <Input
                                    name="assignedSearch"
                                    icon="search"
                                    iconPosition="left"
                                    placeholder="Search Assigned Locations"
                                    fluid
                                    transparent
                                    className="custom"
                                    value={this.assignedSearch}
                                    onChange={this.handleSearchChange}
                                />
                            )}
                        />

                        <div className="list-items">
                            <div className="scroll-wrapper">
                                <Observer
                                    render={() =>
                                        this.placesAssigned.map(place => (
                                            <div key={place.id} className="item" onClick={() => this.remove(place.id)}>
                                                {place.name}
                                                <i className="minus circle icon data-negative-text" />
                                            </div>
                                        ))
                                    }
                                />
                            </div>
                        </div>
                    </Form.Field>
                </Form.Group>
            </Modal.Action>
        );
    }
}
