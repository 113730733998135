// @flow

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import oauthApi from 'api/oauthApi';

// $FlowFixMe
import user_default from 'static/images/user_default.jpg';

type Props = {
    network: string,
    connected?: boolean,
    onDelete: Function,
    avatar?: string,
    connectedName?: string,
    actionRequired?: string,
    options?: Object,
    disabled?: boolean,
};

const formattedNames = {
    youtube: 'YouTube',
    facebook: 'Facebook',
    google: 'Google',
    googleplace: 'Google',
    'facebook-accounts': 'Facebook Accounts',
};

@observer
export default class ConnectButton extends React.Component<Props> {
    @observable expired: boolean = false;

    handleClick = (event: Event) => {
        if (this.props.disabled) return;
        event.preventDefault();
        const { network, options } = this.props;
        oauthApi.auth(network, options);
    };

    get icon() {
        return <Icon name={this.expired ? 'exclamation triangle' : 'check circle'} />;
    }

    get networkClass() {
        let { network } = this.props;
        network = network.split('-')[0];
        if (network === 'googleplace') return 'google';
        return network;
    }

    get buttonText() {
        const { connectedName, network } = this.props;
        if (this.expired) return `Reconnect ${formattedNames[network]}`;
        return connectedName || `Connect ${formattedNames[network]}`;
    }

    @action
    componentDidMount() {
        this.expired = this.props.actionRequired === '2';
    }

    render() {
        const { onDelete, connected, avatar } = this.props;

        return (
            <div className={`connect-button-wrapper ${connected ? 'connected' : ''} clearfix`}>
                {connected && (
                    <React.Fragment>
                        <div className={`status ${this.expired ? 'warning' : ''}`}>{this.icon}</div>
                        <img
                            className="avatar-image"
                            src={avatar || user_default}
                            alt=" Avatar"
                            onError={e => {
                                e.target.src = user_default;
                            }}
                        />
                    </React.Fragment>
                )}
                <a
                    className={`connect-button ${this.networkClass}`}
                    onClick={this.handleClick}
                    id={`${this.props.disabled ? 'disabled' : ''}`}
                >
                    <Icon name={`${this.networkClass}`} />
                    {this.buttonText}
                </a>
                {connected && (
                    <a className="delete-button" onClick={onDelete}>
                        <Icon name="trash alternate" />
                    </a>
                )}
            </div>
        );
    }
}
