// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';
import { uniqueId } from 'app/external/form-for-component-helpers';
import { Form, Input } from 'semantic-ui-react';
import Place from 'models/Place';

import ErrorLabel from 'components/ErrorLabel';

import SelectField from 'fields/Select';

type Props = ComponentProps & {
    places: Place[],
    options: [],
    place: Place,
};

type State = {
    selectValue: string,
};

@observer
export default class PlaceField extends React.Component<Props, State> {
    state = {
        selectValue: '',
    };

    get options(): Object[] {
        const { options, place } = this.props;
        const filteredOptions = options.filter(opt => opt.value !== 'user' && opt.value !== place.email);
        if (!place.email)
            return [...filteredOptions, { text: 'User e-mail', value: 'user' }, { text: 'Custom', value: 'Custom' }];
        return [
            ...filteredOptions,
            { text: 'User e-mail', value: 'user' },
            { text: 'Location e-mail', value: place.email },
            { text: 'Custom', value: 'Custom' },
        ];
    }

    handleSelectChange = (event: SyntheticInputEvent<HTMLSelectElement>, value: any) => {
        if (value === 'Custom') return this.setState({ selectValue: value });
        this.setState({ selectValue: '' });
        this.props.onChange(event, value);
    };

    handleInputChange = (event: SyntheticInputEvent<HTMLSelectElement>, value: any) => {
        this.props.onChange(event, value['value']);
    };

    render() {
        // $FlowFixMe
        const { touched, error, ...rest } = this.props;
        delete rest.options;
        delete rest.place;

        const id = uniqueId(this);
        const isCustomSelected = this.state.selectValue === 'Custom';

        return (
            <React.Fragment>
                {/* // $FlowFixMe */}
                <SelectField
                    {...rest}
                    value={this.state.selectValue || rest.value}
                    options={this.options}
                    onChange={this.handleSelectChange}
                />
                {isCustomSelected && (
                    <Form.Field error={touched && !!error} style={{ position: 'relative' }} required>
                        <label htmlFor={id}>Custom reply to e-mail</label>

                        <Input type="email" id={id} onChange={this.handleInputChange} required />
                        <ErrorLabel error={error} touched={touched} />
                    </Form.Field>
                )}
            </React.Fragment>
        );
    }
}
