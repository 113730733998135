// @flow

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Form as SemanticForm, Label } from 'semantic-ui-react';
import { Form, Field } from 'app/external/mobx-form-for';
import scrollIntoView from 'scroll-into-view';

import User from 'models/User';
import Place from 'models/Place';

import organizationStore from 'stores/organization';
import userInfoStore from 'stores/userInfo';

import { toast, httpResponseErrors } from 'helpers';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import Sortable from 'components/Sortable';
import Modal from 'components/Modal';
import PromptUnsaved from 'components/Prompt/Unsaved';
import Loader from 'components/Loader';
import FormButton from 'components/FormButton';
import Pagination from 'components/Pagination';

import UserEditPartial from './partials/UserEditPartial';
import UserManagementPartial from './partials/UserManagementPartial';

type Props = {};

@observer
@withRouter
export default class UsersPage extends React.Component<Props> {
    @observable newUser: User;
    @observable users: ?(User[]);

    usersMeta: { current_page: number, last_page: number, total: number };
    filters: Object = {
        search: '',
        place_ids: [],
    };

    @observable places: Place[];
    @observable indexedPlaces: { [id: number]: Place };

    @observable creating: boolean = false;
    newUserCounter: number = 1;

    get page(): number {
        return (this.usersMeta || {}).current_page || 1;
    }

    get queryParams(): Object {
        const params: Object = { page: this.page, per_page: 25 };

        const { search, place_ids } = this.filters;
        if (search.length) params.search = search;
        if (userInfoStore.isPlaceManager && !place_ids[0]) params.place_ids = userInfoStore.placesIds;
        if (place_ids.length && place_ids[0]) params.place_ids = place_ids;

        return params;
    }

    @action
    resetNewUser() {
        this.newUser = new User();
        this.newUserCounter++;
    }

    @action
    setUsers(users: ?(User[])) {
        this.users = users;
    }

    @action
    setPlaces(places: Place[]) {
        this.places = places;

        const indexedPlaces = {};
        this.places.forEach(place => (indexedPlaces[place.id] = place));
        this.indexedPlaces = indexedPlaces;
        if (userInfoStore.isPlaceManager) this.places = userInfoStore.places;
    }

    @action
    setCreating(creating: boolean) {
        this.creating = creating;
    }

    @action
    async loadUsers() {
        if (this.users) this.setUsers(null);

        const queryParams = this.queryParams;
        const options = {
            queryParams,
            callback: response => {
                const { data, meta } = response.data;
                this.usersMeta = meta;
                return { data };
            },
        };

        // Force cache clearence due to Premiere issue - it doesn't go through callback twice
        User.reflector.store.cache.lists.clear();

        const users = await organizationStore.organization.users(options);
        if (JSON.stringify(queryParams) !== JSON.stringify(this.queryParams)) return;

        this.setUsers(users);
        scrollIntoView(document.getElementById('omni-search'));
    }

    async loadPlaces() {
        this.setPlaces(await organizationStore.organization.places());
    }

    load = async () => {
        this.resetNewUser();
        if (userInfoStore.isPlaceManager) this.filters.place_ids = userInfoStore.placesIds;

        await Promise.all([this.loadUsers(), this.loadPlaces()]);
    };

    handlePageChange = (_: Event, data: { activePage: number }) => {
        this.usersMeta.current_page = data.activePage;
        this.loadUsers();
    };

    handleCreate = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.newUser.set({
            organization_id: organizationStore.organization.id,
        });
        this.setCreating(true);

        try {
            await this.newUser.saveChanges({ with: ['places'] });
            toast.success(`User ${this.newUser.name} created`);

            this.resetNewUser();
            await this.loadUsers();
        } catch (error) {
            toast.error(httpResponseErrors(error));
        } finally {
            this.setCreating(false);
        }
    };

    handleDeleteUser = async (user: User) => {
        await user.destroy();
        this.loadUsers();
        toast.success(`User ${user.name} deleted`);
    };

    handleSearch = (value: string) => {
        this.usersMeta.current_page = 1;
        this.filters.search = value;
        this.loadUsers();
    };

    handleSelectLocation = () => {
        this.usersMeta.current_page = 1;
        this.loadUsers();
    };

    shouldRenderActions = (user: User) => {
        if (userInfoStore.isPlaceManager) return user.role !== 'organization-manager' && user.role !== 'system-admin';
        return true;
    };

    render() {
        return <Loader onLoad={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        const hasPlaces = !!this.places.length;
        const filterPlaces = userInfoStore.isPlaceManager ? userInfoStore.places : this.places;
        const locationFilter = (
            <Form
                for={this.filters}
                schema={{
                    place_ids: {
                        type: 'Place',
                        search: true,
                        text: 'Filter by Location',
                        icon: 'filter',
                        basic: true,
                        floating: true,
                        labeled: true,
                        button: true,
                        inlineLabel: true,
                        className: 'icon custom',
                    },
                }}
                onChange={this.handleSelectLocation}
                className="ui form tiny"
            >
                <Field
                    name="place_ids"
                    places={[new Place().set({ name: 'All locations', id: null }), ...filterPlaces]}
                />
            </Form>
        );

        return (
            <Page headerTitle headerActions={locationFilter} onSearch={this.handleSearch}>
                <PromptUnsaved for={this.newUser} />

                <Column position="left" aligned>
                    <SettingsPanel title="Add New User">
                        {hasPlaces && (
                            <Form
                                key={this.newUserCounter}
                                for={this.newUser}
                                onSubmit={this.handleCreate}
                                className="ui form tiny"
                            >
                                <Field name="name" />

                                <SemanticForm.Group widths="two">
                                    <Field
                                        id="emailInput"
                                        readOnly={true}
                                        onFocus={() => {
                                            document.getElementById('emailInput').readOnly = false;
                                        }}
                                        name="email"
                                    />
                                    <Field name="phone" />
                                </SemanticForm.Group>

                                <SemanticForm.Group widths="two">
                                    <Field name="password" />
                                    <Field name="password_confirmation" />
                                </SemanticForm.Group>

                                <SemanticForm.Group widths="two">
                                    <Field name="role" />
                                    <Field name="external_id" />
                                </SemanticForm.Group>

                                <Field name="places" places={this.places} label="Quick Add Locations Access:" />
                                {/* <Field name="open_user_management" type="switch" /> */}

                                <SemanticForm.Field>
                                    <FormButton
                                        type="submit"
                                        className="right floated"
                                        loading={this.creating}
                                        icon="plus"
                                        text="Add User"
                                        primary
                                    />
                                </SemanticForm.Field>
                            </Form>
                        )}
                        {!hasPlaces && 'Users cannot be added before a location is created'}
                    </SettingsPanel>
                </Column>

                <Column position="right">
                    <Sortable
                        className="ui basic sortable-table nested shadowed table padded wrap-words unstackable scrollable custom small"
                        data={this.users || []}
                        renderAdjacent={user =>
                            user.places.map(placeId => (
                                <div key={placeId} className="ui label" style={{ float: 'left', marginBottom: '4px' }}>
                                    {(this.indexedPlaces[placeId] || {}).name}
                                </div>
                            ))
                        }
                    >
                        <Sortable.Column
                            title="Name"
                            property="name"
                            render={user => (
                                <React.Fragment>
                                    <span>{user.name} </span>
                                    {user.external_id && (
                                        <Label size="mini" basic>{`external id: ${user.external_id}`}</Label>
                                    )}
                                </React.Fragment>
                            )}
                        />
                        <Sortable.Column title="E-mail" property="email" />
                        <Sortable.Column title="User Role" property="roleName" />

                        <Sortable.Column
                            title="Action(s)"
                            render={user =>
                                this.shouldRenderActions(user) ? (
                                    <React.Fragment>
                                        <Modal.Confirm
                                            className="custom"
                                            title="Delete User"
                                            buttonText="Delete User"
                                            text={`Are you sure you want to delete ${user.name}?`}
                                            onConfirm={() => this.handleDeleteUser(user)}
                                            trigger={<Sortable.Action title="Delete" icon="trash" />}
                                        />

                                        <UserManagementPartial places={this.places} user={user} />
                                        <UserEditPartial user={user} />
                                    </React.Fragment>
                                ) : null
                            }
                        />
                    </Sortable>

                    <Pagination
                        className="custom top-spacer"
                        activePage={this.usersMeta.current_page}
                        totalPages={this.usersMeta.last_page}
                        total={this.usersMeta.total}
                        onPageChange={this.handlePageChange}
                        loading={!this.users}
                        compact
                    />
                </Column>
            </Page>
        );
    };
}
