// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Icon, Form } from 'semantic-ui-react';

type Props = {};

@observer
export default class InlineLoader extends React.Component<Props> {
    render() {
        return (
            <Form.Group>
                <Icon name="spinner" loading />
                <span>Loading...</span>
            </Form.Group>
        );
    }
}
