// @flow

import { action, computed, observable } from 'mobx';
import queryString from 'query-string';

import Place from 'models/Organization';

import sidebarStore from 'stores/sidebar';

const PLACE_STORAGE = 'selectedPlaceId';

class PlaceStore {
    @observable place: Place;
    @observable places: Place[];

    get storagePlace(): ?Place {
        const query = queryString.parse(window.location.search);
        const value = query.placeId || localStorage.getItem(PLACE_STORAGE);

        if (!this.places) {
            return null;
        }

        return value ? this.places.find(place => place.id === parseInt(value, 10)) : null;
    }

    @action
    select(place: Place) {
        this.place = place;
        localStorage.setItem(PLACE_STORAGE, place.id.toString());

        sidebarStore.close();
    }

    @action
    selectDefault() {
        if ((this.place && this.places.includes(this.place)) || !this.places) return;

        const place = this.storagePlace || (this.places.length && this.places[0]);
        if (place) this.select(place);
    }

    @action
    set(places: Place[]) {
        this.places = places;
        this.selectDefault();
    }

    @action
    add(place: Place) {
        this.places.push(place);
    }

    @action
    remove(place: Place) {
        // $FlowFixMe - MobX observable array
        this.places.remove(place);
    }

    @action
    clear() {
        // $FlowFixMe
        this.places = null;
        // $FlowFixMe
        this.place = null;
    }

    @action
    updateFeatures(features) {
        this.place.features = features;
    }

    @computed
    get sortedPlaces(): Place[] {
        return this.places.slice().sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
    }
}

export default new PlaceStore();
