// @flow
import React from 'react';

type Props = {
    chart: Object,
};

export default class CustomLegend extends React.Component<Props> {
    handleLabelClick(target: Object, index: number) {
        const chart = this.props.chart.chartInstance;
        const meta = chart.getDatasetMeta(0);

        if (meta.data[index].hidden) {
            meta.data[index].hidden = null;
            target.classList.remove('hidden');
        } else {
            meta.data[index].hidden = true;
            target.classList.add('hidden');
        }

        chart.update();
    }

    getLabelFromChart() {
        if (undefined === this.props.chart) {
            return [];
        }

        const instance = this.props.chart.chartInstance;
        const meta = instance.getDatasetMeta(0);

        return meta.data.map(item => {
            return item._view.label;
        });
    }

    getLabelColorFromChart(index: number) {
        if (undefined === this.props.chart) {
            return [];
        }

        const instance = this.props.chart.chartInstance;
        const meta = instance.getDatasetMeta(0);

        return meta.data[index]._view.backgroundColor;
    }

    render() {
        return (
            <ul className="custom-legend">
                {this.getLabelFromChart().map((label, i) => (
                    <li key={i} onClick={e => this.handleLabelClick(e.currentTarget, i)}>
                        <span style={{ backgroundColor: this.getLabelColorFromChart(i) }} />
                        {label}
                    </li>
                ))}
            </ul>
        );
    }
}
