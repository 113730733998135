// @flow
import { action, observable } from 'mobx';

import placeStore from 'stores/place';
import InviteAnalytics from 'models/InviteAnalytics';
import Filters from 'models/Filters';
import axiosStore from 'stores/axios';
import * as Sentry from '@sentry/react';

class InviteAnalyticsStore {
    @observable inviteAnalytics: ?InviteAnalytics;
    @observable filters: ?Filters;
    @observable loading: boolean = false;

    @action
    setInviteAnalytics(inviteAnalytics: InviteAnalytics) {
        this.inviteAnalytics = inviteAnalytics;
    }

    @action
    resetState() {
        this.inviteAnalytics = null;
    }

    get queryParams() {
        const params = {};

        if (this.filters && undefined !== this.filters.startDate) {
            params.start_date = this.filters.startDate;
        }

        if (this.filters && undefined !== this.filters.endDate) {
            params.end_date = this.filters.endDate;
        }

        if (this.filters && undefined !== this.filters.getFilterValue('invite_source', 'automated')) {
            params.automated = this.filters.getFilterValue('invite_source', 'automated');
        }

        if (this.filters && undefined !== this.filters.getFilterValue('invite_source', 'manual')) {
            params.manual = this.filters.getFilterValue('invite_source', 'manual');
        }

        if (this.filters && undefined !== this.filters.getFilterValue('delivery_method', 'email')) {
            params.email = this.filters.getFilterValue('delivery_method', 'email');
        }

        if (this.filters && undefined !== this.filters.getFilterValue('delivery_method', 'text')) {
            params.text = this.filters.getFilterValue('delivery_method', 'text');
        }

        if (this.filters && undefined !== this.filters.getFilterValue('department', 'sales')) {
            params.sales = this.filters.getFilterValue('department', 'sales');
        }

        if (this.filters && undefined !== this.filters.getFilterValue('department', 'service')) {
            params.service = this.filters.getFilterValue('department', 'service');
        }

        return params;
    }

    @action
    startLoading() {
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
    }

    @action.bound
    // eslint-disable-next-line no-unused-vars
    async load(ignoreCache: ?boolean = false) {
        this.startLoading();

        if (this.inviteAnalytics) this.resetState();

        const params = `email=${this.queryParams.email}&automated=${this.queryParams.automated}&manual=${this.queryParams.manual}&sales=${this.queryParams.sales}&service=${this.queryParams.service}&text=${this.queryParams.text}&start_date=${this.queryParams.start_date}&end_date=${this.queryParams.end_date}`;

        const response = await axiosStore.axiosApi
            .get(`/analytics/${placeStore.place.id}/invites?${params}`)
            .catch(error => {
                console.error(error);
                Sentry.captureException(error);
            });
        const invitesAnalytics = await response.data;

        this.setInviteAnalytics(invitesAnalytics);

        // this.setInviteAnalytics(
        //     await placeStore.place.inviteAnalytics({
        //         ignoreCache,
        //         queryParams: this.queryParams,
        //     })
        // );

        this.stopLoading();
    }

    @action
    async filter(filters: Filters) {
        if (this.filters) this.filters = undefined;
        this.filters = filters;
        await this.load(true);
    }
}

export default new InviteAnalyticsStore();
