// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';
import Dropzone, { DropEvent, DropzoneRef } from 'react-dropzone';
import proxyS3Image from 'helpers/proxyS3Image';
import ImageFieldPreview from '../Image/Preview';
import * as mediaType from 'enum/mediaType';
import VideoFieldPreview from 'components/VideoFieldPreview';
import SubmissionMedia from 'photo/components/Submission/Media';
import ContentCustomer from 'models/ContentCustomer';
import { Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';

type Props = ComponentProps & {
    text: string,
    onClick: ?Function,
    onDrop: ?Function,
    dropzoneRef: ?Function,
    mediaType: ?string,
    accept: ?string,
    product?: boolean,
    disabled?: boolean,
    helpText?: string,
};

type State = {
    values: Array<Object>,
};

@observer
export default class SelectMediaField extends React.Component<Props, State> {
    input: HTMLElement;
    dropzoneRef: { current: null | DropzoneRef };

    constructor(props: Props) {
        super(props);
        let values: Array<Object> = [];

        this.dropzoneRef = React.createRef();
        this.state = {
            values,
        };
    }

    componentDidMount() {
        this.props.onMount(this.input);

        this.props.dropzoneRef && this.props.dropzoneRef(this.dropzoneRef);
    }

    onClick = (event: Event) => {
        this.props.onClick && this.props.onClick(event);
    };

    onDrop = (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => {
        let newArray: File[] = acceptedFiles.filter(item =>
            item.type.includes(this.props.mediaType === 'photo' ? 'image' : 'video')
        );
        if (this.props.mediaType === 'video' && newArray.length > 1) {
            const firstVideo: File = newArray[0];
            newArray = [];
            newArray.push(firstVideo);
            toast.warn('Maximum of one (1) video is allowed.');
        } else if (newArray.length < acceptedFiles.length) {
            toast.warn('Some files are not supported so they are removed from the selection.');
        }
        this.props.onDrop && this.props.onDrop(newArray, rejectedFiles, event);
        const values = this.state.values;
        const newValues = [];

        newArray.forEach(f => newValues.push({ file: f, product: !!this.props.product }));

        this.setState({
            values: values.concat(newValues),
        });

        this.props.onChange(event, values.concat(newValues));
    };

    handleRemoveElement = (index: number, event: Event) => {
        event.preventDefault();

        const values = this.state.values;
        values.splice(index, 1);

        this.setState({
            values,
        });
        this.props.onChange(null, values);
    };

    open() {
        this.dropzoneRef.current && this.dropzoneRef.current.open();
    }

    render() {
        const value = this.props.value;

        const rootProps = {
            onClick: this.onClick,
            style: value && value.length ? { display: 'none' } : null,
        };

        return (
            <div className="media-item clearfix">
                <div className="media-item-wrapper clearfix">
                    {this.props.helpText && (
                        <Message className="custom" info color="green">
                            {this.props.helpText}
                        </Message>
                    )}

                    <Dropzone ref={this.dropzoneRef} onDrop={this.onDrop} noDrag accept={this.props.accept}>
                        {({ getRootProps, getInputProps }) => (
                            <div className="image-holder upload-image rectangle" {...getRootProps(rootProps)}>
                                <input {...getInputProps()} />
                                <div className="label-wrapper">
                                    <div className="text">{this.props.text}</div>
                                </div>
                            </div>
                        )}
                    </Dropzone>

                    {mediaType.PHOTO === this.props.mediaType &&
                        value &&
                        value.map((v, i) => (
                            <SubmissionMedia
                                key={`image_${i}`}
                                mediaType={mediaType.PHOTO}
                                consented
                                product={v.product}
                                disabled={this.props.disabled}
                                onShare={(customer: ContentCustomer) => (v.customer = customer)}
                                customer={v.customer}
                                onRemoveClick={(event: Event) => this.handleRemoveElement(i, event)}
                            >
                                <ImageFieldPreview key={i} title="Preview" value={proxyS3Image(v.file)} />
                            </SubmissionMedia>
                        ))}
                    {mediaType.VIDEO === this.props.mediaType &&
                        value &&
                        value.map((v, i) => (
                            <SubmissionMedia
                                key={`video_${i}`}
                                mediaType={mediaType.VIDEO}
                                consented
                                product={v.product}
                                disabled={this.props.disabled}
                                onRemoveClick={(event: Event) => this.handleRemoveElement(i, event)}
                            >
                                <VideoFieldPreview key={i} value={proxyS3Image(v.file)} />
                            </SubmissionMedia>
                        ))}
                </div>
            </div>
        );
    }
}
