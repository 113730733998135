// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import Panel from 'components/Panel';

import VideoSort from 'video/components/Video/Sort';
import VideoCardList from 'video/components/Video/Card/List';

type Props = {};

@observer
export default class VideosPartial extends React.Component<Props> {
    render() {
        return (
            <Panel title="Video(s)" position="left" dropdown={<VideoSort />}>
                <VideoCardList ellipsisBreakpoint={20} />
            </Panel>
        );
    }
}
