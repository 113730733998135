// @flow

import * as React from 'react';
import MediaQuery from 'react-responsive';

const MediaQueryDesktop = (props: { children: React.Node }) => (
    <MediaQuery minWidth={1024}>{props.children}</MediaQuery>
);

export default MediaQueryDesktop;
