// @flow

import * as React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter as Router } from 'react-router-dom';

import ToastContainer from 'components/ToastContainer';
import MainRouter from './MainRouter';

type Props = {};

@observer
export default class Main extends React.Component<Props> {
    render() {
        return (
            <React.Fragment>
                <ToastContainer />

                <Router>
                    <MainRouter />
                </Router>
            </React.Fragment>
        );
    }
}
