// @flow

import { action, observable } from 'mobx';
import Model from './Model';

type Filter = {
    name: string,
    label: string,
    value: boolean,
};

export default class FilterGroup extends Model {
    @observable
    name: string;

    @observable
    alias: string;

    @observable
    label: string;

    @observable
    filters: Filter[];

    @action
    addFilter(name: string, label: string, value?: boolean = false) {
        if (!this.filters) this.filters = [];

        this.filters.push({
            name,
            label,
            value,
        });

        return this;
    }

    @action
    setFilterValue(filter: string, value: boolean) {
        const f = this.filters.find(f => f.name === filter);

        if (f) {
            f.value = value;
        }
    }

    @action.bound
    resetFilters() {
        this.filters.forEach(f => (f.value = true));
    }

    getFilterValue(filter: string): ?boolean {
        const f = this.filters.find(f => f.name === filter);

        if (!f) return;

        return f.value;
    }

    getFilter(filter: string): ?Filter {
        const f = this.filters.find(f => f.name === filter);

        if (!f) return;

        return f;
    }

    toObject(): Object {
        const obj = {};

        this.filters.forEach(filter => {
            obj[filter.name] = filter.value;
        });

        return obj;
    }

    fromObject(object: Object) {
        Object.keys(object).forEach(filterName => {
            this.setFilterValue(filterName, object[filterName]);
        });
    }

    get canRemoveFilter(): boolean {
        return this.filters.filter(f => f.value).length > 1;
    }

    get allChecked(): boolean {
        return this.filters.filter(f => f.value).length === this.filters.length;
    }
}
