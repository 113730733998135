// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Modal as SemanticModal, Icon } from 'semantic-ui-react';

import ModalAction from './Action';
import ModalConfirm from './Confirm';

type Props = {
    title: React.Node,
    icon?: string,
    size?: string,
    trigger: React.Node,
    children?: React.Node,
    render?: Function,
};

type State = {
    open: boolean,
};

@observer
export default class Modal extends React.Component<Props, State> {
    state = { open: false };
    hasUnmounted: boolean = false;

    static Action = ModalAction;
    static Confirm = ModalConfirm;
    static Actions = SemanticModal.Actions;
    static Content = SemanticModal.Content;

    handleOpen = () => this.setState({ open: true });

    handleClose = () => {
        if (this.hasUnmounted) return;
        this.setState({ open: false });
    };

    componentWillUnmount() {
        this.hasUnmounted = true;
    }

    render() {
        const { title, icon, trigger, children, render, ...rest } = this.props;
        const { open } = this.state;

        const content = children
            ? children
            : render
            ? render({ onOpen: this.handleOpen, onClose: this.handleClose })
            : null;

        return (
            <SemanticModal
                className="custom"
                trigger={trigger}
                open={open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                {...rest}
            >
                <SemanticModal.Header>
                    {icon && <Icon name={icon} />} {title}
                </SemanticModal.Header>

                {content}
            </SemanticModal>
        );
    }
}
