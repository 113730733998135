// @flow

import { action, observable } from 'mobx';
import { field } from 'app/external/form-for';
import Model from 'models/Model';

export default class Profile extends Model {
    path = 'profiles';

    id: ?number;
    @observable
    @field
    channel: string;

    @observable
    @field({ error: 'validateURL' })
    url: string;

    @observable
    @field({ type: 'switch' })
    review_invite: boolean;

    combine_id: ?string;
    combine_synced_at: ?string;
    identifier: string;
    name: string;
    nickname: string;
    place_id: number;
    is_custom: boolean;
    invite_only: boolean;

    // this is not an actual field to be stored, this is just for
    // google profile, so the API can differentiate the profile
    // creation from a google auth from an url only
    url_only: boolean;

    created_at: string;
    deleted_at: ?string;
    updated_at: string;

    @action
    turnOffReviewInvite() {
        this.review_invite = false;
    }

    normalize_review_invite(review_invite: number): boolean {
        return !!review_invite;
    }

    denormalize_review_invite(review_invite: boolean): string {
        return review_invite ? '1' : '0';
    }

    denormalize_url(url: string): ?string {
        const protocolRegex = /^(https:\/\/)|(http:\/\/)/;
        if ((url || '').match(protocolRegex)) return url;
        if (url) return `http://${url}`;
        return undefined;
    }

    validateURL() {
        const urlRegex = /\.[a-zA-Z]{2,6}/;
        if (this.url && !this.url.match(urlRegex)) return 'Please enter a URL.';
    }

    async persist(): Promise<*> {
        if (this.url.length) {
            const newObject = {
                ...this.mapChanged,
                place_id: this.place_id,
                channel: this.channel,
            };

            const newProfile = await (this.id ? Profile.update(this.id, newObject) : Profile.create(newObject));
            this.key = newProfile.key;
        } else {
            await this.destroy();
            this.id = null;
            this.turnOffReviewInvite();
        }
        this.memorizeChanges();
    }

    static getCreateErrorMessage(values: Object) {
        return `Failed to create profile with url: ${values.url}`;
    }

    static getUpdateErrorMessage(key: any, values: Object) {
        return `Failed to update profile to url ${values.url}`;
    }
}
