// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import InviteAnalytics from 'models/InviteAnalytics';
import DataPlaceholder from 'components/Data/Placeholder';
import CustomLegend from 'components/CustomLegend';

type Props = {
    inviteAnalytics: InviteAnalytics,
};

type State = {
    chart: Object,
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    radiusBackground: {
        color: '#fff',
    },
    cutoutPercentage: 70,
    legend: {
        display: false,
    },
    animation: {
        animateScale: false,
    },
    layout: {
        padding: {
            left: 30,
            right: 30,
            top: 30,
            bottom: 30,
        },
    },
    plugins: {
        datalabels: {
            align: 'end',
            anchor: 'end',
            font: function() {
                return {
                    size: 16,
                };
            },
            formatter: function(value, context) {
                return context.chart.data[context.dataIndex];
            },
        },
    },
};

@observer
export default class NoResponse extends React.Component<Props, State> {
    chart: { current: null | HTMLDivElement };

    constructor(props: Props) {
        super(props);
        this.state = { chart: null };
        this.chart = React.createRef();
    }

    componentDidMount() {
        const chart = this.chart.current;

        this.setState({
            chart: chart,
        });
    }

    getData() {
        const { inviteAnalytics } = this.props;
        const resultsNo = inviteAnalytics.charts.wyru_results_no;

        return {
            labels: ['No Further Action', 'Left Feedback'],
            datasets: [
                {
                    data: [resultsNo.no_further_action, resultsNo.left_feedback],
                    borderWidth: 0,
                    backgroundColor: ['rgba(204, 204, 204, 0.8)', 'rgba(171, 228, 106, 0.8)'],
                    hoverBackgroundColor: ['rgba(204, 204, 204, 1)', 'rgba(171, 228, 106, 1)'],
                },
            ],
        };
    }

    get hasData() {
        const { inviteAnalytics } = this.props;

        if (!inviteAnalytics) {
            return false;
        }

        return inviteAnalytics.charts.wyru_results_no && 0 < inviteAnalytics.charts.wyru_results_no.total;
    }

    render() {
        return (
            <DataPlaceholder for={this.hasData}>
                <div className="chart-title">No Response Further Actions</div>
                <div className="chart">
                    <Doughnut data={this.getData()} options={options} ref={this.chart} />
                </div>
                {this.state.chart && <CustomLegend chart={this.state.chart} />}
            </DataPlaceholder>
        );
    }
}
