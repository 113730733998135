// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';

import FormButton from 'components/FormButton';

type Props = {
    type?: string,
    icon: string,
    text: string,
    link?: string,
    saving?: boolean,
};

@observer
export default class ActionButton extends React.Component<Props> {
    render() {
        const { link, ...props } = this.props;

        if (link) {
            // $FlowFixMe
            props.as = Link;
            // $FlowFixMe
            props.to = this.props.link;
        }

        return <FormButton inverted basic circular className="custom outline option" {...props} />;
    }
}
