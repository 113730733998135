// @flow

import Model from './Model';

export default class ViewMetric extends Model {
    path = 'view-metrics';

    total_videos: number;
    total_shares: number;
    total_unique_views: number;

    charts: {
        unique_views: Object,
    };
}
