// @flow

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Icon, Label, Message } from 'semantic-ui-react';
import reviewStore from 'reputation/stores/review';
import placeStore from 'stores/place';
import userInfoStore from 'stores/userInfo';

import SiteInfo, { type SitesInfoByChannel } from 'models/SiteInfo';
import Profile from 'models/Profile';

import { filterUnsafeHtml, formatReviewSitesUrl, toast } from 'helpers';

import Page from 'components/Page';
import PlaceMonitor from 'components/Place/Monitor';
import Column from 'components/Column';
import Panel from 'components/Panel';
import PanelSelected from 'components/Panel/Selected';
import MediaQueryDesktop from 'components/MediaQuery/Desktop';
import Rating from 'components/Rating';
import InlineLoader from 'components/InlineLoader';

import ReviewCardList from 'reputation/components/Review/Card/List';
import UploadInviteFilePartial from 'reputation/components/UploadInviteFile';
import SelectedReviewPartial from './partials/SelectedReviewPartial';

import SendManualInvite from 'reputation/components/SendManualInvite';
import Filters from 'models/Filters';
import FiltersPopup from 'components/Filters/Popup';
import FiltersBar from 'components/Filters/Bar';
import TriggerDownload from 'components/TriggerDownload';
import ActionButton from 'components/ActionButton';

type Props = {};

@observer
export default class ResponseConsolePage extends React.Component<Props> {
    sitesInfoByChannel: SitesInfoByChannel;
    @observable profiles: ?(Profile[]);
    @observable filters: Filters;
    @observable submitLoading: false;
    @observable responseSuccess: Boolean = false;

    async loadSitesInfoByChannel() {
        this.sitesInfoByChannel = await SiteInfo.allByChannel();
    }

    @action
    setProfiles(profiles: ?(Profile[])) {
        this.profiles = profiles;
    }

    @action
    setFilters(filters: Filters) {
        this.filters = filters;
    }
    @action
    setResponseSuccess(responseSuccess: Boolean) {
        this.responseSuccess = responseSuccess;
    }
    @action
    setSubmitLoading(submitLoading: Boolean) {
        this.submitLoading = submitLoading;
    }

    async loadProfiles() {
        this.setProfiles(await placeStore.place.profiles());
    }

    load = async () => {
        this.setFilters(this.buildFilters());
        await Promise.all([reviewStore.filter(this.filters), this.loadSitesInfoByChannel(), this.loadProfiles()]);
    };

    buildFilters(): Filters {
        const filters = new Filters();

        filters
            .addFilterGroup('reputation_site', 'Reputation Site', 'All Reputation Sites')
            .addFilter('googleplace', 'Google', true)
            .addFilter('carscom', 'Cars.com', true)
            .addFilter('yelp', 'Yelp', true)
            .addFilter('cargurus', 'CarGurus', true)
            .addFilter('dealerrater', 'DealerRater', true)
            .addFilter('facebook', 'Facebook', true)
            .addFilter('carfax', 'Carfax', true);

        filters
            .addFilterGroup('rating', 'Rating', 'All Ratings')
            .addFilter('five_stars', 'Five Stars', true)
            .addFilter('four_stars', 'Four Stars', true)
            .addFilter('three_stars', 'Three Stars', true)
            .addFilter('two_stars', 'Two Stars', true)
            .addFilter('one_star', 'One Star', true);

        filters
            .addFilterGroup('response', 'Response', 'All Response Status')
            .addFilter('with_response', 'With Response', true)
            .addFilter('without_response', 'Without Response', true);

        return filters;
    }

    starLabels(quantity: number) {
        return (
            <label className="popup-star-labels">
                <span className="stars">
                    {[...Array(5)].map((elelemt, index) => {
                        return (
                            <i
                                key={`${quantity}-${index}`}
                                className={`star ${index < quantity ? 'active' : ''} icon`}
                            />
                        );
                    })}
                </span>
            </label>
        );
    }

    get popupRatingLabels() {
        return {
            one_star: this.starLabels(1),
            two_stars: this.starLabels(2),
            three_stars: this.starLabels(3),
            four_stars: this.starLabels(4),
            five_stars: this.starLabels(5),
        };
    }

    get selectedSiteInfo(): Object {
        if (!reviewStore.review) return {};

        const { channel } = reviewStore.review;

        if (this.sitesInfoByChannel[channel]) {
            return this.sitesInfoByChannel[channel];
        }

        return { display_name: channel, icon: 'car' };
    }

    get selectedSiteUrl(): string {
        const { channel } = this.selectedSiteInfo;
        const profile =
            this.profiles &&
            this.profiles.find(profile => {
                return profile.channel === channel;
            });
        if (!profile) return '';
        return formatReviewSitesUrl(profile, placeStore.place, this.selectedSiteInfo);
    }

    get actions() {
        return (
            <React.Fragment>
                <SendManualInvite trigger={<ActionButton icon="send" text="Send Real Time Invite" />} />
                {userInfoStore.isManager && (
                    <UploadInviteFilePartial trigger={<ActionButton icon="cloud upload" text="Upload invite file" />} />
                )}
                <TriggerDownload filename="ra_console_export.xlsx" link={reviewStore.getExportLink(this.filters)}>
                    {(handleOnClick, saving) => (
                        <ActionButton
                            onClick={handleOnClick}
                            text="Export XLSX"
                            icon="file excel outline"
                            saving={saving}
                            link={reviewStore.getExportLink(this.filters)}
                        />
                    )}
                </TriggerDownload>
                <FiltersPopup
                    onChange={this.handleFiltering}
                    onReset={this.handleResetFilters}
                    filters={this.filters}
                    availableFilterGroups={['reputation_site', 'rating', 'response']}
                    labels={{ rating: this.popupRatingLabels }}
                    trigger={<ActionButton icon="filter" text="Filters" />}
                />
            </React.Fragment>
        );
    }

    get mobileActions() {
        return (
            <React.Fragment>
                <SendManualInvite
                    trigger={
                        <Button className="option-btn">
                            <Icon name="send" /> Send Real Time Invite
                        </Button>
                    }
                />
                {userInfoStore.isManager && (
                    <UploadInviteFilePartial
                        trigger={
                            <Button className="option-btn">
                                <Icon name="cloud upload" /> Upload invite file
                            </Button>
                        }
                    />
                )}
                <TriggerDownload filename="ra_console_export.xlsx" link={reviewStore.getExportLink(this.filters)}>
                    {(handleOnClick, saving) => (
                        <Button className="option-btn" onClick={handleOnClick} loading={saving}>
                            <Icon name="file excel outline" /> Export XLSX
                        </Button>
                    )}
                </TriggerDownload>
            </React.Fragment>
        );
    }

    get mobileFiltersBar(): React.Node {
        return (
            <FiltersBar
                filters={this.filters}
                onClick={this.handleFiltering}
                availableFilterGroups={['reputation_site', 'rating', 'response']}
                withPopup={
                    <FiltersPopup
                        onChange={this.handleFiltering}
                        onReset={this.handleResetFilters}
                        filters={this.filters}
                        availableFilterGroups={['reputation_site', 'rating', 'response']}
                        labels={{ rating: this.popupRatingLabels }}
                        trigger={
                            <Label className="tags-field label-btn no-icon clickable">
                                <Icon name="filter" /> Filters
                            </Label>
                        }
                    />
                }
            />
        );
    }
    handleResponseText = async e => {
        e.preventDefault();
        this.setSubmitLoading(true);
        if (reviewStore.responseText) {
            const responce = await reviewStore.response(reviewStore.review.id, reviewStore.responseText);
            if (responce) {
                this.setResponseSuccess(true);
                reviewStore.review.isRespondable = false;
            } else {
                this.setResponseSuccess(false);
            }
            this.setSubmitLoading(false);
            reviewStore.setResponseText('');
        }
    };
    handleResponseReview = () => {
        if (
            !placeStore.place.features.review_response &&
            placeStore.place.id !== 3634 &&
            placeStore.place.id !== 1357
        ) {
            return true;
        }
        return false;
    };
    handleResponseReviewAvailability = () => {
        if (
            !placeStore.place.features.review_response &&
            placeStore.place.id !== 3634 &&
            placeStore.place.id !== 1357
        ) {
            toast.warning(
                'Response feature not enabled; please contact your Customer Service Representative to enable this feature.'
            );
        }
    };
    handleFiltering = () => {
        reviewStore.filter(this.filters);
    };

    handleResetFilters = () => {
        this.setFilters(this.buildFilters());
    };

    componentWillUnmount() {
        reviewStore.filters = undefined;
    }

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        const { review, totalReviews, loading } = reviewStore;
        const beforeInner = reviewStore.loading ? null : (
            <MediaQueryDesktop>
                <FiltersBar
                    filters={this.filters}
                    onClick={this.handleFiltering}
                    availableFilterGroups={['reputation_site', 'rating', 'response']}
                    inLayout
                />
            </MediaQueryDesktop>
        );
        return (
            <Page
                className="three-column-middle-flex clearfix"
                beforeInner={beforeInner}
                actions={this.profiles && this.profiles.length ? this.actions : null}
                mobileActions={this.profiles && this.profiles.length ? this.mobileActions : null}
                mobileFilters={this.mobileFiltersBar}
            >
                {!review && !loading && 'No reviews available'}

                {loading && (
                    <div className="no-data">
                        <InlineLoader />
                    </div>
                )}

                {review && (
                    <React.Fragment>
                        <MediaQueryDesktop>
                            <Column position="left" className="list-items">
                                <Panel title={`${totalReviews} Review${totalReviews !== 1 ? 's' : ''}`}>
                                    <ReviewCardList ellipsisBreakpoint={25} />
                                </Panel>
                            </Column>
                        </MediaQueryDesktop>

                        <Column position="middle">
                            <PanelSelected
                                id="reviews-menu-toggle"
                                title="Review"
                                sidebar="reputation_accelerator.reviews"
                            >
                                <SelectedReviewPartial />
                            </PanelSelected>

                            <Panel title="Selected Review Info" id="selected-review">
                                {reviewStore.reviewLoading ? (
                                    <div id="review-loading">
                                        <InlineLoader />
                                    </div>
                                ) : (
                                    <>
                                        <div className="social-card clearfix">
                                            <a
                                                href={this.selectedSiteUrl}
                                                target={this.selectedSiteUrl ? '_blank' : ''}
                                            >
                                                <div
                                                    className={`card-social-network social ${review.channel} clearfix`}
                                                >
                                                    <Icon name={this.selectedSiteInfo.icon} />{' '}
                                                    {this.selectedSiteInfo.display_name}
                                                    <div className="external-btn">
                                                        View reviews <Icon name="external" />
                                                    </div>
                                                </div>
                                            </a>

                                            <div className="card-title">{review.customer}</div>

                                            {review.rating && (
                                                <React.Fragment>
                                                    <div className="label">Rating</div>
                                                    <Rating
                                                        margin
                                                        showNumberRating
                                                        value={review.rating}
                                                        negative={review.hasNegativeRating ? 'negative' : ''}
                                                    />
                                                </React.Fragment>
                                            )}

                                            {review.comment && (
                                                <React.Fragment>
                                                    <div className="label">Review</div>
                                                    <div
                                                        className="review-container"
                                                        dangerouslySetInnerHTML={{
                                                            __html: filterUnsafeHtml(review.comment),
                                                        }}
                                                    />
                                                </React.Fragment>
                                            )}

                                            {!!(review.responses || []).length && (
                                                <React.Fragment>
                                                    <div className="card-social-network">{'response'}</div>
                                                    {review.responses.map((response, index) => (
                                                        <React.Fragment key={`${response.responder}-${index}`}>
                                                            <div
                                                                className="review-container"
                                                                dangerouslySetInnerHTML={{ __html: response.comment }}
                                                            />
                                                        </React.Fragment>
                                                    ))}
                                                </React.Fragment>
                                            )}
                                            {this.handleResponseReview() ? null : (
                                                <>
                                                    {review.pending_response === 1 && (
                                                        <>
                                                            <div className="card-social-network">
                                                                {'response pending'}
                                                            </div>
                                                            <p>
                                                                {
                                                                    '[A draft response has been submitted and will show here once published.]'
                                                                }
                                                            </p>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {userInfoStore.isPlaceManager ||
                                        userInfoStore.isOrganizationManager ||
                                        userInfoStore.isAdmin ? (
                                            <div id="response-wrapper">
                                                <form onSubmit={this.handleResponseText}>
                                                    <span>Beta</span>
                                                    <div
                                                        className="response-field"
                                                        onClick={this.handleResponseReviewAvailability}
                                                    >
                                                        <textarea
                                                            placeholder="Type a response..."
                                                            onChange={e => reviewStore.setResponseText(e.target.value)}
                                                            value={reviewStore.responseText}
                                                            required
                                                            disabled={
                                                                this.selectedSiteInfo.display_name === 'Yelp' ||
                                                                review.isRespondable === false
                                                                    ? true
                                                                    : false || this.handleResponseReview()
                                                            }
                                                        ></textarea>
                                                        {this.submitLoading ? (
                                                            <div id="response-submit-loader">
                                                                <Icon name="spinner" loading />
                                                            </div>
                                                        ) : (
                                                            <input
                                                                type="submit"
                                                                value={'Submit'}
                                                                disabled={
                                                                    this.selectedSiteInfo.display_name === 'Yelp' ||
                                                                    review.isRespondable === false
                                                                        ? true
                                                                        : false || this.handleResponseReview()
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </Panel>
                        </Column>

                        <Column position="right">
                            <Panel title="Additional Info">
                                <div id="additional-info">
                                    <div className="additional-info">
                                        {(review.time || review.date) && (
                                            <div className="item">
                                                <span className="small-titles">Submitted at:</span>
                                                {review.time && (
                                                    <React.Fragment>
                                                        <span>
                                                            <Icon name="clock outline" /> {review.time}
                                                        </span>{' '}
                                                    </React.Fragment>
                                                )}
                                                {review.date && (
                                                    <span>
                                                        <Icon name="calendar alternate outline" /> {review.date}
                                                    </span>
                                                )}
                                            </div>
                                        )}

                                        {review.hasTitle && (
                                            <div className="item">
                                                <span className="small-titles">Title:</span>
                                                <div>{review.title}</div>
                                            </div>
                                        )}

                                        {review.responded_at && (
                                            <div className="item">
                                                <span className="small-titles">Responded at:</span>
                                                {review.respondedAtTime && (
                                                    <React.Fragment>
                                                        <span>
                                                            <Icon name="clock outline" /> {review.respondedAtTime}
                                                        </span>
                                                    </React.Fragment>
                                                )}
                                                {review.respondedAtDate && (
                                                    <span>
                                                        <Icon name="calendar alternate outline" />
                                                        {review.respondedAtDate}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        {this.handleResponseReview() ? null : (
                                            <>
                                                {userInfoStore.isPlaceManager ||
                                                userInfoStore.isOrganizationManager ||
                                                userInfoStore.isAdmin ? (
                                                    <>
                                                        {this.selectedSiteInfo.display_name === 'Yelp' ? (
                                                            <Message className="custom" info color="green">
                                                                <div className="item">
                                                                    <span className="small-titles">
                                                                        REVIEW RESPONSE NOTES:
                                                                    </span>
                                                                    <p>
                                                                        {
                                                                            'Yelp does not allow response posting; to post a response, go directly to your  location’s Yelp page.'
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </Message>
                                                        ) : (
                                                            <Message className="custom" info color="green">
                                                                <div className="item">
                                                                    <span className="small-titles">
                                                                        REVIEW RESPONSE NOTES:
                                                                    </span>
                                                                    <p>
                                                                        {
                                                                            '1- Responses can take up to 48 hours to publish to third-party review sites'
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {
                                                                            '2- If your response has not posted after 48 hours, please notify your Customer Service Representative'
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </Message>
                                                        )}
                                                    </>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Panel>
                        </Column>
                    </React.Fragment>
                )}
            </Page>
        );
    };
}
