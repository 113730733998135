// @flow

export function toPercentage(value: number, total: number): string {
    if (total === 0) return '-';
    if (value === total) return '100%';

    return ((value * 100) / total).toFixed(1) + '%';
}

export function toName(value: number): string {
    return ['zero', 'one', 'two', 'three', 'four', 'five', 'six'][value];
}
