// @flow

import { action, observable } from 'mobx';
// import { api } from '@friendemic/premiere';
import placeStore from 'stores/place';
import { formatPhoneNumber } from 'helpers';
import axiosStore from 'stores/axios';
import * as Sentry from '@sentry/react';

type Phone = {
    phone: string,
    name?: ?string,
};

type Email = {
    email: string,
    name?: ?string,
};

class NoContactListStore {
    @observable stagedPhones: Phone[];
    @observable stagedEmails: Email[];

    @observable phones: Phone[];
    @observable emails: Email[];

    @action
    setPhones(phones: Phone[]) {
        this.phones = phones;
    }

    @action
    setEmails(emails: Email[]) {
        this.emails = emails;
    }

    @action
    stagePhone(phone: Phone) {
        if (!this.stagedPhones) this.stagedPhones = [];

        this.stagedPhones.push(phone);
    }

    @action
    stageEmail(email: Email) {
        if (!this.stagedEmails) this.stagedEmails = [];

        this.stagedEmails.push(email);
    }

    clearStaged() {
        this.clearStagedPhones();
        this.clearStagedEmails();
    }

    @action
    clearStagedPhones() {
        this.stagedPhones = [];
    }

    @action
    clearStagedEmails() {
        this.stagedEmails = [];
    }

    @action
    unstagePhone(index: number) {
        this.stagedPhones.splice(index, 1);
    }

    @action
    unstageEmail(index: number) {
        this.stagedEmails.splice(index, 1);
    }

    getStagePhone() {
        return this.stagedPhones;
    }

    getStageEmail() {
        return this.stagedEmails;
    }

    get hasStaged(): boolean {
        return (this.stagedPhones && !!this.stagedPhones.length) || (this.stagedEmails && !!this.stagedEmails.length);
    }

    async load() {
        this.clearStaged();
        await Promise.all([this.loadPhones(), this.loadEmails()]);
    }

    async loadPhones() {
        const response = await axiosStore.axiosApi.get(`/unsubscribe/${placeStore.place.id}/phone`).catch(error => {
            console.error(error);
            Sentry.captureException(error);
        });
        // const response = await api.http.get(`/places/${placeStore.place.id}/blacklist-phone`);
        const phones = response.data.map(p => ({ phone: p.phone, name: p.name }));

        this.setPhones(phones);
    }

    async loadEmails() {
        const response = await axiosStore.axiosApi.get(`/unsubscribe/${placeStore.place.id}/email`).catch(error => {
            console.error(error);
            Sentry.captureException(error);
        });
        // const response = await api.http.get(`/places/${placeStore.place.id}/blacklist-email`);
        const emails = response.data.map(e => ({ email: e.email, name: e.name }));

        this.setEmails(emails);
    }

    async saveStagedPhones() {
        const p = this.stagedPhones.map((p: Phone) => {
            // return api.http.post(`/places/${placeStore.place.id}/blacklist-phone`, { data: p }).catch(error => {
            //     error.response.data.value = formatPhoneNumber(p.phone);
            //     throw error;
            // });
            return axiosStore.axiosApi.post(`/unsubscribe/${placeStore.place.id}/phone`, p).catch(error => {
                error.response.data.value = formatPhoneNumber(p.phone);
                throw error;
            });
        });

        await Promise.all(p);

        this.clearStagedPhones();
        this.loadPhones();
    }

    async saveStagedEmails() {
        const e = this.stagedEmails.map((e: Email) => {
            // return api.http.post(`/places/${placeStore.place.id}/blacklist-email`, { data: e }).catch(error => {
            //     error.response.data.value = e.email;
            //     throw error;
            // });
            return axiosStore.axiosApi.post(`/unsubscribe/${placeStore.place.id}/email`, e).catch(error => {
                error.response.data.value = e.email;
                throw error;
            });
        });

        await Promise.all(e);

        this.clearStagedEmails();
        this.loadEmails();
    }

    async saveStaged() {
        await Promise.all([this.saveStagedPhones(), this.saveStagedEmails()]);
    }
}

export default new NoContactListStore();
