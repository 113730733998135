// @flow

import { observable } from 'mobx';
import { field } from 'app/external/form-for';
import Model from 'models/Model';

export default class Password extends Model {
    @observable
    @field({ type: 'email', label: 'Account e-mail', placeholder: 'Your e-mail', required: true })
    email: string;

    @observable
    @field({ type: 'password', label: 'New Password:', error: 'validatePassword' })
    password: string;

    @observable
    @field({ type: 'password', label: 'Confirm New Password:', error: 'validatePasswordConfirmation' })
    password_confirmation: string;

    @observable
    @field
    token: string;

    get hasPassword(): boolean {
        return !!(this.password || '').length;
    }

    get hasPasswordConfirmation(): boolean {
        return !!(this.password_confirmation || '').length;
    }

    validatePasswordConfirmation() {
        const shouldValidate = this.hasPassword || this.hasPasswordConfirmation;
        if (shouldValidate && this.password !== this.password_confirmation)
            return 'Password confirmation does not match';
    }
}
