// @flow

import Profile from 'models/Profile';
import Place from 'models/Place';
import SiteInfo from 'models/SiteInfo';

export default function formatReviewSitesUrl(
    { channel, url }: Profile,
    { slug }: Place,
    { invite_url_example }: SiteInfo
): string {
    if (channel === 'googleplace') return url.replace('writereview', 'reviews');
    if (channel === 'facebook') {
        let [rootUrl, facebookID] = url.split('.com/');
        facebookID = facebookID.split('/')[0];
        return `${rootUrl}.com/${facebookID}/reviews`;
    }
    if (channel === 'dealerrater') {
        if (url.toLowerCase().match(`/dealer/`)) {
            return url;
        }
        let dealerraterID = url.split('writereviews/')[1];
        dealerraterID = (dealerraterID || '').split('?')[0];
        return invite_url_example.replace('Your-Dealership-review-123456', `${slug}-review-${dealerraterID}`);
    }
    return url;
}
