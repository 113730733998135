// @flow

import { observable, action, observe } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import Video from 'models/Video';

import videoStore from 'video/stores/video';
@observer
export default class VideoPlayer extends React.Component<Props> {
    @observable videoSourceURL: ?string;
    disposer: Function;

    @action
    setVideoSourceURL(standaloneUrl: ?string) {
        this.videoSourceURL = standaloneUrl;
    }

    get video(): Video {
        // $FlowFixMe
        return videoStore.video;
    }

    async load() {
        if (this.videoSourceURL) this.setVideoSourceURL(null);
        if (this.video) {
            if (this.video.youtube_id && this.video.youtube_id !== 'pending') {
                let url = await this.video.getYoutubeMp4Path();
                url === 'error' ? this.setVideoSourceURL(this.video.file) : this.setVideoSourceURL(url);
            } else {
                this.setVideoSourceURL(this.video.file);
            }
        }
    }

    componentWillMount() {
        // $FlowFixMe
        this.disposer = observe(videoStore, 'video', () => this.load());
        this.load();
    }

    componentWillUnmount() {
        this.disposer();
    }

    render() {
        const { id, title, file } = this.video;
        if (!this.videoSourceURL) {
            return (
                <div style={{ padding: '20px', color: 'white' }}>
                    <Icon name="circle notched" /> Fetching video info...
                </div>
            );
        } else if (!this.videoSourceURL || !file) {
            return (
                <div style={{ padding: '20px', color: 'white' }}>
                    <Icon name="circle info" /> The video is still being processed, please try again later.
                </div>
            );
        }

        return (
            <video key={id} title={title} controls allowFullScreen>
                <source src={this.videoSourceURL} type="video/mp4" />
            </video>
        );
    }
}
