// @flow

import { observable } from 'mobx';
import Model from './Model';
import { field } from 'app/external/mobx-form-for';

export default class ContentCustomer extends Model {
    path = 'content-customers';

    @observable
    content_story_id: number;

    @observable
    content_photo_id: number;

    @observable
    @field
    name: string;

    @observable
    @field
    phone: string;

    @observable
    @field({ type: 'email', placeholder: 'customer e-mail', required: true })
    email: string;

    @observable
    share_media: boolean = true;

    @observable
    @field({ type: 'switch', label: 'Send Review Invite' })
    send_invite: boolean = false;

    normalize_send_invite(send_invite: string): boolean {
        return send_invite === 1;
    }

    normalize_share_media(share_media: string): boolean {
        return share_media === 1;
    }

    denormalize_send_invite(send_invite: boolean): string {
        return send_invite ? 'true' : 'false';
    }

    denormalize_share_media(share_media: boolean): string {
        return share_media ? 'true' : 'false';
    }

    denormalize_phone(phone: string): string {
        if (!phone) return '';

        return phone.replace(/[^\d]/g, '');
    }
}
