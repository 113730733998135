// @flow

const s3Match = new RegExp('.friendemic.com/|.feedback-invite.com/');
const proxyPath = '/s3/';

export default function proxyS3Image(value: any): any {
    if (!value || typeof value !== 'string') return value;

    const match = value.match(s3Match);
    if (match && match.index !== -1) {
        return `${proxyPath}${value.substring(match.index + match[0].length)}`;
    }

    return value;
}
