// @flow

import { action, observable } from 'mobx';
import { api } from '@friendemic/premiere';
import jwt_decode from 'jwt-decode';

import userInfoStore from 'stores/userInfo';
import placeStore from 'stores/place';
import organizationStore from 'stores/organization';
import axiosStore from 'app/stores/axios/axiosStore';

const TOKEN_STORAGE = 'jwt';

class TokenStore {
    @observable jwtToken: ?string;

    static isExpiredToken(token: string): boolean {
        const jwt = jwt_decode(token);

        const currentTime = new Date().getTime() / 1000;
        const isExpired = currentTime > jwt.exp;

        if (isExpired) localStorage.removeItem(TOKEN_STORAGE);
        return isExpired;
    }

    @action
    async set(token: string) {
        localStorage.setItem(TOKEN_STORAGE, token);

        api.jwtToken = token;
        this.jwtToken = token;
        const errors = await userInfoStore.load().catch(error => {
            this.clear();
            return error;
        });

        if (errors) return;
        axiosStore.set();
        if (userInfoStore.isAdmin) organizationStore.restore();
        else placeStore.set(userInfoStore.places);
    }

    @action.bound
    clear() {
        localStorage.removeItem(TOKEN_STORAGE);
        api.jwtToken = null;
        this.jwtToken = null;

        organizationStore.clear();
        userInfoStore.clear();
    }

    restore() {
        const token = localStorage.getItem(TOKEN_STORAGE);
        if (token && !TokenStore.isExpiredToken(token)) this.set(token);
    }
}

export default new TokenStore();
