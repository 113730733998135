// @flow

import { observer } from 'mobx-react';
import React from 'react';

import GoogleMap from './GoogleMap';

type Props = { geocode: ?{ geometry: { location: Object } } };

@observer
export default class GoogleMapWrapper extends React.Component<Props> {
    get config() {
        const key = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';

        return {
            googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry,drawing,places`,
            loadingElement: <div style={{ height: '100%' }} />,
            containerElement: <div style={{ height: '350px' }} />,
            mapElement: <div style={{ height: '100%' }} />,
        };
    }

    get mapProps() {
        const defaultCoords = {
            center: {
                lat: 39.828348,
                lng: -98.579479,
            },
            zoom: 3.5,
        };
        const props = { coords: defaultCoords, marker: false };

        if (this.props.geocode) {
            // $FLowFixMe
            props.coords = { center: this.props.geocode.geometry.location, zoom: 17 };
            props.marker = true;
        }

        return props;
    }

    render() {
        const props = { ...this.config, ...this.mapProps };

        return <GoogleMap {...props} />;
    }
}
