// @flow

import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import * as React from 'react';
import { Field } from 'app/external/mobx-form-for';

import Place from 'models/Place';

import { toast } from 'helpers';

import Sortable from 'components/Sortable';
import Modal from 'components/Modal';
import { getErrorMessageFromSaveModelError } from 'helpers';

type Props = {
    place: Object,
};

@observer
export default class PlaceEditPartial extends React.Component<Props> {
    @observable place: Place;

    componentWillMount() {
        this.place = this.props.place.clone();
        this.place.memorizeChanges();
    }

    handleSave = async () => {
        try {
            await this.place.saveChanges();
            runInAction(() => Object.assign(this.props.place, this.place));
            toast.success(`Location ${this.place.name} updated`);
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError(error));
        }
    };

    render() {
        return (
            <Modal.Action
                className="custom"
                forObject={this.place}
                title="Edit Location"
                action="Save"
                onAction={this.handleSave}
                trigger={<Sortable.Action title="Edit location" icon="setting" />}
            >
                <Field name="name" autoFocus />
            </Modal.Action>
        );
    }
}
