// @flow

import { observable, action, computed } from 'mobx';
import Model from './Model';
import FilterGroup from './FilterGroup';

export default class Filters extends Model {
    @observable
    defaultStartDate: ?string;

    @observable
    defaultEndDate: ?string;

    @observable
    startDate: ?string;

    @observable
    endDate: ?string;

    @observable
    filterGroups: FilterGroup[];

    @computed
    get dateRange() {
        return {
            startDate: this.startDate,
            endDate: this.endDate,
        };
    }

    @action
    setDateRange(startDate: string, endDate: string) {
        this.startDate = startDate;
        this.endDate = endDate;
    }

    @action
    setDefaultDateRange(start: string, end: string) {
        this.defaultStartDate = start;
        this.defaultEndDate = end;
    }

    @action
    addFilterGroup(name: string, label: string, alias: string): FilterGroup {
        const group = new FilterGroup();

        group.name = name;
        group.label = label;
        group.alias = alias;

        if (!this.filterGroups) this.filterGroups = [];

        this.filterGroups.push(group);

        return group;
    }

    @action
    setFilterValue(group: string, filter: string, value: boolean) {
        const fg = this.filterGroups.find(fg => fg.name === group);

        if (fg) {
            fg.setFilterValue(filter, value);
        }
    }

    getFilterValue(group: string, filter: string): ?boolean {
        const fg = this.filterGroups.find(fg => fg.name === group);

        if (!fg) return;

        return fg.getFilterValue(filter);
    }

    toObject(): Object {
        const o = {};

        if (this.filterGroups) {
            this.filterGroups.forEach(group => {
                o[group.name] = group.toObject();
            });
        }

        return o;
    }

    @action.bound
    resetDateRange() {
        this.startDate = this.defaultStartDate;
        this.endDate = this.defaultEndDate;
    }

    @action.bound
    resetFilters() {
        this.filterGroups &&
            this.filterGroups.forEach(group => {
                group.resetFilters();
            });
    }

    resetAll() {
        this.resetFilters();
        this.resetDateRange();
    }

    get canResetDates() {
        return this.startDate !== this.defaultStartDate || this.endDate !== this.defaultEndDate;
    }

    get canResetAll() {
        return this.canResetDates || this.canResetFilters;
    }

    get canResetFilters(): boolean {
        return this.filterGroups && this.filterGroups.filter(group => !group.allChecked).length > 0;
    }
}
