// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';
import { humanized, uniqueId } from 'app/external/form-for-component-helpers';
import { Label, Icon } from 'semantic-ui-react';

type Props = ComponentProps & {
    label?: string,
    addedAction?: Function,
    disableDelete: ?boolean,
    icon: ?string,
};

@observer
export default class TagsField extends React.Component<Props> {
    input: HTMLElement;

    componentDidMount() {
        this.props.onMount(this.input);
    }

    handleClick = (event: SyntheticEvent<HTMLInputElement>) => {
        const { onChange, addedAction, value } = this.props;
        onChange(event, !value);
        if (addedAction) addedAction();
    };

    get className(): string {
        const { className, disableDelete } = this.props;
        const extraClasses = ['tags-field'];

        if (disableDelete) {
            extraClasses.push('no-icon');
        }

        if (className) {
            return `${className} ${extraClasses.join(' ')}`;
        }

        return extraClasses.join(' ');
    }

    render() {
        let { label, disableDelete, icon, ...rest } = this.props;
        const id = uniqueId(this);
        delete rest.onMount;
        delete rest.onChange;
        delete rest.touched;
        delete rest.addedAction;

        return rest.value ? (
            <Label id={id} className={this.className} {...rest}>
                {label || humanized(this)}
                {!disableDelete && <Icon className="action-btn" name={icon || 'delete'} onClick={this.handleClick} />}
            </Label>
        ) : null;
    }
}
