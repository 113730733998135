// @flow

import React from 'react';
import type { App, AppRoute } from 'types';
import { presets } from 'types';
import { Link } from 'react-router-dom';
import route from 'helpers/route';

import AppIcon from 'components/App/Icon';
import SidebarListItem from 'components/Sidebar/ListItem';
import AppArrayAvailable from 'components/App/ArrayAvailable';
import { isPlaceUpgraded } from 'app/helpers/kenectUpgrade';
import placeStore from 'app/stores/place/placeStore';

type Props = {
    app: App,
};

export default class MainSidebarApp extends React.Component<Props> {
    render() {
        const { app } = this.props;

        if (app.feature === 'soci_enabled') {
            return <></>;
        }

        return (
            <div key={app.feature} className="content-group clearfix">
                <div className="label-title-large content-group-title clearfix">
                    <AppIcon app={app} />
                    <span className="label-title">{app.title}</span>
                </div>

                <AppArrayAvailable render={this.renderRoutes} />
            </div>
        );
    }

    renderRoutes = (availableApps: App[], features): any => {
        const { app } = this.props;
        const routes = app.routes;
        const feature = app.feature;

        const routeKeys = Object.keys(routes);

        if (feature === 'kenect') {
            return (
                <div className="list-item inverted">
                    <a className="main" href={app.path} target="_blank" rel="noopener noreferrer">
                        Kenect Login
                    </a>
                </div>
            );
        }

        if (!routeKeys.length) {
            return (
                <SidebarListItem disabled>
                    <div className="main">Coming soon</div>
                </SidebarListItem>
            );
        }

        if (!availableApps.includes(app)) {
            return (
                <SidebarListItem disabled>
                    <div className="main">You don&apos;t have this app</div>
                </SidebarListItem>
            );
        }

        return routeKeys.map(key => {
            // $FlowFixMe
            const routeItem: AppRoute = routes[key];
            if (routeItem.preset === presets.settings || key === 'default') return null;

            if (routeItem.preset === presets.info) {
                return (
                    <SidebarListItem key={key} disabled={isPlaceUpgraded(placeStore.place)}>
                        <div className="main">{routeItem.title}</div>
                    </SidebarListItem>
                );
            }

            if (key === 'calendar' && !features['soci_enabled']) return null;
            const path = route(app, routeItem);
            return (
                <SidebarListItem
                    key={key}
                    activeLink={path}
                    exact={routeItem.exact}
                    disabled={isPlaceUpgraded(placeStore.place)}
                >
                    <Link className="main" to={path}>
                        {routeItem.title}
                    </Link>
                </SidebarListItem>
            );
        });
    };
}
