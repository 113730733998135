// @flow
import './index.css';
import { configure } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';
import { api } from '@friendemic/premiere';
import TagManager from 'react-gtm-module';
import * as FullStory from '@fullstory/browser';

import tokenStore from 'stores/token';

import Main from './app/Main';
import { unregisterServiceWorker } from './registerServiceWorker';

import './app/fields';

// $FlowFixMe
import 'static/semantic/dist/semantic.min.css';

configure({ enforceActions: 'observed' });

api.base = '/api';

tokenStore.restore();

unregisterServiceWorker();

/**
 * Initialize FullStory
 *
 */
const isFullStoryEnabled = false;
if (isFullStoryEnabled && process.env.REACT_APP_FULLSTORY_ORG_ID) {
    FullStory.init({
        orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
        devMode: process.env.REACT_APP_ENVIRONMENT !== 'production',
    });
}

// registerServiceWorker({
//     onUpdate: registration => {
//         const waitingServiceWorker = registration.waiting;

//         if (waitingServiceWorker) {
//             waitingServiceWorker.addEventListener('statechange', event => {
//                 if (event.target.state === 'activated') {
//                     window.location.reload();
//                 }
//             });
//             waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//         }
//     },
// });

if (process.env.NODE_ENV === 'production') {
    TagManager.initialize({
        gtmId: 'GTM-5P5GRTG',
    });
}

// $FlowFixMe
ReactDOM.render(<Main />, document.getElementById('root'));
