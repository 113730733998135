// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Modal } from 'semantic-ui-react';
import videoStore from 'video/stores/video';
import Video from 'models/Video';
import InlineLoader from 'components/InlineLoader';
import VideoCard from 'video/components/Video/Card';
import { toast } from 'helpers';

type Props = {
    ellipsisBreakpoint: number,
    inverted?: boolean,
};

@observer
export default class VideoCardList extends React.Component<Props> {
    state = {
        confirmationModalOpened: false,
        videoBeingDeleted: null,
    };

    handleOpenDeleteConfirmationModalOpened = video => {
        let that = this;

        this.setState(
            {
                videoBeingDeleted: video,
            },
            () => {
                if (!that.state.videoBeingDeleted) {
                    return;
                }

                that.setState({
                    confirmationModalOpened: true,
                });
            }
        );
    };

    handleCloseDeleteConfirmationModalOpened = () => {
        this.setState({
            confirmationModalOpened: false,
            videoBeingDeleted: null,
        });
    };

    handleVideoDeletion = async (video: Video) => {
        const videoTitle = video.title;
        await video.destroyAndClearCache();
        videoStore.remove(video);

        this.setState(
            {
                videoBeingDeleted: null,
                confirmationModalOpened: false,
            },
            () => {
                toast.success(`Video ${videoTitle} deleted`);
            }
        );
    };

    render() {
        const { videos } = videoStore;

        if (!videos) return null;

        const { inverted, ellipsisBreakpoint } = this.props;
        const { confirmationModalOpened, videoBeingDeleted } = this.state;

        return (
            <React.Fragment>
                <InfiniteScroll
                    initialLoad={false}
                    loadMore={videoStore.loadNext}
                    hasMore={videoStore.hasMore}
                    useWindow={false}
                    loader={
                        <div key={0} className="list-item">
                            <div className="main">
                                <InlineLoader inverted={inverted} />
                            </div>
                        </div>
                    }
                >
                    {videos.map((video, index) => (
                        <VideoCard
                            key={`${video.youtube_id}-${index}` || `${video.title + video.key}`}
                            video={video}
                            inverted={inverted}
                            ellipsisBreakpoint={ellipsisBreakpoint}
                            handleOpenDeleteConfirmationModalOpened={video =>
                                this.handleOpenDeleteConfirmationModalOpened(video)
                            }
                        />
                    ))}
                </InfiniteScroll>

                <Modal
                    className="custom"
                    size="tiny"
                    dimmer="blurring"
                    open={confirmationModalOpened}
                    onClose={this.handleCloseDeleteConfirmationModalOpened}
                >
                    <Modal.Header>Delete Video</Modal.Header>

                    <Modal.Content>
                        <p>
                            Are you sure you want to delete the video{' '}
                            {videoBeingDeleted ? '"' + videoBeingDeleted.title + '"' : ''}?
                        </p>
                    </Modal.Content>

                    <Modal.Actions>
                        <Button
                            type="button"
                            size="tiny"
                            basic
                            color="grey"
                            onClick={this.handleCloseDeleteConfirmationModalOpened}
                        >
                            No
                        </Button>
                        <Button
                            type="button"
                            size="tiny"
                            color={'google plus'}
                            onClick={() => this.handleVideoDeletion(videoBeingDeleted)}
                        >
                            Delete
                        </Button>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}
