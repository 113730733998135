// @flow

import { action, observable } from 'mobx';
import Model from './Model';
import { field } from 'app/external/mobx-form-for';
import * as mediaType from 'enum/mediaType';

export default class DraftContent extends Model {
    path = 'content-submissions/pending';

    @observable
    id: number;

    @observable
    @field({ label: 'Suggested Caption*', placeholder: 'Suggested Caption*' })
    title: string;

    @observable
    @field({ textArea: true, label: 'Intructions/Comments', placeholder: 'Add instructions/comments...' })
    description: string;

    @observable
    @field({ textArea: true, placeholder: 'Add a testimonial...' })
    testimonial: string;

    @observable
    @field({ type: 'selectMedia', text: 'Upload Video', mediaType: mediaType.VIDEO, accept: 'video/*' })
    content_videos: Object[];

    @observable
    @field({ type: 'selectMedia', text: 'Upload Photo', mediaType: mediaType.PHOTO, accept: 'image/*' })
    content_photos: Object[];

    @action
    setId(id: number) {
        this.id = id;
    }

    constructor(data: Object) {
        super(data);
        if (!data) return;

        this.title = data.title;
        this.description = data.description;
        this.content_videos = data.content_videos;
        this.content_photos = data.content_photos;
    }

    get hasCustomerPhoto(): boolean {
        if (this.content_photos && this.content_photos.length) {
            if (undefined !== this.content_photos.find(p => !p.product)) return true;
        }

        return false;
    }
}
