// @flow
import moment from 'moment';

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function name(yearMonth: string): string {
    const parts = yearMonth.split('-');
    const monthIndex = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);

    if (2 === parts.length) {
        return monthNames[monthIndex];
    }

    return `${shortMonthNames[monthIndex]} ${day}`;
}

export function namesFromObject(data: Object): string[] {
    return Object.keys(data).map(key => name(key));
}

export function blanks(monthsAgo: number, date: moment = moment()): Object {
    const blanks = {};
    date.subtract(monthsAgo, 'months');

    for (let i = 0; i <= monthsAgo; i++) {
        const monthString = date.format('M');
        blanks[`${date.format('YYYY')}-${monthString}`] = 0;
        date.add(1, 'months');
    }

    return blanks;
}

export function withBlanks(blanks: Object, data: Object): Object {
    const result = {};
    Object.keys(blanks).forEach(key => {
        result[key] = data[key] || blanks[key];
    });

    return result;
}

export function valuesWithBlanks(blanks: Object, data: Object): any[] {
    return Object.values(withBlanks(blanks, data));
}

export function dateRangeToText(startDate: ?string, endDate: ?string): string {
    if (!startDate && !endDate) {
        return 'All Time';
    }

    if (
        startDate ===
            moment()
                .subtract(1, 'year')
                .format('YYYY-MM-DD') &&
        endDate === moment().format('YYYY-MM-DD')
    ) {
        return 'Last Year';
    }

    if (
        startDate ===
            moment()
                .subtract(6, 'month')
                .format('YYYY-MM-DD') &&
        endDate === moment().format('YYYY-MM-DD')
    ) {
        return 'Last Six Months';
    }

    if (
        startDate ===
            moment()
                .subtract(1, 'month')
                .format('YYYY-MM-DD') &&
        endDate === moment().format('YYYY-MM-DD')
    ) {
        return 'Last Month';
    }

    return `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`;
}
