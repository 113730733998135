/**
 * form-for
 * Removed from github and unpublished from npm by author
 * https://github.com/pedsmoreira/form-for (Not Found)
 * https://registry.npmjs.org/form-for
 */
import { Component, createElement } from 'react';
import PropTypes from 'prop-types';

var classCallCheck = function (instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
};

var createClass = (function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor) descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }

    return function (Constructor, protoProps, staticProps) {
        if (protoProps) defineProperties(Constructor.prototype, protoProps);
        if (staticProps) defineProperties(Constructor, staticProps);
        return Constructor;
    };
})();

var defineProperty = function (obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true,
        });
    } else {
        obj[key] = value;
    }

    return obj;
};

var _extends =
    Object.assign ||
    function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

var get = function get(object, property, receiver) {
    if (object === null) object = Function.prototype;
    var desc = Object.getOwnPropertyDescriptor(object, property);

    if (desc === undefined) {
        var parent = Object.getPrototypeOf(object);

        if (parent === null) {
            return undefined;
        } else {
            return get(parent, property, receiver);
        }
    } else if ('value' in desc) {
        return desc.value;
    } else {
        var getter = desc.get;

        if (getter === undefined) {
            return undefined;
        }

        return getter.call(receiver);
    }
};

var inherits = function (subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
        throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true,
        },
    });
    if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass);
};

var objectWithoutProperties = function (obj, keys) {
    var target = {};

    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }

    return target;
};

var possibleConstructorReturn = function (self, call) {
    if (!self) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === 'object' || typeof call === 'function') ? call : self;
};

var toConsumableArray = function (arr) {
    if (Array.isArray(arr)) {
        for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

        return arr2;
    } else {
        return Array.from(arr);
    }
};

function cloneObject(object) {
    for (var _len = arguments.length, sources = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        sources[_key - 1] = arguments[_key];
    }

    return Object.assign.apply(
        Object,
        [Object.create(Object.getPrototypeOf(object)), object].concat(toConsumableArray(sources))
    );
}

function prefixer() {
    for (var _len = arguments.length, prefixes = Array(_len), _key = 0; _key < _len; _key++) {
        prefixes[_key] = arguments[_key];
    }

    return prefixes
        .filter(function (prefix) {
            return prefix || prefix === 0;
        })
        .map(function (prefix, index) {
            return index === 0 ? prefix : '[' + prefix + ']';
        })
        .join('');
}

var FieldGroup = (function (_React$Component) {
    inherits(FieldGroup, _React$Component);

    function FieldGroup() {
        var _ref;

        var _temp, _this, _ret;

        classCallCheck(this, FieldGroup);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return (
            (_ret =
                ((_temp =
                    ((_this = possibleConstructorReturn(
                        this,
                        (_ref = FieldGroup.__proto__ || Object.getPrototypeOf(FieldGroup)).call.apply(
                            _ref,
                            [this].concat(args)
                        )
                    )),
                    _this)),
                (_this.handleChange = function (name, value, index) {
                    _this.onChange(name, value, index);
                }),
                _temp)),
            possibleConstructorReturn(_this, _ret)
        );
    }

    createClass(FieldGroup, [
        {
            key: 'getChildContext',

            /*
             * Getters
             */

            value: function getChildContext() {
                return {
                    object: this.props.for,
                    schema: this.getSchema(),
                    prefix: this.getPrefix(),
                    onChange: this.handleChange,
                };
            },
        },
        {
            key: 'getPrefix',
            value: function getPrefix() {
                return prefixer(this.context.prefix, this.context.name, this.props.prefix, this.props.index);
            },
        },
        {
            key: 'getSchema',
            value: function getSchema() {
                return this.props.schema || this.props.for.schema || this.throwUndefinedSchema();
            },
        },
        {
            key: 'getNewObjectFor',
            value: function getNewObjectFor(name, value, index) {
                if (typeof index === 'undefined') return cloneObject(this.props.for, defineProperty({}, name, value));

                var previousValue = this.props.for[name];
                var newValue = void 0;

                if (Array.isArray(previousValue)) {
                    newValue = [].concat(toConsumableArray(previousValue));
                    newValue[index] = value;
                } else {
                    // $FlowFixMe
                    newValue = cloneObject(previousValue, defineProperty({}, index, value));
                }

                return cloneObject(this.props.for, defineProperty({}, name, newValue));
            },

            /*
             * Dispatchers
             */
        },
        {
            key: 'dispatchChange',
            value: function dispatchChange(newObject) {
                this.context.name ? this.dispatchNestedChange(newObject) : this.dispatchFormChange(newObject);
            },
        },
        {
            key: 'dispatchNestedChange',
            value: function dispatchNestedChange(newObject) {
                this.context.onChange(this.context.name, newObject, this.props.index);
            },
        },
        {
            key: 'dispatchFormChange',
            value: function dispatchFormChange(newObject) {
                this.context.onFormChange(newObject);
            },

            /*
             * Handlers
             */
        },
        {
            key: 'onChange',
            value: function onChange(name, value, index) {
                var newObject = this.getNewObjectFor(name, value, index);
                this.dispatchChange(newObject);
            },
        },
        {
            key: 'render',

            /*
             * Lifecycle
             */

            value: function render() {
                return this.props.children || null;
            },

            /*
             * Errors
             */
        },
        {
            key: 'throwUndefinedSchema',
            value: function throwUndefinedSchema() {
                var constructor = this.props.for.constructor.name;
                throw new Error('Undefined schema for "' + constructor + '" instance');
            },
        },
    ]);
    return FieldGroup;
})(Component);

FieldGroup.contextTypes = {
    onFormChange: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    name: PropTypes.string,
};
FieldGroup.childContextTypes = {
    object: PropTypes.object,
    schema: PropTypes.object,
    prefix: PropTypes.string,
    onFormChange: PropTypes.func,
    onChange: PropTypes.func,
};

var BaseForm = (function (_React$Component) {
    inherits(BaseForm, _React$Component);

    function BaseForm() {
        var _ref;

        var _temp, _this, _ret;

        classCallCheck(this, BaseForm);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return (
            (_ret =
                ((_temp =
                    ((_this = possibleConstructorReturn(
                        this,
                        (_ref = BaseForm.__proto__ || Object.getPrototypeOf(BaseForm)).call.apply(
                            _ref,
                            [this].concat(args)
                        )
                    )),
                    _this)),
                (_this.handleChange = function (value) {
                    _this.onChange(value);

                    var onChange = _this.props.onChange;

                    if (onChange) onChange(_this.getData());
                }),
                (_this.handleSubmit = function (event) {
                    var onSubmit = _this.props.onSubmit;
                    if (onSubmit) onSubmit(event, _this.getData());
                }),
                _temp)),
            possibleConstructorReturn(_this, _ret)
        );
    }

    /*
     * Getters
     */

    createClass(BaseForm, [
        {
            key: 'getChildContext',
            value: function getChildContext() {
                return {
                    onFormChange: this.handleChange,
                    touchOnMount: !!this.props.touchOnMount,
                    noValidate: !!this.props.noValidate,
                };
            },
        },
        {
            key: 'getData',
            value: function getData() {
                return this.props.for || {};
            },
        },
        {
            key: 'getForm',
            value: function getForm() {
                return this.form || this.throwUndefinedForm();
            },

            /*
             * Handlers
             */
        },
        {
            key: 'onChange',
            // eslint-disable-next-line no-unused-vars
            value: function onChange(data) {},

            /**
             * Since onChange is used by classes that extend BaseForm, handleChange exists to do .bind()
             */
        },
        {
            key: 'render',

            /*
             * Lifecycle
             */

            value: function render() {
                var _this2 = this;

                var _props = _extends({}, this.props),
                    object = _props['for'],
                    schema = _props.schema,
                    children = _props.children,
                    formProps = objectWithoutProperties(_props, ['for', 'schema', 'children']);

                delete formProps.onChange; // Prevent the browser onChange event to be called
                delete formProps.touchOnMount;

                var content = void 0;
                if (!object) {
                    content = children;
                } else {
                    content = createElement(this.constructor.fieldGroupComponent, {
                        for: this.getData(),
                        schema: schema,
                        children: children,
                    });
                }

                return createElement(
                    'form',
                    _extends({}, formProps, {
                        ref: function ref(el) {
                            return (_this2.form = el);
                        },
                        onSubmit: this.handleSubmit,
                    }),
                    content
                );
            },

            /*
             * Errors
             */
        },
        {
            key: 'throwUndefinedForm',
            value: function throwUndefinedForm() {
                throw new Error('Undefined form HTML element');
            },
        },
    ]);
    return BaseForm;
})(Component);

BaseForm.fieldGroupComponent = FieldGroup;
BaseForm.childContextTypes = {
    onFormChange: PropTypes.func,
    touchOnMount: PropTypes.bool,
    noValidate: PropTypes.bool,
};

/*
 * setState(...) only keeps enumerable properties, which causes `schema` created using @field to disappear
 * To work around that it's used the `data` and setState({})
 */

var Form = (function (_BaseForm) {
    inherits(Form, _BaseForm);

    function Form(props) {
        classCallCheck(this, Form);

        var _this = possibleConstructorReturn(this, (Form.__proto__ || Object.getPrototypeOf(Form)).call(this, props));

        _this.data = props.for || {};
        return _this;
    }

    createClass(Form, [
        {
            key: 'getData',
            value: function getData() {
                return this.data;
            },
        },
        {
            key: 'onChange',
            value: function onChange(data) {
                this.data = data;
                this.setState({});
                get(Form.prototype.__proto__ || Object.getPrototypeOf(Form.prototype), 'onChange', this).call(
                    this,
                    data
                );
            },
        },
    ]);
    return Form;
})(BaseForm);

var Field = (function (_React$Component) {
    inherits(Field, _React$Component);

    function Field() {
        var _ref;

        var _temp, _this, _ret;

        classCallCheck(this, Field);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return (
            (_ret =
                ((_temp =
                    ((_this = possibleConstructorReturn(
                        this,
                        (_ref = Field.__proto__ || Object.getPrototypeOf(Field)).call.apply(_ref, [this].concat(args))
                    )),
                    _this)),
                (_this.handleMount = function (target) {
                    _this.target = target;
                    _this.forceUpdate();
                }),
                (_this.handleFocus = function (event) {
                    _this.target = (event || {}).target || _this.target;
                    _this.touchAndRender();

                    if (_this.props.onFocus) _this.props.onFocus(event);
                }),
                // eslint-disable-next-line no-unused-vars
                (_this.handleChange = function (event, value, error) {
                    _this.target = (event || {}).target || _this.target;
                    _this.setValue(value);
                    _this.touch();

                    if (_this.props.onChange) _this.props.onChange(event);
                }),
                _temp)),
            possibleConstructorReturn(_this, _ret)
        );
    }

    /*
     * Component binding
     */

    createClass(
        Field,
        [
            {
                key: 'getChildContext',
                value: function getChildContext() {
                    return {
                        name: this.props.name,
                    };
                },

                /*
                 * Getters
                 */
            },
            {
                key: 'getContextObject',
                value: function getContextObject() {
                    return this.context.object;
                },
            },
            {
                key: 'getContextObjectValue',
                value: function getContextObjectValue() {
                    return this.getContextObject()[this.props.name];
                },
            },
            {
                key: 'getSchemaProperty',
                value: function getSchemaProperty() {
                    var property = this.context.schema[this.props.name];
                    if (!property) this.warnMissingSchemaProperty();

                    return property || {};
                },
            },
            {
                key: 'getType',
                value: function getType() {
                    return this.props.type || this.getSchemaProperty().type || 'text';
                },
            },
            {
                key: 'getPrefixedName',
                value: function getPrefixedName() {
                    return prefixer(this.context.prefix, this.props.name);
                },
            },
            {
                key: 'getComponent',
                value: function getComponent() {
                    return Field.connectedComponents[this.getType()] || this.throwMissingTypeConnection();
                },
            },
            {
                key: 'getValue',
                value: function getValue(incomingValue) {
                    return typeof incomingValue !== 'undefined' ? incomingValue : this.getTargetValue();
                },
            },
            {
                key: 'getTargetValue',
                value: function getTargetValue() {
                    if (this.target.type === 'checkbox') return this.target.checked;
                    return this.target.value;
                },
            },
            {
                key: 'getTargetValidationMessage',
                value: function getTargetValidationMessage() {
                    return (this.target || {}).validationMessage;
                },
            },
            {
                key: 'isTouched',
                value: function isTouched() {
                    return this.touched || this.context.touchOnMount;
                },
            },
            {
                key: 'getSchemaError',
                value: function getSchemaError() {
                    var error = this.getSchemaProperty().error;
                    if (!error) return;

                    if (typeof error === 'string') {
                        error = this.context.object[error];
                    }

                    if (typeof error === 'function') {
                        return error.bind(this.context.object)(this.context.object, this.props.name);
                    }

                    return error;
                },
            },
            {
                key: 'getError',
                // eslint-disable-next-line no-unused-vars
                value: function getError(value) {
                    if (this.context.noValidate) return null;
                    if (this.props.error) return this.props.error;
                    return this.getSchemaError() || this.incomingError || this.getTargetValidationMessage();
                },

                /*
                 * Setters
                 */
            },
            {
                key: 'setValue',
                value: function setValue(incomingValue) {
                    this.context.onChange(this.props.name, this.getValue(incomingValue));
                },
            },
            {
                key: 'setBrowserCustomValidity',
                value: function setBrowserCustomValidity(message) {
                    if (!this.target) return;

                    var targets = Array.isArray(this.target) ? this.target : [this.target];
                    targets.forEach(function (element) {
                        if (element.setCustomValidity) element.setCustomValidity(message || '');
                    });
                },
            },
            {
                key: 'clearBrowserCustomValidity',
                value: function clearBrowserCustomValidity() {
                    this.setBrowserCustomValidity();
                },

                /*
                 * Actions
                 */
            },
            {
                key: 'touch',
                value: function touch() {
                    this.touched = true;
                },
            },
            {
                key: 'touchAndRender',
                value: function touchAndRender() {
                    if (!this.isTouched()) {
                        this.touch();
                        this.forceUpdate();
                    } else {
                        this.touch();
                    }
                },
            },
            {
                key: 'validate',
                value: function validate(incomingError) {
                    this.clearBrowserCustomValidity();

                    this.incomingError = incomingError;
                    var error = this.getError();

                    this.setBrowserCustomValidity(error);
                    return error;
                },

                /*
                 * Handlers
                 */
            },
            {
                key: 'render',

                /*
                 * Lifecycle
                 */

                value: function render() {
                    var error = this.validate();
                    if (!error || (typeof error === 'string' && !error.length)) error = null; // Avoid changes from false, undefined, 0 and ''

                    return createElement(
                        this.getComponent(),
                        _extends({}, this.getSchemaProperty(), this.props, {
                            name: this.getPrefixedName(),
                            value: this.getContextObjectValue() || '',
                            error: error,
                            touched: this.isTouched(),
                            onMount: this.handleMount,
                            onFocus: this.handleFocus,
                            onChange: this.handleChange,
                        })
                    );
                },

                /*
                 * Errors
                 */
            },
            {
                key: 'warnMissingSchemaProperty',
                value: function warnMissingSchemaProperty() {
                    var name = this.props.name;
                    var constructor = this.context.object.constructor.name;
                    console.warn('Undefined property "' + name + '" in schema for "' + constructor + '" instance');
                },
            },
            {
                key: 'throwMissingTypeConnection',
                value: function throwMissingTypeConnection() {
                    var type = this.getType();
                    var name = this.props.name;
                    var constructor = this.context.object.constructor.name;
                    throw new Error(
                        'Missing "' +
                            type +
                            '" connection requested for property "' +
                            name +
                            '" in "' +
                            constructor +
                            '" instance'
                    );
                },
            },
        ],
        [
            {
                key: 'connect',
                value: function connect(type, component) {
                    Field.connectedComponents[type] = component;
                },

                /*
                 * Context
                 */
            },
        ]
    );
    return Field;
})(Component);

Field.connectedComponents = {};
Field.contextTypes = {
    object: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    prefix: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    touchOnMount: PropTypes.bool.isRequired,
    noValidate: PropTypes.bool.isRequired,
};
Field.childContextTypes = {
    name: PropTypes.string,
};

function decorate(target, key, descriptor, properties) {
    if (!target.schema) target.schema = {};
    target.schema[key] = properties;

    return {
        writable: true,
    };
}

function fieldDecorator(targetOrProperties, key, descriptor) {
    // @field - without params
    if (key) return decorate(targetOrProperties, key, descriptor, {});

    // @field({ }) - with params
    return function (target, key, descriptor) {
        return decorate(target, key, descriptor, targetOrProperties);
    };
}

export { BaseForm, Form, Field, FieldGroup, fieldDecorator as field, cloneObject };
