// @flow

import * as React from 'react';

import Page from 'components/Page';
import Column from 'components/Column';
import Panel from 'components/Panel';
import SubColumn from 'components/SubColumn';
import SubmissionCardList from 'photo/components/Submission/Card/List';
import SubmissionMediaList from 'photo/components/Submission/Media/List';
import { Button, Icon } from 'semantic-ui-react';
import ActionButton from 'components/ActionButton';
import PanelSelected from 'components/Panel/Selected';
import SidebarButton from 'components/Sidebar/Button';
import SubmissionCard from 'photo/components/Submission/Card';

import publishedContentStore from 'photo/stores/submission/publishedContentStore';
import PublishedContent from 'models/PublishedContent';
import InlineLoader from 'components/InlineLoader';
import PlaceMonitor from 'components/Place/Monitor';
import { Link } from 'react-router-dom';
import { STATUS_ICON_MAP, NETWORKS_MAP } from '../../../photo/components/Submission/Modal/SocialPostModal';
import placeStore from 'app/stores/place/placeStore';
import SocialPostFormModal from '../../components/Submission/Modal/SocialPostFormModal';
import SubmissionModal from '../../components/Submission/Modal/SubmissionModal';
import useSociNetworks from 'app/hooks/useSociNetworks';

export default function SubmissionsPage() {
    const { networks, loading: loadingNetworks } = useSociNetworks();

    const load = async () => {
        await publishedContentStore.load();
    };

    const handleCardClick = (publishedContent: PublishedContent) => {
        if (!publishedContentStore.content) {
            return;
        }

        publishedContentStore.select(publishedContent);
    };

    const Actions = () => (
        <>
            {placeStore.place.features.soci_enabled ? (
                <>
                    {networks ? (
                        <SocialPostFormModal
                            placeNetworks={networks}
                            trigger={<ActionButton icon="plus" text="Create Content" />}
                        />
                    ) : (
                        <SubmissionModal
                            trigger={<ActionButton icon="plus" text="Create Content" disabled={loadingNetworks} />}
                        />
                    )}
                    <ActionButton icon="calendar" text="Publishing Calendar" link="/photo/calendar" />
                </>
            ) : (
                <SubmissionModal trigger={<ActionButton icon="plus" text="Create Content" />} />
            )}
        </>
    );

    const MobileActions = () => (
        <>
            {placeStore.place.features.soci_enabled ? (
                <>
                    {networks ? (
                        <SocialPostFormModal
                            placeNetworks={networks}
                            trigger={
                                <Button className="option-btn">
                                    <Icon name="plus" />
                                    Create Content
                                </Button>
                            }
                        />
                    ) : (
                        <SubmissionModal
                            trigger={
                                <Button className="option-btn" disabled={loadingNetworks}>
                                    <Icon name="plus" />
                                    Create Content
                                </Button>
                            }
                        />
                    )}
                    <Link to="/photo/calendar">
                        <Button className="option-btn">
                            <Icon name="calendar" />
                            Publishing Calendar
                        </Button>
                    </Link>
                </>
            ) : (
                <SubmissionModal
                    trigger={
                        <Button className="option-btn">
                            <Icon name="plus" />
                            Create Content
                        </Button>
                    }
                />
            )}
        </>
    );

    const renderSelectedSubmission = () => {
        if (!publishedContentStore.content) {
            return null;
        }

        return <SubmissionCard className="not-social" active noMenu content={publishedContentStore.content} />;
    };

    const renderContent = () => {
        return (
            <Page className="three-column-middle-flex clearfix" actions={<Actions />} mobileActions={<MobileActions />}>
                {publishedContentStore.loading && (
                    <div className="no-data">
                        <InlineLoader />
                    </div>
                )}
                {!publishedContentStore.loading && (
                    <React.Fragment>
                        <Column position="left" className="list-items">
                            <Panel title="Submitted Content">
                                <SubmissionCardList
                                    contentCollection={publishedContentStore.contentCollection || []}
                                    selected={publishedContentStore.content}
                                    onCardClick={handleCardClick}
                                />
                            </Panel>
                        </Column>
                        {!!publishedContentStore.content && publishedContentStore.content.hasMedia && (
                            <Column position="middle">
                                <PanelSelected id="selected" title="Content" sidebar="content_fuel.submissions">
                                    <SidebarButton
                                        sidebar="content_fuel.submissions"
                                        render={renderSelectedSubmission}
                                    />
                                </PanelSelected>

                                <Panel id="main-media-container" title="Media">
                                    <SubmissionMediaList content={publishedContentStore.content} />
                                </Panel>
                            </Column>
                        )}
                        {!!publishedContentStore.content && (
                            <Column position="right" className="two-column-vertical">
                                {publishedContentStore.content.testimonial && (
                                    <SubColumn>
                                        <Panel title="Testimonial" noResizer>
                                            <div id="testimonials" className="items-panel clearfix">
                                                <div className="testimonials">
                                                    <div className="item">
                                                        <p>{publishedContentStore.content.testimonial}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </SubColumn>
                                )}
                                <SubColumn>
                                    <Panel title="Info">
                                        <div id="additional-info" className="items-panel clearfix">
                                            <div className="additional-info">
                                                {publishedContentStore.content.posts &&
                                                    publishedContentStore.content.posts.length > 0 && (
                                                        <div className="item">
                                                            <div className="title">Status</div>
                                                            <div className="status">
                                                                <Icon
                                                                    name={
                                                                        STATUS_ICON_MAP[
                                                                            publishedContentStore.content.posts[0]
                                                                                .soci_status
                                                                        ][0]
                                                                    }
                                                                    color={
                                                                        STATUS_ICON_MAP[
                                                                            publishedContentStore.content.posts[0]
                                                                                .soci_status
                                                                        ][1]
                                                                    }
                                                                />
                                                                <p>
                                                                    {
                                                                        STATUS_ICON_MAP[
                                                                            publishedContentStore.content.posts[0]
                                                                                .soci_status
                                                                        ][2]
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                <div className="item">
                                                    <div className="title">Published On</div>
                                                    <p>
                                                        <Icon name="clock outline" />
                                                        {publishedContentStore.content.publishedAtTime}&nbsp;
                                                        <Icon name="calendar alternate outline" />
                                                        {publishedContentStore.content.publishedAtDate}
                                                    </p>
                                                </div>
                                                {publishedContentStore.content.posts &&
                                                    publishedContentStore.content.posts.length > 0 && (
                                                        <div className="item">
                                                            <div className="title">Social Networks</div>
                                                            {publishedContentStore.content.posts
                                                                .map(item => NETWORKS_MAP[item.network_name])
                                                                .join(', ')}
                                                        </div>
                                                    )}
                                                <div className="item">
                                                    <div className="title">Created By</div>
                                                    <p>{publishedContentStore.content.user_name}</p>
                                                </div>
                                                {publishedContentStore.content.description && (
                                                    <div className="item">
                                                        <div className="title">Caption</div>
                                                        <p>{publishedContentStore.content.description}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Panel>
                                </SubColumn>
                            </Column>
                        )}
                    </React.Fragment>
                )}
            </Page>
        );
    };

    return <PlaceMonitor onChange={load} render={renderContent} />;
}
