// @flow

import { action, observable, computed } from 'mobx';

import placeStore from 'stores/place';
import videosWatchedLengthStore from 'video/stores/videosWatchedLength';
import VideoData from 'models/VideoData';

type meta = {
    current_page: number,
    first_page_url: string,
    last_page: number,
    last_page_url: string,
    next_page_url: string,
    per_page: number,
    prev_page_url: string,
    to: number,
    total: number,
};

class VideoDataStore {
    @observable videoDatas: ?(VideoData[]);

    @observable sortDirection: ?string = 'desc';
    @observable sortProperty: ?string = 'created_at';
    @observable searchText: ?string = '';
    @observable page: number = 1;
    @observable meta: meta;
    @observable startDate: ?string;
    @observable endDate: ?string;
    @observable loading: boolean = false;

    @action
    setMeta(meta: meta) {
        this.meta = meta;
    }

    @action
    setPage(page: number) {
        this.page = page;
    }

    @action
    startLoading() {
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
    }

    get queryParams() {
        let queryParams = {
            page: this.page,
            per_page: 8,
            search: this.searchText,
            order_by: this.sortProperty,
            order: this.sortDirection,
            paginated: true,
        };

        let filters = {};

        if (this.startDate) {
            filters.start_date = this.startDate;
        }

        if (this.endDate) {
            filters.end_date = this.endDate;
        }

        return {
            ...queryParams,
            ...filters,
        };
    }

    @action
    setVideoDatas(videoData: VideoData[]) {
        this.videoDatas = videoData;
    }

    @action
    resetState() {
        this.videoDatas = null;
    }

    @action.bound
    searchByDate(startDate: ?string, endDate: ?string) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.page = 1;
        this.sortProperty = 'created_at';
        this.sortDirection = 'desc';

        return this.load(true);
    }

    @action
    sort(property: string) {
        if (property === this.sortProperty) {
            this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
        } else {
            this.sortProperty = property;
            this.sortDirection = 'asc';
        }

        this.resetState();
        return this.load();
    }

    async load(ignoreCache: boolean = false) {
        this.startLoading();

        videosWatchedLengthStore.cancel();

        this.resetState();
        this.setVideoDatas(
            await placeStore.place.videoDatas({
                ignoreCache,
                queryParams: this.queryParams,
                callback: response => {
                    const { data, ...rest } = response.data;
                    this.setMeta({ ...rest });
                    return { data };
                },
            })
        );

        this.stopLoading();
    }

    @computed
    get videoIds(): Array<number> {
        if (!this.videoDatas) return [];

        return this.videoDatas.map(videoData => {
            return videoData.video_id;
        });
    }

    async loadAvgWatchedLength() {
        if (!this.videoDatas || !this.videoDatas.length) return;

        let data = await videosWatchedLengthStore.load(this.startDate, this.endDate, this.videoIds);

        if (!data) return;

        data.forEach(d => {
            if (!this.videoDatas) return;
            const v = this.videoDatas.find(v => v.video_id === d.id);
            if (v) v.setAverageVideoWatchedLength(d.avg_video_watched_length);
        });
    }
}

export default new VideoDataStore();
