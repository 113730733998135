// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import InviteAnalytics from 'models/InviteAnalytics';
import DataPlaceholder from 'components/Data/Placeholder';
import * as channels from 'enum/channels';
import CustomLegend from 'components/CustomLegend';

type Props = {
    inviteAnalytics: InviteAnalytics,
};

type State = {
    chart: Object,
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    radiusBackground: {
        color: '#fff',
    },
    cutoutPercentage: 70,
    legend: {
        display: false,
    },
    animation: {
        animateScale: false,
    },
    layout: {
        padding: {
            left: 30,
            right: 30,
            top: 30,
            bottom: 30,
        },
    },
    plugins: {
        datalabels: {
            align: 'end',
            anchor: 'end',
            font: function() {
                return {
                    size: 16,
                };
            },
            formatter: function(value, context) {
                return context.chart.data[context.dataIndex];
            },
        },
    },
};

@observer
export default class YesResponse extends React.Component<Props, State> {
    chart: { current: null | HTMLDivElement };

    constructor(props: Props) {
        super(props);
        this.state = { chart: null };
        this.chart = React.createRef();
    }

    componentDidMount() {
        const chart = this.chart.current;

        this.setState({
            chart: chart,
        });
    }

    shouldRenderChannel(channel: string): boolean {
        const { inviteAnalytics } = this.props;

        return (
            undefined !== inviteAnalytics.charts.wyru_results_yes[channel] &&
            inviteAnalytics.charts.wyru_results_yes[channel] > 0
        );
    }

    getData() {
        const labels = [],
            data = [],
            backgroundColor = [],
            hoverBackgroundColor = [],
            { inviteAnalytics } = this.props;

        if (this.shouldRenderChannel(channels.GOOGLE)) {
            labels.push('Google');
            data.push(inviteAnalytics.charts.wyru_results_yes[channels.GOOGLE]);
            backgroundColor.push('rgba(41, 167, 113, 0.8)');
            hoverBackgroundColor.push('rgba(41, 167, 113, 1)');
        }

        if (this.shouldRenderChannel(channels.YELP)) {
            labels.push('Yelp');
            data.push(inviteAnalytics.charts.wyru_results_yes[channels.YELP]);
            backgroundColor.push('rgba(243, 112, 60, 0.8)');
            hoverBackgroundColor.push('rgba(243, 112, 60, 1)');
        }

        if (this.shouldRenderChannel(channels.DEALER_RATER)) {
            labels.push('DealerRater');
            data.push(inviteAnalytics.charts.wyru_results_yes[channels.DEALER_RATER]);
            backgroundColor.push('rgba(243, 165, 60, 0.8)');
            hoverBackgroundColor.push('rgba(243, 165, 60, 1)');
        }

        if (this.shouldRenderChannel(channels.FACEBOOK)) {
            labels.push('Facebook');
            data.push(inviteAnalytics.charts.wyru_results_yes[channels.FACEBOOK]);
            backgroundColor.push('rgba(46, 108, 156, 0.8)');
            hoverBackgroundColor.push('rgba(46, 108, 156, 1)');
        }

        if (this.shouldRenderChannel(channels.CARSCOM)) {
            labels.push('Cars.com');
            data.push(inviteAnalytics.charts.wyru_results_yes[channels.CARSCOM]);
            backgroundColor.push('rgba(211, 207, 89, 0.8)');
            hoverBackgroundColor.push('rgba(211, 207, 89, 1)');
        }

        if (this.shouldRenderChannel(channels.EDMUNDS)) {
            labels.push('Edmunds');
            data.push(inviteAnalytics.charts.wyru_results_yes[channels.EDMUNDS]);
            backgroundColor.push('rgba(247, 58, 151, 0.8)');
            hoverBackgroundColor.push('rgba(247, 58, 151, 1)');
        }

        if (this.shouldRenderChannel(channels.CARGURUS)) {
            labels.push('CarGurus');
            data.push(inviteAnalytics.charts.wyru_results_yes[channels.CARGURUS]);
            backgroundColor.push('rgba(245, 247, 58, 0.8)');
            hoverBackgroundColor.push('rgba(245, 247, 58, 1)');
        }

        return {
            labels,
            datasets: [
                {
                    data,
                    borderWidth: 0,
                    backgroundColor,
                    hoverBackgroundColor,
                },
            ],
        };
    }

    get hasData() {
        const { inviteAnalytics } = this.props;

        if (!inviteAnalytics) {
            return false;
        }

        return inviteAnalytics.charts.wyru_results_yes && 0 < inviteAnalytics.charts.wyru_results_yes.total;
    }

    render() {
        return (
            <DataPlaceholder for={this.hasData}>
                <div className="chart-title">Yes Response Further Actions</div>
                <div className="chart">
                    <Doughnut data={this.getData()} options={options} ref={this.chart} />
                </div>
                {this.state.chart && <CustomLegend chart={this.state.chart} />}
            </DataPlaceholder>
        );
    }
}
