// @flow

import * as React from 'react';
import Transition from 'react-transition-group/Transition';
import { Icon } from 'semantic-ui-react';

type Props = {
    handleItemClick: Function,
};

type State = {
    menuOpen: boolean,
    triggerText: 'Review Invites' | 'Customer Feedback' | 'Leaderboards',
};

const duration = 200;

const defaultStyle = {
    transition: `height ${duration}ms ease-in-out`,
    height: '0px',
    display: 'block',
    overflow: 'hidden',
};

const transitionStyles = {
    entering: { height: '0px' },
    entered: { height: '180px', display: 'block' },
};

export default class TabsMenuMobile extends React.Component<Props, State> {
    state = {
        menuOpen: false,
        triggerText: 'Review Invites',
    };

    handleMenuOpen = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        // $FlowFixMe
        const nameAttribute = event.target.getAttribute('name') || event.target.parentElement.getAttribute('name');
        if (this.state.menuOpen) this.props.handleItemClick(event, { name: nameAttribute });
        this.setState(state => ({ menuOpen: !state.menuOpen, triggerText: nameAttribute }));
    };

    render() {
        const { menuOpen } = this.state;
        const { triggerText } = this.state;
        const menus = ['Review Invites', 'Reviews Received', 'Customer Feedback', 'Leaderboards'];

        return (
            <div className={`mobile-tabs ${menuOpen ? 'active' : ''}`}>
                <div
                    className="menu-trigger"
                    name={triggerText}
                    onClick={this.handleMenuOpen}
                    style={{ cursor: 'pointer' }}
                >
                    {triggerText} <Icon name="angle down" />
                </div>
                <Transition in={menuOpen} timeout={200} classNames="menu-slide">
                    {state => (
                        <div
                            key="mobile_menu_items"
                            className="menu clearfix"
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state],
                                cursor: 'pointer',
                            }}
                        >
                            {triggerText !== menus[0] && (
                                <div className="menu-item" name={menus[0]} onClick={this.handleMenuOpen}>
                                    {menus[0]}
                                </div>
                            )}

                            {triggerText !== menus[1] && (
                                <div className="menu-item" name={menus[1]} onClick={this.handleMenuOpen}>
                                    {menus[1]}
                                </div>
                            )}

                            {triggerText !== menus[2] && (
                                <div className="menu-item" name={menus[2]} onClick={this.handleMenuOpen}>
                                    {menus[2]}
                                </div>
                            )}

                            {triggerText !== menus[3] && (
                                <div className="menu-item" name={menus[3]} onClick={this.handleMenuOpen}>
                                    {menus[3]}
                                </div>
                            )}
                        </div>
                    )}
                </Transition>
            </div>
        );
    }
}
