// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import SubmissionCard from 'photo/components/Submission/Card';
import PublishedContent from 'models/PublishedContent';

type Props = {
    contentCollection: PublishedContent[],
    selected: ?PublishedContent,
    onCardClick?: Function,
    invertedCards?: boolean,
};

@observer
export default class SubmissionCardList extends React.Component<Props> {
    render() {
        const { contentCollection, selected, onCardClick, invertedCards } = this.props;

        return (
            <div className="items-panel clearfix">
                {contentCollection &&
                    contentCollection.map((submission, i) => (
                        <SubmissionCard
                            key={i}
                            active={!!selected && selected.id === submission.id}
                            inverted={invertedCards}
                            content={submission}
                            onClick={onCardClick}
                            noMenu
                        />
                    ))}
            </div>
        );
    }
}
