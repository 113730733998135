// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import SiteInfo, { type SitesInfoByChannel } from 'models/SiteInfo';
import InviteAnalytics from 'models/InviteAnalytics';

import DataPlaceholder from 'components/Data/Placeholder';
import * as yearMonth from 'helpers/yearMonth/yearMonth';

type Props = {
    inviteAnalytics: InviteAnalytics,
};

const options = {
    maintainAspectRatio: false,
    legend: {
        position: 'bottom',
        labels: {
            fontColor: '#999',
            boxWidth: 10,
            useLineStyle: true,
            fontSize: 11,
            padding: 10,
        },
    },
    animation: {
        animateScale: true,
    },
    scales: {
        xAxes: [
            {
                stacked: true,
            },
        ],
        yAxes: [
            {
                stacked: true,
                ticks: {
                    suggestedMin: 1,
                    suggestedMax: 6,
                    callback: function(value) {
                        return Number(value).toFixed(0);
                    },
                },
            },
        ],
    },
    plugins: {
        datalabels: {
            display: false,
        },
    },
};

@observer
export default class ReviewChannelsPartial extends React.Component<Props> {
    @observable sitesInfoByChannel: SitesInfoByChannel;

    constructor(props: Props) {
        super(props);
        this.loadSitesInfo();
    }

    @action
    setSitesInfoByChannel(sitesInfoByChannel: SitesInfoByChannel) {
        this.sitesInfoByChannel = sitesInfoByChannel;
    }

    async loadSitesInfo() {
        this.setSitesInfoByChannel(await SiteInfo.allByChannel());
    }

    getData() {
        if (!this.sitesInfoByChannel) return {};

        const { inviteAnalytics } = this.props;
        const monthlyData = inviteAnalytics.charts.reviews_received;
        const datasetsPerChannel = {};

        Object.keys(monthlyData).forEach(month => {
            const channelsData = inviteAnalytics.charts.reviews_received[month];

            Object.keys(channelsData).forEach(channel => {
                const siteInfo = this.sitesInfoByChannel[channel];

                if (!siteInfo) {
                    console.warn(`Incoming channel ${channel} not listed on sites info`);
                    return;
                }

                if (undefined === datasetsPerChannel[channel]) {
                    datasetsPerChannel[channel] = {
                        data: [],
                        label: siteInfo.display_name,
                        backgroundColor: siteInfo.backgroundColor,
                        hoverBackgroundColor: siteInfo.hoverBackgroundColor,
                    };
                }

                datasetsPerChannel[channel].data.push(channelsData[channel]);
            });
        });

        return {
            labels: yearMonth.namesFromObject(monthlyData),
            datasets: Object.values(datasetsPerChannel),
        };
    }

    render() {
        const data = this.getData();

        return (
            <DataPlaceholder for={(data.datasets || {}).length}>
                <div className="chart">
                    <Bar data={data} options={options} />
                </div>
            </DataPlaceholder>
        );
    }
}
