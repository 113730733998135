// @flow

import { presets, type App } from 'types';

import InviteSettingsPage from './pages/InviteSettings';
import CustomizationPage from './pages/Customization';
import ReputationReportPage from './pages/ReputationReport';
import ResponseConsolePage from './pages/ResponseConsolePage';
import ReputationSnapshotPage from './pages/ReputationSnapshot';
import NotificationSettings from 'pages/NotificationSettings';

import ReviewsSidebar from './sidebars/Reviews';

const app: App = {
    feature: 'reputation_accelerator',
    path: 'reputation',
    title: 'Catalyst Reputation',
    mobileTitle: 'Reputation',
    description: 'Request & Manage Reviews',
    className: 'reputation',
    browserThemeColor: '#464703',
    icon: 'ra',
    // marketingMessages: [
    //     'Send review invites via text or email',
    //     'Automate the process with DMS integrations',
    //     'Manage all your reviews in one place with hourly monitoring of review sites and alerts for new reviews',
    // ],
    // marketingMessageDisabled:
    //     'Reputation allows you get more reviews through automated text message (or email) review invites, helping your dealership collect valuable feedback and more positive reviews from your happiest customers. Reputation allows you to stay on top of your new reviews as they come in, and manage the response process.',
    disabledLink: 'http://www.friendemic.com/catalyst-reputation/',
    routes: {
        settings: {
            path: 'invite-settings',
            title: 'Invite Settings',
            preset: presets.settings,
            component: InviteSettingsPage,
            role: 'place-manager',
        },
        customization: {
            path: 'customization',
            title: 'Invite Customization',
            preset: presets.settings,
            component: CustomizationPage,
            role: 'place-manager',
        },
        inbox: {
            path: 'response-console',
            title: 'Response Console',
            component: ResponseConsolePage,
        },
        reputationReport: {
            path: 'report',
            title: 'Report',
            preset: presets.report,
            component: ReputationReportPage,
        },
        reputationSnapshot: {
            path: 'snapshot',
            title: 'Snapshot',
            preset: presets.dashboard,
            component: ReputationSnapshotPage,
        },
        notificationSettings: {
            path: 'notifications',
            title: 'Notifications',
            preset: presets.settings,
            component: NotificationSettings,
        },
    },
    sidebars: {
        reviews: ReviewsSidebar,
    },
    defaultRoute: 'inbox',
};

export default app;
