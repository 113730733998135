// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import image_placeholder from 'images/logo_placeholder.png';
import { STATUS_ICON_MAP } from '../../../photo/components/Submission/Modal/SocialPostModal';
import { proxyS3Image } from 'app/helpers';

const NETWORKS_ICON_MAP = {
    facebook: ['facebook', 'blue'],
    gmb: ['google', 'blue'],
    linkedin: ['linkedin', 'blue'],
    instagram: ['instagram', 'pink'],
    twitter: ['twitter', 'blue'],
    youtube: ['youtube', 'red'],
};

const Event = ({ event }) => {
    const date = new Date(event.start);
    const thumbnail_path =
        event.thumbnail_path &&
        (/https:\/\/s3.amazonaws.com/.test(event.thumbnail_path)
            ? proxyS3Image(event.thumbnail_path)
            : event.thumbnail_path);
    let options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    let timeString = date.toLocaleString('en-US', options);
    return (
        <div className="event-triggered">
            <img
                src={thumbnail_path || image_placeholder}
                width="20"
                height="20"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = `${image_placeholder}`;
                }}
            />
            <span>{timeString}</span>
            {NETWORKS_ICON_MAP[event.network_name] && (
                <Icon
                    name={NETWORKS_ICON_MAP[event.network_name][0]}
                    color={NETWORKS_ICON_MAP[event.network_name][1]}
                />
            )}
            <Icon name={STATUS_ICON_MAP[event.soci_status][0]} color={STATUS_ICON_MAP[event.soci_status][1]} />
        </div>
    );
};

Event.propTypes = {
    event: PropTypes.object.isRequired,
};

export default Event;
