// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';

import MaskedField from 'fields/Masked';

export type Props = ComponentProps & {
    label?: string,
    required?: boolean,
};

@observer
export default class ZipcodeField extends React.Component<Props> {
    render() {
        return <MaskedField {...this.props} mask="11111-1111" placeholder="12345-6789" />;
    }
}
