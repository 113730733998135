// @flow

import * as React from 'react';
import { toast as toastify } from 'react-toastify';
import { Icon } from 'semantic-ui-react';

function toast(message: string, options?: Object): void {
    toastify(message, options);
}

toast.success = (message: string): void => {
    toastify.success(
        <React.Fragment>
            <Icon name="check" /> {message}
        </React.Fragment>
    );
};

toast.warning = (message: string): void => {
    // $FlowFixMe
    toastify.warning(
        <React.Fragment>
            <Icon name="warning sign" /> {message}
        </React.Fragment>
    );
};

toast.eternalWarning = (message: string): void => {
    // $FlowFixMe
    toastify.warning(
        <React.Fragment>
            <Icon name="warning sign" /> {message}
        </React.Fragment>,
        { autoClose: false, closeOnClick: false }
    );
};

toast.error = (message: string): void => {
    toastify.error(
        <React.Fragment>
            <Icon name="remove" /> {message}
        </React.Fragment>
    );
};

export default toast;
