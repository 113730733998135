// @flow

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Form as SemanticForm } from 'semantic-ui-react';
import { Field } from 'app/external/mobx-form-for';
import queryString from 'query-string';

import Password from 'models/Password';

import authApi from 'api/authApi';
import GuestForm from 'components/Guest/Form';
import { toast, httpResponseErrors } from 'helpers';
import tokenStore from 'stores/token';

type Props = {};

type State = {
    loading: boolean,
};

const inputProps = {
    required: true,
    transparent: true,
    inverted: true,
    iconPosition: 'left',
};

@observer
export default class NewPasswordPage extends React.Component<Props, State> {
    @observable password: Password = new Password();
    state = { loading: false };

    handleSubmit = async (
        e: any,
        data: { token: string, email: string, password: string, password_confirmation: string }
    ) => {
        this.setState({ loading: true });

        try {
            const authToken = queryString.parse(window.location.search)['token'];

            const { message } = await authApi.submitNewPassword({ ...data, token: authToken });
            const token = await authApi.login(data.email, data.password);

            await tokenStore.set(token);
            toast.success(message);
        } catch (error) {
            toast.error(httpResponseErrors(error));
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const data = {};

        return (
            <GuestForm for={this.password} onSubmit={this.handleSubmit}>
                <SemanticForm.Field>
                    <Field name="email" {...inputProps} />
                </SemanticForm.Field>

                <SemanticForm.Field>
                    <Field name="password" icon="lock" {...inputProps} />
                </SemanticForm.Field>

                <SemanticForm.Field error={data.password !== data.password_confirmation}>
                    <Field name="password_confirmation" icon="lock" {...inputProps} />
                </SemanticForm.Field>

                <SemanticForm.Field>
                    <Button primary className="submit">
                        Save Password
                    </Button>
                </SemanticForm.Field>
            </GuestForm>
        );
    }
}
