import React from 'react';
import PropTypes from 'prop-types';
import { Calendar as RDRCalendar } from 'react-date-range';

const Calendar = ({ date, minDate, maxDate, onChange }) => {
    return (
        <RDRCalendar
            color=""
            date={date}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            className="kui-react-date-picker"
        />
    );
};

Calendar.defaultProps = {
    onChange: Function.prototype,
};

Calendar.propTypes = {
    onChange: PropTypes.func,
    className: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
};

export default Calendar;
