// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import { Field } from 'app/external/mobx-form-for';

// import userInfoStore from 'stores/userInfo';

import Place from 'models/Place';

import userInfoStore from 'stores/userInfo';

import { toast } from 'helpers';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import PlaceMonitor from 'components/Place/Monitor';
import GoogleMap from 'components/GoogleMap';
import { getErrorMessageFromSaveModelError } from 'helpers';

type Props = {};

type State = {
    rectangleWidth?: number,
    rectangleHeight?: number,
};

const RECTANGLE_DEFAULT_WIDTH = 500;
const RECTANGLE_DEFAULT_HEIGHT = 300;
const WIDTH_PROPORTION = 1.5;

@observer
export default class LocationInfoPage extends React.Component<Props, State> {
    renderCarsComSyndication: boolean;
    @observable place: Place;

    constructor(props: Props) {
        super(props);

        this.state = {
            rectangleWidth: RECTANGLE_DEFAULT_WIDTH,
            rectangleHeight: RECTANGLE_DEFAULT_HEIGHT,
        };
    }

    @action
    setPlace(place: ?Place) {
        if (place) place = place.clone();

        this.place = place;
    }

    load = async (place: Place) => {
        if (this.place) this.setPlace(null);
        this.setPlace(await Place.find(place.id));
        this.renderCarsComSyndication = userInfoStore.isAdmin;
    };

    handleSubmit = async (event: Event, place: Place) => {
        try {
            await place.saveChanges();
            toast.success('Location info updated');
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError(error));
        }
    };

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    calculateRectangleDimensions(resource: Object): Object {
        const { width, height } = resource;

        const adaptedHeight = height > RECTANGLE_DEFAULT_HEIGHT ? RECTANGLE_DEFAULT_HEIGHT : height;
        const adaptedWidth =
            width < height * WIDTH_PROPORTION
                ? adaptedHeight * WIDTH_PROPORTION
                : Math.ceil((width / height) * adaptedHeight);

        return {
            height: adaptedHeight,
            width: adaptedWidth,
        };
    }

    handleImageLoadSuccess = (imageInfo: Object) => {
        if (!imageInfo || !imageInfo.resource) {
            return;
        }

        const { width, height } = this.calculateRectangleDimensions(imageInfo.resource);

        this.setState({ rectangleWidth: width, rectangleHeight: height });
    };

    renderContent = () => {
        return (
            <Page for={this.place} onSubmit={this.handleSubmit}>
                <Column>
                    <SettingsPanel title="Location Address">
                        <Field name="street" />

                        <SemanticForm.Group widths="two">
                            <Field name="city" />
                            <Field name="state" />
                        </SemanticForm.Group>

                        <SemanticForm.Group widths="two">
                            <Field name="zip" />
                            {/* {hasElevatedPrivileges && <Field name="external_id" />} */}
                        </SemanticForm.Group>

                        {!!this.place.geocode && <GoogleMap geocode={this.place.geocode} />}
                    </SettingsPanel>

                    {this.renderCarsComSyndication && (
                        <SettingsPanel title="Cars.com integration">
                            <Field name="carscom_syndication_active" />
                        </SettingsPanel>
                    )}
                </Column>

                <Column>
                    <SettingsPanel title="Location Information">
                        <Field name="name" />

                        <SemanticForm.Group widths="two">
                            <Field name="email" />
                            <Field name="phone" />
                        </SemanticForm.Group>

                        <SemanticForm.Group widths="two">
                            <Field name="website" />
                            <Field name="oem_codes" />
                        </SemanticForm.Group>
                        <SemanticForm.Group widths="two">
                            <Field name="hubspot_id" />
                        </SemanticForm.Group>
                    </SettingsPanel>
                </Column>

                <Column>
                    <SettingsPanel title="Logo">
                        <h5>Rectangular Logo</h5>
                        <Field
                            name="logo_url_rectangle"
                            width={this.state.rectangleWidth}
                            height={this.state.rectangleHeight}
                            onImageLoadSuccess={this.handleImageLoadSuccess}
                        />

                        <h5>Square Logo</h5>
                        <Field name="logo_url" />

                        {this.place.logo_url && this.place.logo_url_rectangle && (
                            <React.Fragment>
                                <h5>Logo Placement</h5>
                                <SemanticForm.Group widths="two">
                                    <Field name="emails_page_logo_format" />
                                    <Field name="vip_page_logo_format" />
                                </SemanticForm.Group>
                            </React.Fragment>
                        )}
                    </SettingsPanel>
                </Column>
            </Page>
        );
    };
}
