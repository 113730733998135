// @flow

import React from 'react';
import indexedFeatureApps from 'indexedFeatureApps';
import Sidebar from 'components/Sidebar';
import MainSidebarApp from './App';

export default class MainSidebar extends React.Component<*> {
    render() {
        return (
            <Sidebar title="Main Menu" link="/">
                {Object.values(indexedFeatureApps).map(app => (
                    // $FlowFixMe
                    <MainSidebarApp key={app.feature} app={app} />
                ))}
            </Sidebar>
        );
    }
}
