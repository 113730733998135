// @flow

import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { Field } from 'app/external/mobx-form-for';

import Place from 'models/Place';

import { getErrorMessageFromSaveModelError, toast } from 'helpers';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';

import PlaceMonitor from 'components/Place/Monitor';

import * as inviteGraphicSource from 'enum/inviteGraphicSource';

type Props = {};

@observer
export default class CustomizationPage extends React.Component<Props> {
    @observable saving: boolean = false;
    @observable place: ?Place;

    @action
    startSaving() {
        this.saving = true;
    }

    @action
    stopSaving() {
        this.saving = false;
    }

    @action
    clearPlace() {
        this.place = null;
    }

    @action
    setPlace(place: Place) {
        place = place.clone();
        this.place = place;
        this.place.memorizeChanges();
    }

    async loadPlace(place: Place) {
        this.setPlace(await Place.find(place.id));
    }

    load = async (place: Place) => {
        this.startSaving();

        this.clearPlace();
        await Promise.all([this.loadPlace(place)]);

        this.stopSaving();
    };

    @action
    handleImageChange = () => {
        if (!this.place) return;

        this.place.invite_graphic_source = inviteGraphicSource.DEFAULT;

        if (this.place.invite_graphic_url !== '') {
            this.place.invite_graphic_source = inviteGraphicSource.CUSTOM;
        }
    };

    handleSubmit = async (event: Event, place: Place) => {
        this.startSaving();
        try {
            await place.saveChanges();
            await this.load(place);
            toast.success('Location settings updated');
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError());
        } finally {
            this.stopSaving();
        }
    };

    render() {
        return <PlaceMonitor onChange={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        return (
            <Page for={this.place} onSubmit={this.handleSubmit}>
                {this.place && (
                    <React.Fragment>
                        <Column key="column-1">
                            <SettingsPanel title="Text Graphic">
                                <Field name="invite_graphic_url" onChange={this.handleImageChange} />
                            </SettingsPanel>
                        </Column>

                        <Column key="column-2">
                            <SettingsPanel title="Text Message">
                                <Field name="invite_message_sms" />
                            </SettingsPanel>
                        </Column>
                    </React.Fragment>
                )}
            </Page>
        );
    };
}
