// @flow

import type { App } from 'types';

import MenuDealershipSearchPage from './pages/MenuDealershipSearch';

const app: App = {
    feature: 'rpm',
    path: 'reputation-scorecards',
    title: 'Reputation Scorecards',
    mobileTitle: 'RS',
    description: 'View Scorecard',
    className: 'reputation-scorecards',
    icon: 'rs',
    browserThemeColor: '#1b183a',
    // marketingMessages: [
    //     'Track metrics across multiple sites to monitor reputation',
    //     'Get actionable recommendations to improve scores',
    //     'Identify trends in data',
    // ],
    // marketingMessageDisabled:
    //     "Get a monthly updates on how your dealership is doing on online review sites to determine how you're doing. Get recommended actions each month to help you improve your ratings.",
    routes: {
        search: {
            title: 'Dealership Search',
            path: 'dealership-search',
            component: MenuDealershipSearchPage,
        },
    },
    defaultRoute: 'search',
};

export default app;
