// @flow

import { api } from '@friendemic/premiere';

class AuthApi {
    async login(email: string, password: string): Promise<string> {
        const response = await api.http.post('/login', { email, password });
        return response.data.token;
    }

    async requestPasswordReset(email: string): Promise<Object> {
        const { data } = await api.http.post('/password/forgot', { data: { email } });
        return data;
    }

    async submitNewPassword(requestParams: Object): Promise<Object> {
        const { data } = await api.http.post('/password/reset', {
            data: requestParams,
        });
        return data;
    }
}

export default new AuthApi();
