// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';

import { roles } from 'types';

import userInfoStore from 'stores/userInfo';

import SelectField from 'fields/Select';

export type Props = ComponentProps;

const reverseRoles = roles.concat([]).reverse();

@observer
export default class RoleField extends React.Component<Props> {
    render() {
        const roleIndex = reverseRoles.indexOf(userInfoStore.roleSlug);
        const options = reverseRoles.slice(roleIndex).filter(role => role !== 'system-admin');

        return <SelectField {...this.props} options={options} search />;
    }
}
