// @flow
import * as React from 'react';

import InviteAnalytics from 'models/InviteAnalytics';
import NumberOfInvitesPartial from './NumberOfInvitesPartial';
import ReportSection from 'components/Report/Section';
import ReviewChannelsPartial from './ReviewChannelsPartial';

type Props = {
    inviteAnalytics: InviteAnalytics,
};

export default class ReviewsRecievedAnalytics extends React.Component<Props> {
    render() {
        const { inviteAnalytics } = this.props;
        return (
            <React.Fragment>
                <ReportSection id="reviewChannels" title="Review Channels">
                    <ReviewChannelsPartial inviteAnalytics={inviteAnalytics} />
                </ReportSection>
                <ReportSection id="numberOfInvites" title="# of Invites Sent">
                    <NumberOfInvitesPartial inviteAnalytics={inviteAnalytics} chart="invites_sent" />
                </ReportSection>
            </React.Fragment>
        );
    }
}
