// @flow

import { observer } from 'mobx-react';
import React from 'react';
import { Input } from 'semantic-ui-react';
import AvatarEditor from 'react-avatar-editor';

type Position = { x: number, y: number };

type Props = {
    value: string | File,
    originalValue: ?(string | File),
    width?: ?number,
    height?: ?number,
    position: ?Position,
    scale: ?number,
    onLoadSuccess: ?Function,
};

type State = {
    scale: number,
    position: Position,
};

@observer
export default class ImageFieldEditor extends React.Component<Props, State> {
    avatarEditor: ?AvatarEditor;

    constructor(props: Props) {
        super(props);

        this.state = {
            position: props.position || { x: 0.5, y: 0.5 },
            scale: props.scale || 1,
        };
    }

    getValueProperties(): { position: Position, scale: number, value: string } {
        const avatarEditor = this.avatarEditor;
        if (!avatarEditor) throw new Error('Undefined avatar editor on ImageFieldEditor');

        const value = avatarEditor.getImageScaledToCanvas().toDataURL();
        const { position, scale } = this.state;
        return { value, position, scale };
    }

    handleSetAvatarEditorRef = (element: ?AvatarEditor) => {
        if (element) this.avatarEditor = element;
    };

    handlePositionChange = (position: Position) => {
        this.setState({ position });
    };

    handleScaleChange = (event: Event, meta: { value: number }) => {
        this.setState({ scale: meta.value });
    };

    render() {
        const { value, originalValue, width, height, onLoadSuccess } = this.props;
        const { position, scale } = this.state;

        return (
            <div className="image-editor">
                <AvatarEditor
                    ref={this.handleSetAvatarEditorRef}
                    image={originalValue || value}
                    width={width}
                    height={height}
                    position={position}
                    onLoadSuccess={onLoadSuccess}
                    onPositionChange={this.handlePositionChange}
                    scale={parseFloat(scale)}
                />

                <footer>
                    <Input
                        type="range"
                        min="0.01"
                        max="2"
                        step="0.01"
                        value={this.state.scale}
                        onChange={this.handleScaleChange}
                    />
                </footer>
            </div>
        );
    }
}
