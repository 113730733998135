// @flow

import { observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import * as React from 'react';
import { Field, FieldGroup } from 'app/external/mobx-form-for';
import { Modal as SemanticModal, Button, Message, Form as SemanticForm, List } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';

import BulkInvite from 'models/BulkInvite';

import placeStore from 'stores/place';

import { toast } from 'helpers';

import FormButton from 'components/FormButton';
import Modal from 'components/Modal';
import DataExporter from 'components/Data/Exporter';
import tokenStore from 'app/stores/token/tokenStore';

type Props = {
    trigger: React.Node,
};

@observer
export default class UploadInviteFilePartial extends React.Component<Props> {
    button: ?HTMLButtonElement;
    @observable bulkInvite: BulkInvite = new BulkInvite();
    @observable termsAccepted: boolean = false;
    @observable busy: boolean = false;
    @observable data: ?Object;

    @action
    resetBusy = () => {
        this.busy = false;
    };

    @action
    setData = (data: Object) => {
        this.data = data;
    };

    @action
    resetData = () => {
        this.data = null;
    };

    @action
    handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        if (!this.bulkInvite.type) {
            throw new Error('You need to select invite type');
        }

        this.busy = true;
        this.resetData();

        try {
            const formData = new FormData();
            formData.append('invite_file', event.currentTarget.invite_file.files[0], 'invite_file.csv');
            formData.append('type', event.currentTarget.type.value);
            formData.append('place_uuid', event.currentTarget.place_uuid.value);
            var myHeaders = new Headers();
            myHeaders.append(
                'Authorization',
                `Bearer ${
                    tokenStore.jwtToken
                        ? tokenStore.jwtToken
                        : localStorage.getItem('jwt')
                        ? localStorage.getItem('jwt')
                        : ''
                }`
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formData,
                redirect: 'follow',
            };

            const data = await BulkInvite.upload(requestOptions);
            this.resetBusy();
            this.setData(data);

            if (data && data[0] && data[0].errors) {
                throw new Error('One or more invites failed. Download the failed file below to see more.');
            }
            toast.success('The invite file was uploaded.');
            return { data };
        } finally {
            this.resetBusy();
        }
    };

    @action.bound
    handleTermsAcceptance() {
        this.termsAccepted = true;
    }

    @action.bound
    handleCancel() {
        this.termsAccepted = false;
        this.data = null;
        this.resetBulkInvite();
    }

    @action
    resetBulkInvite() {
        this.bulkInvite = new BulkInvite();
    }

    @computed
    get uploadFieldMessage(): React.Node {
        const columns = {
            'Customer Name': 'Customer Name',
            'Mobile Number': 'Mobile Number',
            'Email Address': 'Email Address',
            errors: 'errors',
        };

        if (this.data && this.data[0] && this.data[0].errors) {
            return (
                <Message className="custom" info color="red">
                    <DataExporter
                        columns={columns}
                        data={this.data}
                        filename={`failed_invite_entries-${new Date().getTime()}.csv`}
                    >
                        {'Click here to download the file containing the failed invite entries'}
                    </DataExporter>
                </Message>
            );
        }

        return (
            <Message className="custom" info color="green">
                <List
                    as="ol"
                    style={{
                        paddingInlineStart: '1em',
                    }}
                >
                    <List.Item as="li">
                        {'Download the CSV template '}
                        {this.renderCsvTemplate}
                        {'.'}
                    </List.Item>
                    <List.Item as="li">
                        {
                            'Modify and save the file. Please be aware that .numbers files are not accepted. Those files will need to be exported as .csv before upload.'
                        }
                    </List.Item>
                    <List.Item as="li">
                        {'Use the form above to select Sales or Service and upload your modified CSV.'}
                    </List.Item>
                    <List.Item as="li">{'Bulk invites will be delivered between 1-5PM CST.'}</List.Item>
                </List>
            </Message>
        );
    }

    get renderCsvTemplate() {
        const csvExampleValues = [
            { 'Customer Name': 'John Doe', 'Mobile Number': '123456789', 'Email Address': 'johndoe@example.com' },
        ];
        return (
            <CSVLink data={csvExampleValues} filename="invite_file.csv">
                here
            </CSVLink>
        );
    }

    get renderUploadModal() {
        return (
            <Modal.Action
                className="custom"
                title="Upload invite file"
                icon="cloud upload"
                action="Upload"
                onAction={this.handleSubmit}
                trigger={<FormButton primary text="Accept" />}
                encType="multipart/form-data"
                closeOnDimmerClick={false}
                onClose={this.handleCancel}
            >
                <React.Fragment>
                    <input type="hidden" name="place_uuid" value={placeStore.place.uuid} />
                    <input type="hidden" name="type" value={this.bulkInvite.type || ''} />

                    <FieldGroup for={this.bulkInvite}>
                        <SemanticForm.Field>
                            <p style={{ fontSize: '1.3em', fontWeight: 'bold' }}>
                                Download the <code>.CSV</code> template {this.renderCsvTemplate}.
                            </p>
                        </SemanticForm.Field>
                        <Field name="type" disabled={this.busy} />
                        <Field name="invite_file" disabled={this.busy} />
                        {this.uploadFieldMessage}
                    </FieldGroup>
                </React.Fragment>
            </Modal.Action>
        );
    }

    get renderAgreement() {
        return (
            <SemanticModal
                className="custom"
                size="tiny"
                onOpen={this.handleTermsAcceptance}
                onClose={this.handleCancel}
                open={this.termsAccepted}
                closeOnDimmerClick={false}
                trigger={this.props.trigger}
            >
                <SemanticModal.Header>Upload Agreement</SemanticModal.Header>
                <SemanticModal.Content>
                    I understand that electronic communications to consumers, including via this system, may be
                    regulated by federal and local laws. I verify that any list I upload will only contain consumer data
                    to which I may legally send emails and text messages, and that I will only use this system to
                    request feedback from consumers who have had recent interactions with my business in accordance with
                    the Terms of Service of this software. I indemnify the owners of this software for any damages
                    resulting from my misuse of this system in violation of the statements above or in our contract.
                </SemanticModal.Content>
                <SemanticModal.Actions>
                    <Button type="button" onClick={this.handleCancel} size="tiny" color="grey" basic>
                        Cancel
                    </Button>
                    {this.renderUploadModal}
                </SemanticModal.Actions>
            </SemanticModal>
        );
    }

    render() {
        return this.renderAgreement;
    }
}
