// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Icon, Button } from 'semantic-ui-react';

import videoStore from 'video/stores/video';
import VideoClipboardPopup from 'video/components/Video/ClipboardPopup';

import Panel from 'components/Panel';

type Props = {};

@observer
export default class VideoInfoPartial extends React.Component<Props> {
    render() {
        const video = videoStore.video;
        if (!video) return null;

        return (
            <Panel title="Video Info" position="right">
                <div id="additional-info" className="items-panel clearfix">
                    <div className="additional-info">
                        <div className="item">
                            <div className="title">Title</div>
                            <p>{video.title}</p>
                        </div>

                        <div className="item">
                            <div className="title">Submitted By</div>
                            <p>{video.user_name}</p>
                        </div>

                        <div className="item">
                            <div className="title">Date &amp; Time Created</div>
                            <p>
                                <span>
                                    <Icon name="clock outline" /> {video.time}
                                </span>{' '}
                                <span>
                                    <Icon name="calendar alternate outline" /> {video.date}
                                </span>
                            </p>
                        </div>

                        <div className="item">
                            <div className="title">Description</div>
                            <p>{video.description}</p>
                        </div>

                        <div className="item">
                            <div className="title">Video Share URL</div>
                            <p className="share-url">
                                {video.youtube_id && (
                                    <a href={video.shareUrl} target="_blank" rel="noopener noreferrer">
                                        {video.shareUrl}
                                    </a>
                                )}
                            </p>
                            <VideoClipboardPopup video={videoStore.video}>
                                <Button primary className="option-btn">
                                    <Icon name="linkify" /> Copy URL
                                </Button>
                            </VideoClipboardPopup>
                        </div>

                        <div className="item">
                            <div className="title">Video Embed Code</div>
                            <VideoClipboardPopup video={videoStore.video} embedHTML />
                        </div>
                    </div>
                </div>
            </Panel>
        );
    }
}
