// @flow

import { observable } from 'mobx';
import { field } from 'app/external/form-for';
import Model from 'models/Model';
import type { Role } from 'types';
import NotificationSettings from './NotificationSettings';

export default class User extends Model {
    path = 'users';

    @observable
    @field({ required: true })
    name: string;

    @observable
    @field({ type: 'email', placeholder: 'Your e-mail', required: true })
    email: string;

    @observable
    @field({ type: 'tel' })
    phone: string;

    @observable
    @field({ type: 'Place', required: true, search: true })
    places: number[] = [];

    @observable
    @field({ type: 'password', label: 'Current Password:', error: 'validateOldPassword' })
    old_password: string;

    @observable
    @field({ type: 'password', label: 'Password:', error: 'validatePassword', required: true })
    password: string;

    @observable
    @field({ type: 'password', label: 'Confirm New Password:', error: 'validatePasswordConfirmation', required: true })
    password_confirmation: string;

    @observable
    @field({ type: 'Role', required: true })
    role: Role;

    @observable
    @field
    external_id: string;

    created_at: {
        date: string,
        timezone_type: number,
        timezone: string,
    };

    deleted_at: ?string;

    get hasPassword(): boolean {
        return !!(this.password || '').length;
    }

    get hasOldPassword(): boolean {
        return !!(this.old_password || '').length;
    }

    get hasPasswordConfirmation(): boolean {
        return !!(this.password_confirmation || '').length;
    }

    get roleName(): string {
        return this.role
            .split('-')
            .map(w => w[0].toUpperCase() + w.substr(1))
            .join(' ');
    }

    notificationSettings(): Promise<NotificationSettings> {
        return this.hasOne(NotificationSettings);
    }

    validateOldPassword() {
        if (this.hasPassword && !this.hasOldPassword) return 'You must inform the current password';
    }

    validatePassword() {
        if (this.hasOldPassword && !this.hasPassword) return 'The new password must not be blank';
    }

    validatePasswordConfirmation() {
        const shouldValidate = this.hasPassword || this.hasPasswordConfirmation;
        if (shouldValidate && this.password !== this.password_confirmation)
            return 'Password confirmation does not match';
    }
}
