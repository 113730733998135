// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Icon, Message, Modal } from 'semantic-ui-react';
import { proxyS3Image } from 'helpers';
import type { ComponentProps } from 'app/external/form-for';

import ImageFieldDropzone from './Dropzone';
import ImageFieldEditor from './Editor';
import ImageFieldPreview from './Preview';
import { DropzoneRef } from 'react-dropzone';

type Props = ComponentProps & {
    label?: string,
    width?: number,
    height?: number,
    helpText?: string,
    onImageLoadSuccess?: Function,
};

type State = {
    editing?: boolean,
    originalValue: string | File,
    position?: any,
    scale?: any,
};

@observer
export default class ImageField extends React.Component<Props, State> {
    dropzone: { current: null | DropzoneRef };
    editor: ?ImageFieldEditor;

    constructor(props: Props) {
        super(props);
        this.state = { originalValue: proxyS3Image(props.value) };
    }

    dispatchChange(value: string = '') {
        this.props.onChange(null, value);
    }

    handleFileChange = (value: ?File) => {
        if (value) {
            this.setState({ editing: true, originalValue: value });
        } else {
            this.dispatchChange();
        }
    };

    handleEditorChange = () => {
        const editor = this.editor;
        if (!editor) throw new Error('Undefined editor element for image field');

        const { position, scale, value } = editor.getValueProperties();
        this.setState({ editing: false, position, scale });
        this.dispatchChange(value);
    };

    handleOpenEditor = () => {
        this.setState({ editing: true });
    };

    handleCloseEditor = () => {
        this.setState({ editing: false });
    };

    handleRequestUpload = () => {
        if (!this.dropzone || !this.dropzone.current) throw new Error('Undefined file input');

        this.dropzone.current.open();
    };

    handleRemove = () => {
        this.handleFileChange(null);
    };

    get helpText() {
        const { helpText, width, height } = this.props;

        if (helpText) return helpText;

        if (width && height) return `Preferred size ${width}x${height}. JPG, GIF, PNG accepted.`;

        return '';
    }

    render() {
        const { name, label, width, height } = this.props;
        const { editing, originalValue, position, scale } = this.state;

        const value = proxyS3Image(this.props.value);
        const placeholderStyle = value ? { display: 'none' } : null;

        return (
            <React.Fragment>
                <div key="image" className="field">
                    <ImageFieldDropzone
                        name={name}
                        captureRef={el => (this.dropzone = el)}
                        accept=".png,.jpg,.gif"
                        onChange={this.handleFileChange}
                        style={placeholderStyle}
                        uploadIcon="image"
                        uploadText="Upload Image"
                    />
                    {value && <ImageFieldPreview title={label || name} value={value} />}
                    {this.helpText && (
                        <Message className="custom" info color="green">
                            {this.helpText}
                        </Message>
                    )}
                </div>

                {!!value && (
                    <div key="buttons" className="field button-group">
                        {value && (
                            <React.Fragment>
                                <Button type="button" size="tiny" primary onClick={this.handleOpenEditor}>
                                    <Icon name="crop" /> Edit {label}
                                </Button>

                                <Button type="button" size="tiny" primary onClick={this.handleRequestUpload}>
                                    <Icon name="image" /> Upload New {label}
                                </Button>

                                <Button type="button" size="tiny" primary onClick={this.handleRemove}>
                                    <Icon name="remove" /> Remove
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                )}

                <Modal className="custom" size="tiny" dimmer="blurring" open={editing} onClose={this.handleCloseEditor}>
                    <Modal.Header>
                        <Icon name="crop" /> Edit Image
                    </Modal.Header>

                    <Modal.Content>
                        <ImageFieldEditor
                            ref={el => (this.editor = el)}
                            value={value}
                            originalValue={originalValue}
                            width={width}
                            height={height}
                            position={position}
                            scale={scale}
                            onLoadSuccess={this.props.onImageLoadSuccess}
                        />
                    </Modal.Content>

                    <Modal.Actions>
                        <Button type="button" size="tiny" positive onClick={this.handleEditorChange}>
                            <Icon name="checkmark" /> Done
                        </Button>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}
