// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'app/external/mobx-form-for';

import User from 'models/User';

import userInfo from 'stores/userInfo';

import { toast } from 'helpers';

import Page from 'components/Page';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import Loader from 'components/Loader';
import { getErrorMessageFromSaveModelError } from 'helpers';

type Props = {};

@observer
export default class ProfilePage extends React.Component<Props> {
    @observable user: User;

    @action
    setUser(user: User) {
        if (user) user = user.clone();
        this.user = user;
    }

    loadUser = async () => {
        this.setUser(await User.find(userInfo.user.id));
    };

    getPasswordMatch(): boolean {
        // $FlowFixMe
        const { password, password_confirmation } = this.state.data;
        return (password || '') === (password_confirmation || '');
    }

    handleSubmit = async (event: SyntheticEvent<HTMLFormElement>, user: User) => {
        try {
            await user.saveChanges();
            toast.success('Profile updated');
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError(error));
        }
    };

    render() {
        return <Loader onLoad={this.loadUser} render={this.renderContent} />;
    }

    renderContent = () => {
        return (
            <Page for={this.user} onSubmit={this.handleSubmit}>
                <Column key="columns-1">
                    <SettingsPanel title="My Info">
                        <Field name="name" autoFocus />

                        <Form.Group widths="two">
                            <Field name="email" />
                            <Field name="phone" />
                        </Form.Group>
                    </SettingsPanel>

                    <SettingsPanel title="Password">
                        <Form.Group widths="two">
                            <Field name="old_password" />
                        </Form.Group>

                        <Form.Group widths="two">
                            <Field name="password" label="New Password" required={false} />
                            <Field name="password_confirmation" required={false} />
                        </Form.Group>
                    </SettingsPanel>
                </Column>
            </Page>
        );
    };
}
