// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import type { ComponentProps } from 'app/external/form-for';

import SelectField from 'fields/Select';

import states from './states.json';

export type Props = ComponentProps;

@observer
export default class StateField extends React.Component<Props> {
    render() {
        return <SelectField {...this.props} options={states} search />;
    }
}
