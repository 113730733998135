import { api } from '@friendemic/premiere';
import placeStore from 'app/stores/place/placeStore';
import React from 'react';

export default function useSociNetworks() {
    const [networks, setNetworks] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (placeStore.place.features.soci_enabled) {
            setLoading(true);
            api.http.get(`/soci/networks/${placeStore.place.id}`).then(resp => {
                if (resp.data.status) {
                    const data = resp.data.data;
                    if (data.youtube.status) {
                        data.youtube.id = 112233;
                        data.youtube.url = '';
                    }
                    setNetworks(data);
                } else {
                    setNetworks(undefined);
                }
                setLoading(false);
            });
        }
    }, [placeStore.place.id]);

    return { networks, loading };
}
