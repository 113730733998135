// @flow

import { observer } from 'mobx-react';
import React from 'react';
import FilePreview from 'components/FilePreview';

type Props = {
    title: string,
    value: string | File,
};

@observer
export default class ImageFieldPreview extends React.Component<Props> {
    render() {
        const { title, value } = this.props;

        return (
            <FilePreview input={value}>
                {data => (
                    <div className="image-holder flex-image">
                        <img src={typeof value === 'string' ? value : data} alt={title} />
                    </div>
                )}
            </FilePreview>
        );
    }
}
