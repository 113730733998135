// @flow

import { presets } from 'types';
import type { App } from 'types';

import SettingsSidebar from 'sidebars/Settings';
import NotificationsSidebar from 'sidebars/Notifications';

import GeneralPage from './pages/General';

const app: App = {
    feature: 'organization',
    path: 'organization',
    title: 'Organization',
    mobileTitle: 'Organization',
    description: 'Organization Tools',
    className: 'organization',
    browserThemeColor: '#3f3f3f',
    icon: 'university',
    semanticIcon: true,
    role: 'organization-manager',
    routes: {
        general: {
            path: 'general',
            title: 'General Settings',
            component: GeneralPage,
            preset: presets.settings,
            exact: true,
            notLocationDependent: true,
            role: 'organization-manager',
        },
    },
    defaultRoute: 'general',
    sidebars: {
        settings: SettingsSidebar,
        notifications: NotificationsSidebar,
    },
};

export default app;
