// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import PropTypes from 'prop-types';

import placeStore from 'stores/place';

import type { App } from 'types';

import Logo from 'components/Logo';
import SidebarButton from 'components/Sidebar/Button';

type Props = {
    title?: string,
    mobileTitle?: string,
    basic?: boolean,
};

@observer
export default class HeaderMainMenu extends React.Component<Props> {
    static contextTypes = {
        app: PropTypes.object,
    };

    render() {
        const app: App = this.context.app;
        const { title, mobileTitle, basic } = this.props;

        const inactive = basic || !placeStore.place;
        const mainMenuClasses = ['clearfix'];
        if (inactive) mainMenuClasses.push('inactive');

        return (
            <SidebarButton
                sidebar="general.main"
                render={({ onClick }) => (
                    <div
                        id="main-menu-toggle"
                        className={mainMenuClasses.join(' ')}
                        onClick={inactive ? null : onClick}
                    >
                        {!inactive && (
                            <span className="menu-icon-container">
                                <span className="menu-icon">
                                    <span className="line line-1" />
                                    <span className="line line-2" />
                                    <span className="line line-3" />
                                </span>
                            </span>
                        )}

                        <Logo id="main-logo" />
                        <span className="app-label desktop">{title || app.title}</span>
                        <span className="app-label mobile">{mobileTitle || app.mobileTitle}</span>
                    </div>
                )}
            />
        );
    }
}
