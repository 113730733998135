// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import type { App } from 'types';
import { route } from 'helpers';
import * as apps from 'apps';

type Props = {
    routes: string[],
};

export default class Breadcumbs extends React.Component<Props> {
    static contextTypes = {
        app: PropTypes.object,
        route: PropTypes.string,
    };

    render() {
        const { routes } = this.props;

        return (
            <div className="ui large breadcrumb main custom">
                {routes.map((routeName, index) => {
                    const routeNameSplit = routeName.split('.');
                    const app = routeNameSplit.length > 1 ? apps[routeNameSplit[0]] : this.context.app;

                    return (
                        <React.Fragment key={index}>
                            {this.renderRoute(app, routeName)}
                            <i className="right angle icon divider" />
                        </React.Fragment>
                    );
                })}

                {this.renderRoute(this.context.app, this.context.route, true)}
            </div>
        );
    }

    renderRoute(app: App, routeName: string, active?: boolean) {
        const routeItem = app.routes[routeName];

        return (
            <Link to={route(app, routeName)} className={`section ${active ? 'active' : ''}`}>
                {routeItem.title}
            </Link>
        );
    }
}
