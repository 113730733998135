// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Button, Modal as SemanticModal } from 'semantic-ui-react';

import { toast } from 'helpers';

import FormButton from 'components/FormButton';
import Modal from 'components/Modal';

type Props = {
    title: string,
    trigger: React.Node,
    buttonText: string,
    onConfirm: Function,
    text: string,
};

@observer
export default class ModalConfirm extends React.Component<Props> {
    close: Function;

    @observable loading: boolean = false;

    @action
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    handleConfirm = async () => {
        this.setLoading(true);

        try {
            await this.props.onConfirm();
            this.close();
        } catch (e) {
            this.setLoading(false);
            toast.error(`Failed to ${this.props.buttonText.toLowerCase()}`);
        }
    };

    render() {
        const { title, trigger, buttonText, text, ...rest } = this.props;

        return (
            <Modal
                className="custom"
                title={title}
                trigger={trigger}
                size="tiny"
                {...rest}
                render={({ onClose }) => {
                    this.close = onClose;

                    return (
                        <React.Fragment>
                            <SemanticModal.Content>{text}</SemanticModal.Content>

                            <SemanticModal.Actions>
                                {!this.loading && (
                                    <Button type="button" onClick={onClose} size="tiny" color="grey" basic>
                                        Cancel
                                    </Button>
                                )}

                                <FormButton
                                    onClick={this.handleConfirm}
                                    primary
                                    saving={this.loading}
                                    text={buttonText}
                                />
                            </SemanticModal.Actions>
                        </React.Fragment>
                    );
                }}
            />
        );
    }
}
