// @flow

import { observer } from 'mobx-react';
import React from 'react';
import LoaderPortal from './Portal';
import * as Sentry from '@sentry/react';
import SentryFullStory from '@sentry/fullstory';

import { toast } from 'helpers';

import Page from 'components/Page';

type Props = {
    onLoad?: Function,
    render?: Function,
};

type State = {
    status: string,
    error: ?string,
};

if (process.env.REACT_APP_SENTRY_ID) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_ID,
        environment: process.env.REACT_APP_ENVIRONMENT,
        integrations: [new SentryFullStory('catalyst-react')],

        beforeBreadcrumb(breadcrumb) {
            if (
                breadcrumb.category === 'xhr' &&
                breadcrumb.data &&
                breadcrumb.data.url &&
                breadcrumb.data.url.includes('fullstory')
            ) {
                return null;
            } else {
                return breadcrumb;
            }
        },
    });
}

@observer
export default class Loader extends React.Component<Props, State> {
    state = { status: 'loading', error: null };

    submitError(error: Object, errorInfo?: Object) {
        if (process.env.REACT_APP_SENTRY_ID) {
            Sentry.withScope(scope => {
                if (errorInfo) {
                    Object.keys(errorInfo).forEach(key => {
                        // $FlowFixMe
                        scope.setExtra(key, errorInfo[key]);
                    });
                }
                Sentry.captureException(error);
            });
        }
    }

    getErrorMessage(error: Object) {
        try {
            return error.response.data.error;
        } catch (e) {
            console.warn(e.message);
        }

        return error.message;
    }

    componentDidCatch(err: Object, errorInfo: Object) {
        const error = this.getErrorMessage(err);
        this.setState({ status: 'error', error });
        this.submitError(err, errorInfo);
    }

    async load() {
        this.setState({ status: 'loading' });

        try {
            // $FlowFixMe
            this.props.onLoad && (await this.props.onLoad());
            this.setState({ status: 'done' });
        } catch (err) {
            console.error(err);
            this.submitError(err);
            const error = this.getErrorMessage(err);
            this.setState({ status: 'error', error });
            toast.error(error);
        }
    }

    async componentDidMount() {
        await this.load();
    }

    render() {
        const { render } = this.props;
        const { status } = this.state;

        if (!render || status === 'loading') return <LoaderPortal />;
        if (status === 'error') return this.renderErrors();

        return render();
    }

    renderErrors() {
        const { error } = this.state;

        return (
            <Page>
                <div style={{ padding: '20px' }}>{error}</div>
            </Page>
        );
    }
}
