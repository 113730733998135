// @flow

import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import Review from 'models/Review';
import SiteInfo, { type SitesInfoByChannel } from 'models/SiteInfo';

import googleIcon from 'images/google.svg';
import yelpIcon from 'images/yelp.svg';
import facebookIcon from 'images/facebook.svg';
import carscomIcon from 'images/carscom.svg';
import dealerraterIcon from 'images/dealerrater.svg';
import cargurusIcon from 'images/cargurus.svg';
import carfaxIcon from 'images/carfax.svg';

type Props = {
    review: Review,
};

@observer
export default class ReviewThumbnail extends React.Component<Props> {
    @observable sitesInfoByChannel: ?SitesInfoByChannel;

    @action
    setSitesInfoByChannel(sitesInfoByChannel: SitesInfoByChannel) {
        this.sitesInfoByChannel = sitesInfoByChannel;
    }

    async loadSitesInfo() {
        this.setSitesInfoByChannel(await SiteInfo.allByChannel());
    }

    componentWillMount() {
        this.loadSitesInfo();
    }

    @computed
    get channelIcon(): string {
        const { review } = this.props;
        const icons = {
            googleplace: googleIcon,
            yelp: yelpIcon,
            facebook: facebookIcon,
            carscom: carscomIcon,
            dealerrater: dealerraterIcon,
            cargurus: cargurusIcon,
            carfax: carfaxIcon,
        };

        return icons[review.channel];
    }

    render() {
        const { review } = this.props;

        return (
            <div className="thumb background">
                <div className="flip">
                    <div className="faces">
                        <div className={`face front outlined ${review.channel}`}>
                            <img className="image-icon" src={this.channelIcon} alt={review.channel} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
