// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import Video from 'models/Video';

type Props = {
    video: Video,
};

@observer
export default class VideoThumbnail extends React.Component<Props> {
    render() {
        const { video } = this.props;

        return (
            <div
                className="thumb no-select video flex-image"
                style={{
                    // TODO - It works, but move it to the less files at some point
                    backgroundImage: `url(${video.thumbnail})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
        );
    }
}
