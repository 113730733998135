// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Dropdown } from 'semantic-ui-react';

import userInfoStore from 'stores/userInfo';
import tokenStore from 'stores/token';

import UserInitials from 'components/User/Initials';
import HeaderUserButton from './Button';
import placeStore from 'app/stores/place/placeStore';
import { isAnyLocationUpgraded } from 'app/helpers/kenectUpgrade';

type Props = {
    basic?: boolean,
    onToggleSearchOnMobile: Function,
    searchable: boolean,
};

@observer
export default class HeaderUser extends React.Component<Props> {
    render() {
        const isDisabled = isAnyLocationUpgraded(placeStore, userInfoStore);

        const { basic, onToggleSearchOnMobile, searchable } = this.props;
        // const hasNotifications = placeStore.place && !hasRole('system-admin');
        const hasNotifications = false;
        return (
            <div id="user-menu" className="clearfix">
                {!basic && <HeaderUserButton id="settings-menu-toggle" sidebar="general.settings" icon="setting" />}
                {hasNotifications && (
                    <HeaderUserButton id="notifications-menu-toggle" sidebar="general.notifications" icon="alarm" />
                )}
                {searchable && (
                    <HeaderUserButton
                        id="search-menu-toggle"
                        icon="search"
                        onToggleSearchOnMobile={onToggleSearchOnMobile}
                    />
                )}

                <Dropdown pointing="top right" inline icon={null} trigger={this.renderDropdownText()}>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/profile" disabled={isDisabled}>
                            <Icon name="user circle" className="outlined" /> My Profile
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item onClick={tokenStore.clear}>
                            <Icon name="power" /> Log Out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    renderDropdownText = () => {
        const name = userInfoStore.user.name;

        return (
            <div className="text">
                <span key="name" className="name-wrapper">
                    {name} <Icon name="dropdown" />
                </span>

                <UserInitials name={name} className="ui custom circular icon outline button" />
            </div>
        );
    };
}
