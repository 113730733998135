// @flow

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import * as React from 'react';
import { Button, Form as SemanticForm, Icon } from 'semantic-ui-react';
import { Form, Field } from 'app/external/mobx-form-for';
import scrollIntoView from 'scroll-into-view';

import { toast, route } from 'helpers';

import Organization from 'models/Organization';

import Page from 'components/Page';
import Sortable from 'components/Sortable';
import Column from 'components/Column';
import SettingsPanel from 'components/Settings/Panel';
import Modal from 'components/Modal';
import PromptUnsaved from 'components/Prompt/Unsaved';
import Loader from 'components/Loader';
import FormButton from 'components/FormButton';
import Pagination from 'components/Pagination';
import ConnectButton from 'components/ConnectButton';

import OrganizationEditPartial from './partials/OrganizationEditPartial';
import { getErrorMessageFromSaveModelError } from 'helpers';

type Props = { history: Object };

@observer
export default class OrganizationOverviewPage extends React.Component<Props> {
    @observable newOrganization: Organization;
    @observable organizations: ?(Organization[]);

    organizationsMeta: { current_page: number, last_page: number, total: number };
    filters: Object = { search: '' };

    get page(): number {
        return (this.organizationsMeta || {}).current_page || 1;
    }

    get queryParams(): Object {
        const params: Object = { page: this.page, per_page: 25 };

        const { search } = this.filters;
        if (search.length) params.search = search;

        return params;
    }

    @observable creating: boolean = false;
    newOrganizationCounter: number = 0;

    @action
    setCreating(creating: boolean) {
        this.creating = creating;
    }

    @action
    resetNewOrganization() {
        this.newOrganization = new Organization();
        this.newOrganizationCounter++;
    }

    @action
    setOrganizations(organizations: ?(Organization[])) {
        this.organizations = organizations;
    }

    @action
    async loadOrganizations() {
        if (this.organizations) this.setOrganizations(null);

        const queryParams = this.queryParams;
        const options = {
            queryParams,
            callback: response => {
                const { data, meta } = response.data;
                this.organizationsMeta = meta;
                return { data };
            },
        };

        // Force cache clearence due to Premiere issue - it doesn't go through callback twice
        Organization.reflector.store.cache.lists.clear();

        const organizations = await Organization.all(options);
        if (JSON.stringify(queryParams) !== JSON.stringify(this.queryParams)) return;

        this.setOrganizations(organizations);
        scrollIntoView(document.getElementById('omni-search'));
    }

    load = async () => {
        this.resetNewOrganization();
        await this.loadOrganizations();
    };

    handlePageChange = (_: Event, data: { activePage: number }) => {
        this.organizationsMeta.current_page = data.activePage;
        this.loadOrganizations();
    };

    getOrganizationSearch(organization: Organization) {
        return (
            'organization=' +
            JSON.stringify({
                id: organization.id,
                name: organization.name.split('&').join(' '),
            })
        );
    }

    handleCreate = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setCreating(true);

        try {
            await this.newOrganization.saveChanges();
            toast.success(`Organization ${this.newOrganization.name} created`);

            this.resetNewOrganization();
            await this.loadOrganizations();
        } catch (error) {
            toast.error(getErrorMessageFromSaveModelError(error));
        } finally {
            this.setCreating(false);
        }
    };

    handleDelete = async (organization: Organization) => {
        await organization.destroy();
        this.loadOrganizations();
        toast.success(`Organization ${organization.name} deleted`);
    };

    handleSearch = (value: string) => {
        this.organizationsMeta.current_page = 1;
        this.filters.search = value;
        this.loadOrganizations();
    };

    buttonRenderer(routeName: string, property: string, icon: string) {
        return (organization: Object) => (
            <Button
                href={`${route('general', routeName)}?${this.getOrganizationSearch(organization)}`}
                as="a"
                inverted
                circular
                primary
                size="tiny"
                className="custom outline"
                target="_blank"
            >
                <Icon name={icon} /> {organization[property]}
            </Button>
        );
    }

    render() {
        return <Loader onLoad={this.load} render={this.renderContent} />;
    }

    renderContent = () => {
        return (
            <Page headerTitle basic onSearch={this.handleSearch} history={this.props.history}>
                <Column position="left">
                    <SettingsPanel title="Add New Organization">
                        <Form key={this.newOrganizationCounter} for={this.newOrganization} onSubmit={this.handleCreate}>
                            <PromptUnsaved for={this.newOrganization} />
                            <Field name="name" />

                            <SemanticForm.Field>
                                <FormButton
                                    type="submit"
                                    primary
                                    className="right floated"
                                    icon="plus"
                                    text="Add Organization"
                                    saving={this.creating}
                                />
                            </SemanticForm.Field>
                        </Form>
                    </SettingsPanel>
                    <SettingsPanel title="Bulk authentication">
                        <ConnectButton network="facebook-accounts" onDelete={() => {}} options={{}} />
                        <ConnectButton
                            network="googleplace"
                            onDelete={() => {}}
                            options={{ multiple_auth: 1 }}
                            disabled={true}
                        />
                    </SettingsPanel>
                </Column>

                <Column position="right">
                    <Sortable data={this.organizations || []}>
                        <Sortable.Column
                            title="Organization Name"
                            property="name"
                            render={organization => (
                                <a
                                    href={`${route('general', 'appSelection')}?${this.getOrganizationSearch(
                                        organization
                                    )}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {organization.name}
                                </a>
                            )}
                        />

                        <Sortable.Column
                            title="Location(s)"
                            property="place_count"
                            render={this.buttonRenderer('locations', 'place_count', 'map marker alternate')}
                        />

                        <Sortable.Column
                            title="User(s)"
                            property="user_count"
                            render={this.buttonRenderer('users', 'user_count', 'user')}
                        />

                        <Sortable.Column
                            title="Action(s)"
                            render={organization => (
                                <React.Fragment>
                                    <Modal.Confirm
                                        className="custom"
                                        title="Delete Organization"
                                        buttonText="Delete Organization"
                                        text={`Are you sure you want to delete ${organization.name}?`}
                                        onConfirm={() => this.handleDelete(organization)}
                                        trigger={<Sortable.Action title="Delete" icon="trash" />}
                                    />

                                    <OrganizationEditPartial organization={organization} />
                                </React.Fragment>
                            )}
                        />
                    </Sortable>

                    <Pagination
                        className="custom top-spacer"
                        activePage={this.organizationsMeta.current_page}
                        totalPages={this.organizationsMeta.last_page}
                        total={this.organizationsMeta.total}
                        onPageChange={this.handlePageChange}
                        loading={!this.organizations}
                        compact
                    />
                </Column>
            </Page>
        );
    };
}
