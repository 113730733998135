// @flow

import { observer } from 'mobx-react';
import * as React from 'react';
import { Form, Select, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { humanized, uniqueId, stringHelpers } from 'app/external/form-for-component-helpers';
import type { ComponentProps } from 'app/external/form-for';

import ErrorLabel from 'components/ErrorLabel';

export type Props = ComponentProps & {
    // $FlowFixMe
    options: string[] | { value: string, text: string }[],
    label?: string,
    placeholder?: string,
    required?: boolean,
    inlineLabel?: boolean,
    tooltip?: string,
    dropdown?: boolean,
};

@observer
export default class SelectField extends React.Component<Props> {
    input: ?HTMLInputElement;

    handleRef = (input: ?HTMLInputElement) => {
        this.input = input;
    };

    handleChange = (event: SyntheticEvent<HTMLInputElement>, meta: { value: string }) => {
        this.props.onChange(event, meta.value);
    };

    handleHiddenFocus = () => {
        const select = document.getElementById(uniqueId(this));
        if (select) select.focus();
    };

    componentDidMount() {
        this.props.onMount(this.input);
    }

    render() {
        const { tooltip, inlineLabel, error, touched, label, required, options, dropdown, ...rest } = this.props;
        delete rest.onMount;
        delete rest.onChange;

        const id = uniqueId(this);
        const humanizedName = humanized(this);

        const processedOptions = options.map(option =>
            typeof option === 'object'
                ? option
                : {
                      value: option,
                      text: stringHelpers.humanize(option),
                  }
        );

        const Tooltip = tooltip ? (
            <Popup trigger={<Icon name="info circle" />} content={tooltip} inverted position="right center" />
        ) : null;

        return (
            <Form.Field error={touched && !!error} required={required} style={{ position: 'relative' }}>
                {!inlineLabel && (
                    <label htmlFor={id}>
                        {`${label || humanizedName}`}
                        {Tooltip}
                    </label>
                )}

                {!rest.value && required && (
                    <input
                        ref={this.handleRef}
                        type="text"
                        style={{ opacity: '0', height: 0, padding: 0, position: 'absolute', bottom: 0 }}
                        tabIndex="-1"
                        onFocus={this.handleHiddenFocus}
                        required
                    />
                )}
                {dropdown ? (
                    <Dropdown
                        id={id}
                        onChange={this.handleChange}
                        error={touched && !!error}
                        options={processedOptions}
                        {...rest}
                    />
                ) : (
                    <Select
                        id={id}
                        onChange={this.handleChange}
                        error={touched && !!error}
                        options={processedOptions}
                        {...rest}
                    />
                )}

                <ErrorLabel error={error} touched={touched} />
            </Form.Field>
        );
    }
}
