// @flow

import t from 'typy';

export default function httpResponseErrors(error: Object): string {
    const genericErrorMessage = 'Something went wrong';

    if (t(error, 'message').isDefined && !t(error, 'response.data').isDefined) return error.message;
    if (!t(error, 'response.data').isDefined) return genericErrorMessage;

    const errorsObject: Object = error.response.data.errors || error.response.data;
    const errorsObjectFirstKey: string = Object.keys(errorsObject)[0];
    const errorString: string = errorsObject[errorsObjectFirstKey].toString();

    return errorString.replace(/data\./g, '');
}
