// @flow

import * as React from 'react';
import { observer } from 'mobx-react';

import Review from 'models/Review';

import reviewStore from 'reputation/stores/review';

import ReviewMain from 'reputation/components/Review/Main';

type Props = {
    review: Review,
    style?: Object,
    inverted?: boolean,
    ellipsisBreakpoint: number,
};

@observer
export default class ReviewCard extends React.Component<Props> {
    handleClick = () => {
        reviewStore.select(this.props.review);
    };

    render() {
        const { review, style, ellipsisBreakpoint, inverted } = this.props;

        const classes = ['list-item three-lines main-first icon clearfix'];
        if (review === reviewStore.review) classes.push('active');
        if (inverted) classes.push('inverted');

        return (
            <div className={classes.join(' ')} onClick={this.handleClick} style={style}>
                <ReviewMain review={review} ellipsisBreakpoint={ellipsisBreakpoint} />
            </div>
        );
    }
}
