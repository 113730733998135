// @flow

import { observer } from 'mobx-react';
import * as React from 'react';

import videoStore from 'video/stores/video';

import SidebarButton, { type SidebarButtonRender } from 'components/Sidebar/Button';
import Panel from 'components/Panel';
import VideoThumbnail from 'video/components/Video/Thumbnail';

type Props = {};

@observer
export default class SelectedVideoPartial extends React.Component<Props> {
    render() {
        return <SidebarButton sidebar="video.videos" render={this.renderContent} />;
    }

    renderContent = ({ onClick }: SidebarButtonRender) => {
        const video = videoStore.video;
        if (!video) return null;

        return (
            <div className="mobile-item-container" onClick={onClick}>
                <Panel title="Selected Video" position="left">
                    <div id="videos-menu-toggle" className="current-selected-item panel shadow">
                        <div className="items-menu-sidebar">
                            <div className="toggle-icon">
                                <i className="arrow left icon" />
                            </div>
                        </div>

                        <div className="list-item active two-lines main-first icon clearfix">
                            <VideoThumbnail video={video} />
                            <a className="main" tabIndex="0" {...{ href: '#' }}>
                                <span className="main-text">{video.title}</span>
                                <span className="bottom-text">{video.user_name}</span>
                            </a>
                        </div>
                    </div>
                </Panel>
            </div>
        );
    };
}
