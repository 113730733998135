// @flow

import * as React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Icon } from 'semantic-ui-react';

import SidebarButton from 'components/Sidebar/Button';

type Props = {
    icon: string,
    sidebar?: string,
    onToggleSearchOnMobile?: Function,
};

@observer
export default class HeaderUserButton extends React.Component<Props> {
    @observable active: boolean = false;

    @action.bound
    toggleActive() {
        this.active = !this.active;
        // $FlowFixMe
        this.props.onToggleSearchOnMobile(this.active);
    }

    render() {
        const { icon, sidebar, onToggleSearchOnMobile, ...props } = this.props;
        return (
            <React.Fragment>
                {sidebar && (
                    <SidebarButton
                        sidebar={sidebar}
                        render={({ active, onClick }) => (
                            <Button
                                type="button"
                                circular
                                icon
                                onClick={onClick}
                                active={active}
                                {...props}
                                className="custom outline"
                            >
                                <Icon name={icon} />
                            </Button>
                        )}
                    />
                )}
                {onToggleSearchOnMobile && (
                    <Button
                        type="button"
                        circular
                        icon
                        onClick={this.toggleActive}
                        active={this.active}
                        {...props}
                        className="custom outline"
                    >
                        <Icon name={icon} />
                    </Button>
                )}
            </React.Fragment>
        );
    }
}
