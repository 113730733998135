// @flow

import { action, computed, observable } from 'mobx';

import placeStore from 'stores/place';

import PhotoLeaderboard from 'models/PhotoLeaderboard';
import Filters from 'models/Filters';

class PhotoLeaderboardStore {
    @observable filters: ?Filters;
    @observable leaderboards: ?PhotoLeaderboard;
    @observable loading: boolean = false;
    @observable sortDirection: string = 'desc';
    @observable sortedBy: string = 'submissions';

    @action
    setPhotoLeaderboards(leaderboards: PhotoLeaderboard) {
        this.leaderboards = leaderboards;
    }

    @action
    resetState() {
        this.leaderboards = null;
    }

    @action
    startLoading() {
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
    }

    @computed
    get sortedPhotoLeaderboards(): ?PhotoLeaderboard {
        return this.leaderboards;
    }

    get queryParams(): Object {
        let params = {};

        params['with-totals'] = 1;

        if (!this.filters) {
            return params;
        }

        params.order_by = this.sortedBy;
        params.order = this.sortDirection;

        if (this.filters.startDate) {
            params.start_date = this.filters.startDate;
        }

        if (this.filters.endDate) {
            params.end_date = this.filters.endDate;
        }

        return params;
    }

    async load(options: Object = {}) {
        if (this.leaderboards) this.resetState();

        this.startLoading();

        try {
            this.setPhotoLeaderboards(await placeStore.place.PhotoLeaderboards(options));
        } finally {
            this.stopLoading();
        }
    }

    async filter(filters: Filters) {
        this.filters = filters;

        this.load({ queryParams: this.queryParams, ignoreCache: true });
    }

    @action
    sort(property: string) {
        if (property === this.sortedBy) {
            this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
        } else {
            this.sortedBy = property;
            this.sortDirection = 'asc';
        }

        this.load({ queryParams: this.queryParams, ignoreCache: true });
    }

    @action
    getExportLink(filters: Filters): string {
        if (this.filters) this.filters = undefined;
        this.filters = filters;

        const params = new URLSearchParams({
            xls: '1',
            ...this.queryParams,
        });

        return `/places/${placeStore.place.id}/cfleaderboard?${params.toString()}`;
    }
}

export default new PhotoLeaderboardStore();
