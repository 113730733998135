// @flow

import { action, observable } from 'mobx';

import userInfoStore from 'stores/userInfo';
import sidebarStore from 'stores/sidebar';

import NotificationSettings from 'models/NotificationSettings';

class NotificationSettingsStore {
    @observable notificationSettings: NotificationSettings;

    @action
    setNotificationSettings(notificationSettings: NotificationSettings) {
        notificationSettings.set({ user_id: userInfoStore.user.id });
        notificationSettings.setPlaceNotifications();
        notificationSettings.memorizeChanges();
        this.notificationSettings = notificationSettings;
    }

    @action
    select(notificationSettings: NotificationSettings) {
        this.notificationSettings = notificationSettings;
        sidebarStore.close();
    }

    @action
    resetState() {
        this.notificationSettings = new NotificationSettings();
    }

    async load() {
        if (this.notificationSettings) this.resetState();
        this.setNotificationSettings(await userInfoStore.user.notificationSettings());
    }
}

export default new NotificationSettingsStore();
