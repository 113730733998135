// @flow

import Model from 'models/Model';

export default class ReputationLeaderboard extends Model {
    path = 'raleaderboard';

    user_id: number;
    user_img: string;
    user_name: string;
    place_name: string;
    invites_sent: number;
    would_recommend: number;
    would_not_recommend: number;
    no_response: number;
}
