import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    getAMPMFromDate,
    getHoursFromDate,
    getMinuteOptionFromDate,
    hourOptions,
    minuteOptions,
    periodOptions,
} from './TimeSelector';
import { Icon, Select } from 'semantic-ui-react';
import Toggle, { ToggleOption } from '../Toggle';
import moment from 'moment-timezone';

const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

const TimePicker = ({ value = new Date(), onChange }) => {
    const [minutes, setMinutes] = useState(getMinuteOptionFromDate(value));
    const [hours, setHours] = useState(getHoursFromDate(value));
    const [amPm, setAmPm] = useState(getAMPMFromDate(value));

    const onTimeChange = useCallback(
        ({ hours, minutes, amPm }) => {
            if (hours && minutes && amPm) {
                const hours24 = amPm.value === 'AM' ? hours.value : hours.value + 12;
                const newDate = value ? new Date(value.getTime()) : new Date();
                newDate.setHours(hours24);
                newDate.setMinutes(minutes.value);
                newDate.setSeconds(0);
                onChange(newDate);
            }
        },
        [onChange, value]
    );

    const onChangeMinutes = useCallback(
        (_, { value, options }) => {
            const minutes = { value, text: options[value].text };
            setMinutes(minutes);
            onTimeChange({ minutes, hours, amPm });
        },
        [onTimeChange, hours, amPm]
    );

    const onChangeHours = useCallback(
        (_, { value, options }) => {
            const val = value === 0 ? 11 : value - 1;
            const hours = { value, text: options[val].text };
            setHours(hours);
            onTimeChange({ minutes, hours, amPm });
        },
        [onTimeChange, minutes, amPm]
    );

    const onChangePeriod = useCallback(
        newPeriod => {
            setAmPm({ value: newPeriod });
            onTimeChange({ minutes, hours, amPm: { value: newPeriod } });
        },
        [onTimeChange, minutes, hours]
    );

    return (
        <div className="kui-timepicker">
            <div className="kui-timepicker-tz">
                <Icon name="calendar outline" />
                {moment(value).tz(timeZoneString).format('z')}
            </div>
            <div className="kui-timepicker__select">
                <Select
                    id="Hour"
                    value={hours}
                    text={hours.text}
                    options={hourOptions}
                    onChange={onChangeHours}
                    fluid
                />
            </div>

            <span className="kui-timepicker-seperator">:</span>

            <div className="kui-timepicker__select">
                <Select
                    id="Minute"
                    value={minutes}
                    text={minutes.text}
                    options={minuteOptions}
                    onChange={onChangeMinutes}
                    fluid
                />
            </div>

            <div className="kui-timepicker__select">
                <Toggle onChange={onChangePeriod} value={amPm.value}>
                    {periodOptions.map(({ value, label }) => (
                        <ToggleOption key={value} value={value} label={label} />
                    ))}
                </Toggle>
            </div>
        </div>
    );
};

TimePicker.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default TimePicker;
